/* <link rel="import" href="../../bower_components/paper-menu/paper-submenu.html"> */
/* <link rel="import" href="../../bower_components/moment-element/moment-with-locales-import.html"> */
/*
  FIXME(polymer-modulizer): the above comments were extracted
  from HTML and may be out of place here. Review them and
  then delete this comment!
*/
import '@polymer/polymer/polymer-legacy.js';
import moment from 'moment';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-list/iron-list.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-dialog-scrollable/paper-dialog-scrollable.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';

import '@polymer/paper-badge/paper-badge.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-toast/paper-toast.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-input/paper-textarea.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-icon-button/paper-icon-button.js';

import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-item/paper-item-body.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import '@polymer/paper-dropdown-menu/paper-dropdown-menu.js';
import '@polymer/paper-listbox/paper-listbox.js';
import './wo-detail.js';
import '../app-security-behavior.js';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';


class FaultAlertDetail extends mixinBehaviors([AmiBehaviors.SecurityBehavior], PolymerElement) {

    static get is() { return 'fault-alert-detail'; }

    static get properties() {
        return {
            app:Object,

            shutdownId: {
                type: Number,
                observer: 'shutdownIdChanged'
            },

            selected: {
                type: Number,
                value: 0
            },

            url: {
                type: String
            },
            checkTransformerUrl: String,

            isLoading: {
                type: Boolean,
                value: false
            },

            sendAlertUrl: {
                type: String
            },

            username: {
                type: String
            },

            addTransfromerToAlertUrl: {
                type: String
            },
            deleteTransformerToAlertUrl: String,
             alert: {
                type: Object
            },

            info: {
                type: Object,
                notify: true,
            },
            isChangeWorkOrderRequired: {
                type: Boolean,
                value: true
            },
            selectedTransformerInfo: Object, 
            selectedFeederInfo: Object, 
            clashEvents: [],
            isSelectICPsToAdd: Boolean,
            woDto: {
                type: Object
            },
            eventInfo: Object,
            addAlertUrl: {
                    type: String
            },
            multipleTransformers: String
        }
    }

    ready() {
        super.ready();
        document.addEventListener("add-to-alert", (e) => {
            
            if (this.shutdownId) {
                if (e.detail.code){
                    this.selectedTransformerInfo = e.detail;
                    this.set('checkTransformerUrl', `/api/fa/transformer/${e.detail.code}/mergecheck`)
                    this.$.ajaxAddTransfromerCheck.generateRequest();
                }

                if (e.detail.feederId){
                    this.selectedFeederInfo = e.detail;
                    //Open Confirm Dialog
                    this.showAddFeederConfirmation();
                    
                    // this.set('checkTransformerUrl', `/api/fa/feeder/${e.detail.feeder}/mergecheck`)
                    // this.$.ajaxAddFeederCheck.generateRequest();
                }
                
                // this.set('addTransfromerToAlertUrl', `/api/fa/${this.shutdownId}/transformer/add/${this.app.username}`);
                // this.$.ajaxAddTransformer.body = e.detail;
                // this.$.ajaxAddTransformer.generateRequest();
            }
        });

        document.addEventListener("remove-from-alert", (e) => {
            if (this.shutdownId) {
                if (e.detail.code){

                    var data = {
                        "code": e.detail.code
                    };
                    this._onDeleteTransformerByCode(data);
                }
            }
            
        });
       
    }

    static get template() {
        return html`
          <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>
  
        <style>
        :host {
            display: block;
            width: 100%;
            height: 100%;
            --paper-input-container-input: {
                font-size: 13px;
            }
            ;
        }
        
        paper-progress {
            width: 100%;
            --paper-progress-container-color: white;
            --paper-progress-active-color: var(--paper-orange-500);
            --paper-progress-secondary-color: var(--paper-orange-100);
        }
        
        .container {
            padding: 10px;
        }
        
     
        
        paper-dialog.size-position {
            position: fixed;
            top: 16px;
            right: 16px;
            width: 400px;
            height: 600px;
            overflow: auto;
        }
        
        paper-dialog.size-position-small {
            position: fixed;
            top: 16px;
            right: 16px;
            width: 300px;
            height: 200px;
            overflow: auto;
        }
        
        .sublist {
            padding-left: 20px;
        }
        
        iron-icon.active {
            fill: #8BC34A;
        }
        
        iron-icon.warning {
            fill: #ff4081;
        }
        
        iron-icon.live {
            fill: #F44336;
            margin-left: 10px;
        }
        
        app-toolbar.tall {
            --app-toolbar-background: #727272;
            --app-toolbar-title: {
                /*font-style: italic;*/
                /*font-weight: bold;*/
            }
            ;
        }
        .bold{
            font-weight: bold;
        }
        app-toolbar {
            border-bottom: solid 1px #d3d3d3;
            --app-toolbar-background: transparent;
            --app-toolbar-color: rgb(59, 120, 231);
            font-size: 13px;
        }
        
        app-toolbar .title {
            margin-left: 0px;
            font-size: 20px;
        }

         #wodetail {
            position: fixed;
            left: 0px;
            bottom: 0px;
            background: white;
            width: 100%;
            height: 500px;
            max-height: 1000px;
            overflow: auto;
        }
        #dlgAddTransformer {
            position: fixed;
            /*right: 0px;*/
            top: 16px;
        }

       

       #dlgAddTransformer {
            --paper-dialog-scrollable: {
            box-sizing: border-box;
            width: 360px;
            height: 400px;
            }
        }

         #dlgAddWorkOrder {
            /*position: fixed;*/
            /*right: 0px;*/
            /*top: 16px;*/
             width: 600px;
        }
        .spacer{
            width: 16px;
        }
        .addWo{
            color: orange;
        }
        </style>



        <app-header-layout has-scrolling-region="true">
        <app-header scroll-target="content" slot="header" fixed>
            <app-toolbar class="tall" sticky>
                <template is="dom-if" if="{{isEventCreateEditAllowed(app)}}">
                <paper-icon-button icon="announcement" alt="New Event Advice" title="New Event Advice" on-click="onNewNoteClick" ></paper-icon-button>
                <!-- <paper-icon-button icon="youtube-searched-for" alt="Show Transformers" title="Show Transformers" on-click="onShowTransformersClick"></paper-icon-button> -->
                <paper-icon-button icon="offline-pin" alt="Close Event" title="Close Event" on-click="onCloseAlertClick"></paper-icon-button>
                <paper-button on-click="_onAddWorkOrderClick" class="addWo">
                    <iron-icon icon="add"></iron-icon>
                    Work Order
                </paper-button>
                <paper-button on-click="_onMoveToNewEvent">
                    <iron-icon icon="launch"></iron-icon>
                    New Event
                </paper-button>
                </template>
                <!--<paper-icon-button icon="add" alt="Add Work Order" title="Add Work Order" on-click="_onAddWorkOrderClick"></paper-icon-button>-->
                <div class="flex"></div>
                <paper-icon-button icon="close" on-click="close" alt="Close" title="Close"></paper-icon-button>
               
                
            </app-toolbar>
            <app-toolbar sticky> 
                <div class="middle title">Event - <span>{{info.id}}</span></div>
            </app-toolbar>
            <paper-tabs sticky bottom-item selected="{{selected}}" class="bottom self-end" style="width: 300px;">
                    <paper-tab>Detail</paper-tab>
                    <paper-tab>WO</paper-tab>
                    <paper-tab>ICPs</paper-tab>
                    <paper-tab>Restored</paper-tab>
                    <paper-tab>Calls</paper-tab>
                    <paper-tab>History</paper-tab>
                </paper-tabs>
                
        </app-header>
           
            <div class="content">
                <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>
                <iron-pages selected="{{selected}}">
                    <section>
                        <div class="container">
                            <template is="dom-if" if="{{info.isClosed}}">
                                <div class="horizontal layout">
                                    <div>
                                        <paper-input label="Closed" value="{{formatDate(info.closedDate.Time)}}" readonly=""></paper-input>
                                    </div>
                                    <div class="flex"></div>
                                    <div>
                                        <paper-input label="Closed By" value="{{info.closedBy}}" readonly=""></paper-input>
                                    </div>
                                </div>
                            </template>
                            <div class="horizontal layout">
                                <div>
                                    <paper-input label="Created" value="{{formatDate(info.createdDate)}}" readonly=""></paper-input>
                                </div>
                                <div class="flex"></div>
                                <paper-input label="Created by" value="{{info.createdBy}}" readonly=""></paper-input>
                            </div>
                            <div class="horizontal layout">
                                <div>
                                    <paper-input label="Affected Customers" value="{{info.affectedCustomers}}" readonly=""></paper-input>
                                </div>
                                <div class="flex"></div>
                                <div>
                                    <paper-input label="Icps Out #" value="{{info.totalNotRestored}}" readonly=""></paper-input>
                                </div>
                                <div class="flex"></div>
                                <div>
                                    <paper-input label="Restored #" value="{{info.totalRestored}}" readonly=""></paper-input>
                                </div>
                            </div>
                            <div class="horizontal layout">
                                <div>
                                    <paper-input label="Total Emails" value="{{info.totalEmailSent}}" readonly=""></paper-input>
                                </div>
                                <div class="flex"></div>
                                <div>
                                    <paper-input label="Total SMS" value="{{info.totalSmsSent}}" readonly=""></paper-input>
                                </div>
                                
                            </div>
                            <paper-input label="Description" value="{{info.description}}" readonly="">
                                <template is="dom-if" if="{{isEventCreateEditAllowed(app)}}">
                                <paper-icon-button slot="suffix" icon="create" on-click="_onEventDescriptionEditClick"></paper-icon-button>
                                </template>
                            </paper-input>
                            <div class="horizontal layout">
                                <div>
                                    <h4>Medical Dependents</h4></div>
                                <div style="margin-left: 20px; margin-top: 20px;">
                                    <paper-badge label="[[info.medicallyDependentCount]]"></paper-badge>
                                </div>
                            </div>
                            <template is="dom-repeat" items="[[info.medicallyDependents]]">
                                <paper-item role="menuitemcheckbox">
                                    <paper-item-body three-line="">
                                        <div><span>[[item.icp]]</span>
                                            <iron-icon icon="communication:portable-wifi-off" hidden\$="[[item.isSmartMeter]]"></iron-icon>
                                            <iron-icon id="icnOutage" icon="assignment-late" hidden\$="[[!item.isLiveOutage]]" class="live"></iron-icon>
                                        </div>
                                        <div secondary=""><span>[[item.customerName]]</span> (
                                            <span>[[item.customerNo]]</span>)
                                        </div>
                                        <div secondary="">
                                            <iron-icon id="icnMobile" icon="communication:message" class\$="[[computeClass(item.mobileNo)]]"></iron-icon>
                                            <iron-icon icon="communication:email" class\$="[[computeClass(item.email)]]"></iron-icon>
                                            <iron-icon icon="communication:phone" class\$="[[computeClass(item.phoneNo)]]"></iron-icon>
                                        </div>
                                    </paper-item-body>
                                    <paper-icon-button icon="file-upload" on-click="onIcpClicked"></paper-icon-button>
                                </paper-item>
                            </template>

                            <div class="horizontal layout">
                                <div>
                                    <h4>VIP Key Customers</h4></div>
                                <div style="margin-left: 20px; margin-top: 20px;">
                                    <paper-badge label="[[_getTotalVipCustomers(info.vipCustomers)]]"></paper-badge>
                                </div>
                            </div>
                            <template is="dom-repeat" items="[[info.vipCustomers]]">
                                <paper-item role="menuitemcheckbox">
                                    <paper-item-body three-line="">
                                        <div><span>[[item.icp]]</span>
                                            <iron-icon icon="communication:portable-wifi-off" hidden\$="[[item.isSmartMeter]]"></iron-icon>
                                            <iron-icon id="icnOutage" icon="assignment-late" hidden\$="[[!item.isLiveOutage]]" class="live"></iron-icon>
                                        </div>
                                        <div secondary=""><span>[[item.customerName]]</span> (
                                            <span>[[item.customerNo]]</span>)
                                        </div>
                                        <div secondary="">
                                            <iron-icon id="icnMobile" icon="communication:message" class\$="[[computeClass(item.mobileNo)]]"></iron-icon>
                                            <iron-icon icon="communication:email" class\$="[[computeClass(item.email)]]"></iron-icon>
                                            <iron-icon icon="communication:phone" class\$="[[computeClass(item.phoneNo)]]"></iron-icon>
                                        </div>
                                    </paper-item-body>
                                    <paper-icon-button icon="file-upload" on-click="onIcpClicked"></paper-icon-button>
                                </paper-item>
                            </template>
                        </div>
                    </section>
                    <!--Work Orders Section-->
                    <section>
                        <template is="dom-repeat" items="[[info.workOrders]]">
                            <paper-card style="width: 100%; margin: 5px;">
                                <div class="card-content search-card">
                                    <div class="horizontal layout ">
                                         <div class="flex">#: <span>{{item.No}}</span></div>
                                         <div>Status: <span class="bold">{{item.Status}}</span></div>
                                    </div>
                                    <div>Assigned: <span>{{item.ResourceName}}</span></div>
                                           
                                    <div>{{item.Description}}</div>
                                            
                                </div>
                                <div class="card-actions">
                                    <div class="horizontal layout">
                                        <paper-icon-button icon="file-upload" on-click="_onOpenWorkOrderClicked"></paper-icon-button>
                                        <paper-button id="btnSubOrder" hidden\$="{{!hasParentLink(item.ParentNo)}}">
                                            <iron-icon icon="link"></iron-icon>
                                           {{item.ParentNo}}
                                        </paper-button>
                                        <paper-tooltip for="btnSubOrder">Create a linked Work Order</paper-tooltip>
                                    </div>
                                </div>
                            </paper-card>
                        </template>

                    </section>
                    <section>
                        <div>
                            <paper-button on-click="_onAddTransformersClick" class="addWo">
                                <iron-icon icon="add"></iron-icon>
                                Transformers
                            </paper-button>
                            <paper-button on-click="_onAddIcpsClick" class="addWo">
                                <iron-icon icon="add"></iron-icon>
                                Icps
                            </paper-button>
                        </div>
                            <template is="dom-repeat" items="[[info.feeders]]">
                                    <paper-listbox>
                                    <paper-listbox>
                                            <paper-item class="menu-trigger">
                                                <!--<paper-checkbox></paper-checkbox>-->
                                                <paper-item-body>
                                                    <div><span>[[item.feederCode]]</span> - <span>[[item.feederName]]</span></div>
                                                </paper-item-body>
                                                <paper-icon-button icon="offline-pin" on-click="onMarkClosedFeederClicked" disabled="[[info.isClosed]]" hidden\$="{{!isEventCreateEditAllowed(app)}}"></paper-icon-button>
                                            </paper-item>
                                            <paper-listbox class="menu-content sublist">
                                         <template is="dom-repeat" items="[[item.transformers]]" initial-count="20">
                                    
                                        <paper-listbox>
                                            <paper-item class="menu-trigger">
                                                <!--<paper-checkbox></paper-checkbox>-->
                                                <paper-item-body>
                                                    <div>(<span>[[item.feederCode]]</span>) - <span>[[item.transformerNo]]</span> </div>
                                                </paper-item-body>
                                                <paper-icon-button icon="offline-pin" on-click="onMarkClosedTransformerClicked" disabled="[[info.isClosed]]" hidden\$="{{!isEventCreateEditAllowed(app)}}"></paper-icon-button>
                                                <!-- <paper-icon-button icon="delete" on-click="onDeleteTransformerClicked" disabled="[[info.isClosed]]" hidden\$="{{!isEventCreateEditAllowed(app)}}"></paper-icon-button> -->
                                            </paper-item>
                                            <paper-listbox class="menu-content sublist">
                                                <template is="dom-repeat" items="[[item.icps]]" initial-count="20">
                                                    <paper-item role="menuitemcheckbox">
                                                        <paper-checkbox disabled="[[computeDisabled(item.mobileNo,item.email)]]" checked="{{item.isSelected}}"></paper-checkbox>
                                                        <paper-item-body three-line="">
                                                            <div>
                                                                <div><span>[[item.icp]]</span>
                                                                    <iron-icon icon="communication:portable-wifi-off" hidden\$="[[item.isSmartMeter]]"></iron-icon>
                                                                    <iron-icon icon="assignment-late" hidden\$="[[!item.isLiveOutage]]" class="live"></iron-icon>
                                                                </div>
                                                            </div>
                                                            <div secondary=""><span>[[item.customerName]]</span> (<span>[[item.customerNo]]</span>)
                                                            </div>
                                                            <div secondary="">
                                                                <template is="dom-if" if="[[item.isAlertable]]">
                                                                    <iron-icon icon="settings-cell" alt="Has Subscriber"></iron-icon>
                                                                </template>
                                                                <!--<iron-icon id="icnMobileTrans" icon="communication:message" class\$="[[computeClass(item.mobileNo)]]"></iron-icon>
                                                                <iron-icon icon="settings-cell" class\$="[[computeClass(item.email)]]"></iron-icon>
                                                                <iron-icon icon="communication:phone" class\$="[[computeClass(item.phoneNo)]]"></iron-icon>-->
                                                                <!--<paper-button on-click="_onAddIcpWorkOrderClick" class="addWo">
                                                                    <iron-icon icon="add"></iron-icon>
                                                                    Work Order
                                                                </paper-button>-->
                                                            </div>
                                                        </paper-item-body>
                                                        <template is="dom-if" if="[[item.isMedicallyDependent]]">
                                                            <iron-icon icon="warning" alt="Medically Dependent" class="warning"></iron-icon>
                                                        </template>
                                                        <paper-icon-button icon="file-upload" on-click="onIcpClicked"></paper-icon-button>
                                                    </paper-item>
                                                </template>
                                            </paper-listbox>
                                        </paper-listbox>
                                    
                                </template>
                                
                            </paper-listbox></paper-listbox></paper-listbox></template>
                    </section>

                    <!-- Restored Feeders -->
                    <section>
                        <template is="dom-repeat" items="[[info.restoredFeeders]]">
                                <paper-listbox>
                                <paper-listbox>
                                        <paper-item class="menu-trigger">
                                            <!--<paper-checkbox></paper-checkbox>-->
                                            <paper-item-body>
                                                <div><span>[[item.feederCode]]</span> - <span>[[item.feederName]]</span></div>
                                            </paper-item-body>
                                            
                                        </paper-item>
                                        <paper-listbox class="menu-content sublist">
                                     <template is="dom-repeat" items="[[item.transformers]]" initial-count="20">
                                
                                    <paper-listbox>
                                        <paper-item class="menu-trigger">
                                            <!--<paper-checkbox></paper-checkbox>-->
                                            <paper-item-body>
                                                <div>(<span>[[item.feederCode]]</span>) - <span>[[item.transformerNo]]</span> </div>
                                            </paper-item-body>
                                            <!-- <paper-icon-button icon="offline-pin" on-click="onMarkClosedTransformerClicked" disabled="[[info.isClosed]]" hidden\$="{{!isEventCreateEditAllowed(app)}}"></paper-icon-button>
                                            <paper-icon-button icon="delete" on-click="onDeleteTransformerClicked" disabled="[[info.isClosed]]" hidden\$="{{!isEventCreateEditAllowed(app)}}"></paper-icon-button> -->
                                        </paper-item>
                                        <paper-listbox class="menu-content sublist">
                                            <template is="dom-repeat" items="[[item.icps]]" initial-count="20">
                                                <paper-item role="menuitemcheckbox">
                                                    <!-- <paper-checkbox disabled="[[computeDisabled(item.mobileNo,item.email)]]" checked="{{item.isSelected}}"></paper-checkbox> -->
                                                    <paper-item-body three-line="">
                                                        <div>
                                                            <div><span>[[item.icp]]</span>
                                                                <iron-icon icon="communication:portable-wifi-off" hidden\$="[[item.isSmartMeter]]"></iron-icon>
                                                                <iron-icon icon="assignment-late" hidden\$="[[!item.isLiveOutage]]" class="live"></iron-icon>
                                                            </div>
                                                        </div>
                                                        <div secondary=""><span>[[item.customerName]]</span> (<span>[[item.customerNo]]</span>)
                                                        </div>
                                                        <div secondary="">
                                                            <template is="dom-if" if="[[item.isAlertable]]">
                                                                <iron-icon icon="settings-cell" alt="Has Subscriber"></iron-icon>
                                                            </template>
                                                        
                                                        </div>
                                                    </paper-item-body>
                                                    <template is="dom-if" if="[[item.isMedicallyDependent]]">
                                                        <iron-icon icon="warning" alt="Medically Dependent" class="warning"></iron-icon>
                                                    </template>
                                                    <paper-icon-button icon="file-upload" on-click="onIcpClicked"></paper-icon-button>
                                                </paper-item>
                                            </template>
                                        </paper-listbox>
                                    </paper-listbox>
                                
                            </template>
                            </paper-listbox>
                        </paper-listbox></paper-listbox></template>
                </section>

                    <!--Calls Section-->
                    <section>
                        <template is="dom-repeat" items="[[info.calls]]">
                            <paper-card style="width: 100%; margin: 5px;">
                                <div class="card-content search-card">
                                    <div class="horizontal layout justified">
                                         <div>#: <span>{{item.callId}}</span></div>
                                        <div> {{formatDate(item.dateRecorded)}}</div>
                                         <div>Status: <span class="bold">{{item.status}}</span></div>
                                    </div>

                                    <div>ICP: <span>{{item.icp}}</span></div>
                                    <div><span>Name: </span>{{item.customerName}} <span>({{item.customerNo}})</span></div>
                                    <div>Mobile: <span>{{item.mobileNo}}</span></div>
                                    <div>Email: <span>{{item.email}}</span></div>
                                    <div class="horizontal layout justified">
                                        <div>Address: <span>{{item.address}}</span></div>
                                    </div>
                                    <div class="horizontal layout justified">
                                        <div>Premises: <span>{{item.premisesType}}</span></div>
                                        <div>Relationship: <span>{{item.relationship}}</span></div>

                                    </div>
                                    <div>{{item.callTypeDescription}}</div>
                                    <div>Covid or disease question confirmed: <span class="bold">{{item.isCovidQuestionConfirmed}}</span></div>
                                    <div>{{item.details}}</div>
                                  
                                <div class="card-actions">
                                    <div class="horizontal layout">
                                        <paper-icon-button icon="file-upload" on-click="onIcpClicked"></paper-icon-button>
                                    </div>
                                </div>
                            </paper-card>
                        </template>

                        <!-- Reported Faults -->
                   
                        <template is="dom-repeat" items="[[info.reportedFaults]]">
                            <paper-card style="width: 100%; margin: 5px;">
                                <div class="card-content search-card">
                                    <div class="horizontal layout justified">
                                         <div>#: <span>{{item.id}}</span></div>
                                        <div> {{formatDate(item.dateReported)}}</div>
                                         <div>Status: <span class="bold">Reported Online</span></div>
                                    </div>

                                    <div>Fault Type: <span>{{item.faultType}}</span></div>
                                    <div><span>Name: </span>{{item.fullName}} </div>
                                    <div>Mobile: <span>{{item.phone}}</span></div>
                                    <div>Email: <span>{{item.email}}</span></div>
                                    <div class="horizontal layout justified">
                                        <div>Address: <span>{{item.address}}</span></div>
                                        <!-- <div >Suburb: <span>{{item.suburb}}</span></div> -->

                                    </div>
                                    
                                    <div style="margin-top: 10px;">{{item.commentReported}}</div>
                                            
                                </div>
                                <div class="card-actions">
                                    <div class="horizontal layout">
                                        <paper-icon-button icon="file-upload" on-click="onFaultReportedClicked"></paper-icon-button>
                                    </div>
                                </div>
                            </paper-card>
                        </template>

                    </section>

                    <section style="padding-right: 7px">
                        <template is="dom-repeat" items="[[info.historyEvents]]">
                            <paper-card style="width: 100%; margin: 5px;">
                                <div class="card-content">
                                    <div class="horizontal justified layout">
                                        <div>[[formatDate(item.eventDate)]]</div>
                                        <div>Type: <span>[[item.subject]]</span></div>
                                        <div>User: <span>[[item.username]]</span></div>
                                    </div>
                                    <div class="horizontal left-justified layout">
                                        [[item.description]]
                                    </div>
                                </div>
                            </paper-card>
                        </template>
                    </section>
                </iron-pages>
            </div>
        </app-header-layout>
        <!--Alert-->
        <paper-dialog id="dlg" class="size-position">
            <h2>Send Alert</h2>
            <paper-textarea rows="3" label="SMS Alert" value="{{alert.sms}}"></paper-textarea>
            <paper-textarea rows="10" label="Email Alert" value="{{alert.email}}"></paper-textarea>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onSendAdvice">Notify</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgClose" class="size-position-small">
            <h2>Are You Sure?</h2>
            <p>Are you sure you want to close this Event?</p>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onCloseConfirmClick">Confirm</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgClashEvents" class="size-position">
            <h2>Add Transformer Clash</h2>
            <p>Transformer exists in the following Active Events, do you want to remove them from the Events into this one?</p>
            <template is="dom-repeat" items="[[clashEvents]]">
                <span>[[item]]</span>
            </template>
            <p>No Initial Event Message will be sent out</p>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="_onAcceptTransformerEventClash">Accept</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgEditEvent" class="size-position">
            <h2>Edit Event</h2>
            <paper-textarea rows="2" label="Description" value="{{info.description}}"></paper-textarea>
            <paper-checkbox class="styled" checked="{{isChangeWorkOrderRequired}}">
                Change Work Order Description also?
            </paper-checkbox>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="_onEditSave">Save</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgAddTransformer">
            <h2>Add Transformer</h2>
            <p>Are you sure you want add a Transformer to this Event?</p>
            <p>This will send out the Initial Event Message to all connected ICPs</p>
            <paper-checkbox class="styled" checked="{{isSelectICPsToAdd}}">
                Select ICPs to Add
            </paper-checkbox>
            <paper-dialog-scrollable>
                <div hidden\$="{{!isSelectICPsToAdd}}">
                <template is="dom-repeat" items="[[currentOutageIcps]]">
                    <paper-item role="menuitemcheckbox">
                        <paper-checkbox checked="{{item.isSelected}}"></paper-checkbox>
                        <paper-item-body three-line="">
                            <div>
                                <div><span>[[item.icp]]</span>
                                    <iron-icon icon="communication:portable-wifi-off" hidden\$="[[item.isSmartMeter]]"></iron-icon>
                                    
                                </div>
                            </div>
                            <div secondary=""><span>[[item.customerName]]</span> (<span>[[item.customerNo]]</span>)
                            </div>
                            <div secondary="">
                                <paper-icon-button icon="file-upload" on-click="onIcpClicked"></paper-icon-button>
                                 <iron-icon icon="assignment-late" hidden\$="[[!item.isLiveOutage]]" class="live"></iron-icon>
                            </div>
                        </paper-item-body>
                        <template is="dom-if" if="[[item.isMedicallyDependent]]">
                            <iron-icon icon="warning" alt="Medically Dependent" class="warning"></iron-icon>
                        </template>
                        
                    </paper-item>
                </template>
            </div>
      
            </paper-dialog-scrollable>
            
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="_onAddTransformerConfirmClick">Confirm</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgAddWorkOrder">
            <h2>Add Work Order</h2>
            <p>Are you sure you want add a Work Order to this Event?</p>
            <paper-dialog-scrollable>
                <div class="horizontal around-justified layout">
                    <div class="verticle layout flex ">
                        <!--<paper-input label="No" value="{{info.No}}" readonly></paper-input>-->
                        <paper-checkbox checked="{{woDto.isShowOnPublicSite}}">Show on Public Site</paper-checkbox>
                       
                        <paper-dropdown-menu label="Priority" style="width:100%">
                            <paper-listbox slot="dropdown-content" selected="{{woDto.priority}}">
                                <paper-item>Low</paper-item>
                                <paper-item>Medium</paper-item>
                                <paper-item>High</paper-item>
                            </paper-listbox>
                        </paper-dropdown-menu>

                        <paper-dropdown-menu label="Service Type" style="width:100%">
                            <paper-listbox slot="dropdown-content" selected="{{woDto.serviceType}}" attr-for-selected="name">
                                <paper-item name="DISCF">	Disconnect for operations</paper-item>
                                <paper-item name="FEEDER">	Feeder Fault</paper-item>
                                <paper-item name="FIBRE">	Fibre Fault</paper-item>
                                <paper-item name="FLICK">	Flickering power </paper-item>
                                <paper-item name="MEMO">	General request (unspecified)</paper-item>
                                <paper-item name="HIGHLD">	High-load</paper-item>
                                <paper-item name="HOT_W">	Customer has no hot water</paper-item>
                                <paper-item name="LABEL">	Identify and label supply fuses</paper-item>
                                <paper-item name="LINES">	Electricity line down</paper-item>
                                <paper-item name="NOPWR">	No power to installation</paper-item>
                                <paper-item name="OSH-C">	Unsafe customer equipment</paper-item>
                                <paper-item name="OSH-N">	Unsafe network equipment</paper-item>
                                <paper-item name="PINFAULT">Pinpower Fault</paper-item>
                                <paper-item name="PPWR">	Part power to installation </paper-item>
                                <paper-item name="RECON">	Reconnect supply</paper-item>
                                <paper-item name="TREE">	Trees in Network lines</paper-item> 
                                <paper-item name="UGCABLE">	Underground cable fault</paper-item>
                                <paper-item name="USAFE">	Unsafe, immediate action</paper-item>
                                <paper-item name="VMVA">	Vehicle or Man vs Asset</paper-item>
                                <paper-item name="VOLTQ">	Voltage complaint at site</paper-item>
                            </paper-listbox>
                        </paper-dropdown-menu>
                        <paper-input label="Description" value="{{woDto.description}}"></paper-input>
                    </div>
                    <div class="spacer"></div>
                    <div class="verticle layout flex ">
                        <paper-input label="ICP" value="{{woDto.icp}}"></paper-input>
                        <paper-input label="Equipment #" value="{{woDto.equipmentNo}}"> </paper-input>
                        <paper-input label="Equipment Desc#" value="{{woDto.equipmentDescription}}"> </paper-input>
                    </div>
                </div>
                <paper-input label="Public Comment" value="{{woDto.generalComments}}"></paper-input>
                 <paper-textarea label="Fault Comment" value="{{woDto.faultComments}}" rows="3"></paper-textarea>
                <div class="horizontal around-justified layout">
                     <div class="verticle layout flex ">
                        <paper-input label="Customer" value="{{woDto.name}}"></paper-input>
                        <paper-input label="Contact" value="{{woDto.contactName}}"> </paper-input>
                        <paper-input label="Email" value="{{woDto.email}}"> </paper-input>
                        <paper-input label="Phone" value="{{woDto.phoneNo}}"></paper-input>
                        <paper-input label="Phone 2" value="{{woDto.phoneNo2}}"></paper-input>
                        
                    </div>
                    <div class="spacer"></div>
                    <div class="verticle layout flex ">
                        <paper-input label="Property" value="{{woDto.propertyName}}"></paper-input>
                        <paper-input label="Unit" value="{{woDto.unit}}"></paper-input>
                        <paper-input label="Street No" value="{{woDto.number}}"></paper-input>
                        <paper-input label="Street" value="{{woDto.street}}"></paper-input>
                        <paper-input label="Suburb" value="{{woDto.suburb}}"></paper-input>
                        <paper-input label="Town" value="{{woDto.town}}"></paper-input>
                        
                    </div>
                </div>
            </paper-dialog-scrollable>
            
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="_onAddWorkOrderConfirmClick">Confirm</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgNewAlert" class="size-position">
            <h2>Move to New Event</h2>
            <paper-textarea label="Description" value="{{eventInfo.description}}"></paper-textarea>
            <template is="dom-repeat" items="{{eventInfo.icps}}">
                <span>{{item.icp}}</span>
            </template>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onAddNewFaultAlert">Accept</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgAddFeederConfirm" class="size-position-small">
            <h2>Are You Sure?</h2>
            <p>Are you sure you want to Add this Feeder?</p>
            <div>{{selectedFeederInfo.feederName}}</div>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onConfirmAddFeederClick">Confirm</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgRestoreFeederConfirm" class="size-position-small">
            <h2>Restore Feeder</h2>
            <p>Are you sure you want to Mark this Feeder as restored?</p>
            <div>{{selectedFeederInfo.feederName}}</div>
            
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onConfirmRestoreFeederClick">Confirm</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgRestoreTransformerConfirm" class="size-position-small">
            <h2>Restore Transformer</h2>
            <p>Are you sure you want to Mark this Transformer as restored?</p>
            <div>{{selectedTransformerInfo.transformerNo}}</div>
        
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onConfirmRestoreTransformerClick">Confirm</paper-button>
            </div>
        </paper-dialog>

        <!--Add Multiple Transformers-->
        <paper-dialog id="dlgAddMultipleTransformers" class="size-position">
            <h2>Quick Add Transformers</h2>
            <p>This will add multiple transformers to the event.</p>
            <paper-textarea rows="10" label="Transformers" value="{{multiTransRequest.multipleTransformers}}"></paper-textarea>
            <p>Separate Transformer code by new line, comma or space.</p>
            <paper-checkbox  checked="{{multiTransRequest.isOverride}}">Over-ride Transformers</paper-checkbox>
            <p>This will close out any transformer not in the list</p>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onConfirmAddMultipleTransformers">Add</paper-button>
            </div>
        </paper-dialog>

        <!--Add Multiple Icps-->
        <paper-dialog id="dlgAddMultipleIcps" class="size-position">
            <h2>Quick Add Icps</h2>
            <p>This will add multiple Icps to the event.</p>
            <paper-textarea rows="10" label="Icps" value="{{multiIcpRequest.icps}}"></paper-textarea>
            <p>Separate ICP code by new line, comma or space.</p>
            <paper-checkbox  checked="{{multiIcpRequest.isOverride}}">Over-ride Icps</paper-checkbox>
            <p>This will close out any ICPs not in the list</p>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onConfirmAddMultipleIcps">Add</paper-button>
            </div>
        </paper-dialog>

        <iron-ajax auto="" id="ajaxAlertDetail" url="{{url}}" handle-as="json" last-response="{{info}}" loading="{{isLoading}}"></iron-ajax>
        <!--<iron-ajax id="ajaxAlert"-->
        <!--url="{{sendAlertUrl}}"-->
        <!--handle-as="json"-->
        <!--last-response="{{info}}"-->
        <!--loading="{{isLoading}}"></iron-ajax>-->
        <iron-ajax id="ajaxAlertMessage" url="{{sendAlertUrl}}" handle-as="json" method="POST" content-type="application/json" on-response="_refreshAlert" loading="{{isLoading}}"></iron-ajax>
        <iron-ajax id="ajaxAddTransformer" url="{{addTransfromerToAlertUrl}}" handle-as="json" method="POST" content-type="application/json" on-response="_refreshAlert" loading="{{isLoading}}"></iron-ajax>
        <iron-ajax id="ajxDeleteTransformer" url="{{deleteTransformerToAlertUrl}}" handle-as="json" method="POST" content-type="application/json" on-response="_refreshAlert" loading="{{isLoading}}"></iron-ajax>
        <iron-ajax id="ajaxRestoreTransformer" url="/api/fa/[[shutdownId]]/transformer/restore/{{app.username}}" handle-as="json" method="POST" content-type="application/json" loading="{{isLoading}}" on-response="_refreshAlert"></iron-ajax>
        <iron-ajax id="ajaxAddTransfromerCheck" url="{{checkTransformerUrl}}" handle-as="json" on-response="_onCheckTransformerComplete" loading="{{isLoading}}" auto="false"></iron-ajax>
        <iron-ajax id="ajaxAddFeeder" url="/api/fa/[[shutdownId]]/feeder/add/{{app.username}}" body="{{selectedFeederInfo}}" handle-as="json" method="POST" content-type="application/json" on-response="_refreshAlert" loading="{{isLoading}}"></iron-ajax>
        <iron-ajax id="ajaxRestoreFeeder" url="/api/fa/[[shutdownId]]/feeder/restore/{{app.username}}" handle-as="json" method="POST" content-type="application/json" loading="{{isLoading}}" on-response="_refreshAlert"></iron-ajax>
        <!-- <iron-ajax id="ajaxAddFeederCheck" url="{{checkFeederUrl}}" handle-as="json" on-response="_onCheckFeederComplete" loading="{{isLoading}}" auto="false"></iron-ajax> -->
        <iron-ajax id="ajaxEditAlert" url="{{sendEditUrl}}" handle-as="json" method="POST" content-type="application/json" on-response="_refreshAlert" loading="{{isLoading}}"></iron-ajax>
        <iron-ajax id="ajaxAddWorkOrder" url="{{postWoUrl}}" handle-as="json" method="POST" content-type="application/json" on-response="_refreshAlert" loading="{{isLoading}}"></iron-ajax>
        <iron-localstorage name="username" use-raw="" value="{{username}}"></iron-localstorage>
        <iron-localstorage name="cpl-selected-alert" id="lsAlert" value="{{info}}"></iron-localstorage>
        <iron-ajax id="ajaxAlert" url="{{addAlertUrl}}" handle-as="json" method="POST" content-type="application/json" body="{{eventInfo}}" on-response="_refreshAlert" loading="{{isLoading}}"></iron-ajax>
        <iron-ajax id="ajaxAddMultipleTransformers" url="/api/fa/[[shutdownId]]/transformers/add/{{app.username}}" handle-as="json" method="POST" content-type="application/json" on-response="_refreshAlert" loading="{{isLoading}}"></iron-ajax>
        <iron-ajax id="ajaxAddMultipleIcps" url="/api/fa/[[shutdownId]]/icps/add/{{app.username}}" handle-as="json" method="POST" content-type="application/json" on-response="_refreshAlert" loading="{{isLoading}}"></iron-ajax>
        <paper-toast id="toast" text=""></paper-toast>

         <!-- Workorder Overlay-->
        <core-overlay id="wodetail" auto-close-disabled="true" on-wodetail-close="closeWorkOrderOverlay">
            <wo-detail wo-id="{{selectedServiceOrderNo}}" app="{{app}}">
            </wo-detail>
        </core-overlay>

        `;
      }

    // onRemoveTransformersFromAlert(e) {
    //     if (this.shutdownId) {
    //         if (e.detail.code){

    //             var data = {
    //                 "code": e.detail.code
    //             };
    //             this._onDeleteTransformerByCode(data);
    //         }
    //     }
    // }

    shutdownIdChanged(newValue) {
        // console.log('new value: ' + newValue);
        if (newValue) {
            this.set('url', '/api/fa/' + newValue);
        } else {
            this.set('url', null);
        }
    }

    close() {
        this.set("info", null);
        
        this.dispatchEvent(new CustomEvent('alertdetail-close', {bubbles: true,  composed: true}));
    }

    onNewNoteClick() {
        this.set("alert",{sms:'',email:''})
        this.$.dlg.open();
    }

    onCloseAlertClick() {
        this.$.dlgClose.open();
    }

    // onDeleteTransformerClicked(e) {
    //     var model = e.model;
    //     //TODO: Find out if try catch is still needed.
    //     // this.splice('info.trandsformerIcps', model.index, 1);
    //     var data = {
    //         'code': model.item.transformerNo
    //     };
    //     this._onDeleteTransformerByCode(data);
        
    // }

    _onDeleteTransformerByCode(data){
        //Check if Transformer exists in feeders list --> transformers
        if (data){
            let trxExists = false;
            this.info.feeders.forEach(f=>{
                f.transformers.forEach(trx=>{
                    if (data.code == trx.transformerNo){
                        trxExists = true;
                        this.selectedTransformerInfo = trx;
                        return;
                    }
                });

            });

            if(trxExists){
                
                //Show Dialog
                this.$.dlgRestoreTransformerConfirm.open()
                // this.set('deleteTransformerToAlertUrl', '/api/fa/' + this.shutdownId + '/transformer/del/' + this.app.username);
                // this.$.ajxDeleteTransformer.body = JSON.stringify(data);
                // this.$.ajxDeleteTransformer.generateRequest();

            }else{
                this.showToast("Transformer does not exist in Event");
            }
        }
    }

    onMarkClosedFeederClicked(e) {
        var model = e.model;
       
        // var data = {
        //     'code': model.item.feederCode
        // };
        this.selectedFeederInfo = e.model.item;
        //Show Dialog
        this.$.dlgRestoreFeederConfirm.open()

    }

     onConfirmRestoreFeederClick(e) {
        // var model = e.model;
        var data = {
            'feederCode': this.selectedFeederInfo.feederCode
        };
        
        this.$.ajaxRestoreFeeder.body = JSON.stringify(data);
        this.$.ajaxRestoreFeeder.generateRequest();
    }

    onMarkClosedTransformerClicked(e) {
        var model = e.model;
       
        // var data = {
        //     'code': model.item.feederCode
        // };
        this.selectedTransformerInfo = e.model.item;
        //Show Dialog
        this.$.dlgRestoreTransformerConfirm.open()

    }

     onConfirmRestoreTransformerClick(e) {
        // var model = e.model;
        var data = {
            'code': this.selectedTransformerInfo.transformerNo
        };
        
        this.$.ajaxRestoreTransformer.body = JSON.stringify(data);
        this.$.ajaxRestoreTransformer.generateRequest();
    }

    onSendAdvice() {
        this.set('sendAlertUrl', '/api/fa/' + this.shutdownId + '/alert/' + this.app.username);

        var data = {
            'alertMessage': this.alert,
            'faultAlert': this.info
        };

        this.$.ajaxAlertMessage.body = JSON.stringify(data);
        this.$.ajaxAlertMessage.generateRequest();

    }


    onCloseConfirmClick() {
        var data = JSON.stringify({
            'username': this.app.username,
            'id': this.shutdownId
        });
        fetch(`/api/fa/${this.shutdownId}/close/${this.app.username}`, {
                credentials: 'include',
                method: 'POST',
                body: data
            })
            .then(resp => resp.json())
            .then(() => {
                this.$.ajaxAlertDetail.generateRequest()
                this.showToast("Alert Closed Successfully");
            })
            .catch((error) => {
                console.log('Request failed', error);
                this.showToast("Error Close Alert Failed!");
            });

    }
    _getTotalVipCustomers(vips){
        if(vips){
            return vips.length;
        }
        return 0;
        
    }

    showToast(text) {
        this.$.toast.show(text);
    }

    computeClass(s) {
        return s ? '' : 'active';
    }

    formatDate(s) {
        return s ? moment.parseZone(s).format('DD MMM YYYY HH:mm') : "";

    }

    computeClassBool(s) {
        return s ? 'red' : '';
    }

    computeDisabled(mobile, email) {
        return mobile.isEmpty && email.isEmpty;
    }

    onIcpClicked(e) {
        var model = e.model;
        
        this.dispatchEvent(new CustomEvent('show-asset-detail', {bubbles: true,  composed: true,
        detail:{
            id: model.item.id,
            assetType: 'icp'
        }}));
    }

    onFaultReportedClicked(e) {
        var model = e.model;
        
        this.dispatchEvent(new CustomEvent('show-fault-reported-detail', {bubbles: true,  composed: true,
            detail:{
                id: model.item.id
            }}));
        
    }

    // onShowTransformersClick() {

    //     var items = [];
    //     this.info.feeders.forEach(f =>{
    //         var trans = f.transformers;
    //         var trxNos = trans.map((v) => {
    //             return v.transformerNo;
    //         });

    //         items = [...new Set(trxNos)];//items.concat(trxNos);
    //     })
       
   
    // }

    _onCheckTransformerComplete(e){
        // console.log(e.detail.response)
        let data = e.detail.response;
        if(data.events > 0){
            this.clashEvents = data.events;
            this.$.dlgClashEvents.open();
        }else {
            this.set('currentOutageIcps', data.icps)
            this.$.dlgAddTransformer.open();
        }
    }
    _onAddTransformerConfirmClick(){
        this._addTransformer();

    }

    showAddFeederConfirmation(){
        this.$.dlgAddFeederConfirm.open();
    }

    onConfirmAddFeederClick(){
        this.$.ajaxAddFeeder.generateRequest();

    }

    _onAcceptTransformerEventClash(){
        this._addTransformer();

    }

    _addTransformer(){
        this.set('addTransfromerToAlertUrl', `/api/fa/${this.shutdownId}/transformer/add/${this.app.username}`);
        if (this.isSelectICPsToAdd){
            this.selectedTransformerInfo.Icps = this.currentOutageIcps;
        }else{
            this.selectedTransformerInfo.Icps = [];
        }
        this.$.ajaxAddTransformer.body = JSON.stringify(this.selectedTransformerInfo);
        this.$.ajaxAddTransformer.generateRequest();

    }
    _onEventDescriptionEditClick(){
        this.$.dlgEditEvent.open();

    }

    _onEditSave(){
        this.set('sendEditUrl', `/api/fa/${this.shutdownId}/edit/${this.app.username}`);
        let data = {
            description: this.info.description,
            isChangeWorkOrderRequired: this.isChangeWorkOrderRequired
        };
        this.$.ajaxEditAlert.body = JSON.stringify(data);
        this.$.ajaxEditAlert.generateRequest();

    }
    _refreshAlert(e){
        this.$.ajaxAlertDetail.generateRequest();
    }

    _onOpenWorkOrderClicked(e){
        let model = e.model;
        this.selectedServiceOrderNo = model.item.No;
        this.$.wodetail.open();
    }

    closeWorkOrderOverlay() {
        this.selectedServiceOrderNo = null;
            this._refreshAlert(null);
            this.$.wodetail.close();
    }

    _onAddWorkOrderClick(e){
        let dto = {};
        if (this.info.affectedCustomers == 1){
                  //Get ICP from List
     
            let item = this.info.transformerIcps[0].icps[0];
            fetch(`/api/icp/summary/${item.id}`, { credentials: 'include' })
            .then(resp => resp.json())
            .then(icp => {
                dto = {
                    description: this.info.description,
                    icpsAffected: 1,
                    name: icp.customerName,
                    phoneNo: icp.phoneNo,
                    phoneNo2: icp.mobileNo,
                    icp:icp.name,
                    serviceType: "NOPWR",
                    orderType: "FAULTS",
                    status: "SCHEDULED",
                    resourceNo: "",
                    resourceName: "",
                    equipmentNo: "ICP: " + icp.id.toString(),
                    equipmentDescription: "ICP",
                    lat: icp.lat,
                    lng: icp.lng,
                    propertyName: icp.propertyName,
                // dto.unit= icp.unit;
                // dto.number: icp.number;
                    feederCode : icp.feederCode,
                    street: icp.streetAddress,
                    suburb: icp.suburb,
                    town: icp.town,
                    feederName: this.info.feeder,
                    contactName: "",
                    email: icp.email,
                    generalComments: "",
                    isEntireFeeder: false,
                    feederCategory: "",
                    serviceItemGroupCode: "",
                    faultComments: "",
                    priority: 2,
                    isMedicallyDependent: icp.isMedicallyDependent,
                    assetType: "ICP",
                    assetId: icp.id,
                    knownAs: icp.icp,
                    eventId: this.info.id,
                    isShowOnPublicSite: true,
                    alertText: icp.alertText,
                    daytimeOnly: icp.daytimeOnly,
                    dogs: icp.dogs,
                    isHazard: icp.isHazard,
                    twoPersonCrew: icp.twoPersonCrew

                };
            
                this.set('isLoading', false);
                 this.set('woDto',dto);
            //open dialog
            this.$.dlgAddWorkOrder.open();

            });

            }else{
                    dto = {
                    description: this.info.description,
                    icpsAffected: this.info.affectedCustomers,
                    name: "Multiple Assets",
                    phoneNo: "",
                    phoneNo2: "",
                    icp: "Multiple",
                    serviceType: "NOPWR",
                    orderType: "FAULTS",
                    status: "SCHEDULED",
                    propertyName: "Multiple Assets",
                    unit: "",
                    number: "",
                    street: "",
                    suburb: "",
                    town: "",
                    resourceNo: "",
                    resourceName: "",
                    equipmentNo: "",
                    equipmentDescription: "Multiple Assets",
                    lat: this.info.lat,
                    lng: this.info.lng,
                    feederCode: "",
                    feederName: this.info.feeder,
                    contactName: "",
                    email: "",
                    generalComments: "",
                    isEntireFeeder: false,
                    feederCategory: "",
                    serviceItemGroupCode: "",
                    faultComments: "",
                    priority: 2,
                    isMedicallyDependent: this.info.medicallyDependentCount >0,
                    assetType: "",
                    assetId: 0,
                    knownAs: "",
                    eventId: this.info.id,
                    isShowOnPublicSite: true
                };
                 this.set('woDto',dto);
            //open dialog
            this.$.dlgAddWorkOrder.open();
            }

           
    }
    // _onAddIcpWorkOrderClick(e){
    //     let item = e.model.item;
        
        

    // }

    _onAddWorkOrderConfirmClick(e){

        if (this.woDto.serviceType.length < 2) {
            this.showToast("Service Type is required.");
            return;
        }
        this.set('postWoUrl', `/api/fa/${this.shutdownId}/wo/add/${this.app.username}`);
       
        this.$.ajaxAddWorkOrder.body = JSON.stringify(this.woDto);
        this.$.ajaxAddWorkOrder.generateRequest();

    }
    _onMoveToNewEvent(){
        //Check if any icps are selected
        let ei = {
                description:'',
                icps :[]
            };
        this.info.transformerIcps.forEach((trx) =>{
            trx.icps.forEach((icp)=>{
                if(icp.isSelected){
                    ei.icps.push(icp);
                }
            });
        });

        if (ei.icps.length > 0){
            //Show New Event Dailog
            this.set("eventInfo", ei);
            this.$.dlgNewAlert.open();
        }
    }

    onAddNewFaultAlert() {
            this.set('addAlertUrl', '/api/fas/add/' + this.app.username);
            // this.$.ajaxAlert.body = JSON.stringify(this.eventInfo);
            this.$.ajaxAlert.generateRequest();
        }

    hasParentLink(s) {
        if (s.length > 0) {
            return true;
        }
        return false;
    }

    isEventCreateEditAllowed(app){
        return AmiBehaviors.SecurityBehavior.isEventCreateEditAllowed(app.role);

    }

    _onAddTransformersClick(e){
        this.multiTransRequest = {isOverride:false,multipleTransformers:'' }
        this.$.dlgAddMultipleTransformers.open();
    }
    //Post multiple Transformers
    onConfirmAddMultipleTransformers(){
        console.log(this.multiTransRequest)
        //convert to Array
        const trxs = this.multiTransRequest.multipleTransformers.toUpperCase().replace(/,\s+/g,",").split(/[\n,\s+]/)
        console.log(JSON.stringify(trxs))
        this.$.ajaxAddMultipleTransformers.body = JSON.stringify({transformers:trxs, isOverride:this.multiTransRequest.isOverride})
        this.$.ajaxAddMultipleTransformers.generateRequest()


    }

    _onAddIcpsClick(e){
        this.multiIcpRequest = {isOverride:false,icps:'' }
        this.$.dlgAddMultipleIcps.open();
    }
    //Post multiple Transformers
    onConfirmAddMultipleIcps(){
        console.log(this.multiIcpRequest)
        //convert to Array
        const trxs = this.multiIcpRequest.icps.toUpperCase().replace(/,\s+/g,",").split(/[\n,\s+]/)
        console.log(JSON.stringify(trxs))
        this.$.ajaxAddMultipleIcps.body = JSON.stringify({icps:trxs, isOverride:this.multiIcpRequest.isOverride})
        this.$.ajaxAddMultipleIcps.generateRequest()


    }
}
customElements.define(FaultAlertDetail.is, FaultAlertDetail);
