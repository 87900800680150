import '@polymer/polymer/polymer-legacy.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-item/paper-item-body.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';

import './components/admin/user-list.js';

import './components/admin/project-mamager-list';
import './components/admin/hub-cms';

class AdminView extends PolymerElement {
    
    static get is() { return 'admin-view'; }

    static get properties() {
        return {
            app: Object, // notify: true },
            selected: {
                type:Number,
                value:0
            }
        }
    }

    static get template() {
        return html`
        <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>

        <style>
            :host {
                display: block;
                height: 100%;
            }

            html, body {
                height: 100%;
            }

            body {
                font-family: sans-serif;
                color: #FFF;
                margin: 0;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            }

            [drawer] {
                background-color: #f5f5f5;
                border-right: 1px solid #ccc;
            }

            [main] {
                /*background-color: #4F7DC9;*/
            }

            button {
                width: 160px;
                height: 40px;
                font-size: 16px;
                margin: 8px;
            }


            [app-toolbar] {
                color: #ffffff;
            }

            paper-submenu::shadow #submenu {
                /*margin-left: 10px;*/
            }

            paper-checkbox {
                padding-right: 10px;
            }

            [paper-header-panel] {
                height: 100%;
            }
            #left-nav{
                border-right: solid 1.5px #d3d3d3; height: 100%;
                background-color: #fafafa;
            }
            #left-nav .title{
                color: darkgray;

            }
            #left-nav app-toolbar{
                border-bottom: solid 1.5px #d3d3d3;
                --app-toolbar-background: transparent;
            }
            .big {
                -height: 32px;
                -width: 32px;
                color: darkgray;
            }
            #left-nav paper-listbox{
                --paper-listbox-background-color: #fafafa;
            }

            .fh{
                height: 100%;
            }
            .access-message{
                margin-top:200px;
                padding: 50px;
                border: dashed 1px red;
                font-weight: bold;

            }
        </style>
    <template is="dom-if" if="[[!isAdmin(app)]]">
        <div class="horizontal center-justified layout">
           <div class="access-message">You don't have access to this section!</div>
        </div>
     
    </template>
    <template is="dom-if" if="[[isAdmin(app)]]">
     <div class="horizontal layout flex fh">
            <div id="left-nav" class="flex-1">
                <app-toolbar>
                    <paper-icon-button icon="home" class="big"></paper-icon-button>
                    <div class="title">Admin</div>
                </app-toolbar>
                <paper-listbox selected="{{selected}}">
                    
                    <paper-item>
                        <iron-icon icon="account-balance"></iron-icon>
                        <paper-item-body>
                            Users
                        </paper-item-body>
                    </paper-item>

                    <paper-item>
                        <iron-icon icon="dashboard"></iron-icon>
                        <paper-item-body>
                            Project Manager
                        </paper-item-body>
                    </paper-item>

                    <paper-item>
                        <iron-icon icon="dashboard"></iron-icon>
                        <paper-item-body>
                            Hub CMS
                        </paper-item-body>
                    </paper-item>

                </paper-listbox>
            </div>
            <div class="flex-4">
                <iron-pages selected="{{selected}}" selected-attribute="activated" class="flex fh">
                    <!-- <section>Events</section> -->
                    <user-list></user-list>
                    <project-manager-list></project-manager-list>
                    <hub-cms></hub-cms>
                    
                </iron-pages>

            </div>

        </div>
    </template>

        `;
      }

    isAdmin(app){
        return app.role.toUpperCase() === 'ADMINISTRATOR';

    }
}
customElements.define(AdminView.is, AdminView);
