/* <link rel="import" href="../../bower_components/moment-element/moment-with-locales-import.html"> */
/*
  FIXME(polymer-modulizer): the above comments were extracted
  from HTML and may be out of place here. Review them and
  then delete this comment!
*/
import '@polymer/polymer/polymer-legacy.js';
import moment from 'moment';
import './icp-asset-detail.js';
import './core-overlay.js';
import "./wo-detail.js";
import './fault-alert-detail.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/paper-toast/paper-toast.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
// import 'paper-date-picker/paper-date-picker.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@vaadin/vaadin-grid';
import '@vaadin/vaadin-grid/vaadin-grid-column';
import '@vaadin/vaadin-grid/vaadin-grid-sort-column';
import '@polymer/paper-tooltip/paper-tooltip.js';
import '@polymer/iron-icons/av-icons.js';
import '@polymer/iron-icons/notification-icons.js';

let chartCons;
let dur = "7d";
/**
* @polymer
* @extends HTMLElement
**/
class TransformerAssetDetail extends PolymerElement {
    
    static get is() { return 'transformer-asset-detail'; }
    
    static get properties() {
        return {
            app: Object,
            assetId: {
                type: Number,
                observer: 'assetIdChanged'
            },
            transformerCode: {
                type: String,
                observer: 'transformerCodeChanged'
            },
            selected: {
                type: Number,
                value: 0,
                observer: 'selectedTabChanged'
            },
            url: String,
            transInfo: Object,
            subInfo: Object,
            workOrderHistory: Array,
            showBreachMinTable: Boolean,
            selectedAsset: Number,
            data: Array,
            startDate: String,
            isFromDateInvalid: Boolean,
            isToDateInvalid: Boolean,
            isFromTimeInvalid: Boolean,
            isToTimeInvalid: Boolean,
            fromDate: String,
            toDate: String,
            fromTime: {
                type: String,
                value: "00:00"
            },
            toTime: {
                type: String,
                value: "00:00"
            },
            fromDateTime: String,
            toDateTime: String,
            filterStartDate: {
                type: Date,
                value: function() {
                    return new Date();
                },
                observer: 'filterStartDateChanged'
            },
            filterEndDate: {
                type: Date,
                value: function() {
                    return new Date();
                },
                observer: 'filterEndDateChanged'
            },
            selectedServiceOrder: {
                type: Object,
                observer: '_selectedServiceOrderChanged'
            },
            summaryData:{
            type: Array,
            value: ()=>  {
                return [
                {name: 'No. Customers', value:""},
                {name: 'No. ICPs', value:""},
                {name: 'Total SSN',      value :""},
                {name: 'Total Non SSN', value: ""},
                {name: 'Reporting SSN',     value :""},
                {name: 'Max % Reporting',  value :""},
                ];
            }
        },

        }
    }

    static get template() {
        return html`
          <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>

        <style>
        :host {
            display: block;
            width: 100%;
            height: 100%;
            --paper-input-container-input: {
                font-size: 13px;
            };
        }
        
        paper-button.colored {
            color: #3c3f41;
        }
        
        paper-button[raised].colored {
            background: #4285f4;
            color: #fff;
        }
        
        paper-button.custom > iron-icon {
            margin-right: 4px;
        }
        
        paper-button.hover:hover {
            background: #eee;
        }
        
        paper-button.blue-ripple::shadow #ripple {
            color: #4285f4;
        }
        
       
        /*table {*/
        /*width: 50%; /!* Optional *!/*/
        /*padding: 20px;*/
        /*}*/
        /*tbody td, thead th {*/
        /*width: 20%;  /!* Optional *!/*/
        /*}*/
        /*thead, tbody { display: block; }*/
        /*tbody {*/
        /*height: 200px;*/
        /*overflow-y: auto;    /!* Trigger vertical scroll    *!/*/
        /*overflow-x: hidden;  /!* Hide the horizontal scroll *!/*/
        /*}*/
        
        .mdl-data-table {
            position: relative;
            border: 1px solid rgba(0, 0, 0, 0.12);
            border-collapse: collapse;
            white-space: nowrap;
            font-size: 13px;
            background-color: rgb(255, 255, 255);
        }
        
        .mdl-data-table thead {
            padding-bottom: 3px;
        }
        
        .mdl-data-table thead .mdl-data-table__select {
            margin-top: 0;
        }
        
        .mdl-data-table tbody tr {
            position: relative;
            height: 48px;
            -webkit-transition-duration: 0.28s;
            transition-duration: 0.28s;
            -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            -webkit-transition-property: background-color;
            transition-property: background-color;
        }
        
        .mdl-data-table tbody tr.is-selected {
            background-color: #e0e0e0;
        }
        
        .mdl-data-table tbody tr:hover {
            background-color: #eeeeee;
        }
        
        .mdl-data-table td,
        .mdl-data-table th {
            padding: 0 18px 0 18px;
            text-align: right;
        }
        
        .mdl-data-table td:first-of-type,
        .mdl-data-table th:first-of-type {
            padding-left: 24px;
        }
        
        .mdl-data-table td:last-of-type,
        .mdl-data-table th:last-of-type {
            padding-right: 24px;
        }
        
        .mdl-data-table td {
            position: relative;
            vertical-align: top;
            height: 48px;
            border-top: 1px solid rgba(0, 0, 0, 0.12);
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            padding-top: 12px;
            box-sizing: border-box;
        }
        
        .mdl-data-table td .mdl-data-table__select {
            vertical-align: top;
            position: absolute;
            left: 24px;
        }
        
        .mdl-data-table th {
            position: relative;
            vertical-align: bottom;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: bold;
            line-height: 24px;
            letter-spacing: 0;
            height: 48px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.54);
            padding-bottom: 8px;
            box-sizing: border-box;
        }
        
        .mdl-data-table th .mdl-data-table__select {
            position: absolute;
            bottom: 8px;
            left: 24px;
        }
        
        .mdl-data-table__select {
            width: 16px;
        }
        
        .mdl-data-table__cell--non-numeric.mdl-data-table__cell--non-numeric {
            text-align: left;
        }
        
        .mdl-data-table__cell--non-numeric {
            text-align: left;
            width: 130px;
        }
        
        #table {
            position: relative;
            /*border: 1px solid rgba(0, 0, 0, 0.12);*/
            border-collapse: collapse;
            white-space: nowrap;
            font-size: 13px;
            background-color: rgb(255, 255, 255);
            margin: 16px;
        }
        
        .head {
            padding-bottom: 3px;
            background-color: #ddd;
        }
        
        .row {
            position: relative;
            height: 48px;
            -webkit-transition-duration: 0.28s;
            transition-duration: 0.28s;
            -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            -webkit-transition-property: background-color;
            transition-property: background-color;
            border-top: 1px solid rgba(0, 0, 0, 0.12);
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            cursor: pointer;
            @apply --layout-horizontal;
            
        }
        
        .row:focus,
        .row.selected:focus {
            outline: 0;
            background-color: #ddd;
        }
        
        .cell {
            position: relative;
            vertical-align: top;
            box-sizing: border-box;
            padding-left: 20px;
            padding-right: 20px;
            display: inline-flex;
            -webkit-transition-duration: 0.28s;
            transition-duration: 0.28s;
            -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            -webkit-transition-property: background-color;
            transition-property: background-color;
            text-align: right;
            width: 70px;
        }
        
        paper-progress {
            width: 100%;
            --paper-progress-container-color: white;
            --paper-progress-active-color: var(--paper-orange-500);
            --paper-progress-secondary-color: var(--paper-orange-100);
        }
        
        span.secondary {
            display: inline-flex;
            width: 70px;
        }
        /*#table {display: table;}*/
        /*.row {display: table-row;}*/
        /*.cell {display: table-cell;}*/
        
        #assetdetail {
            position: fixed;
            left: 0px;
            bottom: 0px;
            background: white;
            width: 100%;
            height: 500px;
            max-height: 1000px;
            overflow: auto;
        }
        
        #dlgfilter {
            position: absolute;
            bottom: 192px;
            /*left: 50px;*/
            width: 390px;
            height: 240px;
            right: 182px;
        }
        .chart-wrapper {
            padding: 10px;
            height: 300px;
        }
        paper-dialog.paper-date-picker-dialog {
            bottom: 0px;
        }

        paper-card {
            width: 100%;
            margin: 8px;
        }

        #wodetail {
            position: fixed;
            left: 0px;
            bottom: 0px;
            background: white;
            width: 100%;
            height: 500px;
            max-height: 1000px;
            overflow: auto;
        }

        #faultAlertOverlay {
            position: fixed;
            right: 0px;
            top: 64px;
            background: white;
            width: 408px;
            height: 100%;
            overflow: auto;
        }

        .info {
            background-color: #FFE082;
            width:99%;
        }

        </style>
       <app-header-layout has-scrolling-region="true">
        <app-header scroll-target="content" slot="header" fixed>
            <app-toolbar class="small">
                <!--<paper-spinner alt="Loading..." active="{{isLoading}}"></paper-spinner>-->
                <paper-tabs selected="{{selected}}" class="flex">
                    <paper-tab>Transformer Detail</paper-tab>
                    <!-- <paper-tab>Summary</paper-tab> -->
                    <paper-tab>Consumption</paper-tab>
                    <paper-tab>Events</paper-tab>
                    <paper-tab>Work History</paper-tab>
                    <paper-tab>Icps</paper-tab>

                </paper-tabs>
                <div class="bottom flex"></div>
                <paper-icon-button class="bottom " icon="close" on-click="close"></paper-icon-button>
            
            </app-toolbar>
        </app-header>
            
            <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>
            <iron-pages selected="{{selected}}">
                <div>
                    <template is="dom-if" if="{{transInfo.isPartOfEvent}}">
                        <paper-card class="info">
                            <div class="card-content">This Transformer is part of Event <span>{{transInfo.eventId}}</span></div>
                        </paper-card>
                    </template>
                    <div class="horizontal around-justified layout" style="padding: 0 20px">
                        <div class="verticle layout flex two">
                            <paper-input label="Name" value="{{transInfo.name}}" readonly="">
                                <paper-icon-button slot="suffix" icon="settings-input-antenna" on-click="onPingMeters"></paper-icon-button>
                                <template is="dom-if" if="{{transInfo.isHazard}}">
                                    <div slot="suffix" style="display:inline-block">
                                        <iron-icon icon="warning" style="color:#E65100"></iron-icon>
                                        <paper-tooltip>Hazard</paper-tooltip>
                                    </div>
                                </template>
                                <template is="dom-if" if="{{transInfo.isMedicallyDependent}}">
                                    <div slot="suffix" style="display:inline-block">
                                        <iron-icon icon="maps:local-hospital" style="color:red"></iron-icon>
                                        <paper-tooltip>Medically Dependent</paper-tooltip>
                                    </div>
                                </template>
                                <template is="dom-if" if="{{transInfo.isKeyAccount}}">
                                    <div slot="suffix" style="display:inline-block">
                                        <iron-icon icon="communication:vpn-key" style="color:#038a03"></iron-icon>
                                        <paper-tooltip>Key account</paper-tooltip>
                                    </div>
                                </template>
                                <template is="dom-if" if="{{transInfo.isVip}}">
                                    <div slot="suffix" style="display:inline-block">
                                        <iron-icon icon="av:new-releases" style="color:#ff7b00"></iron-icon>
                                        <paper-tooltip>VIP</paper-tooltip>
                                    </div>
                                </template>
                            </paper-input>
                            <paper-input label="Owner" value="{{transInfo.owner}}" readonly=""></paper-input>
                            <paper-input label="Status" value="{{transInfo.transformerStatus}}" readonly=""></paper-input>
                            <paper-input label="Make" value="{{transInfo.make}}" readonly=""></paper-input>
                            <paper-input label="Primary Volts" value="{{transInfo.primaryVolts}}" readonly=""></paper-input>
                            <paper-input label="Secondary Volts" value="{{transInfo.secondaryVolts}}" readonly=""></paper-input>
                            <paper-input label="Operating Voltage" value="{{transInfo.operatingVoltage}}" readonly=""></paper-input>
                        </div>
                        <div class="flex"></div>
                        <div class="verticle layout flex">
                            <paper-input label="Rating" value="{{transInfo.rating}}" readonly=""></paper-input>
                            <paper-input label="No. Phases" value="{{transInfo.noPhases}}" readonly=""></paper-input>
                            <paper-input label="Full Load Loss" value="{{transInfo.fullLoadLoss}}" readonly=""></paper-input>
                            <paper-input label="Impedance" value="{{transInfo.impedance}}" readonly=""></paper-input>
                            <paper-input label="No Load Loss" value="{{transInfo.noLoadLoss}}" readonly=""></paper-input>
                            <paper-input label="Has Cable Box" value="{{_formatBool(transInfo.hasCableBox)}}" readonly=""></paper-input>
                            <paper-input label="Internally Fused" value="{{_formatBool(transInfo.internallyFused)}}" readonly=""></paper-input>
                            <paper-input label="Mount Type" value="{{transInfo.mountType}}" readonly=""></paper-input>
                        </div>
                        <div class="flex"></div>
                        <div class="verticle layout flex two">
                            <paper-input label="Manufactured" value="{{transInfo.manDate}}" readonly=""></paper-input>
                            <paper-input label="Completed" value="{{transInfo.comDate}}" readonly=""></paper-input>
                            <paper-input label="End of Life" value="{{transInfo.endOfLifeDate}}" readonly=""></paper-input>
                            <paper-input label="Decommissioned" value="{{transInfo.dateDecommissioned}}" readonly=""></paper-input>
                            <paper-input label="Network Survey" value="{{transInfo.networkSurvey}}" readonly=""></paper-input>
                            <paper-input label="Notes" value="{{transInfo.notes}}" readonly=""></paper-input>
                            <paper-input label="History" value="{{transInfo.transformerHistory}}" readonly=""></paper-input>
                            <paper-input label="Inspections" value="{{transInfo.transformerInspections}}" readonly=""></paper-input>
                        </div>
                    </div>
                </div>
                <!-- <div>
                    <div class="horizontal around-justified layout" style="padding: 0 20px">
                        <div class="verticle layout flex two">
                            <paper-input label="No. Customers" value="{{transInfo.noOfCustomers}}" readonly=""></paper-input>
                            <paper-input label="No. Smart Meters" value="{{transInfo.numSmartMeter}}" readonly=""></paper-input>
                            <paper-input label="No. Non Smart Meters" value="{{transInfo.numNotSmartMeter}}" readonly=""></paper-input>
                        </div>
                        <div class="verticle layout flex two">
                        </div>
                    </div>
                </div> -->
                <!-- Substation Details-->
                <!-- <div class="horizontal around-justified layout " style="padding: 0 20px">
                    <div class="verticle layout flex two">
                        <paper-input label="Name" value="{{subInfo.name}}" readonly>
                            <paper-icon-button suffix icon="settings-input-antenna" on-click="onPingMeters"></paper-icon-button>
                        </paper-input>
                        <paper-input label="Status" value="{{subInfo.status}}" readonly></paper-input>
                        <paper-input label="Trans Asset Rating" value="{{subInfo.transAssetRating}}" readonly></paper-input>
                        <paper-input label="Segment Id" value="{{subInfo.segmentId}}" readonly></paper-input>
                        <paper-input label="Switch Segment" value="{{subInfo.switchSegment}}" readonly></paper-input>
                        <paper-input label="Switch Segment Id" value="{{subInfo.switchSegmentId}}" readonly></paper-input>
                        <paper-input label="Fuse Type" value="{{subInfo.fuseType}}" readonly></paper-input>
                    </div>
                    <div class="flex"></div>
                    <div class="verticle layout flex">
                        <paper-input label="Site Type" value="{{subInfo.siteType}}" readonly></paper-input>
                        <paper-input label="Is Arrestor" value="{{subInfo.isArrestor}}" readonly></paper-input>
                        <paper-input label="Arrestor Rating" value="{{subInfo.arrestorRating}}" readonly></paper-input>
                        <paper-input label="No Of Customers" value="{{subInfo.noOfCustomers}}" readonly></paper-input>
                        <paper-input label="Critical" value="{{subInfo.criticality}}" readonly></paper-input>
                        <paper-input label="Area Type" value="{{subInfo.areaType}}" readonly></paper-input>
                        <paper-input label="Contractor" value="{{subInfo.contractor}}" readonly></paper-input>
                        <paper-input label="JobRef" value="{{subInfo.jobRef}}" readonly></paper-input>
                    </div>
                    <div class="flex"></div>
                    <div class=" verticle layout flex two">
                        <paper-input label="Date Built" value="{{subInfo.dateBuilt}}" readonly></paper-input>
                        <paper-input label="Decommissioned" value="{{subInfo.subDateDecommissioned}}" readonly></paper-input>
                        <paper-input label="Network Survey" value="{{subInfo.subNetworkSurvey}}" readonly></paper-input>
                        <paper-input label="Traffic Management" value="{{subInfo.trafficManagement}}" readonly></paper-input>
                        <paper-input label="notes" value="{{subInfo.subNotes}}" readonly></paper-input>
                        <paper-input label="Feeder" value="{{subInfo.feeder}}" readonly></paper-input>
                        <paper-input label="Earth Tests" value="{{subInfo.earthTests}}" readonly></paper-input>
                        <paper-input label="Pole" value="{{subInfo.pole}}" readonly></paper-input>
                        <paper-input label="Transformers" value="{{subInfo.transformers}}" readonly></paper-input>
                        <paper-input label="Bus Bars" value="{{subInfo.busBars}}" readonly></paper-input>
                        <paper-input label="Switches" value="{{subInfo.switches}}" readonly></paper-input>
                    </div>
                </div> -->
                <div>
                    <!-- Consumption -->
                    <template is="dom-if" if="[[!isAllowed(app)]]">
                        <div class="horizontal center-justified layout">
                            <div class="access-message">You don't have access to this section!</div>
                        </div>
                    </template>
                    <paper-dialog id="dlgCalFrom" class="paper-date-picker-dialog" on-iron-overlay-closed="dismissDialog">
                        <paper-date-picker id="pickerFrom" date="{{filterStartDate}}" heading-format="D MMM" style="margin:0; padding:0;"></paper-date-picker>
                        <!--<div class="buttons">-->
                        <!--<paper-button dialog-dismiss>Cancel</paper-button>-->
                        <!--<paper-button dialog-confirm>OK</paper-button>-->
                        <!--</div>-->
                    </paper-dialog>
                    <paper-dialog id="dlgCalTo" class="paper-date-picker-dialog" on-iron-overlay-closed="dismissDialog">
                        <paper-date-picker id="pickerTo" date="{{filterEndDate}}" heading-format="D MMM" style="margin:0; padding:0;"></paper-date-picker>
                    </paper-dialog>
                    <template is="dom-if" if="[[isAllowed(app)]]">
                        <section>
                            <div class="horizontal justified layout">
                                <div>
                                    <!-- <paper-dropdown-menu style="width: 200px;" no-label-float no-animations>
                                    <paper-listbox slot="dropdown-content" selected="{{selectedChart}}">
                                        <paper-item>Consumption</paper-item>
                                        <paper-item>Distributed Generation</paper-item>
                                        <paper-item>Voltage Breach</paper-item>
                                        <paper-item>Power Down</paper-item>
                                    </paper-listbox>
                                </paper-dropdown-menu> -->
                                </div>
                                <div>
                                    <paper-button on-click="showDateFilterDialog" alt="Filter" title="Filter" raised="">
                                        <iron-icon icon="filter-list"></iron-icon>
                                        Filter
                                    </paper-button>
                                    <template is="dom-if" if="{{!isLoading}}">
                                        <a href\$="/api/transformer/[[transInfo.name]]/pc/csv/from/[[fromDateTime]]/to/[[toDateTime]]" target="_blank" tabindex="-1">
                                            <paper-button raised="">
                                                <iron-icon icon="file-download"></iron-icon>
                                                Download CSV
                                            </paper-button>
                                        </a>
                                    </template>
                                </div>
                            </div>
                        </section>
                    </template>
                    <paper-card style="height: 350px; width: 99%">
                        <div class="horizontal layout">
                            <div style="width: 280px;">
                                <vaadin-grid id="tblSummary" items="[[summaryData]]" theme="row-stripes" style=" height:280px; margin:8px;">
                                    <vaadin-grid-column path="name" header=""></vaadin-grid-column>
                                    <vaadin-grid-column path="value" header="" width="120px" flex-grow="0" text-align="end"></vaadin-grid-column>
                                </vaadin-grid>
                            </div>
                            
                            <div id="conschart" class="flex chart-wrapper"></div>
                        </div>
                    </paper-card>                    
                </div>
                <div>
                    <div class="horizontal justified layout ">
                        <div>
                        </div>
                        <div>
                            <paper-button raised="" on-click="onShowBreachMinTable">
                                <iron-icon icon="image:filter-1"></iron-icon>
                                Breach Min
                            </paper-button>
                            <paper-button raised="" on-click="onShowBreachMaxTable">
                                <iron-icon icon="image:filter-2"></iron-icon>
                                Breach Max
                            </paper-button>
                            <paper-button raised="" on-click="onShowBreachNeutralTable">
                                <iron-icon icon="image:filter-3"></iron-icon>
                                Breach Neutral
                            </paper-button>
                            <paper-button raised="" on-click="onShowOutageTable">
                                <iron-icon icon="image:filter-4"></iron-icon>
                                Outages
                            </paper-button>
                        </div>
                    </div>
                    <iron-ajax auto="" url="{{url}}" handle-as="json" last-response="{{data}}" loading="{{isLoading}}"></iron-ajax>
                    <!--<template is="dom-repeat" items="[[data]]">-->
                    <!--<div><span>{{item.icp}}</span> </div>-->
                    <!--</template>-->
                    <!--<template is="dom-if" if="{{isLoading}}">-->
                    <!--</template>-->
                    <div id="table" class="">
                        <div class="head">
                            <div class="row">
                                <span class="mdl-data-table__cell--non-numeric">ICP</span>
                                <span class="cell">0-30</span>
                                <span class="cell">30-60</span>
                                <span class="cell">60-90</span>
                                <span class="cell">90+</span>
                                <span class="cell">Total</span>
                            </div>
                        </div>
                        <div class="bod">
                            <template is="dom-repeat" items="[[data]]">
                                <div class="row" on-click="onIcpClicked">
                                    <span class="mdl-data-table__cell--non-numeric">[[item.icp]]</span>
                                    <span class="cell">[[item.zeroToThirty]]</span>
                                    <span class="cell">[[item.thirtyToSixty]]</span>
                                    <span class="cell">[[item.sixtyToNinety]]</span>
                                    <span class="cell">[[item.ninetyPlus]]</span>
                                    <span class="cell">[[item.total]]</span>
                                </div>
                            </template>
                        </div>
                    </div>
                    <!--<iron-list items="[[data]]" as="item"  selection-enabled>-->
                    <!--<template>-->
                    <!--<paper-item>-->
                    <!--<span class="cell mdl-data-table__cell&#45;&#45;non-numeric">[[item.icp]]</span>-->
                    <!--<span class="cell dim">[[item.zeroToThirty]]</span>-->
                    <!--<span class="cell dim">[[item.thirtyToSixty]]</span>-->
                    <!--<span class="cell dim">[[item.sixtyToNinety]]</span>-->
                    <!--<span class="cell dim">[[item.ninetyPlus]]</span>-->
                    <!--<span class="cell dim">[[item.total]]</span>-->
                    <!--</paper-item>-->
                    <!--</template>-->
                    <!--</iron-list>-->
                    <!--</div>-->
                    <!--<table class='mdl-data-table mdl-js-data-table'>-->
                    <!--<thead>-->
                    <!--<tr>-->
                    <!--<th class="mdl-data-table__cell&#45;&#45;non-numeric">ICP</th>-->
                    <!--<th>0-30</th>-->
                    <!--<th>30-60</th>-->
                    <!--<th>60-90</th>-->
                    <!--<th>90+</th>-->
                    <!--<th>Total</th>-->
                    <!--</tr>-->
                    <!--</thead>-->
                    <!--&lt;!&ndash;<tfoot>&ndash;&gt;-->
                    <!--&lt;!&ndash;<tr>&ndash;&gt;-->
                    <!--&lt;!&ndash;<td>Total</td>&ndash;&gt;-->
                    <!--&lt;!&ndash;<td>0</td>&ndash;&gt;-->
                    <!--&lt;!&ndash;</tr>&ndash;&gt;-->
                    <!--&lt;!&ndash;</tfoot>&ndash;&gt;-->
                    <!--<tbody>-->
                    <!--<template is="dom-repeat" items="[[data]]">-->
                    <!--<tr>-->
                    <!--<td class='mdl-data-table__cell&#45;&#45;non-numeric'>[[item.icp]]</td>-->
                    <!--<td>{{item.zeroToThirty}}</td>-->
                    <!--<td>{{item.thirtyToSixty}}</td>-->
                    <!--<td>{{item.sixtyToNinety}}</td>-->
                    <!--<td>{{item.ninetyPlus}}</td>-->
                    <!--<td>{{item.total}}</td>-->
                    <!--</tr>-->
                    <!--</template>-->
                    <!--</tbody>-->
                    <!--</table>-->
                    <!--<table>-->
                    <!--<thead>-->
                    <!--<tr>-->
                    <!--<th>name</th>-->
                    <!--</tr>-->
                    <!--</thead>-->
                    <!--<tbody>-->
                    <!--<template is="dom-repeat" items="[[items]]">-->
                    <!--<tr>-->
                    <!--<td>{{item.name}}</td>-->
                    <!--</tr>-->
                    <!--</template>-->
                    <!--</tbody>-->
                    <!--</table>-->
                    <!--<template is="dom-repeat" items="[[items]]">-->
                    <!--<div><span>{{item.name}}</span></div>-->
                    <!--</template> -->
                </div>
                <div>

                  <paper-card>
                    <div class="card-content">
                        <vaadin-grid id="shTable" items="{{workOrderHistory}}" theme="compact row-stripes" on-active-item-changed="_openWo">
                            <vaadin-grid-sort-column header="No" path="no" width="20%">
                              <template>
                                <template is="dom-if" if="{{item.isEvent}}">
                                    <div style="display:inline-block">
                                        <iron-icon icon="group-work" style="color:#000fe6"></iron-icon>
                                        {{item.no}}
                                    </div>
                                  </template>
                                  <template is="dom-if" if="{{!item.isEvent}}">
                                    <div style="display:inline-block">
                                        <iron-icon icon="social:person" style="color:#000fe6"></iron-icon>
                                        {{item.no}}
                                    </div>
                                  </template>
                                </template>
                            </vaadin-grid-sort-column>
                            <vaadin-grid-sort-column header="Service Type" path="serviceType" width="10%">
                            </vaadin-grid-sort-column>
                            <vaadin-grid-sort-column header="Response Date" path="responseDateTime" width="13%">
                              <template>
                                    <span>{{formatDateTime(item.responseDateTime)}}</span>
                                </template>
                            </vaadin-grid-sort-column>
                            <vaadin-grid-sort-column header="# Affected" path="customersAffected" width="7%">
                            </vaadin-grid-sort-column>
                            <vaadin-grid-sort-column header="Resource Name" path="resourceName" width="15%">
                            </vaadin-grid-sort-column>
                            <vaadin-grid-sort-column header="Feeder" path="feederCode" width="10%">
                            </vaadin-grid-sort-column>
                            <vaadin-grid-sort-column header="Description" path="description" width="25%">
                            </vaadin-grid-sort-column>                            
                        </vaadin-grid>
                    </div>
                  </paper-card>

                  </div>
                  <div>

                    <paper-card style="width: 100%; padding: 0 20px">
                        <div class="card-content">
                            <vaadin-grid id="icpTable" items="{{transInfo.icps}}" theme="compact row-stripes" on-active-item-changed="_openIcp">
                                <vaadin-grid-sort-column header="Icp" path="name" width="17.5%">
                                    <template>
                                        <span>{{item.name}}</span>
                                        <template is="dom-if" if="{{item.isHazard}}">
                                            <div style="display:inline-block">
                                                <iron-icon icon="warning" style="color:#E65100"></iron-icon>
                                                <paper-tooltip>Hazard</paper-tooltip>
                                            </div>
                                        </template>
                                        <template is="dom-if" if="{{item.isMedicallyDependent}}">
                                            <div style="display:inline-block">
                                                <iron-icon icon="maps:local-hospital" style="color:red"></iron-icon>
                                                <paper-tooltip>Medically Dependent</paper-tooltip>
                                            </div>
                                        </template>
                                        <template is="dom-if" if="{{item.isVip}}">
                                            <div style="display:inline-block">
                                                <iron-icon icon="av:new-releases" style="color:#ff7b00"></iron-icon>
                                                <paper-tooltip>VIP</paper-tooltip>
                                            </div>
                                        </template>
                                        <template is="dom-if" if="{{item.isKeyAccount}}">
                                            <div style="display:inline-block">
                                                <iron-icon icon="communication:vpn-key" style="color:#038a03"></iron-icon>
                                                <paper-tooltip>Key account</paper-tooltip>
                                            </div>
                                        </template>
                                    </template>
                                </vaadin-grid-sort-column>
                                <vaadin-grid-sort-column header="No. SSN" path="numSmartMeter" width="7.5%" >
                                    <template>
                                        <div style="display:inline-block">
                                            <iron-icon icon="notification:tap-and-play" style="color:blue"></iron-icon>
                                            <paper-tooltip>Smart meter</paper-tooltip>
                                            <span>{{item.numSmartMeter}} of {{item.totalMeters}}</span>
                                        </div>
                                    </template>
                                </vaadin-grid-sort-column>
                                <vaadin-grid-sort-column header="Customer No" path="customerNumber" width="10%" >
                                </vaadin-grid-sort-column>
                                <vaadin-grid-sort-column header="Customer Name" path="customerName" width="20%">
                                </vaadin-grid-sort-column>
                                <vaadin-grid-sort-column header="Street Address" path="streetAddress" width="15%">
                                </vaadin-grid-sort-column>
                                <vaadin-grid-sort-column header="Suburb" path="suburb" width="10%">
                                </vaadin-grid-sort-column>
                                <vaadin-grid-sort-column header="Town" path="town" width="10%">
                                </vaadin-grid-sort-column>
                                <vaadin-grid-sort-column header="Postcode" path="postcode" width="10%">
                                </vaadin-grid-sort-column>
                            </vaadin-grid>
                        </div>
                    </paper-card>

                </div>
            </iron-pages>
       </app-header-layout>

        <!--Work Order-->
        <core-overlay id="wodetail" auto-close-disabled="true" on-wodetail-close="closeWorkOrderOverlay" on-eventdetail-open="openEventOverlay">
            <wo-detail wo-id="{{selectedServiceOrder.no}}" app="{{app}}">
            </wo-detail>
        </core-overlay>   


        <!--Fault Alert-->
        <core-overlay id="faultAlertOverlay" class="right-side-overlay" auto-close-disabled="true" on-alertdetail-close="closeEventAlertOverlay">
            <fault-alert-detail shutdown-id="{{selectedEventId}}" app="{{app}}"></fault-alert-detail>
        </core-overlay>

        <core-overlay id="assetdetail" auto-close-disabled="true" on-icpdetail-close="closeAssetDetail">
            <icp-asset-detail asset-id="{{selectedAsset}}" is-from-transformer="true">
            </icp-asset-detail>
        </core-overlay>
        <core-overlay id="dlgfilter" autoclosedisabled="true">
            <paper-card>
                <div class="card-content">
                    <!-- Custom Filter-->
                    <div class="horizontal justified layout">
                        <paper-input label="From (dd.mm.yyyy)" value="{{fromDate}}" class="flex-2" required="" maxlength="10" minlength="10" pattern="^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))\$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$" auto-validate="" error-message="Invalid Date" invalid="{{isFromDateInvalid}}"></paper-input>
                        <paper-icon-button icon="icons:event" on-click="onCalFromClick"></paper-icon-button>
                        <paper-input label="HH:MM" value="{{fromTime}}" class="flex" required="" maxlength="5" minlength="5" pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]\$" auto-validate="" error-message="Invalid Time" invalid="{{isFromTimeInvalid}}"></paper-input>
                    </div>
                    <div class="horizontal justified layout">
                        <paper-input label="To (dd.mm.yyyy)" value="{{toDate}}" class="flex-2" required="" maxlength="10" minlength="10" pattern="^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))\$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$" auto-validate="" error-message="Invalid Date" invalid="{{isToDateInvalid}}"></paper-input>
                        <paper-icon-button icon="icons:event" on-click="onCalToClick"></paper-icon-button>
                        <paper-input label="HH:MM" value="{{toTime}}" class="flex" required="" maxlength="5" minlength="5" pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]\$" auto-validate="" error-message="Invalid Time" invalid="{{isToTimeInvalid}}"></paper-input>
                    </div>
                    <paper-button id="btnCustDateSearch" class="meter-data-filter" on-click="onCustDateClick" disabled="[[isFilterInvalid(isFromDateInvalid, isToDateInvalid,isFromTimeInvalid, isToTimeInvalid)]]" raised="">Search
                    </paper-button>
                </div>
                <div class="card-actions" style="background-color: #f5f5f5">
                    <p>Quick Filter</p>
                    <div class="horizontal justified">
                        <paper-icon-button icon="icons:event" disabled=""></paper-icon-button>
                        <paper-button id="btnVb1h" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="1h">1 Hour
                        </paper-button>
                        <paper-button id="btnVb6h" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="6h">6 Hours
                        </paper-button>
                        <paper-button id="btnVb1d" class="meter-data-filter colored" on-click="onBtnFilterClick" data-dur="1d">1 Day
                        </paper-button>
                        <paper-button id="btnVb2d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="2d">2 Days
                        </paper-button>
                    </div>
                    <div class="horizontal justified">
                        <paper-icon-button icon="icons:event" disabled=""></paper-icon-button>
                        <paper-button id="btnVb4d" class="meter-data-filter" data-dur="4d" on-click="onBtnFilterClick">4 Days
                        </paper-button>
                        <paper-button id="btnVb7d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="7d">7 Days</paper-button>
                        <paper-button id="btnVb14d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="14d">14 Days
                        </paper-button>
                        <paper-button id="btnVb30d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="30d">30 Days
                        </paper-button>
                    </div>
                    <paper-button>[[startDate]]</paper-button>
                </div>
            </paper-card>
        </core-overlay>
        <paper-toast id="toast" text=""></paper-toast>
         <iron-localstorage name="username" use-raw="" value="{{username}}"></iron-localstorage>
        `;
      }

    ready(){
        super.ready();
        chartCons = new google.visualization.ColumnChart(this.$.conschart);
        
    }

    assetIdChanged(newValue) {
        console.log(`new value: ${newValue}`);
        if (newValue) {
            this.selected = 0;
            this._getTransformerDetails();
        }
    }

    transformerCodeChanged(newValue) {
        if(newValue) {
            this.selected = 0;
            this._getTransformerDetailsByCode();
        }
    }

    filterStartDateChanged(newValue) {
        this.fromDate = this.formatFilterDate(newValue);
        this.$.dlgCalFrom.close();
    }

    filterEndDateChanged(newValue) {
        this.toDate = this.formatFilterDate(newValue);
        this.$.dlgCalTo.close();
    }

    selectedTabChanged(newValue) {
        //    Consumption
        // if (newValue == 1) {
        //     //Why is this here??
        // }
        //    Consumption
        if (newValue == 1) {
            if(this.transInfo) {
                chartCons.clearChart();
                this._getPowerConsumption(this.transInfo.name);
            }
        }
        // //History
        // if (newValue == 3) {
        //     this.urlLogMessages = `/api/icp/log/${this.icpInfo.name}`;
        //     this.$.ajaxMessageLog.generateRequest();
        // }
    }

    _getTransformerDetails() {
        this.isLoading = true;
        fetch(`/api/transformers/summary/${this.assetId}`, {
                credentials: 'include'
            })
            .then(resp => resp.json())
            .then(data => {
                this.transInfo = data.transformer;
                this.subInfo = data.substation;
                this.workOrderHistory = data.workOrderHistory;
                this.isLoading = false;
            });

    }

    _getTransformerDetailsByCode() {
        this.isLoading = true;
        fetch(`/api/transformers/summary/code/${this.transformerCode}`, {
                credentials: 'include'
            })
            .then(resp => resp.json())
            .then(data => {
                this.transInfo = data.transformer;
                this.subInfo = data.substation;
                this.workOrderHistory = data.workOrderHistory;
                this.isLoading = false;
            });
    }

    hideEventTables() {
        this.showBreachMinTable = false;
    }

    close() {
        this.dispatchEvent(new CustomEvent('assetdetail-close', {bubbles: true,  composed: true}));
    }

    onShowBreachMinTable() {
        this.url = `/api/transformers/${this.transInfo.name}/icps/min`;
    }

    onShowBreachMaxTable() {
        this.url = `/api/transformers/${this.transInfo.name}/icps/max`;
    }

    onShowBreachNeutralTable() {
        this.url = `/api/transformers/icps/breach/neutral/${this.transInfo.name}`;
    }

    onIcpClicked(e) {
        var model = e.model;
        this.selectedAsset = model.item.id;
        this.$.assetdetail.open();
    }

    _openIcp(e) {
        const item = e.detail.value;
        if(item) {
            this.$.icpTable.selectedItems = item ? [item] : [];
            this.selectedAsset = item.id;
            this.$.assetdetail.open();
        }
    }
    closeAssetDetail() {
        this.$.assetdetail.close();
    }

    isAllowed(app) {
        if (app) {
            return app.role.toUpperCase() === 'ADMINISTRATOR' || app.role.toUpperCase() === 'USER';

        } else {
            return false;
        }
    }

    onCalFromClick() {
        this.$.dlgCalFrom.open();
    }

    onCalToClick() {
        this.$.dlgCalTo.open();
    }

    formatFilterDate(d) {
        return moment(d).format('DD.MM.YYYY');
    }
    showDateFilterDialog() {
        this.$.dlgfilter.open();
    }

    isFilterInvalid(fromDateInvalid, toDateInvalid, fromTimeInvalid, toTimeInvalid) {
        if (fromDateInvalid || toDateInvalid || fromTimeInvalid || toTimeInvalid) {
            return true;
        } else {
            return false;
        }
    }

    _getPowerConsumption(code) {
        if(!this.isAllowed(this.app)){
            return;
        }
        this.set('isLoading', true);
        this.set('csvType', "pc");

        fetch(`/api/transformer/${code}/pc/${dur}`, {
                credentials: 'include'
            })
            .then(resp => resp.json())
            .then(data => {
                this.set('fromDateTime', data.startDate);
                this.set('toDateTime', data.endDate);

                let numIcps = this._getNumIcps(this.transInfo.icps);
                
                const summaryData = [
                    {name: 'No. Customers', value:this.transInfo.noOfCustomers},
                    {name:'No. ICPs', value: numIcps},
                    {name:'Total SSN',      value :data.totalSmartMeters},
                    {name: 'Total Non SSN', value:this.transInfo.numNotSmartMeter},
                    {name: 'Reporting SSN',     value :data.activeSmartMeters},
                    {name:'Max Reporting',  value :`${data.meterReadingPercentage}%`}
                ];
                this.summaryData = summaryData;
                this.drawPowerConsumption(data.consumptionData, data.distGenData);
                this.isLoading = false;
            });
        

    }

    _getPowerConsumptionByDateRange(from, to) {
        this.set('isLoading', true);
        this.set('csvType', "pc");
        fetch(`/api/transformer/${this.transInfo.name}/pc/from/${from}/to/${to}`, {
                credentials: 'include'
            })
            .then(resp => resp.json())
            .then(data => {
                this.set('fromDateTime', data.startDate);
                this.set('toDateTime', data.endDate);

                let numIcps = this._getNumIcps(this.transInfo.icps);

                const summaryData = [
                    {name: 'No. Customers', value:this.transInfo.noOfCustomers},
                    {name:'No. ICPs', value: numIcps},
                    {name:'Total SSN',      value :data.totalSmartMeters},
                    {name: 'Total Non SSN', value:this.transInfo.numNotSmartMeter},
                    {name: 'Reporting SSN',     value :data.activeSmartMeters},
                    {name:'Max Reporting',  value :`${data.meterReadingPercentage}%`}
                ];
                this.summaryData = summaryData;
                this.drawPowerConsumption(data.consumptionData, data.distGenData);
                this.isLoading = false;
            });
        

    }

    drawPowerConsumption(ds, distGen) {
        chartCons.clearChart();
        if (ds.length > 0 || distGen.length > 0) {
            // let meters = new Set();
            // ds.forEach((d) => meters.add(d.deviceNo));

            let data = new google.visualization.DataTable();
            data.addColumn('date', 'Date Consumption');
            data.addColumn('number', "Consumption (kWh)");
            data.addColumn('number', "Energy Export (kWh)");
            data.addColumn('number', "% Reporting");
            // meters.forEach((m) => {
            //     data.addColumn('number', m);
            // });

            ds.forEach((d) => {
                    let rowS = new Array(4); //new List(meters.length + 1);
                        rowS[0] = new Date(d.readingTime);
                        rowS[1] = {v: d.total, f: d.total.toFixed(2).toString()};
                        rowS[3] = d.activeMeterPercentage;
                        data.addRow(rowS);
                });

            if (distGen.length > 0) {
                
                distGen.forEach((d) => {
                    let rowS = new Array(4);
                    rowS[0] = new Date(d.readTime);
                    rowS[2] = {v: -d.total, f: d.total.toFixed(2).toString()};
                    data.addRow(rowS);
                });
            }
            //                       var fops = new DateFormatOptions()..pattern = "dd MMM yyyy HH:mm";
            //                       var frmt = new DateFormat(fops);
            //                       frmt.format(data, 0);

            // var ticksY = [{v: -200, f:'200'}, {v: -150, f:'150'}, {v: -100, f:'100'}, {v: -50, f:'50'},  0, 50, 100, 150, 200];


            //Create dynamic ticks by getting the maximum values for both consumption and dist gen
            //and round up to the nearest 10. Include 2 ticks on each side of the v axis
            let maxConsumption = 0;
            let maxDistGen = 0;
            for(let i = 0; i < data.getNumberOfRows(); i++) {
                if(data.getValue(i, 1)) {
                    if(data.getValue(i, 1) > maxConsumption) {
                        maxConsumption = data.getValue(i, 1);
                    }
                }
                
                if(data.getValue(i, 2)) {
                    if(data.getValue(i, 2) < maxDistGen) {
                        maxDistGen = data.getValue(i, 2);
                    }
                }
            }

            maxConsumption = Math.ceil(maxConsumption / 10) * 10;
            maxDistGen = Math.floor(maxDistGen / 10) * 10;

            let dynamicTicks = [
                {v: maxDistGen, f: Math.abs(maxDistGen).toString()}, 
                {v: maxDistGen/2, f: Math.abs(maxDistGen/2).toString()},
                0,
                maxConsumption/2,
                maxConsumption
            ];

            var options = {
                title: "Consumption (kWh)",
                hAxis: {
                    'format': 'dd MMM yy HH:mm'
                },
                series: {
                    0: {type: 'bars', targetAxisIndex: 0},
                    1: {type: 'bars', targetAxisIndex: 0},
                    2: {type: 'line', targetAxisIndex: 1}
                },
                vAxes: { 
                    0: {
                        ticks: dynamicTicks
                    },
                    1: {
                        ticks: [0, 25, 50, 75, 100],
                    }
                },
                isStacked: true,
            };

            var date_formatter = new google.visualization.DateFormat({ 
                pattern: "dd MMM yy HH:mm"
            }); 
            date_formatter.format(data, 0);
            chartCons.draw(data, options);
        }
    }

    onBtnFilterClick(e) {
        let btn = e.currentTarget;
        dur = btn.dataset.dur;
        chartCons.clearChart();
        this._getPowerConsumption(this.transInfo.name);
    }

    onCustDateClick() {
        let from = `${this.fromDate} ${this.fromTime}`;
        let to = `${this.toDate} ${this.toTime}`;
        this._getPowerConsumptionByDateRange(from, to);
        // if (this.selectedChart == 0) {
            // this._getPowerConsumptionByDateRange(from, to);
        // }
    
    }

    onPingMeters(e) {
       
        let data = JSON.stringify({
            'transformer': this.transInfo.name,
            'username': this.username
        });

        fetch(`/api/ping/transformer/${this.transInfo.name}`, {
                credentials: 'include',
                 method: 'POST',
                body: data
            })
            .then(resp => resp.json())
            .then(() => {

                // this._getRealTimeEvents(this.transInfo.name);
                // this.$.ajaxMessageLog.generateRequest();
                this.showToast("Transformer Meters Pinged, you will be notified of the result");
            })
            .catch((error) => {
                console.log('Request failed', error);
                this.showToast("Error Submitting Ping Request");
            });
    }

    showToast(text) {
        this.$.toast.show(text);
    }

    _formatBool(v) {
        return v ? "Yes" : "No";

    }

    formatDateTime(s) {
        return s.includes("0001-01-01")
          ? ""
          : moment.parseZone(s).format("DD MMM YYYY HH:mm");
      }

    _openWo(e) {
        const item = e.detail.value;
        this.$.shTable.selectedItems = item ? [item] : [];
        this.selectedServiceOrder = item;
      }
  
      _selectedServiceOrderChanged(newValue) {
        if (newValue) {
            this.$.wodetail.open();
        }
    }
    
    closeWorkOrderOverlay() {
      this.$.wodetail.close();
      this.selectedServiceOrder = null;
    }
  
    openEventOverlay() {
      if (this.selectedServiceOrder.eventId > 0) {
          this.set('selectedEventId', this.selectedServiceOrder.eventId);
          this.$.faultAlertOverlay.open();
      } else {
          this.showToast("No Event associated with Work Order");
      }
    }
  
    closeEventAlertOverlay() {
      this.set('selectedEventId', null);
      this.$.faultAlertOverlay.close();
    }

    _greaterThanZero(v) {
        return v > 0 ? true : false;
    }

    _getNumIcps(icps) {
        return icps ? icps.length : 0;
    }

}


customElements.define(TransformerAssetDetail.is, TransformerAssetDetail);
