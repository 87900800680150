
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import { setPassiveTouchGestures, setRootPath } from '@polymer/polymer/lib/utils/settings.js';
import '@polymer/app-layout/app-drawer/app-drawer.js';
import '@polymer/app-layout/app-drawer-layout/app-drawer-layout.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-scroll-effects/app-scroll-effects.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-route/app-location.js';
import '@polymer/app-route/app-route.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/iron-selector/iron-selector.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/iron-icons/iron-icons.js';
import '@polymer/iron-icons/social-icons.js';
import '@polymer/iron-icons/device-icons.js';
import '@polymer/iron-icons/hardware-icons.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-item/paper-item-body.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-menu-button/paper-menu-button.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/iron-icons/iron-icons.js';
import '@polymer/iron-icons/social-icons.js';
import '@polymer/iron-icons/device-icons.js';
import '@polymer/iron-icons/hardware-icons.js';
import '@polymer/iron-icons/av-icons.js';
import '@polymer/iron-selector/iron-selector.js';
import '@polymer/iron-flex-layout/iron-flex-layout.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/iron-scroll-threshold/iron-scroll-threshold.js';
import './libs/polymer-sortablejs.js';
// import Sortable from 'sortablejs';
import '@polymer/paper-toast/paper-toast.js';
import '@google-web-components/google-chart'
import './app-storage.js';
import './login-view.js';
// import './dash-shell.js';
// import './map-shell.js';
import './admin-shell.js';
import './my-view1'
import './components/core-overlay.js';
import './app-security-behavior.js';
import './components/substation-ripple-detail.js';
import '@polymer/paper-toggle-button/paper-toggle-button.js';
import moment from 'moment';
// import Amplify from '@aws-amplify/core';
// import { Auth } from '@aws-amplify/auth';
// import {API} from '@aws-amplify/api'

import '@webcomponents/webcomponentsjs/webcomponents-loader.js'
import 'web-animations-js'
// import {load} from '@google-web-components/google-chart/loader';

// import './my-icons.js';

// Gesture events like tap and track generated from touch will not be
// preventable, allowing for better scrolling performance.
setPassiveTouchGestures(true);

// Set Polymer's root path to the same value we passed to our service worker
// in `index.html`.
setRootPath(MyAppGlobals.rootPath);

const WS_URL = ((window.location.protocol === "https:") ? "wss://" : "ws://") + window.location.host + "/ws";
let ws;

class AppShell extends mixinBehaviors([AmiBehaviors.SecurityBehavior], PolymerElement ){
  
  static get template() {
    return html`
    <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>
    <style>
         :host {
          --app-primary-color: #4285f4;
          --app-secondary-color: black;

          display: block;
        }

        app-drawer-layout:not([narrow]) [drawer-toggle] {
          display: none;
        }

        app-header {
          color: #fff;
          background-color: var(--app-primary-color);
          z-index: auto;
        }

        app-header paper-icon-button {
          --paper-icon-button-ink-color: white;
        }

        .drawer-list {
          margin: 0 20px;
        }

        .drawer-list a {
          display: block;
          padding: 0 16px;
          text-decoration: none;
          color: var(--app-secondary-color);
          line-height: 40px;
        }

        .drawer-list a.iron-selected {
          color: black;
          font-weight: bold;
        }
        
        body {
            font-family: sans-serif;
            color: #FFF;
            margin: 0;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        
        #pages {
            height: 100%;
        }
        
        map-view {
            height: 100%;
        }
        
        app-toolbar {
            color: #ffffff;
            background: #3f474d;
        }
         #dlgmessages {
            position: absolute;
            top: 64px;
            right: 1px;
            width: 400px;
            height: 400px;
            /*margin-left: 50px;*/
        }
        #dlgResources {
            position: absolute;
            top: 64px;
            right: 50px;
            width: 400px;
            height: 500px;
            /*margin-left: 50px;*/
        }

        #dlgLoneWorker {
            position: absolute;
            top: 64px;
            right: 100px;
            width: 450px;
            height: 800px;
            /*margin-left: 50px;*/
        }

        #dlgRipple {
            position: absolute;
            top: 64px;
            left: 10px;
            right: 10px;
            height: 300px;
        }

        #wodetail {
            position: fixed;
            left: 0px;
            bottom: 0px;
            background: white;
            width: 100%;
            height: 500px;
            max-height: 1000px;
            overflow: auto;
        }

        #dlgResourceSearch {
            bottom: 0px;
            width: 400px;
            /*height: 400px;*/
            overflow: auto;
        }

        paper-item{
            height: 48px;
        }

        .ping-result{
            margin-right: 10px;
        }
        .ping-success-icon{
            color:#66BB6A;
        }
        .ping-error-icon{
            color:#FF7043;
        }
        .res-state{
            color:#bdbdbd;
        }

        app-toolbar{
            font-size: 13px;
        }
        paper-input {
                --paper-input-container-underline: {
                    display: none;
                };
                --paper-input-container-underline-focus: {
                    display: none;
                };
                --paper-input-container-underline-disabled: {
                    display: none;
                };
            }

            .access-message{
                margin-top:200px;
                padding: 50px;
                border: dashed 1px red;
                font-weight: bold;

            }
        </style>

      <!-- <app-location route="{{route}}" url-space-regex="^[[rootPath]]">
      </app-location>

      <app-route route="{{route}}" pattern="[[rootPath]]:page" data="{{routeData}}" tail="{{subroute}}">
      </app-route> -->

      <app-location route="{{route}}" url-space-regex="^[[rootPath]]" use-hash-as-path>
      </app-location>

      <app-route route="{{route}}" pattern="[[rootPath]]:page" data="{{routeData}}" tail="{{subroute}}" >
      </app-route>
      <div style="height: 100%">
      <!-- <template is="dom-if" if="{{showLogin}}">
        <div class="horizontal center-justified layout">
           <div class="access-message">
               <p>You don't have access to this Application!</p>
               <p>Contact your System Administrator to gain access</p>
            </div>
        </div>
     
    </template> -->
        <template is="dom-if" if="{{showLogin}}">
          <login-view app="{{app}}"></login-view>
        </template>
        <template is="dom-if" if="{{!showLogin}}">
      <app-drawer-layout fullbleed="" narrow="{{narrow}}" force-narrow>
        <!-- Drawer content -->
        <app-drawer id="drawer" slot="drawer" swipe-open="[[narrow]]">
          <app-toolbar><h3>Counties Energy | INDI</h3></app-toolbar>
          <iron-selector selected="[[page]]" attr-for-selected="name" class="drawer-list" role="navigation">
            <a name="dash" href="[[rootPath]]#/dash">
                <paper-item>
                    <iron-icon icon="home"></iron-icon>
                    <paper-item-body>
                        Home
                    </paper-item-body>
                </paper-item>
            </a>
            <a name="map" href="[[rootPath]]#/map">
                <paper-item>
                    <iron-icon icon="device:location-searching"></iron-icon>
                    <paper-item-body>
                        Map
                    </paper-item-body>
                </paper-item>
            </a>
            <a name="admin" href="[[rootPath]]#/admin">
                <paper-item>
                    <iron-icon icon="hardware:security"></iron-icon>
                    <paper-item-body>
                        Admin
                    </paper-item-body>
                </paper-item>
            </a>
          </iron-selector>
        </app-drawer>

        <!-- Main content -->
        
        <app-header-layout has-scrolling-region="">

          <app-header slot="header" fixed>
            <app-toolbar>
              <!-- <paper-icon-button icon="menu" on-click="onNavOpen"></paper-icon-button> -->
              <paper-icon-button icon="menu" drawer-toggle=""></paper-icon-button>
              <div main-title=""><h3>Counties Energy | INDI</h3></div>
              <div>Updated: <span>{{lastUpdatedTime}}</span></div>
              <paper-button alt="Ripple" title="Ripple" on-click="onRippleClicked">
                  <iron-icon icon="invert-colors"></iron-icon>
                  Ripple
              </paper-button>
              <paper-button alt="Lone Worker Status" title="Lone Worker Status" on-click="onLoneWorkerStatusClicked">
                  <iron-icon icon="accessibility" class\$="[[_getTotalLoneWorkerFailedClass(loneWorkerResourceWoStatus, loneWorkerResourceStatus)]]"></iron-icon>
                  {{_getTotalLoneWorkerFailed(loneWorkerResourceWoStatus, loneWorkerResourceStatus)}}
              </paper-button>
              <!--<paper-icon-button icon="perm-contact-calendar" alt="Resources" title="Resources" on-click="onResourceStateClicked"></paper-icon-button>-->
              <paper-button alt="Faults reported" title="Faults Reported">
                  <iron-icon icon="warning"></iron-icon>
                  {{_getFaultsReportedText(outageEventInfo.totalNewFaultsReported)}}
              </paper-button>
              <paper-button alt="Total On Duty" title="Total On Duty" on-click="onResourceStateClicked">
                  <iron-icon icon="perm-contact-calendar"></iron-icon>
                  {{_getTotalOnDuty(resourceWorkorderStates)}}
              </paper-button>
              <paper-icon-button icon="speaker-notes" alt="Messages" title="Messages" on-click="onMessagesClicked" hidden\$="[[!isPingViewAllowed(app)]]"></paper-icon-button>
              
              <paper-menu-button>
                <paper-button alt="Total Connected Users" title="Total Connected Users" slot="dropdown-trigger">
                  <iron-icon icon="social:people"></iron-icon>
                  {{totalConnectedUsers}}
                </paper-button>
                  <paper-listbox slot="dropdown-content">
                  <template is="dom-repeat" items="{{outageEventInfo.connectedUsers}}">
                      <paper-item role="menuitemcheckbox">
                          <iron-icon icon="face"></iron-icon>
                          <paper-item-body>
                              <div style="font-size: 12px;">[[item]]</div>
                          </paper-item-body>
                      </paper-item>
                  </template>
                  </paper-listbox>
              </paper-menu-button>
              <paper-menu-button horizontal-align="right">
                  <paper-icon-button icon="more-vert" slot="dropdown-trigger" alt="More" title="More"></paper-icon-button>
                  <paper-listbox slot="dropdown-content">
                      <!-- <paper-item>
                          <iron-icon icon="settings"></iron-icon>
                          <paper-item-body>
                              <div>Settings</div>
                          </paper-item-body>
                      </paper-item> -->
                      <paper-item on-click="signout">
                          <iron-icon icon="block"></iron-icon>
                          <paper-item-body>
                              <div>Logout</div>
                          </paper-item-body>
                      </paper-item>
                  </paper-listbox>
              </paper-menu-button>
            </app-toolbar>
          </app-header>

          <iron-pages role="main" selected="[[page]]" attr-for-selected="name" style="height: 100%;" selected-attribute="activated">
              <dash-shell name="dash" app="{{app}}" selected="{{homeSubPage}}" outage-event-info="[[outageEventInfo]]" dragged-resource="{{draggedResource}}"></dash-shell>
              <!-- <map-shell name="map" app="{{app}}" outage-event-info="[[outageEventInfo]]" dragged-resource="{{draggedResource}}"></map-shell> -->
              <admin-view name="admin" app="{{app}}" selected="{{adminSubPage}}"></admin-view>
              <my-view1 name="map" app="{{app}}" selected="{{mySubPage}}" outage-event-info="[[outageEventInfo]]" dragged-resource="{{draggedResource}}"></my-view1>
          </iron-pages>
        </app-header-layout>

        
        
        
      </app-drawer-layout>
      </template>
      </div>

       <!--Overlay Ping -->
       <core-overlay id="dlgmessages" autoclosedisabled="true">
                            
          <iron-pages selected="[[pingPage]]" selected-attribute="activated">
                  <section>
                      <paper-card style="width:100%">
                          <div class="card-actions" style="background-color: #f5f5f5">
                              <p>In Progress</p>
                          
                          </div>
                          <div class="card-content">
                              <template is="dom-repeat" items="{{_pingProgressLimit(outageEventInfo.progressPingEvents)}}">
                                  <paper-item>
                                      <paper-item-body two-line="">
                                          <div>[[item.assetNo]]</div>
                                          <div secondary=""><span>[[item.pingType]]</span> - <span>[[item.username]]</span></div>
                                      </paper-item-body>
                                      <iron-icon icon="hardware:router"></iron-icon>
                                      <div class="ping-result">[[item.totalSubmitted]]</div>
                                      <iron-icon class="ping-success-icon" icon="thumb-up"></iron-icon>
                                      <div class="ping-result">[[item.totalSuccess]]</div>
                                      <iron-icon class="ping-error-icon" icon="thumb-down"></iron-icon>
                                      <div>[[item.totalErrored]]</div>
                                  </paper-item>
                              </template>
                          </div>
                          <div class="card-actions" style="background-color: #f5f5f5">
                              <p>Completed</p>
                          
                          </div>
                          <div class="card-content">
                              <template is="dom-repeat" items="{{outageEventInfo.completedPingEvents}}">
                                  <paper-item>
                                      <paper-item-body two-line="">
                                          <div>[[item.assetNo]]</div>
                                          <div secondary=""><span>[[item.pingType]]</span> - <span>[[item.username]]</span></div>
                                  
                                      </paper-item-body>
                                      <iron-icon icon="hardware:router"></iron-icon>
                                      <div class="ping-result">[[item.totalSubmitted]]</div>
                                      <iron-icon class="ping-success-icon" icon="thumb-up"></iron-icon>
                                      <div class="ping-result">[[item.totalSuccess]]</div>
                                      <iron-icon class="ping-error-icon" icon="thumb-down"></iron-icon>
                                      <div>[[item.totalErrored]]</div>
                                      <paper-icon-button icon="arrow-forward" on-click="onPingItemClick"></paper-icon-button>
                                  </paper-item>
                              </template>
                          </div>
                          <div class="card-actions" style="background-color: #f5f5f5">
                          <paper-button on-click="onViewAllPingsClick">View All Results</paper-button>
                          </div>
                      </paper-card>
                  </section>
                  <section>
                      <!--View All-->
                      
                      <paper-card style="width:450px">
                          <div class="card-actions" style="background-color: #f5f5f5">
                              <paper-button on-click="onViewBackPingsClick">
                                  <iron-icon icon="arrow-back"></iron-icon>
                                  Back
                              </paper-button>
                          
                          </div>
                          <div class="card-content" style="padding:0;">
                              <iron-scroll-threshold id="scrollThreshold" on-lower-threshold="loadMorePingData" style="height:400px;">
                                  <template is="dom-repeat" items="[[pingItems]]">
                                      <paper-item>
                                          <paper-item-body two-line="">
                                              <div>[[item.assetNo]]</div>
                                              <div secondary=""> <span>[[item.pingType]]</span> - <span>[[formatDateFromNow(item.createdAt)]]</span> by <span>[[item.username]]</span></div>
                                          </paper-item-body>
                                          <iron-icon icon="hardware:router"></iron-icon>
                                          <div class="ping-result">[[item.totalSubmitted]]</div>
                                          <iron-icon class="ping-success-icon" icon="thumb-up"></iron-icon>
                                          <div class="ping-result">[[item.totalSuccess]]</div>
                                          <iron-icon class="ping-error-icon" icon="thumb-down"></iron-icon>
                                          <div>[[item.totalErrored]]</div>
                                            <paper-icon-button icon="arrow-forward" on-click="onPingItemClick"></paper-icon-button>
                                      </paper-item>
                                  </template>
                              </iron-scroll-threshold>
                              
                          </div>
                      </paper-card>
                          
                  </section>
                    <section>
                      <!--View ICP List-->
                      
                      <paper-card style="width:450px">
                          <div class="card-actions" style="background-color: #f5f5f5">
                              <paper-button on-click="onViewBackPingsClick">
                                  <iron-icon icon="arrow-back"></iron-icon>
                                  Back
                              </paper-button>
                          
                          </div>
                          <div class="card-content" style="padding:0; height:400px; overflow:auto;">
                              
                                  <template is="dom-repeat" items="[[pingMeterItems]]">

                                          <paper-card style="width:450px; margin-bottom: 10px;">
                                                  
                                                  <div class="card-content">
                                                      <div class="horizontal justified layout">
                                                              <!-- <paper-input readonly label="ICP #" value="[[item.icp]]"></paper-input> -->
                                                              <paper-input readonly="" label="[[item.icp]]" value="[[item.deviceNo]]"></paper-input>
                                                              
                                                              <paper-input readonly="" label="When" value="[[formatDateFromNow(item.dateSubmitted)]]"></paper-input>
                                                              <iron-icon class\$="[[getIconClass(item.pingResponse)]]" icon="[[getIcon(item.pingResponse)]]"></iron-icon>
                                                          
                                                      </div>
                                                      <template is="dom-if" if="[[item.actPower3.Valid]]">
                                                              <div style="background-color: #f5f5f5">Total Power</div>
                                                              <div class="horizontal layout" style="background-color: #f5f5f5">
                                                                      <paper-input readonly="" label="Active" value="[[formatFloatCurrentVoltage(item.actPower3)]]"></paper-input>
                                                                      <paper-input readonly="" label="Apparent" value="[[formatFloatCurrentVoltage(item.appPower3)]]"></paper-input>
                                                                      <paper-input readonly="" label="Reactive" value="[[formatFloatCurrentVoltage(item.varPower3)]]"></paper-input>
                                                                      
                                                                  </div>
                                                                  <div>Voltage</div>
                                                                  <div class="horizontal layout">
                                                                          <paper-input readonly="" label="Phase A" value="[[formatFloatCurrentVoltage(item.voltage0)]]"></paper-input>
                                                                          <paper-input readonly="" label="Phase B" value="[[formatFloatCurrentVoltage(item.voltage1)]]"></paper-input>
                                                                          <paper-input readonly="" label="Phase C" value="[[formatFloatCurrentVoltage(item.voltage2)]]"></paper-input>
                                                                          
                                                                  </div>
                                                                  <div style="background-color: #f5f5f5">Current</div>
                                                                  <div class="horizontal layout" style="background-color: #f5f5f5">
                                                                          <paper-input readonly="" label="Phase A" value="[[formatFloatCurrentVoltage(item.current0)]]"></paper-input>
                                                                          <paper-input readonly="" label="Phase B" value="[[formatFloatCurrentVoltage(item.current1)]]"></paper-input>
                                                                          <paper-input readonly="" label="Phase C" value="[[formatFloatCurrentVoltage(item.current2)]]"></paper-input>
                                                                          <paper-input readonly="" label="Neutral" value="[[formatFloatCurrentVoltage(item.current3)]]"></paper-input>
                                                                          
                                                                  </div>

                                                      </template>
                                                      <template is="dom-if" if="[[!item.actPower3.Valid]]">
                                                          <div>No Ping Results</div>
                                                      </template>
                                                  </div>
                                                  
                                          </paper-card>
                                  </template>
                            
                              
                          </div>
                      </paper-card>
                          
                  </section>
          </iron-pages>
            
      
</core-overlay>
                        
                        <!--End overlay-->
                        <!--Resources Ping -->
        <core-overlay id="dlgResources" autoclosedisabled="true">
            <paper-card style="width:450px">
                
                <div class="card-actions" style="background-color: #f5f5f5">
                    
                    <h4>Field Services Team</h4>
                   
                </div>
                <div class="card-actions" style="background-color: whitesmoke;">
                        <div>
                            <paper-input id="tbxResourceSearch" placeholder="Type to search" class="search" value="{{searchText}}">
                                <paper-icon-button slot="prefix" icon="search"></paper-icon-button>
                                <paper-icon-button slot="suffix" icon="communication:clear-all" on-click="onClearSearchText"></paper-icon-button>
                            </paper-input>
                        </div>
                       
                    
                    </div>
                
               
                <div class="card-content" style="padding:0; height:660px; overflow:auto;">
                    <sortable-js on-start="_onDragStart" on-end="_onDragEnd">
                    
                        <template id="resourceList" is="dom-repeat" initial-count="20" items="[[filterItems(resourceWorkorderStates, searchText)]]">
                            <paper-card style="width: 100%; margin-bottom: 5px;">
                                <div class="card-content">
                                    <div class="horizontal layout">
                                        <iron-icon class\$="[[_getResourceStateClass(item.isOnDuty)]]" icon="[[_getResourceStateIcon(item.isOnDuty)]]"></iron-icon>
                                        <div class="flex" style="font-weight: bold; margin-top: 5px;">
                                            <div>[[item.resourceName]]</div>
                                        </div>
                                        <div style="display:inline-block">
                                            <iron-icon style="color:#f44336; margin-left:4px;" icon="warning"></iron-icon>
                                            <paper-tooltip>Issued Orders</paper-tooltip>
                                        </div>
                                        <div>[[item.issuedCount]]</div>
                                        
                                        <div style="display:inline-block">
                                            <iron-icon style="color:#ffc107; margin-left:4px;" icon="assignment-ind"></iron-icon>
                                            <paper-tooltip>Accepted Orders</paper-tooltip>
                                        </div>
                                        <div>[[item.acceptCount]]</div>

                                        <div style="display:inline-block">
                                            <iron-icon style="color:#03a9f4; margin-left:4px;" icon="store"></iron-icon>
                                            <paper-tooltip>On Site</paper-tooltip>
                                        </div>
                                        <div>[[item.onsiteCount]]</div>

                                        <div style="display:inline-block">
                                            <iron-icon style="color:#8bc34a; margin-left:4px;" icon="done-all"></iron-icon>
                                            <paper-tooltip>Completed Today</paper-tooltip>
                                        </div>
                                        <div>[[item.ClosedTodayCount]]</div>
                                        <!--<iron-icon class\$="[[getIconClass(item.pingResponse)]]" icon="[[getIcon(item.pingResponse)]]"></iron-icon>-->
                                    </div>
                                    <div class="spacer"></div>
                                    <div class="horizontal layout" style="margin-top: 8px;">
                                        <div class="flex">
                                            Vehicle: <span style="font-weight: bold">[[item.resourceVehicle]]</span>
                                        </div>
                                        <div class="flex">
                                            Roster: <span style="font-weight: bold">[[item.rosteredTime]]</span>
                                        </div>
                                    </div>
                                    <div class="horizontal layout" style="margin-top: 8px;">
                                        <div class="flex">
                                            Work Type: <span style="font-weight: bold">[[item.workType]]</span>
                                        </div>
                                        <div class="flex">
                                            Passengers: <span style="font-weight: bold">[[item.numPassengers]]</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-actions" style="background-color: whitesmoke;">
                                        <div class="horizontal layout">
                                            <paper-button on-click="_onAddToLoneWorker">
                                                <iron-icon icon="icons:add-alert"></iron-icon>
                                                Add to Lone Worker Check
                                            </paper-button>
                                        </div>
                                </div>
                            </paper-card>
                        </template>
                    
                  </sortable-js>  
                </div>
            </paper-card>
        </core-overlay>
        
        <!--End overlay-->

        <core-overlay id="dlgLoneWorker" autoclosedisabled="true">
            <paper-card style="width:450px; height:800px;  overflow:auto;">
                
                <div class="card-actions" style="background-color: #f5f5f5">
                    <div class="horizontal layout justified">
                        <div><h4>Lone Worker Workorder Check (<span>[[getNoOfItems(loneWorkerResourceWoStatus)]]</span>)</h4></div>
                        <paper-toggle-button checked="{{isShowAllLoneWorkerOn}}">Show All</paper-toggle-button>
                    </div>
                </div>
                
                
                
                <div class="card-content" style="padding:0; ">
                    <!-- <sortable-js on-start="_onDragStart" on-end="_onDragEnd"> -->
                        <template is="dom-if" if="[[!hasItems(loneWorkerResourceWoStatus)]]">
                            <div class="horizontal center-justified layout">
                               <div class="access-message">No Active Workorders</div>
                            </div>
                        </template>
                        <template is="dom-repeat" id="lstWorkorderLoneWorker" items="[[filterLoneworkerWorkorderItems(loneWorkerResourceWoStatus,isShowAllLoneWorkerOn)]]">

                            <div class="cards">
                                <paper-card style="width:100%; margin-bottom: 5px;">
                                    <div class="card-content">
                                        <div class="horizontal layout justified">
                                            <div>
                                                No: <span>[[item.No]]</span>
                                            </div>
                                            <div>
                                                Order Date: <span style="font-weight: bold">[[formatDate(item.OrderDateTime)]]</span>
                                            </div>

                                        </div>

                                        <div class="horizontal layout justified">
                                            <div>
                                                Resource: <span style="font-weight: bold">[[item.ResourceName]]</span>
                                            </div>
                                            
                                        </div>
                                        <div>
                                            <p>[[item.Description]]</p>
                                        </div>

                                        <div class="horizontal layout justified">
                                            <div>
                                                Accept: <span style="font-weight: bold">[[item.AcceptCheck]]</span>
                                            </div>
                                            <div>
                                                ETA: <span style="font-weight: bold">[[item.ETACheck]]</span>
                                            </div>
                                            <div>
                                                ETR: <span style="font-weight: bold">[[item.ETRCheck]]</span>
                                            </div>
                                            <div>
                                                Status: <span style="font-weight: bold">[[item.StatusCheck]]</span>
                                            </div>
                                            
                                        </div>
                                        <div class="horizontal layout justified">
                                            <div>
                                                Checked By: <span style="font-weight: bold">[[item.LastCheckedBy]]</span>
                                            </div>
                                            <div>
                                                    Last Checked: <span style="font-weight: bold">[[formatDateTime(item.LastCheckedAt)]]</span>
                                            </div>
                                                
                                        </div>
                                        
                                        <div class="">
                                                <span></span>[[item.lastComment]]
                                        </div>

                                    </div>
                                    <div class="card-actions horizontal justified layout">
                                        <iron-icon class\$="[[_getLoneWorkerStateClass(item)]]" icon="[[_getLoneWorkerStateIcon(item)]]"></iron-icon>
                                        <paper-icon-button icon="file-upload" on-click="_onOpenWorkOrderClicked"></paper-icon-button>
                                        <!-- <paper-icon-button icon="add-alert" on-click="_onAddLoneWorkerWorkorder"></paper-icon-button> -->
                                        <paper-button on-click="_onClearWorkorderLoneWorkerCheckClick">
                                                <iron-icon icon="icons:restore"></iron-icon>
                                                Clear Check
                                        </paper-button>
                                        <paper-button on-click="_onDeleteWorkorderLoneWorkerCheckClick">
                                                <iron-icon icon="icons:delete"></iron-icon>
                                                
                                        </paper-button>

                                    </div>
                                </paper-card>
                            </div>
                        </template>
                    
                  <!-- </sortable-js>   -->
                </div>
                <div class="card-actions" style="background-color: #f5f5f5">
                        <div class="horizontal justified layout">
                            <div><h4>Lone Worker Resource Check (<span>[[getNoOfItems(loneWorkerResourceStatus)]]</span>)</h4></div>
                            <div><paper-icon-button icon="add-alert" on-click="_onAddLoneWorkerResource"></paper-icon-button></div>
                        </div>
                        
                    
                    </div>
                    
                    
                    <div class="card-content" style="padding:0;">
                        <!-- <sortable-js on-start="_onDragStart" on-end="_onDragEnd"> -->
                            <template is="dom-if" if="[[!hasItems(loneWorkerResourceStatus)]]">
                                <div class="horizontal center-justified layout">
                                   <div class="access-message">No resources added.</div>
                                </div>
                            </template>
                            <template is="dom-repeat" id="lstLoneWorkerResources" items="[[filterLoneworkerResourceItems(loneWorkerResourceStatus,isShowAllLoneWorkerOn)]]">
    
                                <div class="cards">
                                    <paper-card style="width:100%; margin-bottom: 5px;">
                                        <div class="card-content">
                                            <div class="horizontal layout justified">
                                                <div>
                                                    Resource: <span style="font-weight: bold">[[item.resourceName]]</span>
                                                </div>
                                                <div>
                                                    Last Checked: <span style="font-weight: bold">[[formatDateTime(item.lastCheckedAt)]]</span>
                                                </div>
    
                                            </div>
                                            <div class="horizontal layout justified" style="margin-top: 7px; margin-bottom: 7px;">
                                                <div>
                                                    Contact No: <span style="font-weight: bold">[[item.contactNo]]</span>
                                                </div>
                                                <div>
                                                    Vehicle: <span style="font-weight: bold">[[item.vehicleNo]]</span>
                                                </div>
                                                <div>
                                                    Preferred Comms: <span style="font-weight: bold">[[item.preferredCommunication]]</span>
                                                </div>
    
                                            </div>
                                            
    
                                            <div class="horizontal layout justified">
                                                <div>
                                                    Checked By: <span style="font-weight: bold">[[item.lastCheckedBy]]</span>
                                                </div>
                                                <div>
                                                        Status: <span style="font-weight: bold">[[item.statusCheck]]</span>
                                                </div>
                                                
                                            </div>
                                            <div class="">
                                                    <span></span>[[item.lastComment]]
                                            </div>
                                            
    
                                        </div>
                                        <div class="card-actions horizontal justified layout">
                                            <iron-icon class\$="[[_getLoneWorkerResourceStateClass(item)]]" icon="[[_getLoneWorkerResourceStateIcon(item)]]"></iron-icon>
                                            <paper-button on-click="_onClearCheckLoneWorkerCheckClick">
                                                    <iron-icon icon="icons:restore"></iron-icon>
                                                    Clear Check
                                            </paper-button>
                                            <paper-button on-click="_onDeleteCheckLoneWorkerCheckClick">
                                                    <iron-icon icon="icons:delete"></iron-icon>
                                                    
                                            </paper-button>
    
                                        </div>
                                    </paper-card>
                                </div>
                            </template>
                        
                      <!-- </sortable-js>   -->
                    </div>
            </paper-card>
           
        </core-overlay>
        
        <!--End overlay-->

        <core-overlay id="dlgRipple" class="flex-6" autoclosedisabled="true">
            <substation-ripple-detail data="{{substationRippleInfo}}"></substation-ripple-detail>
        </core-overlay>

        <paper-dialog id="dlgResourceSearch">
                <h3>Select Resource</h3>
                <div>
                    <paper-input id="tbxSearch" placeholder="Search by Name" class="search" value="{{searchText}}">
                        <paper-icon-button slot="prefix" icon="search"></paper-icon-button>
                        <paper-icon-button slot="suffix" icon="communication:clear-all" on-click="onClearSearchText"></paper-icon-button>
                    </paper-input>
                </div>
                <paper-dialog-scrollable>
                    <div role="listbox">
                         <template is="dom-repeat" items="[[resources]]" filter="{{computeResourceFilter(searchText)}}">
                            <paper-item data-value="[[item.ResourceNo]]" on-click="onResourceSelected">[[item.ResourceName]]</paper-item>
                        </template>
                    </div>
                </paper-dialog-scrollable>
                <div class="buttons">
                    <paper-button dialog-dismiss="">Cancel</paper-button>
                </div>
            </paper-dialog>

        <paper-dialog id="dlgConfirmAddToLoneWorker" no-cancel-on-outside-click="">
                <h2>Confirm Add to Lone Worker Check</h2>
                <paper-dialog-scrollable>
                    <p>Are you sure you want to add to Lone Worker Check?</p>
                    <paper-input label="Contact No." value="{{selectedLoneWorkerRequest.contactNo}}" placeholder="Enter Contact No."></paper-input>
                    <paper-input label="Vehicle No." value="{{selectedLoneWorkerRequest.vehicleNo}}" placeholder="Enter Vehicle No."></paper-input>
                    <label>Preferred Communication</label>
                    <paper-dropdown-menu style="width: 100px;" no-label-float="" no-animations="">
                        <paper-listbox slot="dropdown-content" selected="{{selectedLoneWorkerRequest.preferredCommunication}}" attr-for-selected="name">
                            <paper-item name="Phone">Phone</paper-item>
                            <paper-item name="Text">Text</paper-item>
                            <paper-item name="RT">RT</paper-item>
                        </paper-listbox>
                    </paper-dropdown-menu>
                </paper-dialog-scrollable>
                
                <div class="buttons">
                    <paper-button dialog-dismiss="">Cancel</paper-button>
                    <paper-button dialog-confirm="" autofocus="" on-click="_onAddToLoneWorkerConfirmClick" raised="">Confirm</paper-button>
                </div>
            </paper-dialog>

            <paper-dialog id="dlgConfirmUpdateLoneWorker" no-cancel-on-outside-click="">
                    <h2>Confirm Update to Lone Worker Check</h2>
                    <paper-dialog-scrollable>
                        <p>Are you sure you want to Update the Lone Worker Check?</p>
                        <paper-card style="width:100%;">
                                <div class="card-actions" style="background-color: white">
                                        <div class="horizontal justified layout">
                                            <paper-button on-click="_onPhoneTextSelected" data-value="Phone">
                                                        <iron-icon icon="icons:perm-phone-msg"></iron-icon>
                                                    Phone
                                            </paper-button>
                                            <paper-button on-click="_onPhoneTextSelected" data-value="Text">
                                                    <iron-icon icon="communication:textsms"></iron-icon>
                                                    Text
                                            </paper-button>
                                            <paper-button on-click="_onPhoneTextSelected" data-value="Radio Telecommunications">
                                                    <iron-icon icon="communication:rss-feed"></iron-icon>
                                                    RT
                                            </paper-button>
                                            <paper-button on-click="_onPhoneTextSelected" data-value="">
                                                    <iron-icon icon="icons:receipt"></iron-icon>
                                                    Other
                                            </paper-button>
                                        </div>
                                </div>
                            </paper-card>
                        <paper-textarea label="Comment" value="{{selectedLoneWorkerRequest.lastComment}}" rows="2"></paper-textarea>
                        
                    </paper-dialog-scrollable>
                    
                    <div class="buttons">
                        <paper-button dialog-dismiss="">Cancel</paper-button>
                        <paper-button dialog-confirm="" autofocus="" on-click="_onUpdateLoneWorkerConfirmClick" raised="">Confirm</paper-button>
                    </div>
                </paper-dialog>

                <paper-dialog id="dlgConfirmUpdateWorkorderLoneWorker" no-cancel-on-outside-click="">
                        <h2>Confirm Update to Lone Worker Check</h2>
                        <paper-dialog-scrollable>
                            <p>Are you sure you want to Update the Lone Worker Check?</p>
                            <paper-card style="width:100%;">
                                <div class="card-actions" style="background-color: white">
                                        <div class="horizontal justified layout">
                                            <paper-button on-click="_onPhoneTextSelected" data-value="Phone">
                                                        <iron-icon icon="icons:perm-phone-msg"></iron-icon>
                                                    Phone
                                            </paper-button>
                                            <paper-button on-click="_onPhoneTextSelected" data-value="Text">
                                                    <iron-icon icon="communication:textsms"></iron-icon>
                                                    Text
                                            </paper-button>
                                            <paper-button on-click="_onPhoneTextSelected" data-value="Radio Telecommunications">
                                                    <iron-icon icon="communication:rss-feed"></iron-icon>
                                                    RT
                                            </paper-button>
                                            <paper-button on-click="_onPhoneTextSelected" data-value="">
                                                    <iron-icon icon="icons:receipt"></iron-icon>
                                                    Other
                                            </paper-button>
                                        </div>
                                </div>
                            </paper-card>
                            <paper-textarea label="Comment" value="{{selectedLoneWorkerRequest.lastComment}}" rows="2"></paper-textarea>
                        </paper-dialog-scrollable>
                        
                        <div class="buttons">
                            <paper-button dialog-dismiss="">Cancel</paper-button>
                            <paper-button dialog-confirm="" autofocus="" on-click="_onUpdateLoneWorkerWorkorderConfirmClick" raised="">Confirm</paper-button>
                        </div>
                    </paper-dialog>

         <!-- Workorder Overlay-->
         <core-overlay id="wodetail" auto-close-disabled="true" on-wodetail-close="closeWorkOrderOverlay">
                <wo-detail wo-id="{{selectedServiceOrderNo}}" app="{{app}}">
                </wo-detail>
            </core-overlay>

        <app-storage app="{{app}}"></app-storage>
        <paper-toast id="toast" text=""></paper-toast>
       
        <!--<iron-ajax id="ajaxSo" url="{{urlSo}}" handle-as="json" last-response="{{sos}}"></iron-ajax>
        <iron-localstorage name="cpl-so" value="{{sos}}"></iron-localstorage>-->
        <!--Vehicles-->
        <iron-ajax id="ajaxVehicles" url="{{urlVehicles}}" handle-as="json" last-response="{{vehicles}}"></iron-ajax>
        <iron-localstorage name="cpl-vehicles" value="{{vehicles}}"></iron-localstorage>
        <!--<iron-ajax id="ajaxVehcilePositions" url="{{urlVehiclePositions}}" handle-as="json" last-response="{{app.vehPositions}}"></iron-ajax>-->
        <!--<iron-localstorage name="cpl-vehicle-positions" value="{{vehPositions}}"></iron-localstorage>-->
        <iron-ajax id="ajaxCallTypes" url="/api/lookup/calltypes" handle-as="json" last-response="{{callTypes}}" loading="{{isLoading}}"></iron-ajax>
        <iron-localstorage name="cpl-call-types" id="lsCallTypes" value="{{callTypes}}"></iron-localstorage>
        <iron-ajax id="ajaxFeeders" url="/api/feeders/" handle-as="json" last-response="{{feeders}}" loading="{{isLoading}}"></iron-ajax>
        <iron-localstorage name="cpl-feeders" id="lsFeeders" value="{{feeders}}"></iron-localstorage>
        <iron-ajax id="ajaxIcpCategories" url="/api/lookup/icp/categories" handle-as="json" last-response="{{icpCategories}}" loading="{{isLoading}}"></iron-ajax>
        <iron-localstorage name="cpl-icp-categories" id="lsCategories" value="{{icpCategories}}"></iron-localstorage>
        <iron-ajax id="ajaxPings" url="/api/pings/[[offset]]" handle-as="json" on-response="_onPingsResponse" loading="{{isLoading}}"></iron-ajax>

                   <iron-ajax id="ajaxPingRequest" url="/api/ping/[[pingRequestId]]" handle-as="json" last-response="{{pingMeterItems}}" loading="{{isLoading}}"></iron-ajax>
                   
        <iron-ajax id="ajaxAddToLoneWorker" url="/api/resource/loneworker/create" method="POST" handle-as="json" content-type="application/json" body="{{selectedLoneWorkerRequest}}" on-response="_onCreateLoneWorkerSuccess" on-error="_onCreateLoneworkerError" loading="{{isLoading}}"></iron-ajax>

        <iron-ajax id="ajaxUpdateLoneWorker" url="/api/resource/loneworker/update" method="POST" handle-as="json" content-type="application/json" body="{{selectedLoneWorkerRequest}}" on-response="_onCreateLoneWorkerSuccess" on-error="_onCreateLoneworkerError" loading="{{isLoading}}"></iron-ajax>

        <iron-ajax id="ajaxUpdateLoneWorkerWorkorder" url="/api/so/loneworker/update" method="POST" handle-as="json" content-type="application/json" body="{{selectedLoneWorkerRequest}}" on-response="_onCreateLoneWorkerSuccess" on-error="_onCreateLoneworkerError" loading="{{isLoading}}"></iron-ajax>

        <iron-ajax id="ajxResources" url="/api/resources" handle-as="json" last-response="{{resources}}" loading="{{isLoading}}"></iron-ajax>
        
    `;
  }

  static get is() { return 'app-shell'; }

    static get properties() {
        return {
            app: {
                type: Object,
                value: function() { return {username: '', role:''}; }
            },
            page: {
                type: String,
                reflectToAttribute: true,
                observer: '_pageChanged'
            },
            showLogin: {
                type: Boolean,
                value: false
            },
            urlSo: {
                type: String,
                value: '/api/so/latest'
            },
            urlVehicles: {
                type: String,
                value: '/api/vehicles/assets'
            },
            urlVehiclePositions: {
                type: String,
                value: '/api/vehicles/latest'
            },
            homeSubPage: {
                type: Number,
                value: 0
            },
            adminSubPage: {
                type: Number,
                value: 0
            },
            routeData: Object,
            subroute: Object,
            pingPage:{
                type:Number,
                value:0,
                observer: '_pingPageChanged'
            },
            pingItems:{
                type:Array,
                value:[]
            },
            prevPingPage: {
                type: Number,
                value: 0
            },
            searchText: {
                type: String,
                value: ""
            },
            rootPath: String,

        }
    }

    static get observers() {
        return ['_routePageChanged(routeData.page)']
    }

    constructor() {
        super();
        // this.initCognito();
      }

  ready() {
    super.ready();
    
    let username = window.localStorage.getItem('username');
    // load('current', {'packages':['corechart','bar','annotationchart','gauge']}).then(_=> console.log("Chart Loaded"));
    // this.app.username = username;

    // window.addEventListener('storage', () => {
    //     if (!username) {
    //         this.signout();

    //     }
    // });

    this.addEventListener('login-required', this.loginRequired);
    this.addEventListener('login-success', this.loginSuccess);
//TODO: Uncomment
    // window.addEventListener('error', (e) => {
    //     if (e && e.detail.error.message.includes("401")) {
    //         this.signout();
    //     }
    // }, true);
   
    fetch(`/api/user/check`, { credentials: 'include' })
    .then(response =>{
        if (response.status !== 200) {
            this.signout();
            return;
        }
        // Examine the text in the response
        response.json().then(json=> {
            // console.log(json);
            if (json !== null) {
                this.set('app', {username: json.email, role:json.role});
                this.init();
                this.set("showLogin", false);
            }
            this.set('isLoading', false);
        });
    })
    .catch((e)=>{
                    console.log(e);
                    this.signout();
                });

    

                // .then(resp => resp.json())
                // .then((json) => {
                //     // if (resp.status === 401) {
                //     //     this.signout();
                //     // }
                //     if (json !== null) {
                //         this.set('app', {username: json.email, role:json.role});
                //         this.init();
                //         this.set("showLogin", false);
                //     }
                //     this.set('isLoading', false);
                // })
                // .catch((e)=>{
                //     console.log(e);
                //     this.signout();
                // });



    // if (!document.cookie.includes("sid")) {
    //     console.log("No Cookie");
    //     this.signout();
    // } else{
    //     if (username) {
    //         fetch(`/api/user/${username}`, { credentials: 'include' })
    //             .then(resp => resp.json())
    //             .then(json => {
    //                 if (json !== null) {
    //                     this.set('app', {username: username, role:json.role});
    //                     this.init();
    //                     this.set("showLogin", false);
    //                 }
    //                 this.set('isLoading', false);
    //             })
    //             .catch(()=>{
    //                 this.set("showLogin", true);
    //             });


    //     } else {
    //         this.set("showLogin", true);
    //     }
    // }

    //chrome bug for height
    //            this.async(()=>this.setFullHeight);
    // this.setFullHeight();
    //                window.addEventListener("resize", ()=>{
    //                    let h = window.innerHeight;
    //                    document.querySelectorAll('.fh').forEach((el)=> el.style.height = (h - 67).toString() + "px");
    //                });

    
}

    // initCognito() {
    //     Amplify.configure({
    //         Auth: {
    //             identityPoolId: 'ap-southeast-2:192fedd8-d669-475b-98c2-5150976dcf1a', //REQUIRED - Amazon Cognito Identity Pool ID
    //             region: 'ap-southeast-2', // REQUIRED - Amazon Cognito Region
    //             userPoolId: 'ap-southeast-2_qTUZoDqCk', //OPTIONAL - Amazon Cognito User Pool ID
    //             userPoolWebClientId: '45rlmd3d5glkh86stuuum9s9n5', //OPTIONAL - Amazon Cognito Web Client ID
    //             // OPTIONAL - Hosted UI configuration
    //             oauth: {
    //                 domain: 'cp-indi.auth.ap-southeast-2.amazoncognito.com',
    //                 scope: ['phone', 'email', 'profile', 'openid'],
    //                 redirectSignIn: `${window.location.protocol}//${window.location.host}`,//'https://indi-test-155098879.ap-southeast-2.elb.amazonaws.com',// 'http://localhost:8080',
    //                 redirectSignOut: '',
    //                 responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    //             }
    //         },
    //         API: {
    //             endpoints: [
    //                 {
    //                     name: "",
    //                     endpoint: "/api"
    //                 }
    //             ]
    //         }
    //     });

    //     // Auth.currentAuthenticatedUser().then(user => console.log(user));
    //     // Auth.currentCredentials().then(creds => console.log(creds));
    //     // console.log(Auth.configure());
    //     Auth.currentAuthenticatedUser({
    //         bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    //     }).then(user => {
    //         console.log(user);
    //         this.app.username = user.username.split("_")[1].split("@")[0];
    //         console.log(this.app.username);

    //         const myInit = {
    //             body: {email: this.app.username, password:""}, // replace this with attributes you need
    //         };

    //         API.post("", '/user/auth', myInit)
    //             .then(response => {
    //                 // Add your code here
    //                 console.log(response);
    //                 this.set('app', {username: this.app.username, role: response.role});
    //                 this.showLogin = false;
    //             })
    //             .catch(error => {
    //                 this.showLogin = true;
    //                 console.log(error.response)
    //             })
    //         this.init();
    //         Auth.currentSession()
    //             .then(data => console.log(data))
    //             .catch(err => console.log(err));
    //     }).catch(err => {
    //         console.log(err);
    //         Auth.federatedSignIn().then(data => console.log(data))
    //         .catch(err => console.log(err));;
    //     });

       

    // }


loginRequired() {
    this.set('showLogin', true);
    // window.localStorage.remove('username');
    window.localStorage.clear();
    ws.onClose.listen(() => null);
    ws.close();
    ws = null;
}

loginSuccess() {
    this.set('showLogin', false);
    this.init();
}

_onDragStart(e){
    // console.log( e.oldIndex);
    if(e.oldIndex >= 0){
        this.$.dlgResources.close();
        // console.log( this.resourceWorkorderStates[e.oldIndex]);
        this.draggedResource = this.resourceWorkorderStates[e.oldIndex];
    }
    
}
 _onDragEnd(e){
    // console.log( e);
    
    this.draggedResource = {index: 0};
}
onClearSearchText() {
    this.set('searchText', '');
}

filterItems(items, t) {
    if(t.length > 0){
        t = t.toUpperCase();
        return items.filter((i) => {
        return i.resourceName.toUpperCase().includes(t);
        });
    }
    return items;
}

hasItems(items){
    if(items){
        return items.length > 0;
    }
    return false;
}

getNoOfItems(items){
    if(items){
        return items.length;
    }
    return 0;
}
_onAddLoneWorkerResource(){
    this.$.ajxResources.generateRequest();
    this.$.dlgResourceSearch.toggle();
}

computeResourceFilter(string) {
    if (!string) {
    // set filter to null to disable filtering
    return null;
    } else {
    // return a filter function for the current search string
    string = string.toLowerCase();
    return function(item) {
        var first = item.ResourceName.toLowerCase();
        // var last = employee.lastname.toLowerCase();
        return (first.indexOf(string) != -1 );
    };
    }
}
_onPhoneTextSelected(e) {
        //   let model = e.model;
          let status = e.currentTarget.getAttribute("data-value");
        //   this.set("selectedLoneWorkerRequest.lastComment", status);
          this.set("selectedLoneWorkerRequest.lastComment", status);
        //   this.selectedLoneWorkerRequest.lastComment = status;
}

onResourceSelected(e) {
        let model = e.model;
        this.$.dlgResourceSearch.close();
        let loneWorkerRequest = {
            username: this.app.username,
            resourceNo: model.item.ResourceNo,
            resourceName: model.item.ResourceName
        }

    this.set("selectedLoneWorkerRequest", loneWorkerRequest);
    this.$.dlgConfirmAddToLoneWorker.open();

        //  let data = JSON.stringify({
        //      no: this.info.No,
        //      resourceNo: model.item.ResourceNo,
        //      resourceName: model.item.ResourceName,
        //      username : this.app.username
        //  });

        // fetch(`/api/so/${this.info.No}/allocate`, {
        //     credentials: 'include',
        //     method: 'POST',
        //     body: data
        // })
        // .then(resp => resp.json())
        // .then(() => {
        //     this.$.ajxWO.generateRequest();
        //     this.showToast("Resource Allocated");
        // })
        // .catch((error) => {
        //     console.log('Request failed', error);
        //     this.showToast("Failed to Allocate Resource");
        // });
       
    }

_onAddToLoneWorker(e) {
    var model = this.$.resourceList.modelForElement(e.target);
  let loneWorkerRequest = {
      username: this.app.username,
      resourceNo: model.item.resourceNo,
      resourceName: model.item.resourceName
    }

    this.set("selectedLoneWorkerRequest", loneWorkerRequest);
    this.$.dlgConfirmAddToLoneWorker.open();
}

_onClearCheckLoneWorkerCheckClick(e) {
    var model = this.$.lstLoneWorkerResources.modelForElement(e.target);
    let loneWorkerRequest = model.item;
    loneWorkerRequest.checkedBy = this.app.username;
    this.set("selectedLoneWorkerRequest", loneWorkerRequest);
    // console.log(loneWorkerRequest);
    this.$.dlgConfirmUpdateLoneWorker.open();
}
_onDeleteCheckLoneWorkerCheckClick(e) {
    var model = this.$.lstLoneWorkerResources.modelForElement(e.target);
    let loneWorkerRequest = model.item;
    loneWorkerRequest.checkedBy = this.app.username;
    loneWorkerRequest.isCompleted = true;
    this.set("selectedLoneWorkerRequest", loneWorkerRequest);
    // console.log(loneWorkerRequest);
    this.$.dlgConfirmUpdateLoneWorker.open();
}

_onClearWorkorderLoneWorkerCheckClick(e) {
    var model = this.$.lstWorkorderLoneWorker.modelForElement(e.target);
    let loneWorkerRequest = model.item;
    loneWorkerRequest.LastCheckedBy = this.app.username;
    this.set("selectedLoneWorkerRequest", loneWorkerRequest);
    // console.log(loneWorkerRequest);
    this.$.dlgConfirmUpdateWorkorderLoneWorker.open();
}
_onDeleteWorkorderLoneWorkerCheckClick(e) {
    var model = this.$.lstWorkorderLoneWorker.modelForElement(e.target);
    let loneWorkerRequest = model.item;
    loneWorkerRequest.LastCheckedBy = this.app.username;
    loneWorkerRequest.IsRemoved = true;
    this.set("selectedLoneWorkerRequest", loneWorkerRequest);
    // console.log(loneWorkerRequest);
    this.$.dlgConfirmUpdateWorkorderLoneWorker.open();
}

_onAddToLoneWorkerConfirmClick(){
    this.$.ajaxAddToLoneWorker.generateRequest();

}
_onUpdateLoneWorkerConfirmClick(){
    this.$.ajaxUpdateLoneWorker.generateRequest();

}
_onUpdateLoneWorkerWorkorderConfirmClick(){
    this.$.ajaxUpdateLoneWorkerWorkorder.generateRequest();

}
_onCreateLoneWorkerSuccess(){
    this.showToast("Lone Worker Successful");
    // this._refresh();
}
_onCreateLoneworkerError(){
    this.showToast("Failed to Update Lone Worker");
    // this._refresh();
}
showToast(text) {
        this.$.toast.show(text);
    }

_routePageChanged(page) {
    if (page === 'index.html') {
        page = 'dash';
    }
    this.page = page || 'dash';
}

_pageChanged(page) {
    // this.importHref(
    //         this.resolveUrl(page + '-shell.html'), null, null, true);
    if (page === 'dash'){
        import('./dash-shell.js').then((p)=>console.log(p));
    }
    // if (page === 'map'){
    //     import('./my-view1.js').then((p)=>console.log(p));
    // } 
}
_pingPageChanged(newPage, oldPage){
    this.prevPingPage = oldPage;
}

init() {
    // setInterval(() => {
    //     if (!this.showLogin) {
    //         this.$.ajaxVehcilePositions.generateRequest();
    //     }
    // }, 60000);

    setInterval(() => {
        if (!this.showLogin) {
            this.checkUser();
        }
    }, 120000);

    setInterval(() => {
        if (!this.showLogin) {
            this.getOutageSummary();
        }
    }, 120000);
    // this.$.ajaxSo.generateRequest();
    // this.$.ajaxVehcilePositions.generateRequest();
    this.$.ajaxVehicles.generateRequest();
    this.$.ajaxCallTypes.generateRequest();
    this.$.ajaxFeeders.generateRequest();
    this.$.ajaxIcpCategories.generateRequest();
    window.localStorage.setItem('username', this.app.username);

    //            this.async(()=>this.setFullHeight());
    this.async(() => this.initWebSocket());
    this.getOutageSummary();
}

checkUser(){
    fetch(`/api/user/check`, { credentials: 'include' })
    .then(response =>{
        if (response.status !== 200) {
            this.signout();
            return;
        }
    })
    .catch((e)=>{
        this.signout();
    });

}

getOutageSummary(){

    fetch(`/api/outages/echo`, { credentials: 'include' })
        .then(resp => resp.json())
        .then(json => {
            let info = json;//JSON.parse(json);
        this.set('lastUpdatedTime', info.currentDate);
        this.set('totalConnectedUsers', info.totalConnectedUsers);
        this.set('outageEventInfo', info);
        this.set('app.vehPositions', info.vehiclePositions);
        this.set('app.sos', info.serviceOrders);
        this.set('resourceWorkorderStates', info.resourceWorkorderStates);
        this.set('loneWorkerResourceWoStatus', info.loneWorkerResourceWoStatus)
        this.set('loneWorkerResourceStatus', info.loneWorkerResourceStatus)
        this.set('substationRippleInfo', info.substationRippleInfo);
        });
}

formatFloatCurrentVoltage(v){
    if(v.Valid){
        return `${v.Float64}`;

    }
    return "";

}
formatDate(s) {
        return moment.parseZone(s).format('DD MMM YYYY');//FormatDate(s);
}

formatDateTime(s) {

    return s==""?"Not Checked": moment.parseZone(s).format('DD MMM YYYY HH:mm');//FormatDate(s);
}




onNavOpen() {
    this.async(() => this.$.md.openDrawer());
}

signout() {
    
    this.set("showLogin", true);
    window.localStorage.clear();
    this.app = {username: '', role:''};
    document.cookie = "sid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    this.dispatchEvent(new CustomEvent('logout-success', {bubbles: true,  composed: true}))
    if (ws) {
        ws.onclose = null;
        ws.close();
        ws = null;
    }

    fetch(`/api/auth/signout`, { credentials: 'include' })
                .then(resp => console.log("Sign Out")).catch(e=>{
                    //do nothing
                });

    //TODO: Logout - should set the expiration time of the authentication session cookie to -1 and redirect the client to the IdP logout endpoint (if the IdP supports one)
    //INDIAuthSessionCookie
    // Auth.signOut({ global: true })
    //     .then(data => console.log(data))
    //     .catch(err => console.log(err));
    // document.cookie = "INDIAuthSessionCookie=; expires=-1; path=/";
    // window.location.replace("https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0");

}

onMessagesClicked(){
    this.$.dlgmessages.open();
}
onResourceStateClicked(){
    this.$.dlgResources.open();
}
onLoneWorkerStatusClicked(){
    this.$.dlgLoneWorker.open();
}
onRippleClicked() {
    this.$.dlgRipple.open();
    // this.getSubstationRippleInfo();
}

onViewAllPingsClick(){
    this.pingPage = 1;
    this.pingItems = [];
    this.loadMorePingData()
}
onViewBackPingsClick(){
    this.pingPage = this.prevPingPage;
    this.prevPingPage = 0;
    // this.pingItems = [];
}
loadMorePingData(){
    // this.$.ironScrollTheshold.clearTriggers();
    this.offset = this.pingItems.length;
    this.$.ajaxPings.generateRequest();
}
_onPingsResponse(e){
        let results = e.detail.response;

        if (results){
            results.forEach((r)=> {
                this.push('pingItems', r);
                // this.push('pastData', r);
            });
            this.$.scrollThreshold.clearTriggers();
        }
       
}

formatDateFromNow(s) {
        // return s ? moment.parseZone(s).format('DD MMM YYYY'): "";
        return moment.parseZone(s).fromNow();
}

onPingItemClick(e){
    this.pingPage = 2;
    let model = e.model;
    
    this.set('pingRequestId',model.get("item.id"));
    this.$.ajaxPingRequest.generateRequest();
}
getIcon(s){
    return s === "ping_success"? "thumb-up": "thumb-down";
}
getIconClass(s){
    return s === "ping_success"? "ping-success-icon": "ping-error-icon";
}
_getTotalOnDuty(items){
    return `${items.filter((i)=> i.isOnDuty).length} On Duty`;
}
_getResourceStateIcon(state){
    return state? "check-circle":"block";
}
_getResourceStateClass(state){
    return state? "ping-success-icon":"ping-error-icon";
}
_getFaultsReportedText(s){
    return `${s} NEW`;
}

filterLoneworkerWorkorderItems(items, t) {
    if(!t && items){
        return items.filter((i) => {
        return i.AcceptCheck === "Failed" || i.ETACheck === "Failed" || i.ETRCheck === "Failed" || i.StatusCheck === "Failed";
        });
    }
    return items;
}
filterLoneworkerResourceItems(items, t) {
    if(!t && items){
        return items.filter((i) => {
        return i.statusCheck == "Failed";
        });
    }
    return items;
}

_getTotalLoneWorkerFailed(items, rItems){
    let totalFailed = 0;
    let total = 0;
    if(items){
        total += items.length;
        totalFailed =  items.filter((i)=> i.AcceptCheck === "Failed" || i.ETACheck === "Failed" || i.ETRCheck === "Failed" || i.StatusCheck === "Failed").length;
    // let totalResourceFailed =  items.filter((i)=> i.statusCheck == "Failed").length;
    // return totalFailed > 0 ?  `${totalFailed}/ ${items.length + rItems.length} Failed`: `${items.length + rItems.length} OK`;
    }
    let totalResourceFailed  = 0;
    if(rItems){
        total += rItems.length;

        totalResourceFailed =  rItems.filter((i)=> i.statusCheck == "Failed").length;
    
    }
    return totalFailed + totalResourceFailed > 0 ?  `${totalFailed + totalResourceFailed}/ ${total} Failed`: `${total} OK`;
    // return "-"
    
}
_getTotalLoneWorkerFailedClass(items, rItems){
    let totalFailed = 0;
    // let total = 0;
    if(items){
        // total += items.length;
        totalFailed =  items.filter((i)=> i.AcceptCheck == "Failed" || i.ETACheck == "Failed" || i.ETRCheck == "Failed" || i.StatusCheck == "Failed").length;
    
    // return total + totalResourceFailed > 0 ? "ping-error-icon":"ping-success-icon";
    }
    let totalResourceFailed = 0
    if(rItems){
        // total += rItems.length;
        totalResourceFailed =  rItems.filter((i)=> i.statusCheck == "Failed").length;
    
    }
    return totalFailed + totalResourceFailed > 0 ? "ping-error-icon":"ping-success-icon";
    // return "ping-success-icon";
}

_getLoneWorkerStateIcon(i){
    return i.AcceptCheck == "Failed" || i.ETACheck == "Failed" || i.ETRCheck == "Failed" || i.StatusCheck == "Failed"? "block":"check-circle";
}
_getLoneWorkerStateClass(i){
    return i.AcceptCheck == "Failed" || i.ETACheck == "Failed" || i.ETRCheck == "Failed" || i.StatusCheck == "Failed"? "ping-error-icon":"ping-success-icon";
}
_getLoneWorkerResourceStateIcon(i){
    return  i.statusCheck == "Failed"? "block":"check-circle";
}
_getLoneWorkerResourceStateClass(i){
    return i.statusCheck == "Failed"? "ping-error-icon":"ping-success-icon";
}

_onOpenWorkOrderClicked(e){
    let model = e.model;
    this.selectedServiceOrderNo = model.item.No;
    this.$.wodetail.open();
}

closeWorkOrderOverlay() {
    this.selectedServiceOrderNo = null;

    this.$.wodetail.close();
}

_pingProgressLimit(data){
    if(data){
        return data.splice(0,5);
    }
    return [];
    
}

// _getDurationFromNow(s){
//     var duration = moment.duration(moment.parseZone(s).diff(moment.parseZone()));
//     console.log(s);
//     console.log(moment.utc());
//     console.log(moment.utc(s));
//     return duration.humanize();

// }

initWebSocket(retrySeconds = 2) {
    let reconnectScheduled = false;

    ws = new WebSocket(WS_URL + "/echo");
    let that = this;

    function scheduleReconnect() {
        if (!reconnectScheduled) {
            let rt = retrySeconds > 120 ? retrySeconds : retrySeconds * 2;
            that.async(() => that.initWebSocket(rt), rt * 1000)
        }
        reconnectScheduled = true;
    }

    ws.onopen = () => {
        ws.send(this.app.username);
    };

    ws.onclose = () => {
        scheduleReconnect();
    };

    ws.onerror = (e) => {
        console.log(e);
        // scheduleReconnect();
    };

    ws.onmessage = (e) => {
        let info = JSON.parse(e.data);
        this.set('lastUpdatedTime', info.currentDate);
        this.set('totalConnectedUsers', info.totalConnectedUsers);
        this.set('outageEventInfo', info);
        this.set('app.vehPositions', info.vehiclePositions);
        this.set('app.sos', info.serviceOrders);
        this.set('resourceWorkorderStates', info.resourceWorkorderStates);
        this.set('loneWorkerResourceWoStatus', info.loneWorkerResourceWoStatus)
        this.set('loneWorkerResourceStatus', info.loneWorkerResourceStatus)
        this.set('substationRippleInfo', info.substationRippleInfo);
    };
}

isPingViewAllowed(app){
    return AmiBehaviors.SecurityBehavior.isPingViewAllowed(app.role);
}
}

window.customElements.define('app-shell', AppShell);
