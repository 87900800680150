/* <link rel="import" href="../../bower_components/moment-element/moment-with-locales-import.html"> */
/*
  FIXME(polymer-modulizer): the above comments were extracted
  from HTML and may be out of place here. Review them and
  then delete this comment!
*/
import '@polymer/polymer/polymer-legacy.js';
import moment from 'moment';
import './fault-alert-detail.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import '@polymer/iron-list/iron-list.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-input/paper-textarea.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/paper-dropdown-menu/paper-dropdown-menu.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
import '@polymer/paper-item/paper-item-body.js';
import '@polymer/paper-badge/paper-badge.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/iron-icons/iron-icons.js';
import '@polymer/iron-icons/social-icons.js';
import '@polymer/iron-icons/device-icons.js';
import '@polymer/iron-icons/hardware-icons.js';
import '@polymer/iron-icons/communication-icons.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';


const HIDE_IGNORED_OUTAGES = "HIDE-IGNORED";
/**
* @polymer
* @extends HTMLElement
**/
class LiveOutagesList extends PolymerElement {

    static get is() { return 'live-outages-list'; }

    static get properties() {
        return {
            showList: {
                type: Boolean,
                observer: 'showListChanged'
            },

            url: {
                type: String,
                value: '/api/sr/latest'
            },

            isLoading: {
                type: Boolean,
                value: false
            },

            username: {
                type: String
            },

            data: {
                type: Array
            },

            icpData: {
                type: Array
            },
            liveOutagesFiltered: {
                type: Array,
                value: () => []
            },

            histData: {
                type: Array
            },

            selectedAlertId: {
                type: Number
            },

            addAlertUrl: {
                type: String,
                value: ""
            },

            searchText: {
                type: String,
                value: ""
            },

            allIcpDisconnectionsChecked: {
                type: Boolean,
                value: true
            },

            allIcpCategoriesChecked: {
                type: Boolean,
                value: true
            },

            showLargeMode: {
                type: Boolean,
                value: true,
                notify: true
            },

            info: {
                type: Object
            },

            icpCategories: {
                type: Object
            },

            uncheckedCats: {
                type: Array,
                notify: true,
                value: function() {
                    return [];
                }
            },

            uncheckedDisconnects: {
                type: Array,
                notify: true,
                function() {
                    return [];
                }
            },

            showIgnoredOutages: {
                type: Boolean,
                value: false,
                observer: 'showIgnoredOutagesChanged'
            },

            showUncategorisedOutages: {
                type: Boolean,
                value: true,
                observer: 'showUncategorisedOutagesChanged'
            },

            selectedDuration: {
                type: String,
                notify: true
            },
            icpOutagesFiltered: {
                type: Array,
                notify: true,
                value: function() {
                    return [];
                }
            }
        }
    }

    static get template() {
        return html`
          <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>
  
        <style>
        :host {
            display: block;
            width: 100%;
            height: 100%;
            font-size: 13px;
            --paper-input-container-input: {
                font-size: 13px;
            };
        }
        
        paper-progress {
            width: 100%;
            --paper-progress-container-color: white;
            --paper-progress-active-color: var(--paper-orange-500);
            --paper-progress-secondary-color: var(--paper-orange-100);
        }
        
        paper-dropdown-menu {
            --primary-text-color: white;
            --paper-dropdown-menu-input: {
                font-size: 13px;
            }
            ;
            --paper-dropdown-menu-icon: {
                color: white;
            }
            ;
            /*--paper-input-container-underline: {*/
            /*visibility:hidden;*/
            /*}*/
        }
        
        .container {
            padding: 10px;
        }
    
        
        .cards {
            @apply --layout-vertical;
            @apply --center-justified;
            /*max-width: 400px;*/
            margin-left: auto;
            margin-right: auto;
        }
        
        paper-card {
            /*width: 100%;*/
            margin: 8px;
        }
        
        app-toolbar.orange {
            background-color: #E64A19;
        }

        app-header {
            background-color: #E64A19;
            color:white;
        }
        
        paper-badge {
            /*--paper-badge-margin-left: 20px;*/
            /*--paper-badge-margin-bottom: 0px;*/
        }
        
        paper-badge.red {
            --paper-badge-background: red;
        }
        
        #faultAlertOverlay1 {
            background: white;
            width: 408px;
            height: 100%;
            overflow: auto;
        }
        
        paper-dialog.size-position {
            position: fixed;
            top: 16px;
            right: 16px;
            width: 300px;
            height: 220px;
            overflow: auto;
        }
        
        .search {
            text-transform: uppercase;
            --paper-input-container-input: {
                text-transform: uppercase;
            }
            ;
            --paper-input-container-input-color: white;
        }
        
        paper-listbox {
            width: 280px;
        }
        
        paper-listbox paper-item {
            font-size: 12px;
        }
        
        paper-item.heading {
            background-color: #B6B6B6;
            font-weight: bold;
        }
        
        paper-icon-button.disconnect {
            color: #FFA000;
        }
        
        paper-icon-button.removed {
            color: #F44336;
        }
        </style>
        <iron-localstorage name="cpl-icp-categories" id="lsCategories" value="{{icpCategories}}"></iron-localstorage>
        <div class="horizontal layout" style="height: 100%">
            <div hidden\$="[[!showLargeMode]]" style="width: 408px;height: 100%; margin-right: 5px; background-color: white;">
                <!-- Shutdown Notification Overlay-->
                <fault-alert-detail shutdown-id="{{selectedAlertId}}" on-shutdowndetail-close="closeFaultAlertOverlay"></fault-alert-detail>
            </div>
            <div class="flex"></div>
            <div style="width: 408px;height: 100%;background-color: white;">
            <app-header-layout has-scrolling-region="true">
                <app-header scroll-target="content" slot="header" fixed>
                    <app-toolbar class="tall orange" sticky>
                        <!--<paper-icon-button icon="note-add" alt="New Shutdown" title="New Shutdown" on-click="onNewNoteClick"></paper-icon-button>-->
                        <paper-button on-click="onNewNoteClick" alt="Add to Event" title="Add to Event" disabled="[[showLargeMode]]">
                            <iron-icon icon="note-add"></iron-icon>
                            New Event
                        </paper-button>
                        <div class="flex"></div>
                        <!-- <paper-dropdown-menu style="width: 100px;" no-label-float no-animations>
                            <paper-listbox slot="dropdown-content" selected="{{selectedDuration}}" attr-for-selected="name">
                                <paper-item name="5M">5 Min</paper-item>
                                <paper-item name="30M">30 Min</paper-item>
                                <paper-item name="1H">1 Hour</paper-item>
                                <paper-item name="6H">6 Hours</paper-item>
                                <paper-item name="12H">12 Hours</paper-item>
                                <paper-item name="1D">1 Day</paper-item>
                                <paper-item name="7D">7 Days</paper-item>
                                <paper-item name="30D">30 Days</paper-item>
                                <paper-item name="60+">60+ Days</paper-item>
                            </paper-listbox>
                        </paper-dropdown-menu> -->
                        <!-- <paper-menu-button horizontal-align="right" horizontal-offset="-80" ignore-select>
                            <paper-icon-button icon="filter-list" class="dropdown-trigger" alt="More" title="More" on-click="_onFilterClick"></paper-icon-button>
                            <paper-menu slot="dropdown-content" id="pmIcpCats">
                           
                                <paper-item class="heading" role="menuitemcheckbox">
                                    <paper-checkbox checked="{{showIgnoredOutages}}"></paper-checkbox>
                                    <paper-item-body>
                                        Show Ignored Outages
                                    </paper-item-body>
                                </paper-item>
                                <paper-item class="heading" role="menuitemcheckbox">
                                    <paper-checkbox checked="{{allIcpDisconnectionsChecked}}" on-change="onAllIcpDisconnectionsChanged"></paper-checkbox>
                                    <paper-item-body>
                                        Disconnections
                                    </paper-item-body>
                                </paper-item>
                                <template is="dom-repeat" items="{{icpCategories.icpDisconnectionTypes}}">
                                    <paper-item role="menuitemcheckbox">
                                        <paper-checkbox checked="{{item.checked}}" on-change="onIcpDisconnectChanged"></paper-checkbox>
                                        <paper-item-body>
                                            [[item.description]]
                                        </paper-item-body>
                                    </paper-item>
                                </template>
                             
                                <paper-item class="heading" role="menuitemcheckbox">
                                    <paper-checkbox checked="{{allIcpCategoriesChecked}}" on-change="onAllIcpCategoryChanged"></paper-checkbox>
                                    <paper-item-body>
                                        Categories
                                    </paper-item-body>
                                </paper-item>
                                <template is="dom-repeat" items="[[icpCategories.icpCategories]]">
                                    <paper-item role="menuitemcheckbox">
                                        <paper-checkbox checked="{{item.checked}}" on-change="onIcpCategoryChanged"></paper-checkbox>
                                        <paper-item-body>
                                            [[item.description]]
                                        </paper-item-body>
                                    </paper-item>
                                </template>
                                <paper-item role="menuitemcheckbox">
                                    <paper-checkbox checked="{{showUncategorisedOutages}}"></paper-checkbox>
                                    <paper-item-body>
                                        Uncategorised
                                    </paper-item-body>
                                </paper-item>
                            </paper-menu>
                        </paper-menu-button> -->
                       
                        <paper-icon-button icon="close" on-click="close" alt="Close" title="Close"></paper-icon-button>
                        
            </app-toolbar>
            <app-toolbar sticky>
                <div class="middle title">Real Time Outages</div>
            </app-toolbar>
            <app-toolbar sticky>
                <div class="bottom flex">
                    <paper-input id="tbxSearch" placeholder="Type to search" class="search" value="{{searchText}}">
                        <paper-icon-button slot="prefix" icon="search"></paper-icon-button>
                        <paper-icon-button slot="suffix" icon="communication:clear-all" on-click="onClearSearchText"></paper-icon-button>
                    </paper-input>
                </div>
            </app-toolbar>
        </app-header>    
            
          
                  
                    <div class="content" style="padding-right: 0px">
                        <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>
                        <!--<iron-list id="itemList" items="{{filterItems(data, searchText)}}"  as="item">-->
                            <template id="itemList" is="dom-repeat" items="[[filterItems(data, searchText)]]" as="item">
                                <div class="cards">
                                    <paper-card>
                                        <div class="card-content">
                                            <div>
                                            </div>
                                            <div class="horizontal layout">
                                                <div class="flex">
                                                    Feeder: <span style="font-weight: bold">[[item.feederName]]</span>
                                                </div>
                                                <div class="flex">
                                                    <span>SSN Meters: </span>
                                                    <span>[[item.smartMeterCount]]</span>
                                                </div>
                                            </div>
                                            <div class="horizontal layout">
                                                <div class="flex">
                                                    Transformer: <span style="font-weight: bold">[[item.transformerCode]]</span>
                                                </div>
                                                <div class="flex-1">
                                                    % Outage:
                                                    <paper-badge label="[[item.outageVsMeterCount]]" class="red"></paper-badge>
                                                </div>
                                            </div>
                                            <div class="horizontal layout">
                                                <div class="flex">
                                                    <span>Unverified: </span>
                                                    <span style="font-weight: bold">[[item.unverifiedCount]]</span>
                                                </div>
                                                <div class="flex">
                                                    <span>Verified: </span>
                                                    <span style="font-weight: bold">[[item.verifiedCount]]</span>
                                                </div>
                                            </div>
                                            <div class="horizontal layout">
                                                <div class="flex">
                                                    No. of Customers: <span>[[item.noOfCustomers]]</span>
                                                </div>
                                                <div class="flex-1">
                                                    No. of Meters: <span>[[item.totalDevices]]</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-actions">
                                            <paper-icon-button icon="communication:location-on" title="Show on Map" on-click="onFeederAlertLocationClicked"></paper-icon-button>
                                            <paper-icon-button icon="note-add" alt="Add to Event" title="Add to Event" disabled="[[!showLargeMode]]" on-click="onFeederAlertClicked"></paper-icon-button>
                                        </div>
                                    </paper-card>
                                </div>
                            </template>
                        <!--</iron-list>-->
                      
                        <!-- <iron-list id="icpList" items="[[icpOutagesFiltered]]" as="item">
                            <template>
                                <div class="cards">
                                    <paper-card>
                                        <div class="card-content">
                                            <div class="horizontal layout">
                                                <div class="flex">
                                                    ICP: <span style="font-weight: bold">[[item.icp]]</span>
                                                </div>
                                                <div class="flex-1">
                                                    Transformer: <span style="font-weight: bold">[[item.transformerCode]]</span>
                                                </div>
                                            </div>
                                            <div>
                                                Feeder: <span style="font-weight: bold">[[item.feederName]]</span>
                                            </div>
                                            <div>
                                                Category: <span>[[item.categoryDescription]]</span>
                                            </div>
                                            <div>
                                                Customer: <span style="font-weight: bold">[[item.customerName]]</span>
                                            </div>
                                            <div>
                                                Address: <span style="font-weight: bold">[[item.streetAddress]]</span> <span style="font-weight: bold">[[item.town]]</span>
                                            </div>
                                            <div>
                                                Duration: <span>[[convertDuration(item.durSec)]]</span>
                                            </div>
                                            <template is="dom-if" if="[[item.isDisconnect]]">
                                                Disc. Reason: <span>[[item.disconnectReason]]</span>
                                            </template>
                                        </div>
                                        <div class="card-actions">
                                            <div class="horizontal layout">
                                                <paper-icon-button icon="communication:location-on" title="Show on Map" on-click="onIcpLocationClicked"></paper-icon-button>
                                                <paper-icon-button icon="file-upload" on-click="onIcpClicked"></paper-icon-button>
                                                <div class="flex"></div>
                                                <template is="dom-if" if="{{item.isDisconnect}}">
                                                    <paper-icon-button icon="cloud-off" class="disconnect" title="Marked for Disconnection" alt="Marked for Disconnection"></paper-icon-button>
                                                </template>
                                                <template is="dom-if" if="{{item.isReconnect}}">
                                                    <paper-icon-button icon="compare-arrows" class="disconnect" title="Marked for Reconnection" alt="Marked for Reconnection"></paper-icon-button>
                                                </template>
                                                <template is="dom-if" if="{{item.isRemoved}}">
                                                    <paper-icon-button icon="image:leak-remove" class="removed" title="Removed from ICP" alt="Removed From ICP"></paper-icon-button>
                                                </template>
                                            </div>
                                        </div>
                                    </paper-card>
                                </div>
                            </template>
                        </iron-list> -->
                    </div>
            </app-header-layout>
            </div>
        </div>
        <paper-dialog id="dlgNewAlert" class="size-position">
            <h2>New Event</h2>
            <paper-textarea label="Description" value="{{info.description}}"></paper-textarea>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onAddNewFaultAlert">Accept</paper-button>
            </div>
        </paper-dialog>
        <iron-ajax id="ajaxAlert" url="{{addAlertUrl}}" handle-as="json" method="POST" content-type="application/json" body="{{info}}" on-response="onNewAlertResponse" loading="{{isLoading}}"></iron-ajax>
        <!--<iron-ajax id="ajaxAddTransformer"-->
        <!--url="{{addTransfromerToAlertUrl}}"-->
        <!--handle-as="json"-->
        <!--method="POST"-->
        <!--content-type="application/json"-->
        <!--last-response="{{info}}"-->
        <!--loading="{{isLoading}}"></iron-ajax>-->
        <!--<iron-ajax id="ajax"-->
        <!--url="{{url}}"-->
        <!--handle-as="json"-->
        <!--last-response="{{histData}}"-->
        <!--loading="{{isLoading}}"></iron-ajax>-->
        <iron-localstorage name="username" use-raw="" value="{{username}}"></iron-localstorage>
        `;
      }

    _onFilterClick(){
        this.$.lsCategories.reload();
    }

    showListChanged() {
        if (this.icpCategories) { //|| this.icpCategories.icpCategories) {
            this.$.lsCategories.reload();
        }
    }

    showIgnoredOutagesChanged(newValue) {
        if (newValue === true) {
            for (let i = 0; i < this.uncheckedCats.length; i++) {
                if(this.uncheckedCats[i] === HIDE_IGNORED_OUTAGES){
                    this.splice("uncheckedCats",i,1);
                }
            }

        } else {
            this.push("uncheckedCats", HIDE_IGNORED_OUTAGES);

        }
        var oText = this.get("searchText");
        this.set('searchText', HIDE_IGNORED_OUTAGES);
        this.set('searchText', oText);
    }

   showUncategorisedOutagesChanged(newValue) {
        if (newValue === true) {
    
            for (let i = 0; i < this.uncheckedCats.length; i++) {
                if(this.uncheckedCats[i] === 'uncategorised'){
                    this.splice("uncheckedCats",i,1);
                }
            }

        } else {
            this.push("uncheckedCats", 'uncategorised');
        }
        var oText = this.get("searchText");
        this.set('searchText', HIDE_IGNORED_OUTAGES);
        this.set('searchText', oText);
    }

    close() {
        this.set('showList', false);
        this.dispatchEvent(new CustomEvent('shutdownlist-close', {bubbles: true,  composed: true}))
    }

    onNewNoteClick() {
        this.$.dlgNewAlert.open();
    }

    onAddNewFaultAlert() {
        this.set('addAlertUrl', '/api/fas/add/$username');
        this.$.ajaxAlert.body = this.info.toJson();
        this.$.ajaxAlert.generateRequest();
    }

    onNewAlertResponse() {
        this.set('selectedAlertId', this.details.response);
        this.set("info", {description:""});
        this.set('showLargeMode', true);
    }

    formatDate(s) {
        returnmoment.parseZone(s).format('DD MMM');
    }

    onFeederAlertClicked(e) {
        var model = this.$.itemList.modelForElement(e.target);
        var code = model.get('itßem.transformerCode');
       
        this.dispatchEvent(new CustomEvent('add-to-alert', {bubbles: true,  composed: true,
        detail:{
            'transformerId': 0,
            'code': code
        }}))
    }

    onFeederAlertLocationClicked(e) {
        var model = this.$.itemList.modelForElement(e.target);
        this.dispatchEvent(new CustomEvent('show-on-map', {bubbles: true,  composed: true,detail:{
            'item': model.get('item')
        }}))
    }

    closeFaultAlertOverlay() {
        this.set('showLargeMode', false);
    }

    onClearSearchText() {
        this.set('searchText', '');
    }

    filterItems(items, t) {
        if(t.length > 0){
            t = t.toUpperCase();
            return items.filter((i) => {
            return i.feederCode.toUpperCase().includes(t) ||
                i.transformerCode.toUpperCase().includes(t) ||
                i.feederName.toUpperCase().includes(t);
            });
        }
        return items;
    }

    computeFilter(t){
        if (!t) {
            // set filter to null to disable filtering
            return null;
        } else {
            // return a filter function for the current search string
            t = t.toUpperCase();
            return function(i) {
                return i.feederCode.toUpperCase().includes(t) ||
                    i.transformerCode.toUpperCase().includes(t) ||
                    i.feederName.toUpperCase().includes(t);
            };
        }
    }

    _getDurationFromFilterDays(duration) {
        var dur = 0;
        switch (duration) {
            case "30M":
                dur = 1800;
                break;
            case "1H":
                dur = 3600;
                break;
            case "6H":
                dur = 21600;
                break;
            case "12H":
                dur = 43200;
                break;
            case "1D":
                dur = 86400;
                break;
            case "7D":
                dur = 604800;
                break;
            case "30D":
                dur = 2592000;
                break;
            case "60+":
                dur = 9999999999999;
                break;

        }
        return dur;
    }

    // filterIcpItems(items, t, duration) {
    //     const dur = this._getDurationFromFilterDays(duration);
    //     this.set('icpOutagesFiltered', []);




    //     var fItems = items.filter((i) => {
    //         return i.icp.toUpperCase().includes(t.toUpperCase()) ||
    //             i.transformerCode.toUpperCase().includes(t.toUpperCase()) ||
    //             i.customerName.toUpperCase().includes(t.toUpperCase()) ||
    //             i.streetAddress.toUpperCase().includes(t.toUpperCase()) ||
    //             i.categoryDescription.toUpperCase().includes(t.toUpperCase()) ||
    //             i.feederName.toUpperCase().includes(t.toUpperCase()) ||
    //             i.feederCode.toUpperCase().includes(t.toUpperCase()) ||
    //             i.town.toUpperCase().includes(t.toUpperCase());
    //     });



    //     this.uncheckedCats.forEach((u) => {
    //         fItems = fItems.filter((i) => {
    //             return !i.categoryDescription.toUpperCase().includes(u.toUpperCase());
    //         });
    //         if (u === HIDE_IGNORED_OUTAGES) {
    //             fItems = fItems.filter((i) => {
    //                 return i.isIgnoreFromRealTime !== true
    //             });
    //         }
    //         if (u === 'uncategorised') {

    //             fItems = fItems.filter((i) => {
    //                 return i.categoryDescription.length !== 0;
    //             });
    //         }
    //     });
    //     this.uncheckedDisconnects.forEach((u) => {
           
    //         fItems = fItems.filter((i) => {
    //             return !i.disconnectionReason.toUpperCase().includes(u.toUpperCase());
    //         });

    //         if (u.includes('DISCONNECT AND REMOVE METERS')) {

    //             fItems = fItems.filter((i) => {
    //                 return i.isRemoved !== true;
    //             });
    //         }
    //     });

    //     for (let j = 0; j < fItems.length; j++) {
    //         if (fItems[j].durSec < dur) {
    //             this.push('icpOutagesFiltered', fItems[j]);
    //         }
    //     }
    //     //  this.$.icpList.notifyResize();

    // }

    onIcpCategoryChanged(e) {
        var model = e.model;
        var description = model.get("item.description");

        if (!model.get("item.checked")) {
            this.push("uncheckedCats", description);
        } else {
            // TODO: splice
            // removeWhere("uncheckedCats", (i) => i == description);

            for (let i = 0; i < this.uncheckedCats.length; i++) {
                if (this.uncheckedCats[i] === description) {
                    this.splice('uncheckedCats', i,1)
                }
            }
            // this.uncheckedCats.splice(this.uncheckedCats.findIndex((i) => { // return i === description; // }), 1);
        }
        //Workaround for notification of filter
        var oText = this.get("searchText");
        this.set('searchText', description);
        this.set('searchText', oText);
    }

    onIcpDisconnectChanged(e) {
        var model = e.model;
        var description = model.get("item.description");

        if (!model.get("item.checked")) {
            this.push("uncheckedDisconnects", description);
        } else {
             for (let i = 0; i < this.uncheckedDisconnects.length; i++) {
                if (this.uncheckedDisconnects[i] === description) {
                    this.splice("uncheckedDisconnects",i,1);
                }
            }
        }
        //Workaround for notification of filter
        var oText = this.get("searchText");
        this.set('searchText', description);
        this.set('searchText', oText);
    }

    onAllIcpDisconnectionsChanged() {
        var value = false;
        //                    this.clear("uncheckedDisconnects");
        this.uncheckedDisconnects = [];
        if (this.allIcpDisconnectionsChecked) {
            value = true;
        }
        for (var i = 0; i < this.icpCategories.icpDisconnectionTypes.length; i++) {
            this.set(`icpCategories.icpDisconnectionTypes.${i}.checked`, value);
            if (!value) {
                this.push("uncheckedDisconnects",
                    this.icpCategories.icpDisconnectionTypes[i].description);
            }
        }
        var oText = this.get("searchText");
        this.set('searchText', 'all');
        this.set('searchText', oText);
    }

    onAllIcpCategoryChanged() {
        let value = this.allIcpCategoriesChecked;
        //                    this.clear("uncheckedCats");
        this.uncheckedCats = [];
        
        this.set('showUncategorisedOutages', value);
     
        //All others
        for (var i = 0; i < this.icpCategories.icpCategories.length; i++) {
            this.set(`icpCategories.icpCategories.${i}.checked`, value);
            if (!value) {
                this.push("uncheckedCats", this.icpCategories.icpCategories[i].description);
            }
        }

        var oText = this.get("searchText");
        this.set('searchText', 'all');
        this.set('searchText', oText);
    }

    convertDuration(dur) {
        // return moment.duration(dur,'seconds').humanize();
        return this.convertDurationToString(dur);
    }

    convertDurationToString(dur) {
        let days = parseInt((dur / 3600) / 24);
        let hours = parseInt((dur / 3600) % 24);
        let minutes = parseInt((dur / 60) % 60);
        // let seconds = parseInt(dur % 60);
        if (days > 0) {
            return `${days} days, ${hours} hrs and ${minutes} mins`;
        } else {
            return `${hours} hrs and ${minutes} mins`;
        }
    }

    onIcpClicked(e) {
        var model = this.$.icpList.modelForElement(e.target);
        
        this.dispatchEvent(new CustomEvent('show-asset-detail', {bubbles: true,  composed: true,detail:{
            'id': model.get("item.id"),
            'assetType': 'icp'
        }}));
    }

    onIcpLocationClicked(e) {
        var model = this.$.icpList.modelForElement(e.target);
        this.dispatchEvent(new CustomEvent('show-on-map', {bubbles: true,  composed: true,detail:{
            'item': model.get('item')
        }}));
    }
}


customElements.define(LiveOutagesList.is, LiveOutagesList);
