import '@polymer/polymer/polymer-legacy.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';

/**
* @polymer
* @extends HTMLElement
**/
class PoleAssetDetail extends PolymerElement {

    static get is() { return 'pole-asset-detail'; }

    static get properties() {
        return {
            assetId:{
                type: Number,
                observer: 'assetIdChanged'
            },
            selected:{
                type: Number,
                value: 0
            },
            url:String
        }
    }

    static get template() {
        return html`
           <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>

        <style>
            :host {
                display: block;
                width: 100%;
                height: 100%;
                --paper-input-container-input: {
                    font-size: 13px;
                };
            }
            paper-progress {
                width: 100%;
                --paper-progress-container-color: white;
                --paper-progress-active-color: var(--paper-orange-500);
                --paper-progress-secondary-color: var(--paper-orange-100);

            }
          

        </style>

<app-header-layout has-scrolling-region="true">
        <app-header scroll-target="content" slot="header" fixed>
            <app-toolbar class="small">
            <paper-tabs selected="{{selected}}" class="flex">
                <paper-tab>Pole Detail</paper-tab>
                <paper-tab>Consumption</paper-tab>
                <paper-tab>Events</paper-tab>

            </paper-tabs>
            <div class="bottom flex"></div>
            <paper-icon-button class="bottom " icon="close" on-click="close"></paper-icon-button>

            </app-toolbar>
        </app-header>

        <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>


        <iron-pages selected="{{selected}}">
            <div class="horizontal around-justified layout" style="padding: 0 20px">
                <div class="verticle layout flex two">
                    <paper-input label="Pole #" value="{{pole.name}}" readonly=""></paper-input>
                    <paper-input label="Owner" value="{{pole.owner}}" readonly=""> </paper-input>
                    <paper-input label="Temp #" value="{{pole.tempNumber}}" readonly=""></paper-input>
                    <paper-input label="Structure" value="{{pole.poleStructure}}" readonly=""></paper-input>
                </div>
                <div class="flex"></div>
                <div class="verticle layout flex two">
                    <paper-input label="Height" value="{{pole.height}}" readonly=""></paper-input>
                    <paper-input label="Material" value="{{pole.material}}" readonly=""></paper-input>
                    <paper-input label="Access" value="{{pole.poleAccess}}" readonly=""></paper-input>
                    <paper-input label="Pole Stay" value="{{pole.poleStay}}" readonly=""></paper-input>
                </div>
            </div>
            <div>Page 2</div>
            <div>Page 3</div>
        </iron-pages>
</app-header-layout>

        <iron-ajax auto="" url="{{url}}" handle-as="json" last-response="{{pole}}" loading="{{isLoading}}"></iron-ajax>

        `;
      }

    assetIdChanged(newValue){
        console.log(`new value: ${newValue}`); // eslint-disable-line

        if(newValue!== ""){
            //      _getPoleDetails();
            this.set('url',`/api/pole/summary/${this.assetId}`);
        }
        else{
            this.set('url',null);
        }
    }

    close()
    {
        this.dispatchEvent(new CustomEvent('assetdetail-close', {bubbles: true,  composed: true}));
    }


    attached() {
    }


}
customElements.define(PoleAssetDetail.is, PoleAssetDetail);
