/* <link rel="import" href="../../bower_components/moment-element/moment-with-locales-import.html"> */
/*
  FIXME(polymer-modulizer): the above comments were extracted
  from HTML and may be out of place here. Review them and
  then delete this comment!
*/
import '@polymer/polymer/polymer-legacy.js';
import moment from 'moment';
import './core-overlay.js';
import './shutdown-detail.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/iron-list/iron-list.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-tabs/paper-tab.js';

import '@polymer/paper-button/paper-button.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import '@polymer/iron-scroll-threshold/iron-scroll-threshold.js';
import { PolymerElement , html } from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-if.js';

const HISTORY_URL = '/api/sr/past';
/**
* @polymer
* @extends HTMLElement
**/
class ShutdownList extends PolymerElement {

    static get is() { return 'shutdown-list'; }

    static get properties() {
        return {
            showList: {
                type: Boolean,
                observer: 'showListChanged'
            },
            selected: {
                type: Number,
                value: 0,
                observer: 'selectedTabChanged'
            },
            url: {
                type: String,
                value: '/api/sr/latest'
            },
            isLoading: {
                type: Boolean,
                value: false
            },
            username: {
                type: String
            },
            data: {
                type: Array,
                observer: 'dataChanged'
                },
            selectedShutdownId: Number,
            pastData: {
                type: Array,
                value: ()=> [],
                observer: 'pastDataChanged'
            }
        }
    }

    static get template() {
        return html`
          <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>

        <style>
            :host {
                display: block;
                width: 100%;
                height: 100%;
                --paper-input-container-input: {
                    font-size: 13px;
                };
            }
            paper-progress {
                width: 100%;
                --paper-progress-container-color: white;
                --paper-progress-active-color: var(--paper-orange-500);
                --paper-progress-secondary-color: var(--paper-orange-100);

            }
            .container{
                padding: 10px;
            }


            .cards {
                @apply --layout-vertical;
                @apply --center-justified;
                /*max-width: 400px;*/
                margin-left: auto;
                margin-right: auto;
            }
            paper-card {
                /*width: 100%;*/
                margin: 8px;
            }
            #shutdownAlertOverlay {
                position: fixed;
                right: 0px;
                top: 0px;
                background: white;
                width: 408px;
                height: 100%;
                overflow: auto;

            }

            .loader {
        background-color: #9FA8DA;
        text-align: center;
        height: 44px;
        font: 13px arial;
        line-height: 44px;
        color: white;
      }


        </style>

   
        <app-header-layout has-scrolling-region="true" fullbleed="">
        <app-header scroll-target="content" slot="header" id="pshp1" fixed >
            <app-toolbar class="tall" sticky>
                <paper-icon-button icon="note-add" alt="New Shutdown" title="New Shutdown" on-click="onNewNoteClick"></paper-icon-button>
                <div main-title class="middle title">Shutdown Requests</div>
                <!-- <div class="flex"></div> -->
                <paper-icon-button icon="close" on-click="close" alt="Close" title="Close"></paper-icon-button>
               
                
            </app-toolbar>
           
       
                <paper-tabs sticky bottom-item selected="{{selected}}" class="bottom self-end" style="width: 300px;">
                    <paper-tab>Current</paper-tab>
                    <paper-tab>History</paper-tab>
                </paper-tabs>
            
          
        </app-header> 
        <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>
            <!-- <div class="bottom flex"></div> -->
        
            <div id="content" style="padding-right: 0px">
            
                

                <iron-pages selected="{{selected}}"  id="pshp1">
                    <iron-list id="itemList" items="[[data]]" as="item">
                        <template>

                            <div class="cards">
                                <paper-card>
                                    <div class="card-content">
                                        <div class="horizontal layout">
                                            <div>
                                                ID: <span style="font-weight: bold">[[item.id]]</span>
                                            </div>
                                            <div class="flex"></div>
                                            <div>
                                                Date: <span style="font-weight: bold">[[formatDate(item.shutdownDate)]]</span>
                                            </div>
                                            <div style="padding-left: 20px">
                                                Feeder: <span style="font-weight: bold">[[item.feeder]]</span>
                                            </div>
                                        </div>
                                        <div class="horizontal layout">
                                            <div>Status: <span style="font-weight: bold">[[item.status]]</span></div>
                                            <div class="flex"></div>
                                            <div>
                                                Calls #: <span style="font-weight: bold">[[item.callCount]]</span>
                                            </div>
                                        </div>
                                        <div>
                                            <p>[[item.description]]</p>
                                        </div>

                                    </div>
                                    <div class="card-actions">
                                        <paper-icon-button icon="chrome-reader-mode" title="Show on Map" on-click="onShutdownClicked"></paper-icon-button>

                                    </div>
                                </paper-card>
                            </div>
                        </template>
                    </iron-list>

                    <section id="scrollable-element" style="overflow: auto; height:100vh">
                        <iron-list id="historyList" items="[[pastData]]" as="item"  >
                            <template>
                                <div class="cards">
                                    <paper-card>
                                        <div class="card-content">
                                            <div class="horizontal layout">
                                                <div>
                                                    ID: <span style="font-weight: bold">[[item.id]]</span>
                                                </div>
                                                <div class="flex"></div>
                                                <div>
                                                    Date: <span style="font-weight: bold">[[formatDate(item.shutdownDate)]]</span>
                                                </div>
                                                <div style="padding-left: 20px">
                                                    Feeder: <span style="font-weight: bold">[[item.feeder]]</span>
                                                </div>
                                            </div>
                                            <div class="horizontal layout">
                                                <div>Status: <span style="font-weight: bold">[[item.status]]</span></div>
                                                <div class="flex"></div>
                                                <div>
                                                    Calls #: <span style="font-weight: bold">[[item.callCount]]</span>
                                                </div>
                                            </div>
                                            <div>
                                                <p>[[item.description]]</p>
                                            </div>

                                        </div>
                                        <div class="card-actions">
                                            <paper-icon-button icon="chrome-reader-mode" title="Show on Map" on-click="onShutdownPastClicked"></paper-icon-button>

                                        </div>
                                    </paper-card>
                                </div>
                            </template>
                        </iron-list>
                        <div class="loader">Fetching new items...</div>
                    </section>
                        
                      
                    <iron-scroll-threshold on-lower-threshold="_loadMoreHistoryData" id="scrollThreshold" scroll-target="scrollable-element" lower-threshold="0"></iron-scroll-threshold>
                

                    
                </iron-pages>


            </div>
        </app-header-layout>

        <!-- Shutdown Notification Overlay-->
        <core-overlay id="shutdownAlertOverlay" class="right-side-overlay" auto-close-disabled="true" on-shutdowndetail-close="closeShutdownAlertOverlay">
            <shutdown-detail shutdown-id="{{selectedShutdownId}}"></shutdown-detail>

        </core-overlay>


        <iron-ajax id="ajax" url="{{url}}" handle-as="json" last-response="{{data}}" loading="{{isLoading}}"></iron-ajax>

        <iron-ajax id="ajaxPast" url="/api/sr/past/0" handle-as="json" on-response="_onHistoryResponse" loading="{{isLoading}}"></iron-ajax>



        <iron-localstorage name="username" use-raw="" value="{{username}}"></iron-localstorage>
        `;
      }

    ready(){
        super.ready();
        // this.$.historyList.scrollTarget = this.ownerDocument.documentElement;
        // this.$.historyList.scrollTarget = this.$.pshp1.scrollTarget;
        // this.$.scrollThreshold.scrollTarget = "historyList";
        
    }

    showListChanged(newValue) {
        if (newValue === true) {

            this.$.ajax.generateRequest();
        }
    }
    connectedCallback(){
        super.connectedCallback();
       
    }

    selectedTabChanged(newValue) {
        this.set('pastData',[]);
        this.$.historyList.notifyResize();
    
            // this.$.scrollThreshold.scrollTarget = this.$.pshp1.scrollTarget;
        
            this.$.historyList.notifyResize();
        
        if (newValue === 1) {
            // this.$.ajaxPast.generateRequest();
            // this.$.historyList.set('items', []);
            
            this._loadMoreHistoryData();
        }
    }
    
    //Issue with List render using ajax last-response
    pastDataChanged() {
        // this.$.historyList.fire('iron-resize');
        // this.$.historyList.notifyResize();
    }
    dataChanged(){
            this.$.itemList.notifyResize();
    }

    close() {
        this.showList = false;
        this.data = [];
        this.dispatchEvent(new CustomEvent('shutdownlist-close', {bubbles: true,  composed: true}))
    }

    onNewNoteClick() {
        this.$.dlg.open();
    }

    formatDate(s) {
        return s ? moment.parseZone(s).format('DD MMM YYYY'): "";
    }

    onShutdownClicked(e) {
        var model = this.$.itemList.modelForElement(e.target);
        this.selectedShutdownId = model.item.id;
        this.$.shutdownAlertOverlay.open();
        this.dispatchEvent(new CustomEvent('show-on-map', {bubbles: true,  composed: true,
            detail:{
                'item': model.item
            }}));

    }
    
    onShutdownPastClicked(e){
        var model = this.$.historyList.modelForElement(e.target);
        this.set('selectedShutdownId', model.get('item.id'));
        this.$.shutdownAlertOverlay.open();
        this.dispatchEvent(new CustomEvent('show-on-map', {bubbles: true,  composed: true,
            detail:{
                'item': model.get('item')
            }}));

    }

    closeShutdownAlertOverlay() {
        this.$.shutdownAlertOverlay.close();
    }

    _loadMoreHistoryData(){
        console.log('lower-threshold triggered');
        this.$.ajaxPast.url = HISTORY_URL + "/" + this.pastData.length;
        this.$.ajaxPast.generateRequest();
    }

    _onHistoryResponse(e){
        let results = e.detail.response;

        results.forEach((r)=> {
            // this.$.historyList.push('items', r);
            this.push('pastData', r);
        });
       
        setTimeout(() => {
            this.$.scrollThreshold.clearTriggers();
            this.$.historyList.notifyResize();
            // document.querySelector('#historyList').fire('iron-resize');
            // this.$.historyList.dispatchEvent(new CustomEvent('iron-resize', {bubbles: true, composed: true}));
            // document.querySelector('iron-list').fire('iron-resize');
            // this.$.historyList.fire('iron-resize');
            
        });
        
    }

}

customElements.define(ShutdownList.is, ShutdownList);
