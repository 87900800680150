import '@polymer/polymer/polymer-legacy.js';
import './icp-asset-detail.js';
import './pole-asset-detail.js';
import './transformer-asset-detail.js';
import './capacitor-asset-detail.js';
import './pillar-asset-detail.js';
import './feeder-asset-detail.js';
import './tree-asset-detail.js';
import './light-asset-detail.js';
import './linkbox-asset-detail.js';
import './oh-switch-asset-detail.js';
import './switch-station-asset-detail.js';
import './substation-asset-detail.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { PolymerElement } from '@polymer/polymer/polymer-element.js';

let comp;
/**
* @polymer
* @extends HTMLElement
**/
class MapAssetDetail extends PolymerElement {
  static get template() {
    return html`
        <style>
            :host {
                display: block;
                height: 100%;
                width: 100%;
            }
            #assetPages{
                height: 500px;
            }


        </style>


           <div id="assetPages" class="flex"></div>

        <!--<iron-pages id='assetPages' selected="0" class="flex">-->
            <!--<div>First</div>-->
            <!--<icp-asset-detail asset-id="{{assetId}}"></icp-asset-detail>-->
            <!--<pole-asset-detail asset-id="{{assetId}}"></pole-asset-detail>-->
            <!--<transformer-asset-detail asset-id="{{assetId}}"></transformer-asset-detail>-->

        <!--</iron-pages>-->
`;
  }

  static get is() { return 'map-asset-detail'; }

  static get properties() {
      return {
          app: Object,
          assetType: {
              type: String,
              value: '',
              observer: 'assetTypeChanged'
          },
          assetId:{
              type: String,
              value: '',
              observer: 'assetIdChanged'
          }
      }
  }


  assetIdChanged(newValue) {
      // console.log(`AssetID changed from ${oldValue} to ${newValue}`);
      if(comp !== undefined && newValue){
          
          comp.assetId = newValue;
          comp.set('assetId',newValue);
      }
  }

  assetTypeChanged(newValue) {
      switch (newValue) {
          case "icp":
              comp =  document.createElement("icp-asset-detail");
              // comp.set('assetId', this.assetId);
              comp.assetId =null;
              comp.set('app', this.app);
              break;
          case "icp_ssn":
              comp = document.createElement("icp-asset-detail");
              // comp.set('assetId',this.assetId);
              comp.assetId =null;
              comp.set('app', this.app);
              break;
          case "last_gasp":
              comp = document.createElement("icp-asset-detail");
              // comp.set('assetId',this.assetId);
              comp.assetId =null;
              comp.set('app', this.app);
              break;
          case "icp_disconnect":
              comp = document.createElement("icp-asset-detail");
              // comp.set('assetId',this.assetId);
              comp.assetId =null;
              comp.set('app', this.app);
              break;
          case "pole":
              comp = document.createElement("pole-asset-detail");
              // comp.set('assetId',this.assetId);
              comp.assetId =null;
              break;
          case "transformer":
              comp = document.createElement("transformer-asset-detail");
              comp.assetId =null;
              comp.set('app', this.app);
              // comp.assetId =this.assetId;
              // comp.set('assetId',this.assetId);

              break;
          case "capacitor":
              comp = document.createElement("capacitor-asset-detail");
              comp.set('assetId',null);
              break;
          case "pillar":
              comp = document.createElement("pillar-asset-detail");
              comp.set('assetId',null);
              break;
          case "feeder":
              comp = document.createElement("feeder-asset-detail");
              comp.set('assetId',null);
              break;
          case "tree":
              comp = document.createElement("tree-asset-detail");
              comp.set('assetId',null);
              break;
          case "substation":
              comp = document.createElement("substation-asset-detail");
              comp.set('assetId',null);
              break;
          case "light":
              comp = document.createElement("light-asset-detail");
              comp.set('assetId',null);
              break;
          case "linkbox":
              comp = document.createElement("linkbox-asset-detail");
              comp.set('assetId',null);
              break;
          case "oh_switch":
              comp = document.createElement("ohswitch-asset-detail");
              comp.set('assetId',null);
              break;
          case "switching_station":
              comp = document.createElement("switchstation-asset-detail");
              comp.set('assetId',null);
              break;
          default:
              //md.selected = 0;
              break;
      }

      let div = this.$.assetPages;
      // div.innerHTML = '';//.children.clear();
      if(comp!== undefined){
          div.innerHTML = '';
          div.appendChild(comp);
      }
  }
}


customElements.define(MapAssetDetail.is, MapAssetDetail);
