import '@polymer/polymer/polymer-legacy.js';
import '@polymer/iron-image/iron-image.js';
import '@polymer/iron-a11y-keys/iron-a11y-keys.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-button/paper-button.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { PolymerElement } from '@polymer/polymer/polymer-element.js';
/**
* @polymer
* @extends HTMLElement
**/
class LoginView extends PolymerElement {
  static get template() {
    return html`
        <style>
        :host {
            display: block;
            height: 100%;
        }
        
        #card {
            @apply --layout-vertical;
            @apply --center-justified;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
            flex: 1;
        }
        
        .container {
            padding: 100px;
        }
        
        .card-content .title {
            position: absolute;
            top: 30px;
            left: 100px;
            color: black;
        }
        
        .card-content .medium {
            padding-bottom: 10px;
            font-size: 24px;
            font-weight: bold;
        }
        
        paper-card {
            width: 100%;
            margin-bottom: 16px;
        }
        </style>
        <div class="container">
            <div id="card">
                <paper-card>
                    <div class="card-content">
                        <iron-image sizing="contain" fade src="/images/cpl.png"></iron-image>
                        <!--<img src="http://placehold.it/350x150">-->
                        <div class="title">
                            <div class="medium">Login</div>
                            <!--<div class="small">Enter your login details</div>-->
                        </div>
                    </div>
                    <div class="card-content">
                        <paper-input label="Username" value="{{userInfo.email}}">
                            <iron-icon icon="account-box" slot="prefix"></iron-icon>
                        </paper-input>
                        <paper-input label="Password" type="password" value="{{userInfo.password}}">
                            <iron-icon icon="lock" slot="prefix"></iron-icon>
                        </paper-input>
                    </div>
                    <div class="card-actions">
                        <div class="horizontal end-justified layout">
                            <paper-button on-click="onLoginClick" focused="">Login</paper-button>
                        </div>
                    </div>
                </paper-card>
            </div>
        </div>
        <iron-a11y-keys keys="enter" on-keys-pressed="onEnter"></iron-a11y-keys>
        <iron-ajax id="ajxAuthUser" url="{{urlAuthUser}}" handle-as="json" method="POST" content-type="application/json" on-response="onAuthResponse" on-error="onAuthError" loading="{{isLoading}}"></iron-ajax>
        <iron-localstorage name="username" use-raw="" value="{{username}}"></iron-localstorage>
`;
  }

  static get is() { return 'login-view'; }

  static get properties() {
      return {
          app: {
              type: Object,
              notify: true
          },
          isLoggedIn: Boolean,
          userInfo: {
              type: Object,
              value: () => ({
                  email: '',
                  password: ''
              })
          },
          urlAuthUser: {
              type: String,
              value: '/api/user/auth'
          },
          username: String,
          isLoading: Boolean
      }
  }

  onLoginClick() {
      this.login();
  }

  onEnter() {
      this.login();
  }

  login() {
      this.$.ajxAuthUser.body = JSON.stringify(this.userInfo);
      this.$.ajxAuthUser.generateRequest();
  }

  onAuthResponse(e) {
      this.set('userInfo.password', '');

      if (e.detail.succeeded) {
          let resp = e.detail.response;
          // console.log(resp); 
          //Handle https for Test System
          // if (window.location.protocol === "https:"){
          //     document.cookie = `sid=${resp['sid']}; expires=0; path=/; Secure; HttpOnly;`;

          // }else{
          //     document.cookie = `sid=${resp['sid']}; expires=0; path=/;`;
          // }
          
          window.localStorage.setItem('username', this.userInfo.email);
          this.set('app', {username: this.userInfo.email, role: resp.role});

       
          this.dispatchEvent(new CustomEvent('login-success',{bubbles: true,composed: true}));
      }

  }
  onAuthError() {
      this.set('userInfo.password', '');

  }
}
customElements.define(LoginView.is, LoginView);
