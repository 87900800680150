import '@polymer/polymer/polymer-legacy.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
const $_documentContainer = document.createElement('template');


/**
* @polymer
* @extends HTMLElement
**/
class CapacitorAssetDetail extends PolymerElement {
    
    static get is() { return 'capacitor-asset-detail'; }

    static get properties() {
        return {
            assetId:{
                type: Number,
                observer: 'assetIdChanged'
            },

            selected: {
                type: Number,
                value: 0
            },

            url: {
                type: String
            },
        }
    }

    static get template() {
        return html`
          <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>
  

        <style>
            :host {
                display: block;
                width: 100%;
                height: 100%;
                --paper-input-container-input: {
                    font-size: 13px;
                };
            }
            paper-progress {
                width: 100%;
                --paper-progress-container-color: white;
                --paper-progress-active-color: var(--paper-orange-500);
                --paper-progress-secondary-color: var(--paper-orange-100);

            }
            

        </style>

<app-header-layout has-scrolling-region="true">
        <app-header scroll-target="content" slot="header" fixed>
            <app-toolbar class="small">
                <paper-tabs selected="{{selected}}" class="flex">
                    <paper-tab>Capacitor Detail</paper-tab>
                    <paper-tab>History</paper-tab>


                </paper-tabs>
                <div class="bottom flex"></div>
                <paper-icon-button class="bottom " icon="close" on-click="close"></paper-icon-button>

            </app-toolbar>
        </app-header>
           
            <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>


            <iron-pages selected="{{selected}}">
                <div class="horizontal around-justified layout" style="padding: 0 20px">
                    <div class="verticle layout flex two">
                        <paper-input label="Name" value="{{info.name}}" readonly=""></paper-input>
                        <paper-input label="Switch Segment" value="{{info.switchSegment}}" readonly=""> </paper-input>
                        <paper-input label="Make" value="{{info.make}}" readonly=""></paper-input>
                        <paper-input label="Size" value="{{info.size}}" readonly=""></paper-input>
                    </div>
                    <div class="flex"></div>
                    <div class="verticle layout flex two">
                        <paper-input label="Date Installed" value="{{info.dateInstalled}}" readonly=""></paper-input>
                        <paper-input label="End Of Life Date" value="{{info.endOfLifeDate}}" readonly=""></paper-input>
                        <paper-input label="Date Decommissioned" value="{{info.dateDecommissioned}}" readonly=""></paper-input>
                        <paper-input label="Feeder" value="{{info.feeder}}" readonly=""></paper-input>
                    </div>
                </div>
                <div>History Details</div>

            </iron-pages>
</app-header-layout>

        <iron-ajax auto="" url="{{url}}" handle-as="json" last-response="{{info}}" loading="{{isLoading}}"></iron-ajax>
        `;
      }

    assetIdChanged(newValue) {
        console.log('new value: ' + newValue);
        if (newValue != ''){
            this.set('url', '/api/capacitor/summary/' + newValue);
        }
        else{
            this.set('url', null);
        }
    }

    close() {
        this.dispatchEvent(new CustomEvent('assetdetail-close', {bubbles: true,  composed: true}));
    }

}
customElements.define(CapacitorAssetDetail.is, CapacitorAssetDetail);
