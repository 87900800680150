import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import '@vaadin/vaadin-date-picker/vaadin-date-picker.js';
import moment from 'moment';

/**
 * `LowerCaseDashedName` Description
 *
 * @customElement
 * @polymer
 * @demo
 * 
 */
class DatePicker extends PolymerElement {
    static get properties() {
        return {
            value:{
                type:String, 
                notify: true
            },
            label: String,
            placeholder: String

        }
    }

    static get template() {
        return html`
        <vaadin-date-picker label="{{label}}" placeholder="{{placeholder}}" value={{value}} id="picker">
        </vaadin-date-picker>

        `;
    }

    /**
     * Instance of the element is created/upgraded. Use: initializing state,
     * set up event listeners, create shadow dom.
     * @constructor
     */
    constructor() {
        super();
        
    }

    /**
     * Use for one-time configuration of your component after local
     * DOM is initialized.
     */
    ready() {
        super.ready();
        moment.locale('en-NZ');
        var datepicker = this.$.picker.i18n.formatDate = (date) =>  moment(date).format("DD MMM YYYY");
        // datepicker.i18n = {
        //     week: 'Week',
        //     calendar: 'Calendar',
        //     clear: 'Clear',
        //     today: 'Today',
        //     cancel: 'Cancel',
        //     firstDayOfWeek: 1,
        //     monthNames:
        //         'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
        //     weekdays: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
        //     weekdaysShort: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
        //     formatDate: function(date) {
        //         // Sugar Date expects a native date. The `date` is in format `{ day: ..., month: ..., year: ... }`
        //         return moment(date).format("DD MMM YYYY");//Sugar.Date.format(Sugar.Date.create(date), '{short}');
        //     },
        //     formatTitle: function(monthName, fullYear) {
        //         return monthName + ' ' + fullYear;
        //     },
        //     parseDate: function(dateString) {
        //         return moment.parseZone(dateString).format("DD MMM YYYY");
        //         // const date = Sugar.Date.create(dateString);
        //         // return {
        //         // day: date.getDate(),
        //         // month: date.getMonth(),
        //         // year: date.getFullYear()
        //         // };
        //     }
        // }
        
    }
}

customElements.define('date-picker', DatePicker);