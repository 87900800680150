import { html, PolymerElement } from "@polymer/polymer/polymer-element.js";
import  Sortable from "sortablejs"
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
// import '@polymer/iron-icon/iron-icon.js';

class PolymerSortableElement extends PolymerElement {
  static get template() {
    return html`
        <slot></slot>
    `;
  }

  static get properties() {
    let props = {
      group: {
        type: String,
        value: function() {
          return Math.random();
        }
      },
      sort: { type: Boolean, value: true },
      disabled: { type: Boolean, value: false },
      store: { type: Object, value: null },
      handle: { type: String, value: null },
      // scroll            : { type: Boolean, value: true },
      scrollSensitivity: { type: Number, value: 30 },
      scrollSpeed: { type: Number, value: 10 },
      // draggable         : { type: Boolean },
      ghostClass: { type: String, value: "sortable-ghost" },
      chosenClass: { type: String, value: "sortable-chosen" },
      dragClass: { type: String, value: "sortable-drag" },
      ignore: { type: String, value: "a, img" },
      filter: { type: Object, value: null },
      preventOnFilter: { type: Boolean, value: true },
      animation: { type: Number, value: 0 },
      dropBubble: { type: Boolean, value: false },
      dragoverBubble: { type: Boolean, value: false },
      dataIdAttr: { type: String, value: "data-id" },
      delay: { type: Number, value: 0 },
      forceFallback: { type: Boolean, value: false },
      fallbackClass: { type: String, value: "sortable-fallback" },
      fallbackOnBody: { type: Boolean, value: false },
      fallbackTolerance: { type: Number, value: 0 },
      fallbackOffset: {
        type: Object,
        value: function() {
          return { x: 0, y: 0 };
        }
      }
    };

    for (let prop of Object.keys(props)) {
      let observerName = `_${prop}PropertyChanged`;

      this.prototype[observerName] = (newValue, oldValue) => {
        if (this.sortable) {
          this.sortable.option(prop, newValue);
        }
      };

      props[prop].observer = observerName;
    }

    return props;
  }

  get draggable() {
    return this._draggable || this.getAttribute("draggable") || ">*";
  }

  set draggable(value) {
    this._draggable = value;
    if (this.sortable) {
      this.sortable.option("draggable", value);
    }
  }

  get scroll() {
    return this._scroll || JSON.parse(this.getAttribute("scroll")) || "true";
  }

  set scroll(value) {
    this._scroll = value;
    if (this.sortable) {
      this.sortable.option("scroll", value);
    }
  }

  connectedCallback() {
    super.connectedCallback();

    // Given
    //   <sortable-js>
    //     <template is="dom-repeat" items={{data}}>
    //       <div>
    //         <template is="dom-if" if="true">
    //           <span>hello</span></template></div>
    // After render, it becomes
    //   <sortable-js>
    //     <div>
    //       <span>hello</span>
    //       <template is="dom-if">
    //     <template is="dom-repeat">
    this.initialize();
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.destroy();
  }

  initialize() {
    let templates = this.querySelectorAll("template[is='dom-repeat']");
    let template = templates[templates.length - 1];

    let options = {};

    for (let prop of Object.keys(this.constructor.properties)) {
      options[prop] = this[prop];
    }

    let eventCallbacks = {
      onUpdate: e => {
        if (template) {
          template.splice(
            "items",
            e.newIndex,
            0,
            template.splice("items", e.oldIndex, 1)[0]
          );

          /*
                if (manuallyHandleUpdateEvents) {
                    template.items.splice(e.newIndex, 0, template.items.splice(e.oldIndex, 1)[0]);
                } else {
                    template.splice("items", e.newIndex, 0, template.splice("items", e.oldIndex, 1)[0]);
                }
                */
        }

        this.dispatchEvent(new CustomEvent("sortable-update", e));
      },

      onAdd: e => {
        if (template) {
          let froms = e.from.querySelectorAll("template[is='dom-repeat']");
          let from = froms[froms.length - 1];
          let item = from.items[e.oldIndex];
          template.splice("items", e.newIndex, 0, item);
        }
        this.dispatchEvent(new CustomEvent("sortable-add", e));
      },

      onRemove: e => {
        if (template) {
          template.splice("items", e.oldIndex, 1);
        }

        this.dispatchEvent(new CustomEvent("sortable-remove", e));
      },

      onChoose: e => {
        this.dispatchEvent(new CustomEvent("sortable-choose", e));
      },

      onStart: e => {
        this.dispatchEvent(new CustomEvent("sortable-start", e));
      },

      onEnd: e => {
        this.dispatchEvent(new CustomEvent("sortable-end", e));
      },

      onSort: e => {
        this.dispatchEvent(new CustomEvent("sortable-sort", e));
      },

      onFilter: e => {
        this.dispatchEvent(new CustomEvent("sortable-filter", e));
      },

      onMove: e => {
        this.dispatchEvent(new CustomEvent("sortable-move", e));
      },

      onClone: e => {
        this.dispatchEvent(new CustomEvent("sortable-clone", e));
      }
    };

    for (let callbackName of Object.keys(eventCallbacks)) {
      options[callbackName] = eventCallbacks[callbackName];
    }

    this.sortable = Sortable.create(this, options);
  }

  destroy() {
    if (this.sortable) {
      this.sortable.destroy();
    }
  }
}

customElements.define("sortable-js", PolymerSortableElement);
