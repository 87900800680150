import '@polymer/polymer/polymer-legacy.js';
import moment from 'moment';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-list/iron-list.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import '@polymer/paper-tooltip/paper-tooltip.js';
import '@polymer/iron-icon/iron-icon.js';
import { PolymerElement , html} from '@polymer/polymer/polymer-element.js';
const $_documentContainer = document.createElement('template');


/**
* @polymer
* @extends HTMLElement
**/
class CustomerDetail extends PolymerElement {
    
    static get is() { return 'customer-detail'; }

    static get properties() {
        return {
            customerNo:{
                type: String,
                observer: 'customerNoChanged'
            },

            selected: {
                type: Number,
                value: 0
            },

            url: {
                type: String
            },
        }
    }

    static get template() {
        return html`
          <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>

        <style>
            :host {
                display: block;
                width: 100%;
                height: 100%;
                --paper-input-container-input: {
                    font-size: 13px;
                };
            }

            paper-progress {
                width: 100%;
                --paper-progress-container-color: white;
                --paper-progress-active-color: var(--paper-orange-500);
                --paper-progress-secondary-color: var(--paper-orange-100);

            }


        </style>

<app-header-layout has-scrolling-region="true">
        <app-header scroll-target="content" slot="header" fixed>
            <app-toolbar class="small">
                <paper-tabs selected="{{selected}}" class="flex">
                    <paper-tab>Customer Detail</paper-tab>
                    <paper-tab>History</paper-tab>

                </paper-tabs>
                <div class="bottom flex"></div>
                <paper-icon-button class="bottom " icon="close" on-click="close"></paper-icon-button>
            </app-toolbar>
        </app-header>
            
            <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>


            <iron-pages selected="{{selected}}">
                <div class="horizontal around-justified layout" style="padding: 0 20px">
                    <div class="verticle layout flex two">
                        <paper-input label="No #" value="{{info.customerNo}}" readonly="">
                            <template is="dom-if" if="{{info.isKeyAccount}}">
                                <div slot="suffix" style="display:inline-block">
                                    <iron-icon icon="communication:vpn-key" style="color:#038a03"></iron-icon>
                                    <paper-tooltip>Key account</paper-tooltip>
                                </div>
                            </template>
                            <template is="dom-if" if="{{info.isVip}}">
                                <div slot="suffix" style="display:inline-block">
                                    <iron-icon icon="av:new-releases" style="color:#ff7b00"></iron-icon>
                                    <paper-tooltip>VIP</paper-tooltip>
                                </div>
                            </template>
                        </paper-input>
                        <paper-input label="Name" value="{{info.name}}" readonly=""></paper-input>
                        <paper-input label="Address" value="{{info.address}}" readonly=""></paper-input>
                        <paper-input label="Address" value="{{info.address2}}" readonly=""></paper-input>
                        <paper-input label="City" value="{{info.city}}" readonly=""></paper-input>

                    </div>
                    <div class="flex"></div>
                    <div class="verticle layout flex two">
                        <paper-input label="Email" value="{{info.email}}" readonly=""></paper-input>
                        <paper-input label="Mobile" value="{{info.mobileNo}}" readonly=""></paper-input>
                        <paper-input label="Phone" value="{{info.phoneNo}}" readonly=""></paper-input>
                        <paper-input label="Phone 2" value="{{info.phoneNo2}}" readonly=""></paper-input>
                    </div>
                </div>
                <section>
                    <iron-list items="[[info.subscriberLogs]]" as="item">
                        <template>
                            <paper-card>
                                <div class="card-content">
                                    <div class="horizontal layout">
                                        <div>
                                            Date: <span>[[formatDate(item.dateSent)]]</span>
                                        </div>
                                        <div style="padding-left: 20px">
                                            Type: <span>[[item.messageType]]</span>
                                        </div>
                                    </div>
                                    <div>
                                        [[item.message]]
                                    </div>

                                </div>

                            </paper-card>

                        </template>
                    </iron-list>
                </section>

            </iron-pages>
</app-header-layout>

        <iron-ajax auto="" url="{{url}}" handle-as="json" last-response="{{info}}" loading="{{isLoading}}"></iron-ajax>

        `;
      }

    customerNoChanged(newValue) {
        console.log('new value: ' + newValue);
        if (newValue != ''){
            this.set('url', '/api/customers/' + newValue);
        }
        else{
            this.set('url', null);
        }
    }

    close() {
        this.dispatchEvent(new CustomEvent('customerdetail-close', {bubbles: true,  composed: true}));
    }

    formateDate(s) {
        //TODO: to fix date
        return moment.parseZone(s).format('DD MMM YYYY HH:mm');//FormateDateTime(s);
    }
}

customElements.define(CustomerDetail.is, CustomerDetail);
