/* <link rel="import" href="../../bower_components/paper-menu/paper-submenu.html"> */
/* <link rel="import" href="../../bower_components/moment-element/moment-with-locales-import.html"> */
/*
  FIXME(polymer-modulizer): the above comments were extracted
  from HTML and may be out of place here. Review them and
  then delete this comment!
*/
import '@polymer/polymer/polymer-legacy.js';
import moment from 'moment';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-input/paper-textarea.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-menu-button/paper-menu-button.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-item/paper-item-body.js';
import '@polymer/paper-badge/paper-badge.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';


/**
* @polymer
* @extends HTMLElement
**/
class ShutdownDetail extends PolymerElement {

    static get is() { return 'shutdown-detail'; }

    static get properties() {
        return {
            app: Object,
            shutdownId: {
                type: Number,
                observer: 'shutdownIdChanged'
            },
            selected: {
                type: Number,
                value: 0
            },
            url: String,
            isLoading: {
                type: Boolean,
                value: false
            },
            sendAlertUrl: String,
            username: String,
            info: Object
        }
    }

    static get template() {
        return html`
          <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>
    
        <style>
        :host {
            display: block;
            width: 100%;
            height: 100%;
            --paper-input-container-input: {
                font-size: 13px;
            };

        }
        
        paper-progress {
            width: 100%;
            --paper-progress-container-color: white;
            --paper-progress-active-color: var(--paper-orange-500);
            --paper-progress-secondary-color: var(--paper-orange-100);
        }
        
        .container {
            padding: 10px;
        }
        
     
        
        paper-dialog.size-position {
            position: fixed;
            top: 16px;
            right: 16px;
            width: 400px;
            height: 600px;
            overflow: auto;
        }
        
        .sublist {
            padding-left: 20px;
        }
        
        iron-icon.active {
            fill: #8BC34A;
        }
        
        iron-icon.warning {
            fill: #ff4081;
        }

        paper-checkbox{
            --paper-checkbox-vertical-align: top;
        }

        </style>
        <app-header-layout has-scrolling-region="true">
        <app-header scroll-target="content" slot="header" fixed>
            <app-toolbar class="tall" sticky>
                <paper-icon-button icon="note-add" alt="Send Alert" title="Send Alert" on-click="onNewNoteClick"></paper-icon-button>
                <!-- <paper-icon-button icon="add-alert" alt="Reminder" title="Reminder" on-click="onReminderClick"></paper-icon-button>
                <paper-menu-button horizontal-align="right">
                    <paper-icon-button icon="alarm" class="dropdown-trigger" alt="Postpone Alerts" title="Postpone Alerts"></paper-icon-button>
                    <paper-listbox slot="dropdown-content">
                        <paper-item on-click="onPostponeWeatherClick">
                            <iron-icon icon="communication:call-made"></iron-icon>
                            <paper-item-body>
                                <div>Postpone (weather)</div>
                            </paper-item-body>
                        </paper-item>
                        <paper-item on-click="onPostponeOtherClick">
                            <iron-icon icon="communication:call-made"></iron-icon>
                            <paper-item-body>
                                <div>Postpone (other)</div>
                            </paper-item-body>
                        </paper-item>
                        <paper-item on-click="onPostponeOverrunClick">
                            <iron-icon icon="communication:phonelink-erase"></iron-icon>
                            <paper-item-body>
                                <div>Overrun</div>
                            </paper-item-body>
                        </paper-item>
                    </paper-listbox>
                </paper-menu-button>
                <paper-menu-button horizontal-align="right">
                    <paper-icon-button icon="thumb-up" class="dropdown-trigger" alt="Power On Alerts" title="Power On"></paper-icon-button>
                    <paper-listbox slot="dropdown-content">
                        <paper-item on-click="onPowerEarlyClick">
                            <iron-icon icon="done"></iron-icon>
                            <paper-item-body>
                                <div>Early On</div>
                            </paper-item-body>
                        </paper-item>
                        <paper-item on-click="onPowerOnClick">
                            <iron-icon icon="done"></iron-icon>
                            <paper-item-body>
                                <div>Power On</div>
                            </paper-item-body>
                        </paper-item>
                    </paper-listbox>
                </paper-menu-button> -->
                <paper-icon-button icon="offline-pin" alt="Close Shutdown Request" title="Close Shutdown Request"></paper-icon-button>
                <div class="flex"></div>
                <paper-icon-button icon="close" on-click="close" alt="Close" title="Close"></paper-icon-button>
                
                
            </app-toolbar>
            
            <app-toolbar fixed sticky>
                <div class="middle title">Shutdown Request - <span>{{info.id}}</span></div>
            </app-toolbar>
            
            <paper-tabs sticky bottom-item selected="{{selected}}" class="bottom self-end" style="width: 300px;">
                    <paper-tab>Detail</paper-tab>
                    <paper-tab>ICPs</paper-tab>
                    <paper-tab>Calls</paper-tab>
                    <paper-tab>History</paper-tab>
                    <paper-tab>Docs</paper-tab>
            </paper-tabs>
                
        </app-header>
        <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>
            <div class="content">
                
                <iron-pages selected="{{selected}}">
                    <section>
                        <div class="container">
                            <div>
                                <span>Medically Dependent</span>
                                <paper-badge label="[[info.medicallyDependentCount]]"></paper-badge>
                            </div>
                            <div class="horizontal layout">
                                <div>
                                    <paper-input label="Date" value="{{formatDate(info.shutdownDate)}}" readonly=""></paper-input>
                                </div>
                                <div class="flex"></div>
                                <div>
                                    <paper-input label="Affected Customers" value="{{info.affectedCustomers}}" readonly=""></paper-input>
                                </div>
                            </div>
                            
                            <div class="horizontal layout">
                                <div>
                                    <paper-input label="Time1" value="{{info.shutdownTime1}}" readonly="">
                                        <paper-icon-button slot="suffix" icon="today" on-click="onTime1Click" disabled="[[!info.hasShutdownTime1]]"></paper-icon-button>
                                    </paper-input>
                                </div>
                                <div class="flex"></div>
                                <div>
                                    <paper-input label="Time2" value="{{info.shutdownTime2}}" readonly="">
                                        <paper-icon-button slot="suffix" icon="today" on-click="onTime2Click" disabled="[[!info.hasShutdownTime2]]"></paper-icon-button>
                                    </paper-input>
                                </div>
                            </div>
                            <paper-input label="Status" value="{{info.status}}" readonly=""></paper-input>
                            <paper-input label="Feeder" value="{{info.feeder}}" readonly=""></paper-input>
                            <paper-input label="Manager" value="{{info.projectManager}}" readonly=""></paper-input>
                            <paper-textarea label="Description" value="{{info.description}}" readonly=""></paper-textarea>
                            <div class="horizontal layout">
                                <div>
                                    <paper-input label="Total Emails" value="{{info.totalEmailSent}}" readonly=""></paper-input>
                                </div>
                                <div class="flex"></div>
                                <div>
                                    <paper-input label="Total SMS" value="{{info.totalSmsSent}}" readonly=""></paper-input>
                                </div>
                                
                            </div>
                            <div class="horizontal layout">
                                <div>
                                    <paper-input label="Actual Start Date" value="{{formatDateTime(info.stats.actualStartTime)}}" readonly=""></paper-input>
                                    <paper-input label="Actual End Date" value="{{formatDateTime(info.stats.actualEndTime)}}" readonly=""></paper-input>
                                    <paper-input label="Actual Outages" value="{{info.stats.icpPlannedCount}}" readonly=""></paper-input>
                                    <paper-input label="Duration" value="{{convertDuration(info.stats.plannedTimeSec)}}" readonly=""></paper-input>
                                    <paper-input label="Minimum" value="{{convertDuration(info.stats.minPlannedDurSec)}}" readonly=""></paper-input>
                                    <paper-input label="Average" value="{{convertDuration(info.stats.avgPlannedDurSec)}}" readonly=""></paper-input>
                                    <paper-input label="Maximum" value="{{convertDuration(info.stats.maxPlannedDurSec)}}" readonly=""></paper-input>
                                </div>
                                <div class="flex"></div>
                                <div>
                                    <paper-input label="Actual Extra Date" value="{{formatDateTime(info.stats.actualExtraStartTime)}}" readonly=""></paper-input>
                                    <paper-input label="Actual Extra Date" value="{{formatDateTime(info.stats.actualExtraEndTime)}}" readonly=""></paper-input>
                                    <paper-input label="Actual Extra Outages" value="{{info.stats.icpExtraCount}}" readonly=""></paper-input>
                                    <paper-input label="Extra Duration" value="{{convertDuration(info.stats.extraTimeSec)}}" readonly=""></paper-input>
                                    <paper-input label="Extra Minimum" value="{{convertDuration(info.stats.minExtraDurSec)}}" readonly=""></paper-input>
                                    <paper-input label="Extra Average" value="{{convertDuration(info.stats.avgExtraDurSec)}}" readonly=""></paper-input>
                                    <paper-input label="Extra Maximum" value="{{convertDuration(info.stats.maxExtraDurSec)}}" readonly=""></paper-input>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <template is="dom-repeat" items="[[info.transformerIcps]]">
                            <paper-listbox>
                                <paper-listbox>
                                    <paper-item class="menu-trigger">
                                        <!--<paper-checkbox></paper-checkbox>-->
                                        <paper-item-body>
                                            <div>Transformer -<span>[[item.transformerNo]]</span> </div>
                                        </paper-item-body>
                                    </paper-item>
                                    <paper-listbox class="menu-content sublist">
                                        <template is="dom-repeat" items="[[item.icps]]">
                                            <paper-item role="menuitemcheckbox" style="margin-bottom: 20px;">
                                                <paper-checkbox disabled="[[computeDisabled(item.mobileNo,item.email)]]" checked="{{item.isAlertable}}"></paper-checkbox>
                                                <paper-item-body three-line="">
                                                    <div><span>[[item.icp]]</span>
                                                        <iron-icon icon="communication:portable-wifi-off" hidden\$="[[item.isSmartMeter]]"></iron-icon>
                                                        <iron-icon icon="assignment-late" hidden\$="[[!item.isLiveOutage]]" class="live"></iron-icon>
                                                    </div>
                                                    <div secondary=""><span>[[item.customerName]]</span> (<span>[[item.customerNo]]</span>)</div>
                                                    <div secondary="">
                                                        <iron-icon id="icnMobile" icon="communication:message" class\$="[[computeClass(item.mobileNo)]]"></iron-icon>
                                                        <iron-icon icon="communication:email" class\$="[[computeClass(item.email)]]"></iron-icon>
                                                        <iron-icon icon="communication:phone" class\$="[[computeClass(item.phoneNo)]]"></iron-icon>
                                                    </div>
                                                    <div secondary="">Outage: <span>{{formatDateTime(item.actualStartTime)}}</span></div>
                                                    <div secondary="">Dur: <span>{{convertDuration(item.actualDurSec)}}</span></div>
                                                    <div secondary="">Extra: <span>{{formatDateTime(item.actualExtraStartTime)}}</span></div>
                                                    <div secondary="">Extra Dur: <span>{{convertDuration(item.actualExtraDurSec)}}</span></div>
                                                </paper-item-body>
                                                <template is="dom-if" if="[[item.isMedicallyDependent]]">
                                                    <iron-icon icon="warning" alt="Medically Dependent" class="warning"></iron-icon>
                                                </template>
                                                <paper-icon-button icon="file-upload" on-click="onIcpClicked"></paper-icon-button>
                                            </paper-item>
                                        </template>
                                    </paper-listbox>
                                </paper-listbox>
                            </paper-listbox>
                        </template>
                    </section>
                    <section>
                        <template is="dom-repeat" items="[[info.calls]]">
                            <paper-card style="width: 100%; margin: 5px;">
                                <div class="card-content search-card">
                                    <div class="horizontal layout justified">
                                        <div>#: <span>{{item.callId}}</span></div>
                                        <div> {{formatDate(item.dateRecorded)}}</div>
                                        <div>Status: <span class="bold">{{item.status}}</span></div>
                                    </div>

                                    <div>ICP: <span>{{item.icp}}</span></div>
                                    <div><span>Name: </span>{{item.customerName}} <span>({{item.customerNo}})</span></div>
                                    <div>Mobile: <span>{{item.mobileNo}}</span></div>
                                    <div>Email: <span>{{item.email}}</span></div>
                                    <div class="horizontal layout justified">
                                        <div>Premises: <span>{{item.premisesType}}</span></div>
                                        <div>Relationship: <span>{{item.relationship}}</span></div>

                                    </div>
                                    <div>{{item.callTypeDescription}}</div>
                                    <div>{{item.details}}</div>

                                </div>
                                <div class="card-actions">
                                    <div class="horizontal layout">
                                        <paper-icon-button icon="file-upload" on-click="onIcpClicked"></paper-icon-button>
                                    </div>
                                </div>
                            </paper-card>
                        </template>

                    </section>
                    <section style="padding-right: 7px">
                        <template is="dom-repeat" items="[[info.historyEvents]]">
                            <paper-card style="width: 100%; margin: 5px;">
                                <div class="card-content">
                                    <div class="horizontal justified layout">
                                        <div>[[formatDate(item.eventDate)]]</div>
                                        <div>Type: <span>[[item.eventType]]</span></div>
                                        <div>User: <span>[[item.username]]</span></div>
                                    </div>
                                    <div class="horizontal left-justified layout">
                                        [[item.description]]
                                    </div>
                                </div>
                            </paper-card>
                        </template>
                    </section>
                    <section>
                        <template is="dom-repeat" items="[[info.communicationRequest]]">
                            <paper-card style="width: 100%; margin: 5px;">
                                <div class="card-content">
                                    <div class="horizontal layout justified" style="margin-right: 20px;">
                                        <div>Template: <span style="font-weight: bold">[[item.communicationTemplate]]</span></div>
                                        <div>Status: <span style="font-weight: bold">[[item.processingStatus]]</span></div>
                                    </div>
                                    <p>Proccessing Completed At: <span style="font-weight: bold">[[formatDateTime(item.processingCompletedAt)]]</span></p>
                                    <p>File Generated At: <span style="font-weight: bold">[[formatDateTime(item.eiepFileGeneratedAt)]]</span></p>
                                </div>
                                <div class="card-actions">
                                    <template is="dom-if" if=[[_isPdf(item.fileUrl)]]>
                                        <a href\$="[[item.fileUrl]]" target="_blank" tabindex="-1">
                                            <paper-button id="btnPdf">
                                                <iron-icon icon="file-download"></iron-icon>
                                                All Letters PDF
                                            </paper-button>
                                            <paper-tooltip for="btnPdf">Download PDF</paper-tooltip>
                                        </a>
                                    </template>
                                </div>
                            </paper-card>
                        </template>
                    </section>
                </iron-pages>
            </div>
        </app-header-layout>
        <!--First Advice-->
        <paper-dialog id="dlg" class="size-position">
            <h2>Send Alert</h2>
            <paper-textarea rows="3" label="SMS Alert" value="{{alert.sms}}"></paper-textarea>
            <paper-textarea rows="10" label="Email Alert" value="{{alert.email}}"></paper-textarea>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onSendAdvice">Notify</paper-button>
            </div>
        </paper-dialog>
        <!--Reminder-->
        <paper-dialog id="dlgReminder" class="size-position">
            <h2>Reminder (one day before)</h2>
            <p>Are you sure you want to send the Reminder Alert?</p>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onSendReminderAdvice">Accept</paper-button>
            </div>
        </paper-dialog>
        <!--Postponement-->
        <paper-dialog id="dlgPostponeWeather" class="size-position">
            <h2>Postpone (Weather)</h2>
            <p>Are you sure you want to send the Postpone (weather) Alert?</p>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onSendPostponeWeather">Accept</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgPostponeOther" class="size-position">
            <h2>Postpone (Other)</h2>
            <p>Are you sure you want to send the Postpone (other) Alert?</p>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onSendPostponeOther">Accept</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgPostponeOverrun" class="size-position">
            <h2>Overrun</h2>
            <p>Are you sure you want to send the Overrun Alert?</p>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onSendPostponeOverrun">Accept</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgPowerEarlyOn" class="size-position">
            <h2>Early On</h2>
            <p>Are you sure you want to send the Early On Alert?</p>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onSendPowerOnEarly">Accept</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgPowerOn" class="size-position">
            <h2>Power On</h2>
            <p>Are you sure you want to send the Power On Alert?</p>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onSendPowerOn">Accept</paper-button>
            </div>
        </paper-dialog>
        <iron-ajax id="ajaxShutdownDetail" auto="" url="{{url}}" handle-as="json" last-response="{{info}}" loading="{{isLoading}}"></iron-ajax>
        <!--<iron-ajax id="ajaxAlert"-->
        <!--url="{{sendAlertUrl}}"-->
        <!--handle-as="json"-->
        <!--last-response="{{info}}"-->
        <!--loading="{{isLoading}}"></iron-ajax>-->
        <!-- <iron-ajax id="ajaxAlert" url="{{sendAlertUrl}}" handle-as="json" method="POST" content-type="application/json" body="{{info}}" last-response="{{info}}" loading="{{isLoading}}"></iron-ajax> -->
        <iron-ajax id="ajaxAlertMessage" url="{{sendAlertUrl}}" handle-as="json" method="POST" content-type="application/json" on-response="_refreshAlert" loading="{{isLoading}}"></iron-ajax>
        <iron-localstorage name="username" use-raw="" value="{{username}}"></iron-localstorage>
 
        `;
      }
    
    shutdownIdChanged(newValue) {
        this.info = null;
        if (newValue) {
            this.url = `/api/sr/summary/${newValue}`;
        } else {
            this.url = null;
        }
    }

    close() {
        this.dispatchEvent(new CustomEvent('shutdowndetail-close', {bubbles: true,  composed: true}));
    }

    _refreshAlert(e){
        this.$.ajaxShutdownDetail.generateRequest();
    }

    onNewNoteClick() {
        this.set("alert",{sms:`CASE: CP${this.shutdownId.toString().slice(1)}`,email:``})
        this.$.dlg.open();
    }

    onSendAdvice() {

        this.sendAlertUrl = `/api/sr/alert/${this.shutdownId}/${this.username}`;

        var data = {
            'alertMessage': this.alert,
            'faultAlert': this.info
        };

        this.$.ajaxAlertMessage.body = JSON.stringify(data);
        this.$.ajaxAlertMessage.generateRequest();

    }

    // onReminderClick() {
    //     this.$.dlgReminder.open();
    // }

    // onPostponeWeatherClick() {
    //     this.$.dlgPostponeWeather.open();
    // }

    // onPostponeOtherClick() {
    //     this.$.dlgPostponeOther.open();
    // }

    // onPostponeOverrunClick() {
    //     this.$.dlgPostponeOverrun.open();
    // }

    // onPowerEarlyClick() {
    //     this.$.dlgPowerEarlyOn.open();
    // }

    // onPowerOnClick() {
    //     this.$.dlgPowerOn.open();
    // }

    // onSendFirstAdvice() {
    //     this.sendAlertUrl = `/api/sr/alert/${this.shutdownId}/first-advice/${this.username}`;
    //     this.postAlert();
    // }

    // onSendReminderAdvice() {
    //     this.sendAlertUrl = `/api/sr/alert/${this.shutdownId}/reminder-advice/${this.username}`;
    //     this.postAlert();
    // }

    // onSendPostponeWeather() {
    //     this.sendAlertUrl = `/api/sr/alert/${this.shutdownId}/postpone-weather/${this.username}`;
    //     this.postAlert();
    // }

    // onSendPostponeOther() {
    //     this.sendAlertUrl = `/api/sr/alert/${this.shutdownId}/postpone-other/${this.username}`;
    //     this.postAlert();
    // }

    // onSendPostponeOverrun() {
    //     this.sendAlertUrl = `/api/sr/alert/${this.shutdownId}/postpone-overrun/${this.username}`;
    //     this.postAlert();
    // }

    // onSendPowerOn() {
    //     this.sendAlertUrl = `/api/sr/alert/${this.shutdownId}/power-on/${this.username}`;
    //     this.postAlert();
    // }

    // onSendPowerOnEarly() {
    //     this.sendAlertUrl = `/api/sr/alert/${this.shutdownId}/power-on-early/${this.username}`;
    //     this.postAlert();
    // }

    onIcpClicked(e) {
        var model = e.model;
        
        this.dispatchEvent(new CustomEvent('show-asset-detail', {bubbles: true,  composed: true,
            detail:{
                'id': model.item.id,
                'assetType': 'icp'
            }}));
    }

    onTime1Click() {

        this.dispatchEvent(new CustomEvent('show-power-down-heatmap', {bubbles: true,  composed: true,
            detail:{
                "lat": this.info.lat,
                "lng": this.info.lng,
                "plannedStartTime": this.getLookupDateTime(this.info.plannedStartTime),
                "plannedEndTime": this.getLookupDateTime(this.info.plannedEndTime),
                "distance": this.info.distance,
                "transformerIcps": this.info.transformerIcps
            }}));
        
    }

    onTime2Click() {
        

        this.dispatchEvent(new CustomEvent('show-power-down-heatmap', {bubbles: true,  composed: true,
            detail:{
                "lat": this.info.lat,
                "lng": this.info.lng,
                "plannedStartTime": this.getLookupDateTime(this.info.plannedExtraStartTime),
                "plannedEndTime": this.getLookupDateTime(this.info.plannedExtraEndTime),
                "distance": this.info.distance
            }}));
    }

    computeClass(s) {
        return !s ? '' : 'active';
    }

    formatDate(s) {
        return s ? moment.parseZone(s).format('DD MMM YYYY') : "";
    }

    computeDisabled(mobile, email) {
        return this._isEmpty(mobile) && this._isEmpty(email);
    }

    postAlert() {
        this.$.ajaxAlert.generateRequest();
    }

    getLookupDateTime(s) {
        return s ? moment.parseZone(s).format("DD.MM.YYYY HH:mm") : "";
    }

    _isEmpty(s) {
        return typeof s == 'string' && !s.trim() || typeof s == 'undefined' || s === null;
    }

    formatDateTime(s) {
        return s ? moment.parseZone(s).format('DD MMM YYYY HH:mm') : "";
    }

    convertDuration(dur) {
//                return moment.duration(dur,'seconds').humanize();
        return this.convertDurationToString(dur);
    }

    convertDurationToString(dur) {
        let days = parseInt(( dur / 3600 ) / 24);
        let hours = parseInt(( dur / 3600 ) % 24);
        let minutes = parseInt(( dur / 60 ) % 60);
        // let seconds = parseInt(dur % 60);
        if (days > 0) {
            return `${days} days, ${hours} hrs and ${minutes} mins`;
        } else {
            return `${hours} hrs and ${minutes} mins`;
        }
    }

    _isPdf(v) {
        return v !== "" ? true : false;
    }
}
customElements.define(ShutdownDetail.is, ShutdownDetail);
