import '@polymer/polymer/polymer-legacy.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';



class OhswitchAssetDetail extends PolymerElement {

    static get is() { return 'ohswitch-asset-detail'; }

    static get properties() {
        return {
            assetId:{
                type: Number,
                observer: 'assetIdChanged'
            },

            selected:{
                type: Number,
                value: 0
            },

            url: {
                type: String
            }
        }
    }

    static get template() {
        return html`
          <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>
 
        <style>
            :host {
                display: block;
                width: 100%;
                height: 100%;
                --paper-input-container-input: {
                    font-size: 13px;
                };
            }
            paper-progress {
                width: 100%;
                --paper-progress-container-color: white;
                --paper-progress-active-color: var(--paper-orange-500);
                --paper-progress-secondary-color: var(--paper-orange-100);

            }
          

        </style>

<app-header-layout has-scrolling-region="true">
        <app-header scroll-target="content" slot="header" fixed>
            <app-toolbar class="small">
                <paper-tabs selected="{{selected}}" class="flex">
                    <paper-tab>OH Siwtch Detail</paper-tab>
                    <paper-tab>History</paper-tab>


                </paper-tabs>
                <div class="bottom flex"></div>
                <paper-icon-button class="bottom " icon="close" on-click="close"></paper-icon-button>

            </app-toolbar>
        </app-header>
        
            <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>


            <iron-pages selected="{{selected}}">
                <div class="horizontal around-justified layout" style="padding: 0 20px">
                    <div class="verticle layout flex two">
                        <paper-input label="Switch #" value="{{info.name}}" readonly=""></paper-input>
                        <paper-input label="Type" value="{{info.type}}" readonly=""> </paper-input>
                        <paper-input label="Serial No." value="{{info.serialNo}}" readonly=""></paper-input>
                        <paper-input label="Make" value="{{info.make}}" readonly=""></paper-input>
                        <paper-input label="Model" value="{{info.model}}" readonly=""></paper-input>
                        <paper-input label="Insulation Type" value="{{info.insulationType}}" readonly=""></paper-input>
                        <paper-input label="Insulation Weight" value="{{info.insulationWeight}}" readonly=""></paper-input>
                        <paper-input label="Feeder" value="{{info.feeder}}" readonly=""></paper-input>
                        <paper-input label="Pole" value="{{info.pole}}" readonly=""></paper-input>
                        <paper-input label="Contractor" value="{{info.contractor}}" readonly=""></paper-input>
                        <paper-input label="Switch Inspections" value="{{info.ohSwitchInspections}}" readonly=""></paper-input>
                    </div>
                    <div class="flex"></div>
                    <div class="verticle layout flex two">
                        <paper-input label="Rated Voltage" value="{{info.ratedVoltage}}" readonly=""></paper-input>
                        <paper-input label="Operating Voltage" value="{{info.operatingVoltage}}" readonly=""> </paper-input>
                        <paper-input label="Normal State" value="{{info.normalState}}" readonly=""></paper-input>
                        <paper-input label="Operating State" value="{{info.operationalState}}" readonly=""></paper-input>
                        <paper-input label="Operation" value="{{info.operation}}" readonly=""></paper-input>
                        <paper-input label="Status" value="{{info.status}}" readonly=""></paper-input>
                        <paper-input label="Comm Port" value="{{info.commPort}}" readonly=""></paper-input>
                        <paper-input label="ABS Type" value="{{info.absType}}" readonly=""></paper-input>
                        <paper-input label="Motorised" value="{{info.isMotorised}}" readonly=""></paper-input>
                        <paper-input label="Job Ref." value="{{info.jobref}}" readonly=""></paper-input>
                        <paper-input label="Switch Segments" value="{{info.switchSegments}}" readonly=""></paper-input>

                    </div>
                    <div class="flex"></div>
                    <div class="verticle layout flex two">
                        <paper-input label="Pole Age" value="{{info.poleAge}}" readonly=""></paper-input>
                        <paper-input label="Date Installed" value="{{info.dateInstalled}}" readonly=""> </paper-input>
                        <paper-input label="End of Life date" value="{{info.endOfLifeDate}}" readonly=""></paper-input>
                        <paper-input label="Date Manufactured" value="{{info.dateManufactured}}" readonly=""></paper-input>
                        <paper-input label="Date Decommissioned" value="{{info.dateDecommissioned}}" readonly=""></paper-input>
                        <paper-input label="Date Network Survey" value="{{info.networkSurvey}}" readonly=""></paper-input>
                        <paper-input label="CT Ratios" value="{{info.ctRatios}}" readonly=""></paper-input>
                        <paper-input label="Critical" value="{{info.criticality}}" readonly=""></paper-input>
                        <paper-input label="Area Type" value="{{info.areaType}}" readonly=""></paper-input>
                        <paper-input label="Traffic Mgmt" value="{{info.trafficMgmt}}" readonly=""></paper-input>
                        <paper-input label="Note" value="{{info.note}}" readonly=""></paper-input>
                    </div>
                </div>
                <div>History Details</div>

            </iron-pages>
</app-header-layout>

        <iron-ajax auto="" url="{{url}}" handle-as="json" last-response="{{info}}" loading="{{isLoading}}"></iron-ajax>


        `;
      }

    assetIdChanged(newValue){
        console.log('new value: ' + newValue);

        if(newValue != ""){
            this.set('url','/api/ohs/summary/' + this.assetId);
        }
        else{
            this.set('url', null);
        }
    }

    close(){
        this.dispatchEvent(new CustomEvent('assetdetail-close', {bubbles: true,  composed: true}));
    }


}
customElements.define(OhswitchAssetDetail.is, OhswitchAssetDetail);
