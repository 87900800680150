
import '@polymer/polymer/polymer-legacy.js';
import moment from 'moment';
import '@polymer/iron-list/iron-list.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-image/iron-image.js';
import '@polymer/iron-collapse/iron-collapse.js';
import '@polymer/iron-selector/iron-selector.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-input/paper-textarea.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/paper-toast/paper-toast.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-item/paper-item-body.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import '@polymer/paper-progress/paper-progress.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import './core-overlay.js';

import { getIconByAssetType } from "../utils/assetIcons.js";

let sTimer;

/**
* @polymer
* @extends HTMLElement
**/
class FaultReportedDetail extends PolymerElement {

    static get is() { return 'fault-reported-detail'; }

    static get properties() {
        return {
            faultId: {
                type: Number,
                observer: 'faultIdChanged',
                value: 0
            },

            app: {
                type: Object
                // observer: 'appChanged'
            },

            selected: {
                type: Number,
                value: 0
            },

            fault: {
                type: Object
            },

            owner: {
                type: String
            },

            emailInfo: {
                type: Object
            },

            noteInfo: {
                type: Object
            },

            smsInfo: {
                type: Object
            },

            fpInfo: {
                type: Object
            },
            subInfo: {
                type: Object
            },

            data: {
                type: Array
            },

            cSelected: {
                type: Number,
                value: 0
            },
            isLoading: {
                type: Boolean,
                value: false
            },
            isShowSearchResults: {
                type: Boolean,
                value: true
            },
            addressItems: {
                type: Array,
                value: ()=> []
            },
        }
    }

    static get template() {
        return html`
        <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>

        <style>
        :host {
            display: block;
            width: 100%;
            height: 100%;
            --paper-input-container-input: {
                font-size: 13px;
            };
        }
        
        section {
            padding: 10px;
        }
        
        paper-item {
            font-size: 12px;
        }
        
        paper-button.colored {
            color: #3c3f41;
        }
        
        paper-button[raised].colored {
            background: #4285f4;
            color: #fff;
        }
        
        paper-button.custom > core-icon {
            margin-right: 4px;
        }
        
        paper-button.hover:hover {
            background: #eee;
        }
        
        paper-button.blue-ripple::shadow #ripple {
            color: #4285f4;
        }
        
        paper-dialog {
            /*position: fixed;*/
            /*top: 16px;*/
            /*right: 16px;*/
            width: 400px;
            height: 400px;
            overflow: auto;
        }

        #dlgSubWorkOrder{

            width: unset;
            height: unset;
            overflow: auto;
        }
        
        paper-progress {
            width: 100%;
            --paper-progress-container-color: white;
            --paper-progress-active-color: var(--paper-orange-500);
            --paper-progress-secondary-color: var(--paper-orange-100);
        }
        
        .spacer{
            width: 40px;
        }

        #overlaySearchResults {
            position: absolute;
            top: 64px;
            right:20%;
            border-radius: 16px;
            width: 450px;
            height: 630px;
            background-color: #fafafa;
            /*margin-left: 50px;*/
        }
        #overlaySearchResults .cards {
            height:450px;
        }

        #overlaySearchResults paper-card {
            width: 100%;
            margin-bottom: 4px;
            
        }

        .avatar {
            margin: 10px;
        }
        </style>


        <app-header-layout has-scrolling-region="true">
        <app-header scroll-target="content" slot="header" fixed>
            <app-toolbar class="small">
                <paper-tabs selected="{{selected}}" class="flex">
                    <paper-tab>Fault Detail</paper-tab>
                    <paper-tab>History</paper-tab>
                </paper-tabs>
                <div class="bottom flex"></div>
                <paper-icon-button class="bottom " icon="close" on-click="close"></paper-icon-button>
            </app-toolbar>
        </app-header>
           
            <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>
            <iron-pages selected="{{selected}}">
                <div>
                    <section>
                        <div class="horizontal justified layout">
                            <div class="horizontal around-justified layout" style="padding: 0 20px">
                               
                                <paper-input label="Matched ICP" readonly="" value="{{fault.matchedIcp}}"></paper-input>
                                <div class="spacer"></div>
                                <paper-input label="Matched Transformer" readonly="" value="{{fault.matchedTransformer}}"></paper-input>
                                <div class="spacer"></div>
                                <paper-input label="Matched Feeder" readonly="" value="{{fault.matchedFeeder}}"></paper-input>
                                <div class="spacer"></div>
                                <paper-input label="Matched Customer" readonly="" value="{{fault.matchedCustomerName}}"></paper-input>
                            </div>
                            <div>
                                <paper-button raised="" on-click="_onCreateWorkOrderClick">
                                    <iron-icon icon="social:person"></iron-icon>
                                    + WO
                                </paper-button>
                                <paper-button raised="" on-click="onAcceptClick">
                                    <iron-icon icon="done"></iron-icon>
                                    Close
                                </paper-button>
                                <paper-button on-click="onEmailClick" raised="">
                                    <iron-icon icon="communication:email"></iron-icon>
                                    Email
                                </paper-button>
                                <!-- <paper-button raised>
                                    <iron-icon icon="communication:dialer-sip"></iron-icon>
                                    Call
                                </paper-button> -->
                                <paper-button on-click="onSmsClick" raised="">
                                    <iron-icon icon="communication:textsms"></iron-icon>
                                    SMS
                                </paper-button>
                                <paper-button on-click="onAddNoteClick" raised="">
                                    <iron-icon icon="communication:comment"></iron-icon>
                                    Note
                                </paper-button>
                            </div>
                        </div>
                    </section>
                    <div class="horizontal around-justified layout" style="padding: 0 20px">
                        <div class="verticle layout flex two">
                            
                            <paper-input label="Date Reported" readonly="" value="{{formatDateTime(fault.dateReported)}}"></paper-input>
                            <paper-input label="Name" readonly="" value="{{fault.fullName}}"></paper-input>
                            <paper-input label="Fault Type" readonly="" value="{{fault.faultType}}"></paper-input>
                            <paper-input label="Address" readonly="" value="{{fault.address}}"></paper-input>
                        </div>
                        <div class="spacer"></div>
                        <div class="verticle layout flex two">
                            <paper-input value="{{fault.email}}" readonly="" label="Email"></paper-input>
                            <paper-input value="{{fault.phone}}" readonly="" label="Phone"></paper-input>
                            <paper-input label="Contact Options" value="{{fault.contactOptions}}" readonly=""></paper-input>
                            <paper-textarea id="t1" value="{{fault.commentReported}}" rows="4" style=" height: 150px;" readonly="" label="Comment"></paper-textarea>
                        </div>
                        <div class="spacer"></div>
                        <div class="verticle layout flex three">
                            <iron-image style="width:300px; height:300px; background-color: lightgray;" sizing="cover" preload="" src="[[fault.url]]"></iron-image>
                        </div>
                    </div>
                </div>
                <div>

                </div>
            </iron-pages>
        </app-header-layout>
        <paper-dialog id="dlgAccept">
            <h2>Process Fault</h2>
            <p>Accept Fault and Mark as Processed.</p>
            <paper-textarea label="Message" id="t5" value="{{fpInfo.text}}"></paper-textarea>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onProcessedSendClick">OK</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgEmail">
            <h2>Email - <span>{{emailInfo.to}}</span></h2>
            <p>Email <span>{{fault.fullName}}</span></p>
            <paper-input label="Subject" value="{{emailInfo.subject}}" readonly=""> </paper-input>
            <paper-textarea label="Comment" id="t2" value="{{emailInfo.body}}" rows="4" style=" height: 150px;"></paper-textarea>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onEmailSendClick">Email</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgSms">
            <h2>Send SMS</h2>
            <paper-input label="Mobile">{{smsInfo.to}}</paper-input>
            <paper-textarea label="Message" id="t4" value="{{smsInfo.text}}" rows="4" style=" height: 150px;"></paper-textarea>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onSubmitSmsSendClick">Send SMS</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgNote">
            <h2>Add Note</h2>
            <p>Note will be added to Event History</p>
            <paper-input label="Subject">{{noteInfo.subject}}</paper-input>
            <paper-textarea label="Comment" id="t3" value="{{noteInfo.description}}" rows="4" style=" height: 150px;"></paper-textarea>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onSubmitAddNoteClick">Add Note</paper-button>
            </div>
        </paper-dialog>
        
        <paper-toast id="toast" text=""></paper-toast>

        

        <paper-dialog id="dlgSubWorkOrder" no-cancel-on-outside-click="">
                <!--<h2>Add Work Order</h2>-->
                <app-toolbar>
                    <div main-title="">Add Work Order</div>
                    
                </app-toolbar>
                <paper-dialog-scrollable>
                
                <div class="horizontal around-justified layout">
                <div class="verticle layout flex ">
                        <paper-checkbox checked="{{subInfo.IsShowOnPublicSite}}">Show on Public Site</paper-checkbox>
                        <!-- <paper-input label="Parent No" value="{{subInfo.No}}" readonly></paper-input> -->

                <paper-dropdown-menu label="Priority" style="width:100%">
                        <paper-listbox slot="dropdown-content" selected="{{subInfo.Priority}}">
                    <paper-item>Low</paper-item>
                    <paper-item>Medium</paper-item>
                    <paper-item>High</paper-item>
                </paper-listbox>
                </paper-dropdown-menu>

                <paper-dropdown-menu label="Service Type" style="width:100%">
                        <paper-listbox slot="dropdown-content" selected="{{subInfo.ServiceType}}" attr-for-selected="name">
                    <paper-item name="DISCF">	Disconnect for operations</paper-item>
                    <paper-item name="FEEDER">	Feeder Fault</paper-item>
                    <paper-item name="FIBRE">	Fibre Fault</paper-item>
                    <paper-item name="FLICK">	Flickering power </paper-item>
                    <paper-item name="MEMO">	General request (unspecified)</paper-item>
                    <paper-item name="HIGHLD">	High-load</paper-item>
                    <paper-item name="HOT_W">	Customer has no hot water</paper-item>
                    <paper-item name="LABEL">	Identify and label supply fuses</paper-item>
                    <paper-item name="LINES">	Electricity line down</paper-item>
                    <paper-item name="NOPWR">	No power to installation</paper-item>
                    <paper-item name="OSH-C">	Unsafe customer equipment</paper-item>
                    <paper-item name="OSH-N">	Unsafe network equipment</paper-item>
                    <paper-item name="PINFAULT">Pinpower Fault</paper-item>
                    <paper-item name="PPWR">	Part power to installation </paper-item>
                    <paper-item name="RECON">	Reconnect supply</paper-item>
                    <paper-item name="TREE">	Trees in Network lines</paper-item>
                    <paper-item name="UGCABLE">	Underground cable fault</paper-item>
                    <paper-item name="USAFE">	Unsafe, immediate action</paper-item>
                    <paper-item name="VMVA">	Vehicle or Man vs Asset</paper-item>
                    <paper-item name="VOLTQ">	Voltage complaint at site</paper-item>
                </paper-listbox>
                </paper-dropdown-menu>

                </div>
                    <div class="spacer"></div>
                <div class="verticle layout flex ">
                        <paper-checkbox checked="{{subInfo.IsSendAlertEnabled}}">Send Comms</paper-checkbox>
                        <paper-input label="Asset ({{subInfo.AssetType.String}})" value="{{subInfo.Icp}}" readonly>
                            <paper-icon-button slot="suffix" icon="search" on-click="onSearchAssetClick"></paper-icon-button>
                        </paper-input>
                        <paper-input label="Equipment #" value="{{subInfo.EquipmentNo}}" readonly> </paper-input>
                        <paper-input label="Equipment Desc#" value="{{subInfo.EquipmentDescription}}" readonly> </paper-input>
                </div>
                </div>
                        <paper-input label="Description" value="{{subInfo.Description}}"></paper-input>
                        <paper-textarea label="Public Comment" value="{{subInfo.GeneralComments}}" rows="3"></paper-textarea>
                        <!--<paper-input label="Public Comment" value="{{subInfo.GeneralComments}}"></paper-input>-->
                        <paper-textarea label="Fault Comment" value="{{subInfo.FaultComments}}" rows="3"></paper-textarea>
                <div class="horizontal around-justified layout">
                <div class="verticle layout flex ">
                        <paper-input label="Customer" value="{{subInfo.Name}}"></paper-input>
                        <paper-input label="Contact" value="{{subInfo.ContactName}}"> </paper-input>
                        <paper-input label="Email" value="{{subInfo.Email}}"> </paper-input>
                        <paper-input label="Phone" value="{{subInfo.PhoneNo}}"></paper-input>
                        <paper-input label="Phone 2" value="{{subInfo.PhoneNo2}}"></paper-input>

                </div>
                    <div class="spacer"></div>
                <div class="verticle layout flex ">
                        <paper-input label="Property" value="{{subInfo.PropertyName}}"></paper-input>
                        <paper-input label="Unit" value="{{subInfo.Unit}}"></paper-input>
                        <paper-input label="Street No" value="{{subInfo.Number}}"></paper-input>
                        <paper-input label="Street" value="{{subInfo.Street}}"></paper-input>
                        <paper-input label="Suburb" value="{{subInfo.Suburb}}"></paper-input>
                        <paper-input label="Town" value="{{subInfo.Town}}"></paper-input>

                </div>
                </div>
                </paper-dialog-scrollable>

                    <div class="buttons">
                        <paper-button dialog-dismiss="" on-click="_onCancelEditWorkOrderClick">Cancel</paper-button>
                        <paper-button dialog-confirm="" autofocus="" on-click="_onSubWorkOrderConfirmClick">Confirm</paper-button>
                    </div>

                <paper-dialog id="overlaySearchResults" no-cancel-on-outside-click="">
                    <div class="flex">
                        <paper-input id="tbxSearch" placeholder="Type address or asset" class="search" value="{{searchText}}" on-input="onSearchTextInput" on-keydown="onSearchTextKeyDown">
                            <paper-icon-button slot="prefix" icon="search"></paper-icon-button>
                            <paper-icon-button slot="suffix" icon="communication:clear-all" on-click="onClearSearchText"></paper-icon-button>
                        </paper-input>
                    </div>
                    <paper-dialog-scrollable>
                        <div class="cards">
                            <template is="dom-repeat" id="addressItems" items="[[addressItems]]">
                                
                                    <paper-card>
                                        <div class="card-content search-card">
                                            <div>{{item.name}}</div>
                                            <div class="search-asset-type" secondary="">{{item.assetType}}</div>
                                            <div>{{item.customerName}}</div>
                                            <div><span>{{item.streetAddress}}</span> <span>{{item.town}}</span></div>
                                            <template is="dom-if" if="{{item.isInShutdown}}">
                                                <div class="info"><span>In Current Shutdown:</span> <span>{{item.shutdownId}}</span></div>
                                            </template>
                                        </div>
                                        <div class="card-actions">
                                            <div class="horizontal layout">
                                                <img class="avatar" src="[[iconForAssetType(item.assetType, item.category)]]">
                                                <div class="flex"></div>
                                                <paper-button on-click="onSelectAssetClick" >
                                                    <iron-icon icon="check"></iron-icon>
                                                    Select
                                                </paper-button>
                                                
                                            </div>
                                        </div>
                                    </paper-card>
                            </template>
                        </div>
                    </paper-dialog-scrollable>
                    
                    <div class="buttons">
                        <paper-button dialog-dismiss="">Cancel</paper-button>
                        
                    </div>
                </paper-dialog>

            </paper-dialog>


            


        <iron-ajax id="ajxSubWoAdd" url="/api/so/add/fault/add" method="POST" handle-as="json" content-type="application/json" body="{{subInfo}}" on-response="_onUpdateWoSuccess" on-error="_onUpdateWoError" loading="{{isLoading}}"></iron-ajax>

        `;
      }

    faultIdChanged() {
        // console.log(`Fault ID new value: ${newValue}`);
        if (this.faultId && this.faultId !== 0) {
            this._getFaultDetails();
        }

    }

    _getFaultDetails() {
        this.fault = null;
        if(this.faultId){
            fetch(`/api/faults/summary/${this.faultId}`, {
                credentials: 'include'
                })
                    .then(resp => resp.json())
                    .then(data => {
                        this.fault = data;
                        this._getFaultHistoryDetails();
            });
        }


        // this.isLoading = false;
    }

    _getFaultHistoryDetails() {
        // let newData = [];
        fetch(`/api/faults/history/${this.faultId}`, {
                credentials: 'include'
            })
            .then(resp => resp.json())
            .then(fh => {
                this.data = fh;

            });
    }

    onAcceptClick() {
        this.fpInfo = {
            "reportId": this.fault.id,
            "text": ""
        }
        this.$.dlgAccept.open();
    }

    onProcessedSendClick() {
        let fp = JSON.stringify(this.fpInfo);
        fetch(`/api/faults/process`, {
                credentials: 'include',
                method: 'POST',
                body: fp
            })
            .then(resp => resp.json())
            .then(() => {
                this._getFaultHistoryDetails();
                this.showToast("Fault Set to Processed");
                this.dispatchEvent(new CustomEvent('faults-refresh', {bubbles: true,  composed: true}));
            })
            .catch((error) => {
            console.log('Request failed', error);
            this.showToast("Failed to Close Fault");
            this.dispatchEvent(new CustomEvent('faults-refresh', {bubbles: true,  composed: true}));
        });
        

    }

    onEmailClick() {

        this.emailInfo = {
            "reportId": this.fault.id,
            "to": this.fault.email,
            "subject": "Fault Reported",
            "body": ""
        }
        this.$.dlgEmail.toggle();
    }
    onEmailSendClick() {
        let ei = JSON.stringify(this.emailInfo);
        fetch(`/api/faults/process`, {
                credentials: 'include',
                method: 'POST',
                body: ei
            })
            .then(resp => resp.json())
            .then(() => {
                this._getFaultHistoryDetails();
                this.showToast("Email Sent");
            });
    }

    onAddNoteClick() {

        this.noteInfo = {
            "reportId": this.fault.id,
            "subject": '',
            "discription": ''
        };
        this.$.dlgNote.toggle();
    }

    onSubmitAddNoteClick() {
        let note = JSON.stringify(this.noteInfo.toJson());
        fetch(`/api/faults/note`, {
                credentials: 'include',
                method: 'POST',
                body: note
            })
            .then(resp => resp.json())
            .then(() => {
                this._getFaultHistoryDetails();
                this.showToast("Note Saved");
            });
    }

    onSmsClick() {
        this.smsInfo = {
            "reportId": this.fault.id,
            "to": this.fault.phone,
            "text": ''
        };
        this.$.dlgSms.toggle();
    }

    onSubmitSmsSendClick() {
        let data = JSON.stringify(this.smsInfo.toJson());
        fetch(`/api/faults/sms`, {
                credentials: 'include',
                method: 'POST',
                body: data
            })
            .then(resp => resp.json())
            .then(() => {
                this._getFaultHistoryDetails();
                this.showToast("SMS Sent");
            });
    }

    showToast(text) {
        let toast = this.$.toast;
        toast.show(text);
    }

    close(){
        this.dispatchEvent(new CustomEvent('fault-reported-close', {bubbles: true,  composed: true}));
        this.dispatchEvent(new CustomEvent('faults-refresh', {bubbles: true,  composed: true}));
    }

    formatDateTime(s) {
        return s ? moment.parseZone(s).format('DD MMM YYYY HH:mm') : "";
    }

    _onCreateWorkOrderClick(){
        this.subInfo = {
            Priority: 0,
            Description: this.fault.faultType,
            FaultComments: this.fault.commentReported,
            ContactName: this.fault.fullName,
            Email: this.fault.email,
            PhoneNo: this.fault.phone,
            Number: this.fault.streetNo,
            Street:this.fault.streetAddress,
            Suburb:this.fault.suburb,
            Town:this.fault.city,
            ServiceType: this.getServiceTypeFromFault(this.fault.faultType),
            Status:"SCHEDULED",
            IsSendAlertEnabled:true,
            IsShowOnPublicSite: true,
            FaultID: this.fault.id,
            Lat: this.fault.lat,
            Lng: this.fault.lng,
            EquipmentDescription:'',
            Icp:this.fault.matchedIcp,
            TransformerCode: this.fault.matchedTransformer,
            FeederCode: this.fault.matchedFeeder,
            Username: this.app.username,
            AssetType: {String:this.fault.matchedAssetType, Value:true},
            EquipmentNo: this.fault.matchedIcp,
            EquipmentDescription: this.fault.matchedAssetType,
            // CustomerNo: this.fault.matchedCustomerNo,
            Name: this.fault.matchedCustomerName,
            PropertyName: this.fault.matchedPropertyName,
            AlertText: this.fault.matchedAccessNotes,
        };
        
        // this.set('addressItems', this.fault.nearbyAssets);
        this.$.dlgSubWorkOrder.open();
    }

    _onSubWorkOrderConfirmClick(){
        if (this.subInfo.ServiceType.length < 2) {
            this.showToast("Service Type is required");
            return
        }

        this.$.ajxSubWoAdd.generateRequest();

    }

    _onUpdateWoSuccess(){
        this.showToast("Work Order Added Successfully");
        // this._refresh();
        this.dispatchEvent(new CustomEvent('fault-reported-close', {bubbles: true,  composed: true}));
        this.dispatchEvent(new CustomEvent('faults-refresh', {bubbles: true,  composed: true}));
    
    }
    _onUpdateWoError(){
        this.showToast("Failed to Add Work Order");
        this.dispatchEvent(new CustomEvent('faults-refresh', {bubbles: true,  composed: true}));
        // this._refresh();
    }
    
    getServiceTypeFromFault(faultType){
        let serviceType = "OTHER"
        switch (faultType) {
            case "Flickering Power":
                serviceType = "FLICK";
                break;
            
            case "Lines down":
                serviceType = "LINES";
                break;

            case "No hot water":
                serviceType = "HOT_W";
                break;

            case "No Power":
                serviceType = "NOPWR";
                break;

            case "Part power":
                serviceType = "PPWR";
                break;

            case "Unsafe Equipment":
                serviceType = "USAFE";
                break;

            case "Vehicle or Man vs Asset":
                serviceType = "VMAVA";
                break;
        
            default:
                break;
        }
        return serviceType;
    }

    onSearchAssetClick(){
        this.searchText = "";
        this.$.overlaySearchResults.open();
        this.addressItems = this.fault.nearbyAssets;
        // this.$.addressItems.render();
        
    }

    onSearchTextInput() {
        // this.$.dlgSearch.open();
        if (this.searchText.length > 0) {
            this.isShowSearchResults = true;
        } else {
            this.isShowSearchResults = false;
        }
        if (sTimer !== null) {
            clearTimeout(sTimer);
        }


        this.set('addressItems', []);


        sTimer = setTimeout(() => {
            if (this.searchText.length > 0) {
                // this.addressSearch(this.searchText);
                this.searchAsset();
            }
        }, 1000);
    }

    onSearchTextKeyDown(e) {
        if (e.keyCode === 27) { // 27 is the ESC key
            this.searchText = "";
            this.isShowSearchResults = false;
        }

    }

    onClearSearchText() {
        this.searchText = "";
        // this.$.dlgSearch.close();
        this.addressItems = this.fault.nearbyAssets;
        this.isShowSearchResults = false;
    }

    iconForAssetType(assetType, category) {
        const img =  getIconByAssetType(assetType, 0, category);
        console.log(img);
        return img;
    }

    searchAsset() {
        if (this.searchText.trim().length > 0) {
            this.set('isLoading', true);
            fetch(`/api/assets/search?q=${encodeURIComponent(this.searchText)}`, { credentials: 'include' })
                .then(resp => resp.json())
                .then(results => {
                    if (results !== null) {
                        this.addressItems = results;
                    }
                    this.set('isLoading', false);
                }).catch(err => {
                    if (err.name === 'AbortError') {
                        console.log('Fetch aborted');
                    } else {
                        console.error('Uh oh, an error!', err);
                    }
                });


        }
    }

    onSelectAssetClick(e){
        console.log(e.model.item);
        const item = e.model.item;
        const assetType = item.assetType.toLowerCase().includes("icp") || item.assetType.toLowerCase().includes("icp")?"ICP":item.assetType.toUpperCase();
        this.set('subInfo.Icp',item.name);
        this.set('subInfo.TransformerCode',item.transformerCode);
        this.set('subInfo.FeederCode',item.feederCode);
        this.set('subInfo.AssetType',{String:assetType, Valid:true});
        this.set('subInfo.EquipmentNo',item.name);
        this.set('subInfo.EquipmentDescription',assetType);
        this.set('subInfo.CustomerNo',item.customerNo);
        this.set('subInfo.Name',item.customerName);
        // this.set('subInfo.PropertyName',item.streetAddress);
        // this.set('subInfo.AlertText',item.category);
        this.set('subInfo.Lat',item.lat);
        this.set('subInfo.Lng',item.lng);
        this.set('subInfo.ModifiedBy',this.app.username);
        this.$.overlaySearchResults.close();
        
    }
}
customElements.define(FaultReportedDetail.is, FaultReportedDetail);
