//Rewrite in ES6 style


window.AmiBehaviors = window.AmiBehaviors || {};
AmiBehaviors.SecurityBehavior = {

//        properties: {
//            isHighlighted: {
//                type: Boolean,
//                value: false,
//                notify: true,
//                observer: '_highlightChanged'
//            }
//        },

//        listeners: {
//
//        },

    created: function() {
//            console.log('Highlighting for ', this, 'enabled!');
    },

    isShutdownViewAllowed: function(role) {
        var a = ["CONTRACTOR","PLANNING","FINANCE"];
        return !this._check(a, role);
    },

    isConsumptionViewAllowed: function(role) {
        var a = ["ADMINISTRATOR","USER", "CUSTOMER SERVICE","BILLING","METERING"];
        return this._check(a, role);
    },

    isDispatchViewAllowed: function(role) {
        var a = ["ADMINISTRATOR","USER","CUSTOMER SERVICE", "CALL CARE","CORPORATE", "FSM", "TL", "PERFORMANCE"];
        return this._check(a, role);
    },

    isDispatchMergeAllowed: function(role) {
        var a = ["ADMINISTRATOR","USER","CUSTOMER SERVICE", "CALL CARE"];
        return this._check(a, role);
    },

    //Event
    isEventViewAllowed: function(role) {
        var a = ["CONTRACTOR","PLANNING"];
        return !this._check(a, role);
    },
    isEventCreateEditAllowed: function(role) {
        var a = ["ADMINISTRATOR","USER","CUSTOMER SERVICE"];
        return this._check(a, role);
    },

    //Subscriber
    isSubscriberViewAllowed: function(role) {
        var a = ["ADMINISTRATOR","USER","CUSTOMER SERVICE", "CALL CARE","CORPORATE"];
        return this._check(a, role);
    },
    isSubscriberEditAllowed: function(role) {
        var a = ["ADMINISTRATOR","USER","CUSTOMER SERVICE"];
        return this._check(a, role);
    },

    //Call Manager
    isCallManagerViewAllowed: function(role) {
        var a = ["ADMINISTRATOR","USER","CUSTOMER SERVICE", "CALL CARE","PERFORMANCE"];
        return this._check(a, role);
    },
    isCallManagerCreateCallAllowed: function(role) {
        var a = ["ADMINISTRATOR","USER","CUSTOMER SERVICE", "CALL CARE"];
        return this._check(a, role);
    },

    //Map - Search
    isMapSearchViewAllowed: function(role) {
        var a = ["CONTRACTOR"];
        return !this._check(a, role);
    },

    isOutageViewAllowed: function(role) {
        var a = ["CONTRACTOR"];
        return !this._check(a, role);
    },

    isMeterDataViewAllowed: function(role) {
        var a = ["ADMINISTRATOR","USER","CUSTOMER SERVICE","CORPORATE", "FSM", "PLANNING", "PERFORMANCE", "BILLING","METERING"];
        return this._check(a, role);
    },

    isVehicleViewAllowed: function(role) {
        var a = ["CONTRACTOR","PLANNING"];
        return !this._check(a, role);
    },

    isMapShutdownViewAllowed: function(role) {
        var a = ["PLANNING"];
        return !this._check(a, role);
    },

    isRealTimeViewAllowed: function(role) {
        var a = ["ADMINISTRATOR","USER","CORPORATE","CUSTOMER SERVICE", "CALL CARE", "PERFORMANCE"];
        return this._check(a, role);
    },

    //Ping
    isPingViewAllowed: function(role) {
        var a = ["ADMINISTRATOR","USER","CORPORATE","CUSTOMER SERVICE", "CALL CARE", "FSM","TEAM","PERFORMANCE"];
        return this._check(a, role);
    },

    isPingRequestAllowed: function(role) {
        var a = ["ADMINISTRATOR","USER","CUSTOMER SERVICE", "CALL CARE", "FSM","PERFORMANCE"];
        return this._check(a, role);
    },

    isIcpCallNoteAllowed: function(role) {
        var a = ["ADMINISTRATOR","USER","CUSTOMER SERVICE", "CALL CARE"];
        return this._check(a, role);
    },

    isIcpLocationViewAllowed: function(role) {
        var a = ["CORPORATE"];
        return !this._check(a, role);
    },

    //Name Address Location
    isNameAddressLocationAllowed: function(role) {
        var a = ["CONTRACTOR","CORPORATE"];
        return !this._check(a, role);
    },



    _check(a, role){
         return a.includes(role.toUpperCase());
    }


};
