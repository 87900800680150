
import '@polymer/polymer/polymer-legacy.js';
import moment from 'moment';
import '@polymer/iron-collapse/iron-collapse.js';
import '@polymer/iron-list/iron-list.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-input/paper-textarea.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/iron-icons/av-icons.js';
// import 'paper-date-picker/paper-date-picker.js';
import "@polymer/paper-item/paper-item.js";
import "@polymer/paper-checkbox/paper-checkbox.js";
import "@polymer/paper-listbox/paper-listbox.js";
import "@polymer/paper-toast/paper-toast.js";
import "@polymer/paper-dropdown-menu/paper-dropdown-menu.js";
import "@polymer/iron-flex-layout/iron-flex-layout-classes.js";
import "@polymer/iron-scroll-threshold/iron-scroll-threshold.js";
import "@vaadin/vaadin-grid";
import "@vaadin/vaadin-grid/vaadin-grid-column";
import "@vaadin/vaadin-grid/vaadin-grid-sort-column";
import "@vaadin/vaadin-date-picker/vaadin-date-picker";
import "@vaadin/vaadin-text-field/vaadin-text-field";
import "../app-security-behavior.js";
import "./core-overlay.js";
import "./meter-detail.js";
import "./customer-detail.js";
import "./wo-detail.js";
import './fault-alert-detail.js';
import { PolymerElement, html } from "@polymer/polymer/polymer-element.js";
import "@polymer/polymer/lib/elements/dom-if.js";
import "@polymer/polymer/lib/elements/dom-repeat.js";
import '@polymer/paper-tooltip/paper-tooltip.js';

// let chartBreach;
let chartDistGen;
let chartPowerDown;
let chartCons;
let chartControlledLoad;
let dur = "7d";
/**
 * @polymer
 * @extends HTMLElement
 **/
class IcpAssetDetail extends PolymerElement {
  static get is() {
    return "icp-asset-detail";
  }

  static get properties() {
    return {
      app: Object,
      assetId: {
        type: Number,
        observer: "assetIdChanged",
      },
      selected: {
        type: Number,
        value: 0,
        observer: "selectedTabChanged",
      },
      icpInfo: {
        type: Object,
        value: function () {
          return {};
        },
      },
      isLoading: Boolean,
      isFromDateInvalid: Boolean,
      isToDateInvalid: Boolean,
      isFromTimeInvalid: Boolean,
      isToTimeInvalid: Boolean,
      isFromTransformer: Boolean,
      isFromSubscriber: Boolean,
      urlLogMessages: String,
      urlResources: String,
      startDate: String,
      icpMeters: Array,
      removedMeters: Array,
      selectedMeterNo: String,
      selectedCustomerNo: String,
      emailInfo: Object,
      noteInfo: Object,
      smsInfo: Object,
      subscriberInfo: Object,
      username: String,
      discoRecos: Array,
      workOrderHistory: Array,
      //                    data: Object,
      data: {
        type: Array,
        value: () => [],
        observer: "pastDataChanged",
      },
      callTypes: Array,
      icpCategories: Object,
      icpCallInfo: Object,
      searchText: {
        type: String,
        value: "",
      },
      selectedChart: {
        type: Number,
        value: 0,
        observer: "selectedChartChanged",
      },
      fromDate: String,
      toDate: String,
      fromTime: {
        type: String,
        value: "00:00",
      },
      toTime: {
        type: String,
        value: "00:00",
      },
      csvType: String,
      fromDateTime: String,
      toDateTime: String,
      filterStartDate: {
        type: Date,
        value: function () {
          return new Date();
        },
        observer: "filterStartDateChanged",
      },
      filterEndDate: {
        type: Date,
        value: function () {
          return new Date();
        },
        observer: "filterEndDateChanged",
      },
      selectedDeviceNo: String,
      selectedEventTime: String,
      icpMessageHistoryType: {
        type: String,
        value: 2,
        observer: 'icpMessageHistoryTypeChanged',
      },
      loadControlDetail: Array,
      selectedServiceOrder: {
        type: Object,
        observer: '_selectedServiceOrderChanged'
      },
      selectedItems: Array,
    };
  }

  static get template() {
    return html`
          <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>

        <style>
        :host {
            display: block;
            width: 100%;
            height: 100%;
            --paper-input-container-input: {
                font-size: 13px;
            };
        }
        
        section {
            padding: 10px;
        }
        
        paper-button.colored {
            color: #3c3f41;
        }
        
        paper-button[raised].colored {
            background: #4285f4;
            color: #fff;
        }
        
        paper-button.custom> core-icon {
            margin-right: 4px;
        }
        
        paper-button.hover:hover {
            background: #eee;
        }
        
        paper-button.blue-ripple::shadow #ripple {
            color: #4285f4;
        }
        
        paper-button.call {
            background: #ffc107;
        }
        
        .chart-wrapper {
            padding: 10px;
            height: 300px;
        }
        
        paper-progress {
            width: 100%;
            --paper-progress-container-color: white;
            --paper-progress-active-color: var(--paper-orange-500);
            --paper-progress-secondary-color: var(--paper-orange-100);
        }
        
      

        #customerdetail {
            position: fixed;
            left: 0px;
            bottom: 0px;
            background: white;
            width: 100%;
            height: 500px;
            max-height: 1000px;
            overflow: auto;
        }
        
        #meterdetail {
            position: fixed;
            left: 0px;
            bottom: 0px;
            background: white;
            width: 100%;
            height: 500px;
            max-height: 1000px;
            overflow: auto;
        }

        #wodetail {
            position: fixed;
            left: 0px;
            bottom: 0px;
            background: white;
            width: 100%;
            height: 500px;
            max-height: 1000px;
            overflow: auto;
        }

        #faultAlertOverlay {
            position: fixed;
            right: 0px;
            top: 64px;
            background: white;
            width: 408px;
            height: 100%;
            overflow: auto;
        }
      
        iron-icon.live {
            fill: #F44336;
            margin-right: 10px;
        }
        
        paper-dialog.function-dialog {
            bottom: 50px;
            width: 400px;
            height: 400px;
            overflow: auto;
        }
       
        
        paper-dialog.paper-date-picker-dialog {
            bottom: 0px;
        }
        
        iron-list {
            --iron-list-items-container: {
                margin: auto;
            };
        }
        
        #dlgNewCall {
            bottom: 0px;
            width: 500px;
            height: 700px;
            overflow: auto;
        }
        
        #collapse {
            position: absolute;
            background-color: #f5f5f5;
            max-height: 230px;
            z-index: 1000;
            border: solid 1px #B6B6B6;
            width: 290px;
        }
        
        .cards {
            margin-right: 20px;
        }
        
        paper-card {
            width: 100%;
            margin: 8px;
        }
        
        #dlgfilter {
            position: absolute;
            bottom: 138px;
            /*left: 50px;*/
            width: 390px;
            height: 240px;
            right: 182px;
        }
        .access-message{
                /*margin-top:200px;*/
                padding: 20px;
                border: dashed 1px red;
                font-weight: bold;

            }
        .card-wrap{
           @apply --layout-horizontal;
            @apply --layout-wrap;
        }

        .card-wrap  paper-card{
            width: 600px;
            margin: 4px;
        }
        paper-card .info{

        }
        .info {
            background-color: #FFE082;
        }
        .spacer{
            width: 20px;
        }

        .icon-green{
          color: #66BB6A;
        }

        .icon-grey{
          color: #ffc107;
        }

        vaadin-text-field {
            padding: 5px; 
            font-size: 14px;
            width: 150px;
        }

        </style>

<app-header-layout has-scrolling-region="true" fullbleed="">
        <app-header scroll-target="scrollable-element" slot="header"   fixed>
            <app-toolbar class="small">
                <!--<paper-spinner alt="Loading..." active="{{isLoading}}"></paper-spinner>-->
                <paper-tabs selected="{{selected}}" class="flex">
                    <paper-tab disabled="{{isLoading}}">ICP Detail</paper-tab>
                    <paper-tab disabled="{{isLoading}}">Meter Details</paper-tab>
                    <paper-tab disabled="{{isLoading}}">Charts</paper-tab>
                    <!--<paper-tab>Distributed Generation</paper-tab>-->
                    <!--<paper-tab>Events</paper-tab>-->
                    <!--<paper-tab>Power Down</paper-tab>-->

                    <paper-tab disabled="{{isLoading}}">Subscribers</paper-tab>
                    <paper-tab disabled="{{isLoading}}">Message History</paper-tab>
                    <paper-tab disabled="{{isLoading}}">Disco/Reco</paper-tab>
                    <paper-tab>Work History</paper-tab>

                </paper-tabs>
                <div class="bottom flex"></div>
                <paper-icon-button class="bottom " icon="close" on-click="close"></paper-icon-button>
           
        </app-header>

      
        
            <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>
            <iron-pages selected="{{selected}}" id="scrollable-element" style="overflow: auto; height:100vh">
                <div>
                    <div class="horizontal justified layout" style="margin-bottom: 20px;">
                        <div>
                            <!--Spacer-->
                        </div>
                        <div>
                            <paper-button on-click="onNewCallClick" class="call" raised="" hidden\$="[[!isIcpCallNoteViewAllowed(app)]]">
                                <iron-icon icon="perm-phone-msg"></iron-icon>
                                New Call
                            </paper-button>
                            <paper-button on-click="onEmailClick" raised="">
                                <iron-icon icon="communication:email"></iron-icon>
                                Email
                            </paper-button>
                            <paper-button on-click="onSmsClick" raised="">
                                <iron-icon icon="communication:textsms"></iron-icon>
                                SMS
                            </paper-button>
                            <paper-button on-click="onAddNoteClick" raised="" hidden\$="[[!isIcpCallNoteViewAllowed(app)]]">
                                <iron-icon icon="communication:comment"></iron-icon>
                                Note
                            </paper-button>
                        </div>
                    </div>
                    <div class="horizontal around-justified layout" style="padding: 0 20px">
                        <div class="verticle layout flex">
                            <paper-input label="Customer #" value="{{icpInfo.customerNumber}}" readonly="" hidden\$="[[!isNameAddressLocationViewAllowed(app)]]">
                                <paper-icon-button slot="suffix" icon="perm-identity" on-click="showCustomerDetailOverlay"></paper-icon-button>
                                <template is="dom-if" if="{{icpInfo.isKeyAccount}}">
                                  <div slot="suffix" style="display:inline-block">
                                    <iron-icon icon="communication:vpn-key" style="color:#038a03"></iron-icon>
                                    <paper-tooltip>Key account</paper-tooltip>
                                  </div>
                                </template>
                                <template is="dom-if" if="{{icpInfo.isVip}}">
                                  <div slot="suffix" style="display:inline-block">
                                    <iron-icon icon="av:new-releases" style="color:#ff7b00"></iron-icon>
                                    <paper-tooltip>VIP</paper-tooltip>
                                  </div>
                                </template>
                            </paper-input>
                            <paper-input label="Customer Name" value="{{icpInfo.customerName}}" readonly="" hidden\$="[[!isNameAddressLocationViewAllowed(app)]]"></paper-input>
                            <paper-input label="ICP Name" value="{{icpInfo.name}}" readonly="">
                                 <paper-icon-button slot="suffix" icon="settings-input-antenna" on-click="onPingIcp" hidden\$="[[!isPingRequestAllowed(app)]]"></paper-icon-button>
                            </paper-input>
                            <paper-input label="Property Name" value="{{icpInfo.propertyName}}" readonly=""></paper-input>

                        </div>
                        <div class="spacer">
                            <!--Spacer-->
                        </div>
                        <div class="verticle layout flex two">
                            <paper-input label="Property Category" value="{{icpInfo.categoryDescription}}" readonly="">
                                <paper-icon-button slot="suffix" icon="launch" on-click="onShowCategoryDialog"></paper-icon-button>
                            </paper-input>
                            <paper-input label="Feeder Code" value="{{icpInfo.feederCode}}" readonly="">
                            </paper-input>
                            <paper-input label="Feeder Category" value="{{icpInfo.feederCategoryDescription}}" readonly=""></paper-input>
                            <paper-input label="Retailer" value="{{icpInfo.trader}}" readonly=""></paper-input>
                            
                            <paper-checkbox checked="{{icpInfo.isHazard}}" disabled="">Hazard</paper-checkbox>
                            <template is="dom-if" if="{{icpInfo.isHazard}}">
                                <paper-textarea label="Alert Text" value="{{icpInfo.alertText}}" rows="3" readonly=""></paper-textarea>
                                <paper-checkbox checked="{{icpInfo.daytimeOnly}}" disabled="">Daytime Only</paper-checkbox>
                                <paper-checkbox checked="{{icpInfo.twoPersonCrew}}" disabled="">2-Person Crew</paper-checkbox>
                                <paper-checkbox checked="{{icpInfo.dogs}}" disabled="">Dogs</paper-checkbox>

                            </template>
                            
                        </div>

                        <div class="spacer">
                            <!--Spacer-->
                        </div>
                        <div class="verticle layout flex two">
                            <paper-input label="Street Address" value="{{icpInfo.streetAddress}}" readonly="" hidden\$="[[!isNameAddressLocationViewAllowed(app)]]">
                                <paper-icon-button slot="suffix" icon="communication:location-on" on-click="onShowOnMap"></paper-icon-button>
                            </paper-input>
                            <paper-input label="Suburb" value="{{icpInfo.suburb}}" readonly=""></paper-input>
                            <paper-input label="Town" value="{{icpInfo.town}}" readonly=""></paper-input>
                            <paper-input label="Postcode" value="{{icpInfo.postcode}}" readonly=""></paper-input>
                            <paper-input label="Disconnection Type" value="{{icpInfo.disconnectionReason}}" readonly="">
                                <paper-icon-button slot="suffix" icon="launch" on-click="onShowCategoryDialog"></paper-icon-button>
                            </paper-input>
                        </div>
                        <div class="spacer">
                            <!--Spacer-->
                        </div>
                        <div class="verticle layout flex two">
                            <paper-input label="Email" value="{{icpInfo.email}}" readonly="">
                                <paper-icon-button slot="suffix" icon="communication:email" on-click="onEmailClick"></paper-icon-button>
                            </paper-input>
                            <paper-input label="Mobile" value="{{icpInfo.mobileNo}}" readonly="">
                                <paper-icon-button slot="suffix" icon="communication:textsms" on-click="onSmsClick"></paper-icon-button>
                            </paper-input>
                            <paper-input label="Phone" value="{{icpInfo.phoneNo}}" readonly=""></paper-input>
                            <paper-input label="Phone 2" value="{{icpInfo.phoneNo2}}" readonly=""></paper-input>
                            <template is="dom-if" if="{{icpInfo.isIgnoreFromRealTime}}">
                                <paper-input label="Ignore from Real-time" value="Yes" readonly="">Yes</paper-input>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="verticle layout">
                    <div class="horizontal layout">
                        <div class="flex">
                            <h6> Current Meters: </h6>
                            <template is="dom-repeat" items="{{icpMeters}}">
                                <!--<div>-->
                                <!--<iron-icon icon="assignment-late"  class="live"></iron-icon><span>{{item.deviceNo}}</span> - Duration: <span>{{convertDuration(item.duration)}}</span>-->
                                <!--</div>-->
                                <!--<template is="dom-if" if="{{item.hasConsumption}}">-->
                                <!--<iron-icon icon="lightbulb-outline" class="disconnect"></iron-icon>Consumption Since Outage: <span>{{item.consumptionAfterOutage}}</span>-->
                                <!--<paper-button on-click="onSetMeterActive" alt="Set Active" title="Set Active" class="call" raised>-->
                                <!--<iron-icon icon="done"></iron-icon>-->
                                <!--Set Meter as Active-->
                                <!--</paper-button>-->
                                <!--</template>-->
                                <div class="cards">
                                    <paper-card>
                                        <div class="card-content">
                                            <div>
                                            </div>
                                            <div class="horizontal layout">
                                                <div class="flex">
                                                    Device #: <span style="font-weight: bold">{{item.deviceNo}}</span>
                                                </div>
                                                <div>
                                                    Consumption since <span>[[formatDate(item.installedDate)]]</span>: <span style="font-weight: bold">{{_calcTotalConsumption(item.channelTotalConsumption)}}</span> kWh
                                                </div>
                                            </div>
                                            
                                              <div class="flex">
                                                Multiplier: <span style="font-weight: bold">[[item.multiplier]]</span>
                                              </div>
                                            <template is="dom-repeat" items="{{item.channelTotalConsumption}}">
                                              <div class="horizontal layout" style="margin-top: 4px;">
                                                  <div class="flex">
                                                  </div>
                                                  <div>
                                                      Channel: <span style="font-weight: bold">[[item.channel]]</span> Last Read: <span style="font-weight: bold">[[formatDate(item.lastRead)]]</span> Consumption: <span style="font-weight: bold">[[item.tc]]</span> kWh
                                                  </div>
                                              </div>
                                            </template>
                                            <template is="dom-if" if="[[hasResponse(item.pingResponse)]]">
                                                <div class="horizontal layout" style="margin-top: 4px;">
                                                    <div class="flex">
                                                    
                                                    </div>
                                                    <div>
                                                        Last Ping: <span style="font-weight: bold">[[formatDateTime(item.pingCompletedAt)]]</span> <span>[[item.pingResponse]]</span> 
                                                    </div>
                                                </div>
                                            </template>
                                            <template is="dom-if" if="{{item.hasOutage}}">
                                                <div class="horizontal layout">
                                                    <div class="flex">
                                                        <span>Duration: </span>
                                                        <span>{{convertDuration(item.duration)}}</span>
                                                    </div>
                                                </div>
                                            </template>
                                            <template is="dom-if" if="{{item.hasConsumption}}">
                                                <div class="flex">
                                                    Consumption Since Outage: <span style="font-weight: bold">{{item.consumptionAfterOutage}}</span> kWh
                                                </div>
                                            </template>
                                            <div class="flex">
                                                Disco/Reco Details: <span style="font-weight: bold">[[formatDateTime(item.lastConnectionRequestCompletedAt)]]</span> - <span style="font-weight: bold">{{item.lastConnectionRequestType}}</span>  - <span style="font-weight: bold">{{item.lastConnectionRequestStatus}}</span> - <span style="font-weight: bold">{{item.lastConnectionRetailerCode}}</span>
                                            </div>
                                        </div>
                                        <div class="card-actions">
                                            <div class="horizontal layout">
                                                <template is="dom-if" if="{{item.hasOutage}}">
                                                    <iron-icon icon="assignment-late" class="live"></iron-icon>
                                                </template>
                                                <paper-icon-button icon="communication:location-on" on-click="onShowOnMap"></paper-icon-button>
                                                <paper-icon-button icon="file-upload" on-click="onOpenMeterDetails"></paper-icon-button>
                                                <template is="dom-if" if="{{item.isSmartMeter}}">
                                                  <paper-icon-button icon="settings-input-antenna" on-click="onPingMeter" hidden\$="[[!isPingRequestAllowed(app)]]"></paper-icon-button>
                                                </template>
                                                <div class="flex"></div>
                                                <!--<template is="dom-if" if="{{item.hasConsumption}}">
                                                    <paper-button on-click="onSetMeterActive" alt="Set Active" title="Set Active" class="call" raised hidden\$="{{!item.hasConsumption}}">
                                                        <iron-icon icon="done"></iron-icon>
                                                        Set Meter as Active
                                                    </paper-button>
                                                </template>-->
                                                <paper-button on-click="onSetMeterActive" alt="Set Active" title="Set Active" class="call" raised="" hidden\$="{{!item.hasConsumption}}">
                                                        <iron-icon icon="done"></iron-icon>
                                                        Set Meter as Active
                                                    </paper-button>
                                            </div>
                                        </div>
                                    </paper-card>
                                </div>
                            </template>
                        </div>
                        <div class="flex">
                            <h6>Removed Meters</h6>
                            <template is="dom-repeat" items="{{removedMeters}}">
                                <!--<div>-->
                                <!--<iron-icon icon="assignment-late"  class="live"></iron-icon><span>{{item.deviceNo}}</span> - Duration: <span>{{convertDuration(item.duration)}}</span>-->
                                <!--</div>-->
                                <!--<template is="dom-if" if="{{item.hasConsumption}}">-->
                                <!--<iron-icon icon="lightbulb-outline" class="disconnect"></iron-icon>Consumption Since Outage: <span>{{item.consumptionAfterOutage}}</span>-->
                                <!--<paper-button on-click="onSetMeterActive" alt="Set Active" title="Set Active" class="call" raised>-->
                                <!--<iron-icon icon="done"></iron-icon>-->
                                <!--Set Meter as Active-->
                                <!--</paper-button>-->
                                <!--</template>-->
                                <div class="cards">
                                    <paper-card>
                                        <div class="card-content">
                                            <div>
                                            </div>
                                            <div class="horizontal layout">
                                                <div class="flex">
                                                    Device #: <span style="font-weight: bold">{{item.deviceNo}}</span>
                                                </div>
                                                <div>
                                                    Consumption: <span style="font-weight: bold">{{_calcTotalConsumption(item.channelTotalConsumption)}}</span> kWh
                                                </div>
                                            </div>
                                           
                                            <div class="flex">
                                              Multiplier: <span style="font-weight: bold">[[item.multiplier]]</span>
                                            </div>

                                            <!-- <div class="horizontal layout">
                                                <div class="flex">
                                                   
                                                </div>
                                                <div>
                                                    Last Read: <span style="font-weight: bold">[[formatDate(item.lastReadDate)]]</span> 
                                                </div>
                                            </div> -->
                                            <template is="dom-repeat" items="{{item.channelTotalConsumption}}">
                                              <div class="horizontal layout" style="margin-top: 4px;">
                                                  <div class="flex">
                                                  </div>
                                                  <div>
                                                      Channel: <span style="font-weight: bold">[[item.channel]]</span> Last Read: <span style="font-weight: bold">[[formatDate(item.lastRead)]]</span> Consumption: <span style="font-weight: bold">[[item.tc]]</span> kWh
                                                  </div>
                                              </div>
                                            </template>
                                            <div class="horizontal layout" style="margin-top: 4px;">
                                                <div class="flex">
                                                    <span>Removed: </span>
                                                    <span>{{formatDate(item.removedDate)}}</span>
                                                </div>
                                            </div>
                                            <template is="dom-if" if="{{item.hasOutage}}">
                                                <div class="horizontal layout">
                                                    <div class="flex">
                                                        <span>Outage Time: </span>
                                                        <span>{{formatDateTime(item.outageTime)}}</span>
                                                        <!--<iron-icon icon="assignment-late" class="live"></iron-icon>-->
                                                    </div>
                                                </div>
                                            </template>
                                            <template is="dom-if" if="{{item.isInstalledOtherIcp}}">
                                                <div class="flex">
                                                    Installed On: <span style="font-weight: bold">{{item.installedOtherIcp}}</span>
                                                </div>
                                            </template>
                                            <!--<div class="horizontal layout">-->
                                            <!--<div class="flex">-->
                                            <!--&lt;!&ndash;<span>Removed: </span>&ndash;&gt;-->
                                            <!--<span>{{convertDuration(item.removedDate)}}</span>-->
                                            <!--</div>-->
                                            <!--</div>-->
                                            <!--<template is="dom-if" if="{{item.hasConsumption}}">-->
                                            <!--<div class="flex">-->
                                            <!--Consumption Since Outage: <span style="font-weight: bold">{{item.consumptionAfterOutage}}</span>-->
                                            <!--</div>-->
                                            <!--</template>-->
                                            
                                            <div class="flex">
                                                Disco/Reco Details: <span style="font-weight: bold">[[formatDateTime(item.lastConnectionRequestCompletedAt)]]</span> - <span style="font-weight: bold">{{item.lastConnectionRequestType}}</span> - <span style="font-weight: bold">{{item.lastConnectionRequestStatus}}</span> - <span style="font-weight: bold">{{item.lastConnectionRetailerCode}}</span>    
                                            </div>                                      
                                        </div>
                                        <div class="card-actions">
                                            <div class="horizontal layout">
                                                <!--<template is="dom-if" if="{{item.hasOutage}}">-->
                                                <!--<iron-icon icon="assignment-late"  class="live"></iron-icon>-->
                                                <!--</template>-->
                                                <!--<paper-icon-button icon="communication:location-on" on-click="onShowOnMap"></paper-icon-button>-->
                                                <paper-icon-button icon="file-upload" on-click="onOpenMeterDetails"></paper-icon-button>
                                                <div class="flex"></div>
                                            </div>
                                        </div>
                                    </paper-card>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div>
                <template is="dom-if" if="[[!isAllowed(app)]]">
                    <div class="horizontal center-justified layout">
                       <div class="access-message">You don't have access to this section!</div>
                    </div>
                </template>

                 <paper-dialog id="dlgCalFrom" class="paper-date-picker-dialog" on-iron-overlay-closed="dismissDialog">
                        <paper-date-picker id="pickerFrom" date="{{filterStartDate}}" heading-format="D MMM" style="margin:0; padding:0;"></paper-date-picker>
                        <!--<div class="buttons">-->
                        <!--<paper-button dialog-dismiss>Cancel</paper-button>-->
                        <!--<paper-button dialog-confirm>OK</paper-button>-->
                        <!--</div>-->
                    </paper-dialog>
                    <paper-dialog id="dlgCalTo" class="paper-date-picker-dialog" on-iron-overlay-closed="dismissDialog">
                        <paper-date-picker id="pickerTo" date="{{filterEndDate}}" heading-format="D MMM" style="margin:0; padding:0;"></paper-date-picker>
                    </paper-dialog>

                <template is="dom-if" if="[[isAllowed(app)]]">
                   

                    <section>
                        <div class="horizontal justified layout">
                            <div>
                                <paper-dropdown-menu style="width: 200px;" no-label-float="" no-animations="">
                                    <paper-listbox slot="dropdown-content" selected="{{selectedChart}}">
                                        <paper-item>Consumption</paper-item>
                                        <paper-item>Distributed Generation</paper-item>
                                        <paper-item>Voltage Breach</paper-item>
                                        <paper-item>Power Down</paper-item>
                                        <paper-item>Controlled Load</paper-item>
                                    </paper-listbox>
                                </paper-dropdown-menu>
                            </div>
                            <div>
                              <vaadin-text-field placeholder="Placeholder" value={{formatDate(fromDate)}} readonly="" on-click="showDateFilterDialog">
                              </vaadin-text-field>
                              <span style="font-size: 18px"> > </span>
                              <vaadin-text-field placeholder="Placeholder" value={{formatDate(toDate)}} readonly="" on-click="showDateFilterDialog">
                              </vaadin-text-field>
                                <paper-button on-click="showDateFilterDialog" alt="Filter" title="Filter" raised="">
                                    <iron-icon icon="filter-list"></iron-icon>
                                    Filter
                                </paper-button>
                        
                                <template is="dom-if" if="{{!isLoading}}">
                                    <a href\$="/api/icp/[[csvType]]/csv/[[icpInfo.name]]/from/[[fromDateTime]]/to/[[toDateTime]]" target="_blank" tabindex="-1">
                                        <paper-button raised="">
                                            <iron-icon icon="file-download"></iron-icon>
                                            Download CSV
                                        </paper-button>
                                    </a>
                                </template>
                            </div>
                        </div>
                    </section>
                </template>
               
                    <section>
                        <iron-pages selected="{{selectedChart}}">
                            <div id="conschart" class="flex chart-wrapper"></div>
                            <div id="chartDistGen" class="chart-wrapper"></div>
                            <div id="chart" class="chart-wrapper"></div>
                            <div id="chartPowerDown" class="chart-wrapper"></div>
                            <div id="chartControlledLoad" class="char-wrapper"></div>
                        </iron-pages>
                    </section>
                    <core-overlay id="dlgfilter" autoclosedisabled="true">
                        <paper-card>
                            <div class="card-content">
                                <!-- Custom Filter-->
                                <div class="horizontal justified layout">
                                    <vaadin-date-picker label="From" placeholder="Placeholder" id="fromCal" value="{{fromDate}}">
                                    </vaadin-date-picker>
                                    <!-- <paper-input label="From (dd.mm.yyyy)" value="{{fromDate}}" class="flex-2" required="" maxlength="10" minlength="10" pattern="^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))\$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$" auto-validate="" error-message="Invalid Date" invalid="{{isFromDateInvalid}}"></paper-input>
                                    <paper-icon-button icon="icons:event" on-click="onCalFromClick"></paper-icon-button>
                                    <paper-input label="HH:MM" value="{{fromTime}}" class="flex" required="" maxlength="5" minlength="5" pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]\$" auto-validate="" error-message="Invalid Time" invalid="{{isFromTimeInvalid}}"></paper-input> -->
                                </div>
                                <div class="horizontal justified layout">
                                    <vaadin-date-picker label="To" placeholder="Placeholder" id="toCal" value="{{toDate}}">
                                    </vaadin-date-picker>
                                    <!-- <paper-input label="To (dd.mm.yyyy)" value="{{toDate}}" class="flex-2" required="" maxlength="10" minlength="10" pattern="^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))\$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$" auto-validate="" error-message="Invalid Date" invalid="{{isToDateInvalid}}"></paper-input>
                                    <paper-icon-button icon="icons:event" on-click="onCalToClick"></paper-icon-button>
                                    <paper-input label="HH:MM" value="{{toTime}}" class="flex" required="" maxlength="5" minlength="5" pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]\$" auto-validate="" error-message="Invalid Time" invalid="{{isToTimeInvalid}}"></paper-input> -->
                                </div>
                                <paper-button id="btnCustDateSearch" class="meter-data-filter" on-click="onCustDateClick" disabled="[[isFilterInvalid(isFromDateInvalid, isToDateInvalid,isFromTimeInvalid, isToTimeInvalid)]]" raised="">Search
                                </paper-button>
                            </div>
                            <div class="card-actions" style="background-color: #f5f5f5">
                                <p>Quick Filter</p>
                                <div class="horizontal justified">
                                    <paper-icon-button icon="icons:event" disabled=""></paper-icon-button>
                                    <!-- <paper-button id="btnVb1h" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="1:h">1 Hour
                                    </paper-button>
                                    <paper-button id="btnVb6h" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="6:h">6 Hours
                                    </paper-button> -->
                                    <paper-button id="btnVb1d" class="meter-data-filter colored" on-click="onBtnFilterClick" data-dur="1">1 Day
                                    </paper-button>
                                    <paper-button id="btnVb2d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="2">2 Days
                                    </paper-button>
                                    <paper-button id="btnVb4d" class="meter-data-filter" data-dur="4" on-click="onBtnFilterClick">4 Days
                                    </paper-button>
                                </div>
                                <div class="horizontal justified">
                                    <paper-icon-button icon="icons:event" disabled=""></paper-icon-button>
                                    <paper-button id="btnVb7d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="7">7 Days</paper-button>
                                    <paper-button id="btnVb14d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="14">14 Days
                                    </paper-button>
                                    <paper-button id="btnVb30d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="30">30 Days
                                    </paper-button>
                                </div>
                                <paper-button>[[startDate]]</paper-button>
                            </div>
                        </paper-card>
                    </core-overlay>
                    
                </div>
                <!--Subscribers-->
                <section>
                    <template is="dom-if" if="[[!isSubscriberViewAllowed(app)]]">
                        <div class="horizontal center-justified layout">
                            <div class="access-message">You don't have access to this section!</div>
                        </div>
                    </template>
                    <template is="dom-if" if="[[isSubscriberViewAllowed(app)]]">
                        <div class="horizontal justified layout" style="margin-bottom: 20px;">
                            <div>
                                <!--Spacer-->
                            </div>
                            <div>
                                <template is="dom-if" if="[[isSubscriberEditAllowed(app)]]">
                                    <paper-button on-click="onOpenSubscriberDetails" raised="">
                                        <iron-icon icon="communication:email"></iron-icon>
                                        Add Subscriber
                                    </paper-button>
                                </template>

                            </div>
                        </div>
                        <div class="card-wrap">
                            <template is="dom-repeat" items="{{subscribers}}">

                                <div>
                                    <paper-card>
                                        <div class="card-content">
                                            <div>
                                            </div>
                                            <div class="horizontal layout">
                                                <div class="flex">
                                                    First Name #: <span style="font-weight: bold">{{item.firstName}}</span>
                                                </div>
                                                <div>
                                                    Last Name: <span style="font-weight: bold">{{item.lastName}}</span>
                                                </div>
                                            </div>
                                            <div class="horizontal layout">
                                                <div class="flex">
                                                    Phone #: <span style="font-weight: bold">{{item.phone}}</span>
                                                </div>
                                                <div>
                                                    Email: <span style="font-weight: bold">{{item.email}}</span>
                                                </div>
                                            </div>
                                            <div class="horizontal layout">
                                                <div class="flex">
                                                    Message Type: <span style="font-weight: bold">{{item.powerOutageUpdates}}</span>
                                                </div>
                                                <div>
                                                    When: <span style="font-weight: bold">{{item.updateTime}}</span>
                                                </div>
                                            </div>
                                            <div class="horizontal layout">
                                                <div class="flex">
                                                    Last Updated: <span style="font-weight: bold">{{formatDateTime(item.updatedAt)}}</span>
                                                </div>
                                                <div>
                                                    Status: <span style="font-weight: bold">{{item.status}}</span>
                                                </div>

                                            </div>


                                        </div>
                                        <div class="card-actions">
                                            <div class="horizontal layout">
                                                <template is="dom-if" if="[[isSubscriberEditAllowed(app)]]">
                                                <paper-icon-button icon="create" on-click="onEditSubscriberDetails"></paper-icon-button>

                                                <div class="flex"></div>

                                                    <paper-button on-click="onChangeSubscriberStatus" hidden\$="{{!_isSubscriberActive(item.status)}}" style="color: red;">
                                                        <iron-icon icon="communication:phonelink-erase"></iron-icon>
                                                        Deactivate
                                                    </paper-button>
                                                    <paper-button on-click="onChangeSubscriberStatus" hidden\$="{{_isSubscriberActive(item.status)}}" style="color: green;">
                                                        <iron-icon icon="communication:phonelink-ring"></iron-icon>
                                                        Activate
                                                    </paper-button>
                                                </template>

                                            </div>
                                        </div>
                                    </paper-card>
                                </div>

                            </template>
                        </div>
                    </template>

                    <iron-ajax id="ajaxSubscribers" url="[[urlSubscribers]]" last-response="{{subscribers}}" loading="{{isLoading}}"></iron-ajax>


                </section>
                <!--Message History-->
                <section>
                    <template is="dom-if" if="[[!isIcpCallNoteViewAllowed(app)]]">
                        <div class="horizontal center-justified layout">
                            <div class="access-message">You don't have access to this section!</div>
                        </div>
                    </template>
                    <template is="dom-if" if="[[isIcpCallNoteViewAllowed(app)]]">
                        <div class="horizontal justified layout" style="margin-bottom: 20px;">
                            <div>
                                <!--Spacer-->
                            </div>
                            <div>
                            <paper-dropdown-menu style="width: 250px;" no-label-float="" no-animations="">
                              <paper-listbox slot="dropdown-content" selected="{{icpMessageHistoryType}}">
                                  <paper-item name="Events">Events</paper-item>
                                  <paper-item name="Load Control">Load Control</paper-item>
                                  <paper-item name=Messages">Messages</paper-item>
                              </paper-listbox>
                            </paper-dropdown-menu>
                                <paper-button on-click="onEmailClick" raised="">
                                    <iron-icon icon="communication:email"></iron-icon>
                                    Email
                                </paper-button>
                                <paper-button raised="">
                                    <iron-icon icon="communication:dialer-sip"></iron-icon>
                                    Call
                                </paper-button>
                                <paper-button on-click="onSmsClick" raised="">
                                    <iron-icon icon="communication:textsms"></iron-icon>
                                    SMS
                                </paper-button>
                                <paper-button on-click="onAddNoteClick" raised="">
                                    <iron-icon icon="communication:comment"></iron-icon>
                                    Note
                                </paper-button>
                            </div>
                        </div>
                    </template>
                    <section >
                        <iron-pages selected="{{icpMessageHistoryType}}">
                                
                            <!-- Events Table-->
                            <div class="card-content">
                              <vaadin-grid id="shTable" items="{{data}}" theme="compact row-stripes">
                                  <vaadin-grid-sort-column header="Type" path="messageType" auto-width flex-grow="0">
                                  </vaadin-grid-sort-column>
                                  <vaadin-grid-sort-column header="Message" path="message" resizable>
                                  </vaadin-grid-sort-column>
                                  <vaadin-grid-sort-column header="Date" path="dateSent" auto-width flex-grow="0">
                                      <template>
                                          <span>{{formatDateTime(item.dateSent)}}</span>
                                      </template>
                                  </vaadin-grid-sort-column>
                              </vaadin-grid>
                            </div>

                            <!-- Load Control Table -->
                            <div class="card-content">
                              <vaadin-grid id="shTable" items="{{loadControlDetail}}" theme="compact row-stripes">
                                  <vaadin-grid-sort-column header="Device No" path="deviceNo">
                                  </vaadin-grid-sort-column>
                                  <vaadin-grid-sort-column header="Event Name" path="eventName">
                                    <template>
                                        <div style="display:inline-block">
                                            <iron-icon class\$="{{_getEventColor(item.eventName)}}" icon="{{_getEventIcon(item.eventName)}}"></iron-icon>
                                            <span>{{_getEventName(item.eventName)}}</span>
                                        </div>
                                    </template>
                                  </vaadin-grid-sort-column>
                                  <vaadin-grid-sort-column header="Event Time" path="eventTime">
                                      <template>
                                          <span>{{formatDateTime(item.eventTime)}}</span>
                                      </template>
                                  </vaadin-grid-sort-column>
                              </vaadin-grid>
                            </div>

                            <!-- Messages -->
                            <div class="card-content">
                              <vaadin-grid id="shMessages" items="{{messageData}}" theme="compact row-stripes"  on-active-item-changed="showItemDetails" selected-items="{{selectedItems}}" details-opened-items="{{selectedItems}}">
                              
                              <vaadin-grid-sort-column header="Type" path="messageType" auto-width flex-grow="0">
                              </vaadin-grid-sort-column>
                              <vaadin-grid-sort-column header="Subject" path="subject" resizable>
                                <template>
                                    <span >{{item.subject}}</span>
                                </template>
                              </vaadin-grid-sort-column>
                              <vaadin-grid-sort-column header="Date" path="dateSent" auto-width flex-grow="0">
                                  <template>
                                      <span>{{formatDateTime(item.dateSent)}}</span>
                                  </template>
                              </vaadin-grid-sort-column>
                              <template class="row-details">
                                <div class="horizontal layout justified">
                                    <div>
                                        Message Type: <span style="font-weight: bold">{{item.messageType}}</span>
                                    </div>
                                    <div>
                                        When: <span style="font-weight: bold">{{formatDateTime(item.dateSent)}}</span>
                                    </div>
                                    <div>
                                        To: <span style="font-weight: bold">{{item.toAddress}}</span>
                                    </div>
                                </div>
                                <div class="horizontal layout">
                                    <div>
                                        Subject: <span style="font-weight: bold">{{item.subject}}</span>
                                    </div>
                                </div>
                                <div class="horizontal layout">
                                    <div>
                                        Message: <span inner-h-t-m-l="{{item.message}}"></span>
                                    </div>
                                </div>
                              </template>
                            </vaadin-grid>
                            </div>
                                
                        </iron-pages>
                    </section>

                    <!-- <iron-ajax id="ajaxMessageLog" url="[[urlLogMessages]]" on-response="_onHistoryResponse" loading="{{isLoading}}"></iron-ajax> -->

                </section>
                <!--Disco/Reco-->
                <div>
                    <paper-card>
                        <div class="card-content">
                            <vaadin-grid id="shTable" items="{{discoRecos}}" theme="compact row-stripes">
                                <vaadin-grid-column header="Meter No" path="meterNo" width="13%">
                                </vaadin-grid-column>
                                <vaadin-grid-column header="Request Type" path="requestType" width="13%">
                                </vaadin-grid-column>
                                <vaadin-grid-column header="Reason Code" path="reasonCode" width="13%">
                                </vaadin-grid-column>
                                <vaadin-grid-column header="Retailer Code" path="retailerCode" width="13%">
                                </vaadin-grid-column>
                                <vaadin-grid-column header="Completed At" path="completedAt.Time" width="13%">
                                    <template>
                                        <span>{{formatDateTime(item.completedAt.Time)}}</span>
                                    </template>
                                </vaadin-grid-column>
                                <vaadin-grid-column header="Message" path="responseMessage" width="35%">
                                </vaadin-grid-column>
                                
                            </vaadin-grid>
                        </div>
                    </paper-card>
                </div>
                <div>
                  <paper-card>
                    <div class="card-content">
                        <vaadin-grid id="shTable" items="{{workOrderHistory}}" theme="compact row-stripes" on-active-item-changed="_openWo">
                            <vaadin-grid-sort-column header="No" path="no" width="20%">
                              <template>
                                <template is="dom-if" if="{{item.isEvent}}">
                                    <div style="display:inline-block">
                                        <iron-icon icon="group-work" style="color:#000fe6"></iron-icon>
                                        {{item.no}}
                                    </div>
                                  </template>
                                  <template is="dom-if" if="{{!item.isEvent}}">
                                    <div style="display:inline-block">
                                        <iron-icon icon="social:person" style="color:#000fe6"></iron-icon>
                                        {{item.no}}
                                    </div>
                                  </template>
                                </template>
                            </vaadin-grid-sort-column>
                            <vaadin-grid-sort-column header="Service Type" path="serviceType" width="10%">
                            </vaadin-grid-sort-column>
                            <vaadin-grid-sort-column header="Response Date" path="responseDateTime" width="13%">
                              <template>
                                    <span>{{formatDateTime(item.responseDateTime)}}</span>
                                </template>
                            </vaadin-grid-sort-column>
                            <vaadin-grid-sort-column header="# Affected" path="customersAffected" width="7%">
                            </vaadin-grid-sort-column>
                            <vaadin-grid-sort-column header="Resource Name" path="resourceName" width="15%">
                            </vaadin-grid-sort-column>
                            <vaadin-grid-sort-column header="Feeder" path="feederCode" width="10%">
                            </vaadin-grid-sort-column>
                            <vaadin-grid-sort-column header="Description" path="description" width="25%">
                            </vaadin-grid-sort-column>                            
                        </vaadin-grid>
                    </div>
                  </paper-card>
                </div>
            </iron-pages>
</app-header-layout>

        <!--Work Order-->
        <core-overlay id="wodetail" auto-close-disabled="true" on-wodetail-close="closeWorkOrderOverlay" on-eventdetail-open="openEventOverlay">
            <wo-detail wo-id="{{selectedServiceOrder.no}}" app="{{app}}">
            </wo-detail>
        </core-overlay>   
        <!--Fault Alert-->
        <core-overlay id="faultAlertOverlay" class="right-side-overlay" auto-close-disabled="true" on-alertdetail-close="closeEventAlertOverlay">
            <fault-alert-detail shutdown-id="{{selectedEventId}}" app="{{app}}"></fault-alert-detail>
        </core-overlay>
        <!--Customer-->
        <core-overlay id="customerdetail" auto-close-disabled="true" on-customerdetail-close="closeCustomerDetailOverlay">
            <customer-detail customer-no="{{selectedCustomerNo}}">
            </customer-detail>
        </core-overlay>
        <!--Meter-->
        <core-overlay id="meterdetail" auto-close-disabled="true" on-meterdetail-close="closeMeterDetailOverlay">
            <meter-detail meter-no="{{selectedMeterNo}}" icp-no="{{icpInfo.name}}">
            </meter-detail>
        </core-overlay>
        <paper-dialog id="dlgEmail" class="function-dialog">
            <h2>Email - <span>{{icpInfo.customerName}}</span></h2>
            <p>Email: <span>{{emailInfo.to}}</span></p>
            <paper-input label="Subject" value="{{emailInfo.subject}}"> </paper-input>
            <paper-textarea label="Comment" id="t1" value="{{emailInfo.body}}" rows="4" style=" height: 150px;"></paper-textarea>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onEmailSendClick">Email</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgSms" class="function-dialog">
            <h2>Send SMS</h2>
            <paper-input label="Mobile" value="{{smsInfo.to}}"></paper-input>
            <paper-textarea label="Message" id="t4" value="{{smsInfo.text}}" rows="4" style=" height: 150px;"></paper-textarea>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onSubmitSmsSendClick">Send SMS</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgNote" class="function-dialog">
            <h2>Add Note</h2>
            <p>Note will be added to History</p>
            <paper-input label="Subject" value="{{noteInfo.subject}}"></paper-input>
            <paper-textarea label="Comment" id="t3" value="{{noteInfo.description}}" rows="4" style=" height: 150px;"></paper-textarea>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onSubmitAddNoteClick">Add Note</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgNewCall" class="function-dialog">
             <div class="horizontal layout">
                 <template is="dom-if" if="{{!icpCallInfo.isWorkOrderRequired}}">
                    <template is="dom-if" if="{{icpCallInfo.isPartOfEvent}}">
                        <paper-card class="info">
                            <div class="card-content">This ICP is part of Event <span>{{icpCallInfo.eventId}}</span> and will be attached to that event.</div>
                        </paper-card>
                    </template>
                    <template is="dom-if" if="{{icpCallInfo.isPartOfShutdown}}">
                        <paper-card class="info">
                            <div class="card-content">This ICP is part of Planned Shutdown <span>{{icpCallInfo.shutdownId}}</span> and will be attached to that Shutdown.</div>
                        </paper-card>
                    </template>
                </template>
                <template is="dom-if" if="{{icpCallInfo.isWorkOrderRequired}}">
                    <paper-card class="info">
                        <div class="card-content">A new Event and associated Work Order will be created.</div>
                    </paper-card>
                </template>
             </div>
             <template is="dom-if" if="{{icpCallInfo.isWorkOrderRequired}}">
                <div class="horizontal layout justified">
                    <paper-checkbox checked="{{icpCallInfo.isShowOnPublicSite}}">Show on Public Site</paper-checkbox>
                    <paper-checkbox checked="{{icpCallInfo.isSendAlertEnabled}}">Send Comms</paper-checkbox>
                </div>
            </template>

            <paper-input label="New Call - Name" value="{{icpCallInfo.customerName}}"></paper-input>
            <div class="horizontal layout">
                <paper-input label="Email" value="{{icpCallInfo.email}}" class="flex-2"></paper-input>
                <paper-input label="Mobile" value="{{icpCallInfo.mobileNo}}" class="flex-1"></paper-input>
            </div>
            <div class="horizontal layout">
                <div class="flex-2">
                    <paper-input label="Call Type" value="{{searchText}}" on-input="onSearchTextInput">
                        <paper-icon-button slot="suffix" icon="arrow-drop-down" on-click="onToggleCallTypes"></paper-icon-button>
                    </paper-input>
                    <iron-collapse id="collapse" style="overflow:auto;">
                        <div>
                            <template is="dom-repeat" items="[[filterItems(callTypes,searchText)]]">
                                <paper-item style="border-bottom: solid 1px #B6B6B6" on-click="onCallTypeSelected">{{item.description}}</paper-item>
                            </template>
                        </div>
                    </iron-collapse>
                </div>
            </div>
            <div>
                <paper-checkbox checked="{{icpCallInfo.isWorkOrderRequired}}" disabled="{{_checkDisabledWorkorder(icpInfo, icpCallInfo.serviceCallType)}}">Create Work Order Event</paper-checkbox>
            </div>
            <div class="horizontal layout">
                <div class="flex-2">
                     <paper-dropdown-menu label="Premises Type" placeholder="HOUSE">
                        <paper-listbox slot="dropdown-content" selected="{{icpCallInfo.premisesType}}" attr-for-selected="data-value">
                            <paper-item data-value="BUSINESS">BUSINESS</paper-item>
                            <paper-item data-value="AGRICULTURE">Agriculture</paper-item>
                            <paper-item data-value="FARM COTTAGE">FARM COTTAGE</paper-item>
                            <paper-item data-value="HOUSE">HOUSE</paper-item>
                            <paper-item data-value="PUMP">PUMP</paper-item>
                            <paper-item data-value="RADIO SITE">RADIO SITE</paper-item>
                            <paper-item data-value="WATER PUMP">WATER PUMP</paper-item>
                            <paper-item data-value="WOOLSHED">WOOLSHED</paper-item>
                            <paper-item data-value="WORKSHOP">WORKSHOP</paper-item>
                        </paper-listbox>
                    </paper-dropdown-menu>
                </div>
                <div class="flex-1">
                   <paper-dropdown-menu label="Relationship">
                        <paper-listbox slot="dropdown-content" selected="{{icpCallInfo.relationship}}" attr-for-selected="name">
                            <paper-item name="Owner">Owner</paper-item>
                            <paper-item name="Tenant">Tenant</paper-item>
                            <paper-item name="Property Manager">Property Manager</paper-item>
                            <paper-item name="Other">Other</paper-item>
                        </paper-listbox>
                    </paper-dropdown-menu>
                </div>
            </div>
            <!--<paper-checkbox checked="{{icpCallInfo.isPropertyOwner}}">Property Owner</paper-checkbox>-->
            <paper-checkbox checked="{{icpCallInfo.isAcceptAllCharges}}">Accept All Charges</paper-checkbox>
            <paper-checkbox checked="{{icpCallInfo.isCovidQuestionsConfirmed}}" style="margin-top: 30px">All current COVID-19 or infectious disease screening questions confirmed to be of no interest</paper-checkbox>
            <paper-textarea label="Details" id="t2" value="{{icpCallInfo.details}}" rows="2"></paper-textarea>
            <paper-checkbox checked="{{icpCallInfo.isEmailUpdates}}">Email Updates</paper-checkbox>
            <paper-checkbox checked="{{icpCallInfo.isSmsUpdates}}">SMS Updates</paper-checkbox>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onNewCallSubmitClick">Create Call</paper-button>
            </div>
        </paper-dialog>
        <iron-localstorage name="cpl-icp-categories" id="lsCategories" value="{{icpCategories}}"></iron-localstorage>
        <paper-dialog id="dlgCategory" class="function-dialog">
            <paper-dropdown-menu label="Property Category" placeholder="[[icpInfo.categoryDescription]]" horizontal-align="right" style="width: 80%;" id="ddlIcpCat">
                <paper-listbox slot="dropdown-content" selected="{{icpInfo.category}}" attr-for-selected="data-value">
                    <template is="dom-repeat" items="[[icpCategories.icpCategories]]">
                        <paper-item data-value="[[item.code]]" on-click="onIcpCategorySelected">[[item.description]]</paper-item>
                    </template>
                    <!--<paper-item data-value=""></paper-item>-->
                    <!--<paper-item data-value="BUSINESS">BUSINESS</paper-item>-->
                    <!--<paper-item data-value="COWSHED">COWSHED</paper-item>-->
                    <!--<paper-item data-value="DISC">Disconnect supply for Retailer</paper-item>-->
                    <!--<paper-item data-value="DISCF">Disconnect for operations</paper-item>-->
                    <!--<paper-item data-value="DISCV">No longer used. Disc Vacant</paper-item>-->
                    <!--<paper-item data-value="DISCM">Disconnect & remove meters</paper-item>-->
                    <!--<paper-item data-value="FARM COTTAGE">FARM COTTAGE</paper-item>-->
                    <!--<paper-item data-value="HOUSE">HOUSE</paper-item>-->
                    <!--<paper-item data-value="PUMP">PUMP</paper-item>-->
                    <!--<paper-item data-value="RADIO SITE">RADIO SITE</paper-item>-->
                    <!--<paper-item data-value="WATER PUMP">WATER PUMP</paper-item>-->
                    <!--<paper-item data-value="WOOLSHED">WOOLSHED</paper-item>-->
                    <!--<paper-item data-value="WORKSHOP">WORKSHOP</paper-item>-->
                    <!--<paper-item data-value="CHURCH">Church</paper-item>-->
                    <!--<paper-item data-value="HALL">Hall</paper-item>-->
                    <!--<paper-item data-value="COMMUNITY-FACILITY">Community Facility</paper-item>-->
                    <!--<paper-item data-value="SCHOOL">School</paper-item>-->
                    <!--<paper-item data-value="SLEEP-OUT">Sleep-out</paper-item>-->
                    <!--<paper-item data-value="STREET-LIGHT">Streetlight</paper-item>-->
                </paper-listbox>
            </paper-dropdown-menu>
            <paper-checkbox checked="{{icpInfo.isDisconnected}}">Disconnected</paper-checkbox>
            <paper-dropdown-menu label="Disconnection Type" placeholder="[[icpInfo.disconnectionReason]]" horizontal-align="right" style="width: 80%;" id="ddlDiscType">
                <paper-listbox slot="dropdown-content" selected="{{icpInfo.disconnectionType}}" attr-for-selected="data-value">
                    <!--<paper-item data-value=""></paper-item>-->
                    <template is="dom-repeat" items="[[icpCategories.icpDisconnectionTypes]]">
                        <paper-item data-value="[[item.code]]" on-click="onIcpDisconnectionSelected">[[item.description]]</paper-item>
                    </template>
                </paper-listbox>
            </paper-dropdown-menu>
            <!--<paper-textarea label="Details" id="t2" value="{{icpInfo.categoryDescription}}" rows="2" ></paper-textarea>-->
            <paper-checkbox checked="{{icpInfo.isIgnoreFromRealTime}}">Ignore from Real-time Events</paper-checkbox>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onUpdateCategoryClick">Update</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgConfirmMeterActive" class="function-dialog">
            <h6>Confirm Meter Active</h6>
            <p>Are you sure you want to mark the meter as active?</p>
            <p>This will remove it from the Real-time Outages list.</p>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onConfirmMeterActiveClick">Mark Active</paper-button>
            </div>
        </paper-dialog>
        <!--Subscriber Dialog-->
         <paper-dialog id="dlgEditSubscriber" class="function-dialog">
              <div class="horizontal layout">
                <paper-input label="FirstName" value="{{subscriberInfo.firstName}}" class="flex"></paper-input>
                <paper-input label="Last Name" value="{{subscriberInfo.lastName}}" class="flex"></paper-input>
            </div>
            <paper-input label="Email" value="{{subscriberInfo.email}}" class="flex-2"></paper-input>
            <paper-input label="Mobile" value="{{subscriberInfo.phone}}" class="flex-1"></paper-input>
           
            <div class="horizontal layout">
                <div class="flex-2">
                    <paper-dropdown-menu label="Message Type" placeholder="Both">
                        <paper-listbox slot="dropdown-content" selected="{{subscriberInfo.powerOutageUpdates}}" attr-for-selected="data-value">
                            <paper-item data-value="Both">Both</paper-item>
                            <paper-item data-value="Email">Email</paper-item>
                            <paper-item data-value="Text">Text</paper-item>
                            <paper-item data-value="Neither">Neither</paper-item>
                        </paper-listbox>
                    </paper-dropdown-menu>
                </div>
                <div class="flex-1">
                    <paper-dropdown-menu label="When" placeholder="Anytime">
                        <paper-listbox slot="dropdown-content" selected="{{subscriberInfo.updateTime}}" attr-for-selected="data-value">
                            <paper-item data-value="Anytime">Anytime</paper-item>
                            <paper-item data-value="Daytime">Daytime</paper-item>
                        </paper-listbox>
                    </paper-dropdown-menu>
                </div>
            </div>
           
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onEditSubscriberSubmitClick">Post Subscriber</paper-button>
            </div>
        </paper-dialog>

        <paper-toast id="toast" text=""></paper-toast>
        <iron-localstorage name="username" use-raw="" value="{{username}}"></iron-localstorage>
        <iron-localstorage name="cpl-call-types" id="lsCallTypes" value="{{callTypes}}"></iron-localstorage>

        `;
  }

  isAllowed(app) {
    if (app) {
      return AmiBehaviors.SecurityBehavior.isConsumptionViewAllowed(app.role);
      //                    return  app.role.toUpperCase() === 'ADMINISTRATOR' || app.role.toUpperCase() === 'USER';
    } else {
      return false;
    }
  }

  pastDataChanged() {
    // this.$.lstLogs.notifyResize();
  }

  formatFilterDate(d) {
    return moment.parseZone(d).format("DD.MM.YYYY");
  }
  hasResponse(s) {
    return s.length > 0;
  }

  filterStartDateChanged(newValue) {
    this.fromDate = this.formatFilterDate(newValue);
    this.$.dlgCalFrom.close();
  }

  filterEndDateChanged(newValue) {
    this.toDate = this.formatFilterDate(newValue);
    this.$.dlgCalTo.close();
  }

  formatDate(s) {
    // TODO: Moment
    // return FormatDate(s);
    return moment.parseZone(s).format("DD MMM YYYY");
  }

  formatDateTime(s) {
    return s.includes("0001-01-01")
      ? ""
      : moment.parseZone(s).format("DD MMM YYYY HH:mm");
  }

  assetIdChanged(newValue) {
    if (newValue) {
      this._getAssetDetails();
      this.$.lsCallTypes.reload();
      this.$.lsCategories.reload();
    }
  }

  selectedTabChanged(newValue) {
    //    Consumption
    // if (newValue == 1) {
    //     //Why is this here??
    // }
    //    Consumption
    if (newValue == 2) {
      this._getPowerConsumptionByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
      if (this.selectedChart == 0) {
        this._getPowerConsumptionByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
      }
      //Dist Gen
      if (this.selectedChart == 1) {
        this._getDistGenEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
      }
      //Breached Events
      if (this.selectedChart == 2) {
        this._getVoltageBreachedEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
      }
      //Power Down
      if (this.selectedChart == 3) {
        this._getPowerDownEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
      }
      //Controlled Load
      if (this.selectedChart == 4) {
        this._getControlledLoadEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
      }
    }
    //Subscribers
    if (newValue == 3) {
      this.urlSubscribers = `/api/subscribers/icp/${this.icpInfo.name}`;
      this.$.ajaxSubscribers.generateRequest();
    }
    //History
    if (newValue == 4) {
      this.icpMessageHistoryTypeChanged(2);
      //                    this.urlLogMessages = `/api/icp/log/${this.icpInfo.name}`;
      //                    this.$.ajaxMessageLog.generateRequest();
      // if(this.icpMessageHistoryType) {
      //   this.icpMessageHistoryTypeChanged(3);
      //   if(this.icpMessageHistoryType === "Events") {
      //     this.set("data", []);
      //     // this.$.lstLogs.notifyResize();
      //     this.getHistoryEventDetail();
      //   } else {
      //     this.getLoadControl();
      //   }
      // } else {
      //   this.icpMessageHistoryType = "Events";
      // }
    }
  }

  _loadMoreHistoryData() {
    if (this.icpInfo && this.icpInfo.name) {
      this.urlLogMessages = `/api/icp/log/${this.icpInfo.name}/${this.data.length}`;
      this.$.ajaxMessageLog.generateRequest();
    }

    //                this.$.ajaxPast.url = HISTORY_URL + "/" + this.data.length;
    //                this.$.ajaxPast.generateRequest();
  }
  _onHistoryResponse(e) {
    let results = e.detail.response;

    results.forEach((r) => {
      // this.$.lstLogs.push('items', r);
      this.push("data", r);
    });
    setTimeout(() => {
      this.$.scrollThreshold.clearTriggers();
      // this.$.lstLogs.notifyResize();
    });
  }

  _getLatestLogs() {
    this.set("data", []);
    // this.$.lstLogs.notifyResize();
    this._loadMoreHistoryData();
  }

  onCustDateClick() {

    
    if (this.selectedChart == 0) {
      this._getPowerConsumptionByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
    }
    //Dist Gen
    if (this.selectedChart == 1) {
      this._getDistGenEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
    }
    //Breached Events
    if (this.selectedChart == 2) {
      this._getVoltageBreachedEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
    }
    //Power Down
    if (this.selectedChart == 3) {
      this._getPowerDownEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
    }
    //Controlled Load
    if (this.selectedChart == 4) {
      this._getControlledLoadEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
    }

    this.$.dlgfilter.close();
  }

  selectedChartChanged(newValue) {
    //    Consumption
    if(this.icpInfo.name) {
      if (newValue == 0) {
        this._getPowerConsumptionByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
      }
      //Dist Gen
      if (newValue == 1) {
        this._getDistGenEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
      }
      //Breached Events
      if (newValue == 2) {
        this._getVoltageBreachedEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
      }
      //Power Down
      if (newValue == 3) {
        this._getPowerDownEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
      }
      //Controlled Load
      if (this.selectedChart == 4) {
        this._getControlledLoadEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
      }
    }
  }

  close() {
    if (this.isFromTransformer || this.isFromSubscriber) {
      this.dispatchEvent(
        new CustomEvent("icpdetail-close", { bubbles: true, composed: true })
      );
    } else {
      this.dispatchEvent(
        new CustomEvent("assetdetail-close", { bubbles: true, composed: true })
      );
    }
  }

  showCustomerDetailOverlay() {
    this.selectedCustomerNo = this.icpInfo.customerNumber;
    this.$.customerdetail.open();
  }

  closeCustomerDetailOverlay() {
    this.$.customerdetail.close();
  }

  convertDuration(dur) {
    return this.convertDurationToString(dur);
  }

  convertDurationToString(dur) {
    let days = parseInt(dur / 3600 / 24);
    let hours = parseInt((dur / 3600) % 24);
    let minutes = parseInt((dur / 60) % 60);
    // let seconds = parseInt(dur % 60);
    if (days > 0) {
      return `${days} days, ${hours} hrs and ${minutes} mins`;
    } else {
      return `${hours} hrs and ${minutes} mins`;
    }
  }

  onToggleCallTypes() {
    this.$.collapse.toggle();
  }

  onSearchTextInput() {
    this.$.collapse.show();
  }

  filterItems(items, t) {
    if (!items) return [];
    return items.filter((i) =>
      i.description.toUpperCase().includes(t.toUpperCase())
    );
  }

  onCallTypeSelected(e) {
    let model = e.model;
    this.$.collapse.hide();
    this.set("icpCallInfo.isWorkOrderRequired", false);
    if (
      model.item.callType === "Fault" &&
      this.icpInfo.eventId === 0 &&
      this.icpInfo.shutdownId === 0
    ) {
      this.set("icpCallInfo.isWorkOrderRequired", true);
    } else if (
      model.item.callType === "Fault" &&
      (this.icpInfo.eventId > 0 || this.icpInfo.shutdownId > 0)
    ) {
      this.set("icpCallInfo.isPartOfEvent", this.icpInfo.eventId > 0);
      this.set("icpCallInfo.isPartOfShutdown", this.icpInfo.shutdownId > 0);
    } else {
      this.set("icpCallInfo.isWorkOrderRequired", false);
      this.set("icpCallInfo.isPartOfEvent", false);
      this.set("icpCallInfo.isPartOfShutdown", false);
    }
    this.set("icpCallInfo.callType", model.item.value);
    this.set("icpCallInfo.callTypeDescription", model.item.description);
    this.set("icpCallInfo.serviceCallType", model.item.callType);
    this.searchText = model.item.description;

    if ( model.item.value == 'MEMO' || model.item.value == 'DISCF'){
      this.set('icpCallInfo.isShowOnPublicSite', false);
      this.set('icpCallInfo.isSendAlertEnabled', false);
    }else{
        this.set('icpCallInfo.isShowOnPublicSite', true);
        this.set('icpCallInfo.isSendAlertEnabled', true);
    }
  }

  _checkDisabledWorkorder(icpInfo, serviceCallType) {
    // if(icpInfo && (icpInfo.eventId >0 || icpInfo.shutdownId > 0) && serviceCallType === "Fault"){
    //     return true;
    // }
    return false;
  }

  onIcpCategorySelected(e) {
    let model = e.model;
    this.$.ddlIcpCat.close();
    this.icpInfo.category = model.item.code;
    this.icpInfo.categoryDescription = model.item.description;
  }

  onIcpDisconnectionSelected(e) {
    let model = e.model;
    this.$.ddlDiscType.close();
    this.icpInfo.disconnectionType = model.item.code;
    this.icpInfo.disconnectionReason = model.item.description;
  }

  showDateFilterDialog(e) {
    this.$.dlgfilter.open();
    if(!this.fromDate || !this.toDate) {
      this.toDate = moment.parseZone().format("YYYY-MM-DD");
      let ed = moment.parseZone().add(-7,'days');
      this.fromDate = ed.format("YYYY-MM-DD");
    }
  }

  isFilterInvalid(
    fromDateInvalid,
    toDateInvalid,
    fromTimeInvalid,
    toTimeInvalid
  ) {
    if (fromDateInvalid || toDateInvalid || fromTimeInvalid || toTimeInvalid) {
      return true;
    } else {
      return false;
    }
  }

  onCalFromClick() {
    this.$.dlgCalFrom.open();
  }

  onCalToClick() {
    this.$.dlgCalTo.open();
  }

  _getAssetDetails() {
    this.clearCharts();
    if (this.assetId === null) {
      return;
    }
    this.isLoading = true;
    this.icpInfo = null;
    this.selected = 0;

    fetch(`/api/icp/summary/${this.assetId}`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((icp) => {
        this.icpInfo = icp;
        this.icpMeters = icp.icpMeters;
        this.removedMeters = icp.removedMeters;
        this.discoRecos = icp.discoRecos;
        this.workOrderHistory = icp.workOrderHistory;

        this.isLoading = false;
        if (this.isFromSubscriber) {
          this.selected = 3;
        }
      });
  }

  onBtnFilterClick(e) {
    // TODO
    // querySelectorAll('.meter-data-filter')
    //     .classes
    //     .remove("colored");
    let btn = e.currentTarget;
    dur = btn.dataset.dur;
    //                     btn.classes.add.colored;
    chartCons.clearChart();
    // this.selectedChartChanged(this.selectedChart, 0);

    this.toDate = moment.parseZone().format("YYYY-MM-DD");
    let ed = moment.parseZone().subtract(dur, 'days');
    this.fromDate = ed.format("YYYY-MM-DD");

    if (this.selectedChart == 0) {
      this._getPowerConsumptionByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
    }
    //Dist Gen
    if (this.selectedChart == 1) {
      this._getDistGenEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
    }
    //Breached Events
    if (this.selectedChart == 2) {
      this._getVoltageBreachedEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
    }
    //Power Down
    if (this.selectedChart == 3) {
      this._getPowerDownEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
    }
    //Controlled Load
    if (this.selectedChart == 4) {
      this._getControlledLoadEventsByDateRange(this.icpInfo.name, this.fromDate, this.toDate);
    }
  }

  ready() {
    super.ready();
    chartCons = new google.visualization.ColumnChart(this.$.conschart);
    chartDistGen = new google.visualization.ColumnChart(this.$.chartDistGen);
    chartPowerDown = new google.visualization.ColumnChart(
      this.$.chartPowerDown
    );
    chartControlledLoad = new google.visualization.ColumnChart(this.$.chartControlledLoad);
    // this.$.lstLogs.scrollTarget = this.$.pshp1.scroller;
    // this.$.scrollThreshold.scrollTarget = this.$.pshp1.scroller;

    const calDateParser = (dateString)=>{
      const dt = moment.parseZone(dateString);
        return {
          day: dt.date(), 
          month: dt.month(), 
          year: dt.year(),
        }
    };

    this.$.fromCal.i18n.formatDate = (date) =>  moment(date).format("DD MMM YYYY");
    this.$.fromCal.i18n.parseDate = calDateParser;
    this.$.toCal.i18n.formatDate = (date) =>  moment(date).format("DD MMM YYYY");
    this.$.toCal.i18n.parseDate = calDateParser;

    this.toDate = moment.parseZone().format("YYYY-MM-DD");
    let ed = moment.parseZone().add(-7,'days');
    this.fromDate = ed.format("YYYY-MM-DD");

  }

  clearCharts() {
    //                    chartBreach.clearChart();
    if (chartCons) {
      chartDistGen.clearChart();
      chartPowerDown.clearChart();
      chartCons.clearChart();
      chartControlledLoad.clearChart();
    }
  }

  _getVoltageBreachedEvents(icpName) {
    this.isLoading = true;
    this.csvType = "breach";
    let chart = new google.visualization.AnnotationChart(this.$.chart);
    chart.clearChart();
    fetch(`/api/icp/breach/voltage/${icpName}/${dur}`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.set("fromDateTime", data.startDate);
        this.set("toDateTime", data.endDate);
        if (data.meters.length > 0) {
          this.drawEventVoltageBreachChart(data.meters);
        }
        this.isLoading = false;
      });
  }

  drawEventVoltageBreachChart(meters) {
    let chart = new google.visualization.AnnotationChart(this.$.chart);
    chart.clearChart();

    let ds = new google.visualization.DataTable();
    ds.addColumn("datetime", "Date");

    let idx = 1;
    meters.forEach((m) => {
      const deviceNo = m.deviceNo;
      ds.addColumn("number", deviceNo + "- ST");
    });
    meters.forEach((m) => {
      const deviceNo = m.deviceNo;
      ds.addColumn("number", deviceNo + "- END");
    });

    meters.forEach((m) => {
      let sData = m.startData;

      sData.forEach((d) => {
        let rowS = new Array(2 * meters.length + 1);
        rowS[0] = new Date(d.eventDate);
        rowS[idx] = d.voltage;

        ds.addRow(rowS);
      });
      idx++;
    });

    meters.forEach((m) => {
      let eData = m.endData;
      eData.forEach((ed) => {
        let rowE = new Array(2 * meters.length + 1);
        rowE[0] = new Date(ed.eventDate);
        rowE[idx] = ed.voltage;
        ds.addRow(rowE);
      });
      idx++;
    });
    chart.draw(ds, {
      displayAnnotations: true,
      displayAnnotationsFilter: true,
      min: 0,
      max: 300,
      displayExactValues: true,
    });
  }

  drawDistGenEvents(dgResponse) {
    var ds = dgResponse.powerOutageData;

    chartDistGen.clearChart();
    if (ds.length > 0) {
      let meters = new Set();
      ds.forEach((d) => meters.add(d.deviceNo));

      let data = new google.visualization.DataTable();
      data.addColumn("datetime", "Date");
      meters.forEach((m) => {
        data.addColumn("number", m);
      });

      let idx = 1;
      meters.forEach((m) => {
        ds.forEach((d) => {
          if (m == d.deviceNo) {
            let rowS = new Array(meters.size + 1);
            rowS[0] = new Date(d.eventTime);
            rowS[idx] = d.duration / 60;

            data.addRow(rowS);
          }
        });
        idx++;
      });
      //                       var fops = new DateFormatOptions()..pattern = "dd MMM yyyy HH:mm";
      //                       var frmt = new DateFormat(fops);
      //                       frmt.format(data, 0);
      var options = {
        title: "Distributed Generation (Minutes)",
        bar: { groupWidth: "5" },
        vAxis: {
          minValue: 0,
        },
        hAxis: {
          format: "dd MMM yy HH:mm",
          gridlines: {
            count: 10,
          },
          ticks: this.getDates(
            moment(dgResponse.startDate),
            moment(dgResponse.endDate).toDate()
          ),
        },
      };
      chartDistGen.draw(data, options);
    }
  }

  _getDistGenEvents(icpName) {
    this.set("isLoading", true);
    this.set("csvType", "distgen");
    fetch(`/api/icp/distgen/${icpName}/${dur}`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.set("fromDateTime", data.startDate);
        this.set("toDateTime", data.endDate);
        this.drawDistGenEvents(data);
        this.isLoading = false;
      });
  }

  _getPowerDownEvents(icpName) {
    this.set("isLoading", true);
    this.set("csvType", "po");
    fetch(`/api/icp/po/${icpName}/${dur}`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.set("fromDateTime", data.startDate);
        this.set("toDateTime", data.endDate);
        this.drawPowerDownEvents(data);
        this.isLoading = false;
      });
  }

  drawPowerDownEvents(outageResponse) {
    var ds = outageResponse.powerOutageData;

    chartPowerDown.clearChart();
    if (ds.length > 0) {
      let meters = new Set();
      ds.forEach((d) => meters.add(d.deviceNo));

      let data = new google.visualization.DataTable();
      data.addColumn("datetime", "Date");
      meters.forEach((m) => {
        data.addColumn("number", m);
      });

      let idx = 1;
      meters.forEach((m) => {
        ds.forEach((d) => {
          if (m == d.deviceNo) {
            let rowS = new Array(meters.size + 1);
            rowS[0] = new Date(d.eventTime);
            rowS[idx] = d.duration / 60;

            data.addRow(rowS);
          }
        });
        idx++;
      });
      //                       var fops = new DateFormatOptions()..pattern = "dd MMM yyyy HH:mm";
      //                       var frmt = new DateFormat(fops);
      //                       frmt.format(data, 0);
      var options = {
        title: "Power Down (Minutes)",
        bar: { groupWidth: "5" },
        vAxis: {
          minValue: 0,
        },
        hAxis: {
          format: "dd MMM yy HH:mm",
          gridlines: {
            count: 10,
          },
          ticks: this.getDates(
            moment(outageResponse.startDate),
            moment(outageResponse.endDate).toDate()
          ),
          // viewWindowMode:'explicit',
          // viewWindow: {
          //     min: moment(this.fromDateTime).toDate(),
          //     max: moment(this.toDateTime).toDate()
          // }
        },
      };
      chartPowerDown.draw(data, options);
    }
  }

  getDates(startDate, stopDate) {
    let dateArray = new Array();
    let currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = moment(currentDate).add(1, "days").toDate();
    }
    return dateArray;
  }

  drawPowerConsumption(ds) {
    chartCons.clearChart();
    if (ds.length > 0) {
      let meters = new Set();
      ds.forEach((d) => meters.add(d.deviceNo));

      let data = new google.visualization.DataTable();
      data.addColumn("datetime", "Date");
      meters.forEach((m) => {
        data.addColumn("number", m);
      });

      let idx = 1;
      meters.forEach((m) => {
        ds.forEach((d) => {
          if (m == d["deviceNo"]) {
            let rowS = new Array(meters.size + 1); //new List(meters.length + 1);
            rowS[0] = new Date(d.eventTime);
            rowS[idx] = d.duration;
            data.addRow(rowS);
          }
        });
        idx++;
      });
      //   var fops = new DateFormatOptions()..pattern = "dd MMM yyyy HH:mm";
      //   var frmt = new DateFormat(fops);
      //   frmt.format(data, 0);
      var options = {
        title: "Consumption (kWh)",
        vAxis: {
          minValue: 0,
        },
        hAxis: {
          format: "dd MMM yy HH:mm",
          gridlines: {
            count: 10,
          },
        },
      };
      chartCons.draw(data, options);
    }
  }

  drawControlledLoad(loadResponse) {
    var ds = loadResponse.controlledLoadData;
  
    chartControlledLoad.clearChart();
    if (ds.length > 0) {
      let meters = new Set();
      ds.forEach((d) => meters.add(d.deviceNo));

      let data = new google.visualization.DataTable();
      data.addColumn("datetime", "Date");
      meters.forEach((m) => {
        data.addColumn("number", m);
      });

      let idx = 1;
      meters.forEach((m) => {
        ds.forEach((d) => {
          if (m == d['deviceNo']) {
            let rowS = new Array(meters.size + 1);
            rowS[0] = new Date(d.eventTime);
            rowS[idx] = d.duration;
            data.addRow(rowS);
          }
        });
        idx++;
      });

      var options = {
        title: "Controlled Load (Minutes)",
        bar: { groupWidth: "5" },
        vAxis: {
          minValue: 0,
        },
        hAxis: {
          format: "dd MMM yy HH:mm",
          gridlines: {
            count: 10,
          },
          ticks: this.getDates(
            moment(loadResponse.startDate),
            moment(loadResponse.endDate).toDate()
          ),
        },
      };
      chartControlledLoad.draw(data, options);
    }
  }

  _getPowerDownEventsByDateRange(icpName, from, to) {
    this.set("isLoading", true);
    this.set("csvType", "po");
    fetch(`/api/icp/po/${icpName}/from/${from}/to/${to}`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.set("fromDateTime", data.startDate);
        this.set("toDateTime", data.endDate);
        this.drawPowerDownEvents(data);
        this.isLoading = false;
      });
  }

  _getVoltageBreachedEventsByDateRange(icpName, from, to) {
    this.set("isLoading", true);
    this.set("csvType", "breach");
    let chart = new google.visualization.AnnotationChart(this.$.chart);
    chart.clearChart();
    fetch(`/api/icp/breach/voltage/${icpName}/from/${from}/to/${to}`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.set("fromDateTime", data.startDate);
        this.set("toDateTime", data.endDate);
        if (data.meters.length > 0) {
          this.drawEventVoltageBreachChart(data.meters);
        }
        this.isLoading = false;
      });
  }

  _getDistGenEventsByDateRange(icpName, from, to) {
    this.set("isLoading", true);
    this.set("csvType", "distgen");
    fetch(`/api/icp/distgen/${icpName}/from/${from}/to/${to}`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.set("fromDateTime", data.startDate);
        this.set("toDateTime", data.endDate);
        this.drawDistGenEvents(data);
        this.isLoading = false;
      });
  }

  _getPowerConsumptionByDateRange(icpName, from, to) {
    this.set("isLoading", true);
    this.set("csvType", "pc");
    fetch(`/api/icp/pc/${icpName}/from/${from}/to/${to}`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.set("fromDateTime", data.startDate);
        this.set("toDateTime", data.endDate);
        this.drawPowerConsumption(data.consumptionData);
        this.isLoading = false;
      });
  }

  _getControlledLoadEventsByDateRange(icpName, from, to) {
    this.isLoading = true;
    this.csvType = "cl";

    fetch(`/api/icp/cl/${icpName}/from/${from}/to/${to}`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.set("fromDateTime", data.startDate);
        this.set("toDateTime", data.endDate);
        this.drawControlledLoad(data);
        this.isLoading = false;
      });
  }

  _getPowerConsumption(icpName) {
    if (!this.isAllowed(this.app)) {
      return;
    }
    this.set("isLoading", true);
    this.set("csvType", "pc");

    fetch(`/api/icp/pc/${icpName}/${dur}`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.set("fromDateTime", data.startDate);
        this.set("toDateTime", data.endDate);
        this.drawPowerConsumption(data.consumptionData);
        this.isLoading = false;
      });
  }

  _getControlledLoadEvents(icpName) {
    this.isLoading = true;
    this.csvType = "cl";
    
    fetch(`/api/icp/cl/${icpName}/${dur}`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.set("fromDateTime", data.startDate);
        this.set("toDateTime", data.endDate);
        this.drawControlledLoad(data);
        this.isLoading = false;
      });
  }

  _getRealTimeEvents(icpName) {
    this.set("isLoading", true);
    fetch(`/api/icp/rt/${icpName}`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.set("realTimeOutages", data);
        this.isLoading = false;
      });
  }

  onShowOnMap() {
    this.dispatchEvent(
      new CustomEvent("show-on-map", {
        bubbles: true,
        composed: true,
        detail: {
          item: {
            lat: this.icpInfo.lat,
            lng: this.icpInfo.lng,
          },
        },
      })
    );
  }

  onEmailClick() {
    this.emailInfo = {
      id: this.assetId,
      icp: this.icpInfo.name,
      customerNo: this.icpInfo.customerNumber,
      to: this.icpInfo.email,
      subject: "",
      username: this.username,
    };
    this.$.dlgEmail.toggle();
  }

  onEmailSendClick() {
    let data = JSON.stringify(this.emailInfo);

    fetch(`/api/icp/email`, {
      credentials: "include",
      method: "POST",
      body: data,
    })
      .then((resp) => resp.json())
      .then(() => {
        //                        this.$.ajaxMessageLog.generateRequest();
        // this._getLatestLogs();
        this.showToast("Email Sent");
      })
      .catch((error) => {
        console.log("Request failed", error);
        this.showToast("Failed to Send Email");
      });
  }

  onAddNoteClick() {
    this.noteInfo = {
      id: this.assetId,
      icp: this.icpInfo.name,
      customerNo: this.icpInfo.customerNumber,
      subject: "",
      username: this.username,
    };
    this.$.dlgNote.toggle();
  }

  onShowCategoryDialog() {
    this.$.dlgCategory.toggle();
  }

  onSubmitAddNoteClick() {
    let data = JSON.stringify(this.noteInfo);

    fetch(`/api/icp/note`, {
      credentials: "include",
      method: "POST",
      body: data,
    })
      .then((resp) => resp.json())
      .then(() => {
        //                        this.$.ajaxMessageLog.generateRequest();
        // this._getLatestLogs();
        this.showToast("Note Saved");
      })
      .catch((error) => {
        console.log("Request failed", error);
        this.showToast("Failed to Save Note");
      });
  }

  onUpdateCategoryClick() {
    let data = JSON.stringify({
      icp: this.icpInfo.name,
      category: this.icpInfo.category,
      categoryDescription: this.icpInfo.categoryDescription,
      isDisconnected: this.icpInfo.isDisconnected,
      disconnectionType: this.icpInfo.isDisconnected
        ? this.icpInfo.disconnectionType
        : "",
      disconnectionReason: this.icpInfo.isDisconnected
        ? this.icpInfo.disconnectionReason
        : "",
      isIgnoreFromRealTime: this.icpInfo.isIgnoreFromRealTime,
      customerNo: this.icpInfo.customerNumber,
      username: this.username,
    });

    fetch(`/api/icp/category/update`, {
      credentials: "include",
      method: "POST",
      body: data,
    })
      .then((resp) => resp.text())
      .then(() => {
        this._getAssetDetails();
        //                        this.$.ajaxMessageLog.generateRequest();
        // this._getLatestLogs();
        this.showToast("ICP Update Posted Successfully");
      })
      .catch((error) => {
        console.log("Request failed", error);
        this.showToast("Failed ICP Update");
      });
  }

  onSmsClick() {
    this.smsInfo = {
      id: this.assetId,
      icp: this.icpInfo.name,
      customerNo: this.icpInfo.customerNumber,
      to: this.icpInfo.mobileNo,
      username: this.username,
    };

    this.$.dlgSms.toggle();
  }

  onSubmitSmsSendClick() {
    let data = JSON.stringify(this.smsInfo);

    fetch(`/api/icp/sms`, {
      credentials: "include",
      method: "POST",
      body: data,
    })
      .then((resp) => resp.json())
      .then(() => {
        //                        this.$.ajaxMessageLog.generateRequest();
        // this._getLatestLogs();
        this.showToast("SMS Sent");
      })
      .catch((error) => {
        console.log("Request failed", error);
        this.showToast("Failed Sending SMS");
      });
  }

  //            onGetLogsResponse(e) {
  //                this.set('data', e.detail.response);
  //                this.$.lstLogs.fire('iron-resize');
  //            }

  onNewCallClick() {
    this.icpCallInfo = {
      icp: this.icpInfo.name,
      customerNo: this.icpInfo.customerNumber,
      customerName: this.icpInfo.customerName,
      email: this.icpInfo.email,
      mobileNo: this.icpInfo.mobileNo,
      username: this.username,
      assetType: "ICP",
      callType: "",
      callTypeDescription: "",
      premisesType: "HOUSE",
      isPropertyOwner: false,
      isAcceptAllCharges: false,
      details: "",
      isEmailUpdates: false,
      isSmsUpdates: false,
      lat: this.icpInfo.lat,
      lng: this.icpInfo.lng,
      isWorkOrderRequired: false,
      isMedicallyDependent: this.icpInfo.isMedicallyDependent,
      feederCode: this.icpInfo.feederCode,
      transformerCode: this.icpInfo.transformerCode,
      relationship: "",
      isPartOfEvent: this.icpInfo.isPartOfEvent,
      eventId: this.icpInfo.eventId,
      isPartOfShutdown: this.icpInfo.isPartOfShutdown,
      shutdownId: this.icpInfo.shutdownId,
      icpInfo: this.icpInfo,
      serviceCallType: "",
      isShowOnPublicSite: true,
    };
    this.set("searchText", "");
    this.$.dlgNewCall.toggle();
  }

  onNewCallSubmitClick() {
    if (this.icpCallInfo.relationship == "Owner") {
      this.set("icpCallInfo.isPropertyOwner", true);
    }
    if (!this.icpCallInfo.isPartOfEvent) {
      this.set("icpCallInfo.eventId", 0);
    }
    if (!this.icpCallInfo.isPartOfShutdown) {
      this.set("icpCallInfo.shutdownId", 0);
    }

    let data = JSON.stringify(this.icpCallInfo);
    fetch(`/api/icp/calls`, {
      credentials: "include",
      method: "POST",
      body: data,
    })
      .then((resp) => resp.text())
      .then(() => {
        // this.$.ajaxMessageLog.generateRequest();
        this.showToast("New Call Created");
      })
      .catch((error) => {
        console.log("Request failed", error);
        this.showToast("Failed Creating New Call");
      });
  }

  onSetMeterActive(e) {
    //    set('showLargeMode',true);
    let model = e.model;
    this.set("selectedDeviceNo", model.get("item.deviceNo"));
    this.set("selectedEventTime", model.get("item.eventTime"));
    this.$.dlgConfirmMeterActive.open();
  }

  onConfirmMeterActiveClick() {
    let data = JSON.stringify({
      icp: this.icpInfo.name,
      deviceNo: this.selectedDeviceNo,
      eventTime: this.selectedEventTime,
      customerNo: this.icpInfo.customerNumber,
      username: this.username,
    });

    fetch(`/api/icp/rt/activate`, {
      credentials: "include",
      method: "POST",
      body: data,
    })
      .then((resp) => resp.json())
      .then(() => {
        this.$.dlgConfirmMeterActive.close();
        this._getRealTimeEvents(this.icpInfo.name);
        //                        this.$.ajaxMessageLog.generateRequest();
        // this._getLatestLogs();
        this.showToast("Meter marked as active");
      })
      .catch((error) => {
        console.log("Request failed", error);
        this.showToast("Error updating active status");
      });
  }

  showToast(text) {
    this.$.toast.show(text);
  }

  closeMeterDetailOverlay() {
    this.$.meterdetail.close();
  }

  onOpenMeterDetails(e) {
    let model = e.model;
    this.set("selectedMeterNo", model.get("item.deviceNo"));

    this.$.meterdetail.open();
  }
  onPingMeter(e) {
    let model = e.model;

    let data = JSON.stringify({
      icp: this.icpInfo.name,
      deviceNo: model.get("item.deviceNo"),
      customerNo: this.icpInfo.customerNumber,
      username: this.username,
    });

    fetch(
      `/api/ping/icp/${this.icpInfo.name}/meter/${model.get("item.deviceNo")}`,
      {
        credentials: "include",
        method: "POST",
        body: data,
      }
    )
      .then((resp) => resp.json())
      .then(() => {
        this._getRealTimeEvents(this.icpInfo.name);
        //                        this.$.ajaxMessageLog.generateRequest();
        // this._getLatestLogs();
        this.showToast("Meter Pinged, you will be notified of the result");
      })
      .catch((error) => {
        console.log("Request failed", error);
        this.showToast("Error Submitting Ping Request");
      });
  }

  onPingIcp(e) {
    let data = JSON.stringify({
      icp: this.icpInfo.name,
      customerNo: this.icpInfo.customerNumber,
      username: this.username,
    });

    fetch(`/api/ping/icp/${this.icpInfo.name}`, {
      credentials: "include",
      method: "POST",
      body: data,
    })
      .then((resp) => resp.json())
      .then(() => {
        this._getRealTimeEvents(this.icpInfo.name);
        //                        this.$.ajaxMessageLog.generateRequest();
        // this._getLatestLogs();
        this.showToast("ICP Pinged, you will be notified of the result");
      })
      .catch((error) => {
        console.log("Request failed", error);
        this.showToast("Error Submitting Ping Request");
      });
  }

  onOpenSubscriberDetails() {
    this.subscriberInfo = {
      id: 0,
      icp: `${this.icpInfo.name}`,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      firstName2: "",
      lastName2: "",
      email2: "",
      phone2: "",
      firstName_: "",
      lastName3: "",
      email3: "",
      phone3: "",
      powerOutageUpdates: "Text",
      otherUpdates: "Email",
      updateTime: "Anytime",
      updated: "",
      status: "active",
      updatedAt: "",
    };

    this.$.dlgEditSubscriber.toggle();
  }

  onEditSubscriberDetails(e) {
    let model = e.model;
    this.set("subscriberInfo", model.get("item"));
    this.$.dlgEditSubscriber.toggle();
  }

  onEditSubscriberSubmitClick() {
    let data = JSON.stringify(this.get("subscriberInfo"));

    fetch(`/api/subscriber/post`, {
      credentials: "include",
      method: "POST",
      body: data,
    })
      .then((resp) => resp.json())
      .then(() => {
        // this.$.ajaxMessageLog.generateRequest();
        this.$.ajaxSubscribers.generateRequest();
        this.showToast("Subscriber Posted");
      })
      .catch((error) => {
        console.log("Request failed", error);
        this.showToast("Failed Posting Subscriber");
      });
  }

  onChangeSubscriberStatus(e) {
    let model = e.model;
    this.set("subscriberInfo", model.get("item"));
    // let sb = model.get("item");
    this.set(
      "subscriberInfo.status",
      this.get("subscriberInfo.status") == "active" ? "inactive" : "active"
    );

    this.onEditSubscriberSubmitClick();
  }

  _isSubscriberActive(s) {
    return s == "active" ? true : false;
  }

  isSubscriberEditAllowed(app) {
    return AmiBehaviors.SecurityBehavior.isSubscriberEditAllowed(app.role);
  }

  isPingRequestAllowed(app) {
    return AmiBehaviors.SecurityBehavior.isPingRequestAllowed(app.role);
  }

  isSubscriberViewAllowed(app) {
    if (app) {
      return AmiBehaviors.SecurityBehavior.isSubscriberViewAllowed(app.role);
    } else {
      return false;
    }
  }

  isIcpCallNoteViewAllowed(app) {
    if (app) {
      return AmiBehaviors.SecurityBehavior.isIcpCallNoteAllowed(app.role);
    } else {
      return false;
    }
  }

  isNameAddressLocationViewAllowed(app) {
    if (app) {
      return AmiBehaviors.SecurityBehavior.isNameAddressLocationAllowed(
        app.role
      );
    } else {
      return false;
    }
  }

  icpMessageHistoryTypeChanged(newValue) {
    if (this.icpInfo.name) {
      if (newValue === 0) {
        this.getHistoryEventDetail();
      } else if (newValue === 1) {
        this.getLoadControl();
      } else if(newValue === 2) {
        //Messages
        this.getMessageHistory();
      }
    }
  }

  getLoadControl() {
    this.isLoading = true;
    fetch(`/api/icp/cl/${this.icpInfo.name}`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((data) => {
        this.loadControlDetail = data;
        
      }).finally(() => {
        this.isLoading = false;
      });
  }

  getHistoryEventDetail() {
    this.isLoading = true;
    fetch(`/api/icp/log/${this.icpInfo.name}/6months`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((info) => {
        this.data = info;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  getMessageHistory() {
   
    this.isLoading = true;
    fetch(`/api/icp/${this.icpInfo.name}/messages`, {
      credentials: "include",
    })
      .then((resp) => resp.json())
      .then((info) => {
        this.messageData = info;
      }).finally(() => {
        this.isLoading = false;
      });

    
  }

  // _isLoadControl(v) {
  //   return v === "Load Control" ? true : false;
  // }

  _getEventIcon(eventName) {
    let icon;
        switch (eventName) {
            case "LGIEC_CONTROLLED_LOAD_OPEN":
                icon = "image:flash-off";
                break;
            case "LGIEC_CONTROLLED_LOAD_CLOSE":
                icon = "image:flash-on";
                break;
            default:
        }
        return icon;
  }

  _getEventColor(eventName) {
    return eventName === "LGIEC_CONTROLLED_LOAD_OPEN" ? "icon-grey" : "icon-green";
  }

  _getEventName(eventName) {
    return eventName == "LGIEC_CONTROLLED_LOAD_OPEN" ? "Hot Water - Open" : "Hot Water - Close";
  }

  _calcTotalConsumption(channelTotalCons) {
    let total = 0;
    if(channelTotalCons.length > 0) {
      channelTotalCons.forEach((c) => {
        total += c.tc;
      });
      return total.toFixed(2);
    } else {
      return 0;
    }
  }


    _openWo(e) {
      const item = e.detail.value;
      this.$.shTable.selectedItems = item ? [item] : [];
      this.selectedServiceOrder = item;
    }

    _selectedServiceOrderChanged(newValue) {
      if (newValue) {
          this.$.wodetail.open();
      }
  }
  
  closeWorkOrderOverlay() {
    this.$.wodetail.close();
    this.selectedServiceOrder = null;
  }

  openEventOverlay() {
    if (this.selectedServiceOrder.eventId > 0) {
        this.set('selectedEventId', this.selectedServiceOrder.eventId);
        this.$.faultAlertOverlay.open();
    } else {
        this.showToast("No Event associated with Work Order");
    }
  }

  closeEventAlertOverlay() {
    this.set('selectedEventId', null);
    this.$.faultAlertOverlay.close();
  }

  rowDetailsRenderer(root,grid,model) {
    
    return root.replaceChildren( `<template>${model.item.deviceNo}</template>`);
  }

  showItemDetails(e){
    const item = e.detail.value;
    this.selectedItems = item ? [item] : [];
    // this.$.itemDetails.open();
  }
}
customElements.define(IcpAssetDetail.is, IcpAssetDetail);
