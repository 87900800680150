import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import "@vaadin/vaadin-grid";
import "@vaadin/vaadin-grid/vaadin-grid-column";
import "@vaadin/vaadin-grid/vaadin-grid-column-group";
import moment from 'moment';
import '@polymer/iron-icons/av-icons.js';
import '@polymer/iron-icons/iron-icons.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';

let chartRipple;
class SubstationRippleDetail extends PolymerElement {
    static get is() {
        return 'substation-ripple-detail';
    }

    static get properties() {
        return {
            app: Object,
            data: Array,
            substationPage: {
                type: Number,
                value: 0,
            },
            selectedSubstation: {
                type: Object,
                observer: 'onSelectedSubstationChanged',
            },
            fromDate: String,
            toDate: String,
        }
    }

    static get template() {
        return html`
        <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>
        <style>
            .icon-green{
              color: #66BB6A;
            }
            .icon-red{
                color: red;
            }
            .time-since {
                display: block;
                font-size: 9px;
            }

            .chart-wrapper {
                padding: 10px;
                height: 600px;
            }

            /* .bottom {
                position: absolute;
                right: 10px;
            } */

            #dlgChartRipple {
                position: fixed;
                top: 372px;
                left: 10px;
                right: 10px;
                height: 700px;
            }  

            #dlgfilter {
                position: absolute;
                width: 305px;
                height: 372px;
                right: 60px;
                top: 370px;
            } 
            
        </style>

            <vaadin-grid id="shTable" items="[[data]]" style="height: 290px; padding: 5px; margin: 5px;" theme="compact row-stripes column-borders" on-active-item-changed="openSubstation">
                <vaadin-grid-column header="Substation" path="substation" width="7.5%">
                    <template>
                        <span style="font-size: 14px;">{{item.substation}}</span>
                    </template>
                </vaadin-grid-column>
                <vaadin-grid-column header="Recorded At" path="recordedAt" width="11%">
                    <template>
                        <span style="font-size: 14px;">[[formatDateTime(item.recordedAt)]]</span>
                    </template>
                </vaadin-grid-column>
                <vaadin-grid-column-group header="Domestic Control" text-align="center">
                    <vaadin-grid-column header="1" path="is1Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is1Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel1)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="2" path="is2Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is2Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel2)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="3" path="is3Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is3Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel3)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="4" path="is4Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon  class\$="{{_getButtonColor(item.is4Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel4)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="5" path="is5Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is5Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel5)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="6" path="is6Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is6Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel6)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="7" path="is7Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is7Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel7)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="8" path="is8Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is8Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel8)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="9" path="is9Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is9Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel9)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="10" path="is10Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is10Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel10)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="11" path="is11Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is11Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel11)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="12" path="is12Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon  class\$="{{_getButtonColor(item.is12Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel12)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="13" path="is13Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is13Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel13)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="14" path="is14Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is14Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel14)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                </vaadin-grid-column-group>

                <vaadin-grid-column-group header="Street Lights" text-align="center">
                    <vaadin-grid-column header="46" path="is46Active" width="4%" text-align="center" style="background: blue;">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is46Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel46)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="47" path="is47Active" width="4%" text-align="center" >
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is47Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel47)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                </vaadin-grid-column-group>

                <vaadin-grid-column-group header="Peak Saver" text-align="center">
                    <vaadin-grid-column header="50" path="is50Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is50Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel50)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="51" path="is51Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is51Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel51)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="52" path="is52Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is52Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel52)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="53" path="is53Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is53Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel53)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="54" path="is54Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is54Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel54)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                    <vaadin-grid-column header="55" path="is55Active" width="3.35%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is55Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel55)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                </vaadin-grid-column-group>
            
                <vaadin-grid-column-group header="Night Rate" text-align="center">
                    <vaadin-grid-column header="96" path="is96Active" width="6.5%" text-align="center">
                        <template>
                            <div>
                                <iron-icon class\$="{{_getButtonColor(item.is96Active)}}" icon="av:fiber-manual-record"></iron-icon>
                                <span class="time-since">{{getDateSince(item.sinceDateChannel96)}}</span>
                            </div>
                        </template>
                    </vaadin-grid-column>
                </vaadin-grid-column-group>
        
            </vaadin-grid>
            
            <!--Chart Overlay -->
            <core-overlay id="dlgChartRipple" auto-close-disabled="true"> 
                <app-toolbar class="small" sticky>
                    <div main-title="">{{selectedSubstation.substation}} Ripple Channel Chart</div>
                    <vaadin-text-field placeholder="Placeholder" value={{formatDate(fromDate)}} readonly="" on-click="showDateFilterDialog">
                        </vaadin-text-field>
                        <span style="font-size: 18px; margin-left: 5px; margin-right: 5px;"> > </span>
                        <vaadin-text-field placeholder="Placeholder" value={{formatDate(toDate)}} readonly="" on-click="showDateFilterDialog">
                        </vaadin-text-field>
                        <paper-button on-click="showDateFilterDialog" alt="Filter" title="Filter" raised="" style="font-size: 14px">
                            <iron-icon icon="filter-list"></iron-icon>
                            Filter
                        </paper-button>
                    <paper-icon-button class="bottom" icon="close" on-click="closeChartRippleOverlay"></paper-icon-button>
                </app-toolbar>
                <div id="chartRipple" class="chart-wrapper"></div>
            </core-overlay>

            <!--Date Filter -->
            <core-overlay id="dlgfilter" autoclosedisabled="true">
                <paper-card>
                    <div class="card-content">
                        <!-- Custom Filter-->
                        <div class="horizontal justified layout">
                            <vaadin-date-picker label="From" placeholder="Placeholder" id="fromCal" value="{{fromDate}}">
                            </vaadin-date-picker>
                        </div>
                        <div class="horizontal justified layout">
                            <vaadin-date-picker label="To" placeholder="Placeholder" id="toCal" value="{{toDate}}">
                            </vaadin-date-picker>
                        </div>
                        <paper-button id="btnCustDateSearch" class="meter-data-filter" on-click="onCustDateClick" raised="">
                            Search
                        </paper-button>
                    </div>
                    <div class="card-actions" style="background-color: #f5f5f5">
                        <p>Quick Filter</p>
                        <div class="horizontal justified">
                            <paper-icon-button icon="icons:event" disabled=""></paper-icon-button>
                            <paper-button id="btnVb1d" class="meter-data-filter colored" on-click="onBtnFilterClick" data-dur="1">1 Day
                            </paper-button>
                            <paper-button id="btnVb2d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="2">2 Days
                            </paper-button>
                            <paper-button id="btnVb4d" class="meter-data-filter" data-dur="4" on-click="onBtnFilterClick">4 Days
                            </paper-button>
                        </div>
                        <div class="horizontal justified">
                            <paper-icon-button icon="icons:event" disabled=""></paper-icon-button>
                            <paper-button id="btnVb7d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="7">7 Days</paper-button>
                            <paper-button id="btnVb14d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="14">14 Days
                            </paper-button>
                            <paper-button id="btnVb30d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="30">30 Days
                            </paper-button>
                        </div>
                        <paper-button>[[startDate]]</paper-button>
                    </div>
                </paper-card>
            </core-overlay>
        `
    }

    ready() {
        super.ready();
        chartRipple = new google.visualization.Timeline(this.$.chartRipple);

        const calDateParser = (dateString)=>{
            const dt = moment.parseZone(dateString);
              return {
                day: dt.date(), 
                month: dt.month(), 
                year: dt.year(),
              }
          };
      
          this.$.fromCal.i18n.formatDate = (date) =>  moment(date).format("DD MMM YYYY");
          this.$.fromCal.i18n.parseDate = calDateParser;
          this.$.toCal.i18n.formatDate = (date) =>  moment(date).format("DD MMM YYYY");
          this.$.toCal.i18n.parseDate = calDateParser;
      
          this.toDate = moment.parseZone().format("YYYY-MM-DD");
          let ed = moment.parseZone().add(-1,'days');
          this.fromDate = ed.format("YYYY-MM-DD");
    }

    clearChart() {
        if (chartRipple) {
            chartRipple.clearChart();
        }
    }
    
    _getButtonColor(v) {
        return v == true ? "icon-green" : "icon-red";
    }

    formatDateTime(s) {
        return s.includes("0001-01-01")
            ? ""
            : moment.parseZone(s).format("DD MMM YYYY HH:mm");
    }

    formatDate(s) {
        return s.includes("0001-01-01")
            ? ""
            : moment.parseZone(s).format("DD MMM YYYY");
    }

    getDateSince(d) {
        var hrs = moment().diff(d, 'hours');
        var mins = moment().diff(d, 'minutes');
        if (mins >= 60) {
            mins = mins % 60;
        }
        return hrs + "h" + mins + "m";
    }

    openSubstation(e) {
        const item = e.detail.value;
        this.$.shTable.selectedItems = item ? [item] : [];
        this.selectedSubstation = item;
    }

    onSelectedSubstationChanged(newValue) {
        if(newValue) {
            this.$.dlgChartRipple.open();
            this.getSubstationChannelEventsByDateRange();
        }
        if (!this.selectedSubstation) {
            this.$.dlgChartRipple.close();
        }
    }

    getSubstationChannelEventsByDateRange() {
    
        fetch(`/api/substation/${this.selectedSubstation.substation}/ripple/channel/from/${this.fromDate}/to/${this.toDate}`, {
            credentials: "include",
          })
            .then((resp) => resp.json())
            .then((data) => {
              this.drawRippleChannelEvents(data);
            });
    }

    drawRippleChannelEvents(channelData) {
        chartRipple.clearChart();
        
        if(channelData) {
            let data = new google.visualization.DataTable();
            
            data.addColumn({ type: 'string', id: 'channel' });
            data.addColumn({ type: 'string', id: 'Duration' });
            data.addColumn({type: 'string', role: 'tooltip'});
            data.addColumn({ type: 'string', id: 'style', role: 'style' });
            data.addColumn({ type: 'date', id: 'Start' });
            data.addColumn({ type: 'date', id: 'End' });

            //var colors = [];

            channelData.forEach((c) => {
                let duration = c.duration;
                let hrs, mins, tooltip, formattedDuration;
                let actualOnDate = moment(c.actualOnDate).format("DD MMM");
                let actualOffDate;
                if(c.actualOffDate != "") {
                    actualOffDate = moment(c.actualOffDate).format("DD MMM");
                } else {
                    actualOffDate = moment().format("DD MMM");
                }
                if(duration % 60 == 0 && duration >= 60) {
                    if(duration == 60) {
                        tooltip = `${actualOnDate} - ${actualOffDate} | Duration: 1 hr`;
                        formattedDuration = "1 hr";
                    } else {
                        hrs = duration / 60;
                        tooltip = `${actualOnDate} - ${actualOffDate} | Duration: ${hrs} hrs`;
                        formattedDuration = `${hrs} hrs`;
                    }
                }
                else if(duration >= 60) {
                    hrs = Math.floor(duration / 60);
                    mins = duration % 60;
                    tooltip = `${actualOnDate} - ${actualOffDate} | Duration: ${hrs} hrs ${mins} mins`;
                    formattedDuration = `${hrs} hrs ${mins} mins`;

                } else {
                    mins = duration % 60;
                    tooltip = `${actualOnDate} - ${actualOffDate} | Duration: ${mins} mins`;
                    formattedDuration = `${mins} mins`;
                }
               
                let color;
                if(c.channel <= 14) {
                    color = '#FFCCBC';
                } else if (c.channel <= 47) {
                    color = '#C8E6C9';
                } else if (c.channel <= 55) {
                    color = '#BBDEFB';
                } else {
                   color = '#E1BEE7';
                }

                let row = [c.channel.toString(), formattedDuration, tooltip, color, new Date(c.chartOnDate), new Date(c.chartOffDate)];
                data.addRow(row);
            });

            var options = {
                timeline: {
                    rowLabelStyle: { fontSize: 12 }, 
                    barLabelStyle: { fontSize: 7 },
                },
                tooltip: {
                    isHtml: false,
                },
                hAxis: {
                    minValue: new Date(this.fromDate),
                    maxValue: new Date(this.toDate),
                },
            };

            chartRipple.draw(data, options);
        }    
    }

    closeChartRippleOverlay() {
        this.$.dlgChartRipple.close();
    }

    showDateFilterDialog() {
        this.$.dlgfilter.open();
        if(!this.fromDate || !this.toDate) {
            this.toDate = moment.parseZone().format("YYYY-MM-DD");
            let ed = moment.parseZone().add(-7,'days');
            this.fromDate = ed.format("YYYY-MM-DD");
        }
    }

    onBtnFilterClick(e) {
        let btn = e.currentTarget;
        let dur = btn.dataset.dur;
        
        chartRipple.clearChart();
    
        this.toDate = moment.parseZone().format("YYYY-MM-DD");
        let ed = moment.parseZone().subtract(dur, 'days');
        this.fromDate = ed.format("YYYY-MM-DD");
    
        this.getSubstationChannelEventsByDateRange();
        this.$.dlgfilter.close();
       
    }

    onCustDateClick() {
        this.getSubstationChannelEventsByDateRange();
        this.$.dlgfilter.close();
    }
    
}
customElements.define(SubstationRippleDetail.is, SubstationRippleDetail);