import '@polymer/polymer/polymer-legacy.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';

/**
* @polymer
* @extends HTMLElement
**/
class SubstationAssetDetail extends PolymerElement {

    static get is() { return 'substation-asset-detail'; }

    static get properties() {
        return {
            assetId:{
                type: Number,
                observer: 'assetIdChanged'
            },
            selected:{
                type: Number,
                value: 0
            },
            url:String
        }
    }

    static get template() {
        return html`
           <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>

        <style>
            :host {
                display: block;
                width: 100%;
                height: 100%;
                --paper-input-container-input: {
                    font-size: 13px;
                };
            }
            paper-progress {
                width: 100%;
                --paper-progress-container-color: white;
                --paper-progress-active-color: var(--paper-orange-500);
                --paper-progress-secondary-color: var(--paper-orange-100);

            }
          

        </style>

<app-header-layout has-scrolling-region="true">
        <app-header scroll-target="content" slot="header" fixed>
            <app-toolbar class="small">
                <paper-tabs selected="{{selected}}" class="flex">
                    <paper-tab>Substation Detail</paper-tab>
                    <paper-tab>History</paper-tab>


                </paper-tabs>
                <div class="bottom flex"></div>
                <paper-icon-button class="bottom " icon="close" on-click="close"></paper-icon-button>

            </app-toolbar>
        </app-header>
        
            <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>


            <iron-pages selected="{{selected}}">
                <div class="horizontal around-justified layout " style="padding: 0 20px">
                    <div class="verticle layout flex two">
                        <paper-input label="Name" value="{{subInfo.name}}" readonly="">
                            <!-- <paper-icon-button suffix icon="settings-input-antenna" on-click="onPingMeters"></paper-icon-button> -->
                        </paper-input>
                        <paper-input label="Status" value="{{subInfo.status}}" readonly=""></paper-input>
                        <paper-input label="Trans Asset Rating" value="{{subInfo.transAssetRating}}" readonly=""></paper-input>
                        <paper-input label="Segment Id" value="{{subInfo.segmentId}}" readonly=""></paper-input>
                        <paper-input label="Switch Segment" value="{{subInfo.switchSegment}}" readonly=""></paper-input>
                        <paper-input label="Switch Segment Id" value="{{subInfo.switchSegmentId}}" readonly=""></paper-input>
                        <paper-input label="Fuse Type" value="{{subInfo.fuseType}}" readonly=""></paper-input>
                    </div>
                    <div class="flex"></div>
                    <div class="verticle layout flex">
                        <paper-input label="Site Type" value="{{subInfo.siteType}}" readonly=""></paper-input>
                        <paper-input label="Is Arrestor" value="{{subInfo.isArrestor}}" readonly=""></paper-input>
                        <paper-input label="Arrestor Rating" value="{{subInfo.arrestorRating}}" readonly=""></paper-input>
                        <paper-input label="No Of Customers" value="{{subInfo.noOfCustomers}}" readonly=""></paper-input>
                        <paper-input label="Critical" value="{{subInfo.criticality}}" readonly=""></paper-input>
                        <paper-input label="Area Type" value="{{subInfo.areaType}}" readonly=""></paper-input>
                        <paper-input label="Contractor" value="{{subInfo.contractor}}" readonly=""></paper-input>
                        <paper-input label="JobRef" value="{{subInfo.jobRef}}" readonly=""></paper-input>
                    </div>
                    <div class="flex"></div>
                    <div class=" verticle layout flex two">
                        <paper-input label="Date Built" value="{{subInfo.dateBuilt}}" readonly=""></paper-input>
                        <paper-input label="Decommissioned" value="{{subInfo.subDateDecommissioned}}" readonly=""></paper-input>
                        <paper-input label="Network Survey" value="{{subInfo.subNetworkSurvey}}" readonly=""></paper-input>
                        <paper-input label="Traffic Management" value="{{subInfo.trafficManagement}}" readonly=""></paper-input>
                        <paper-input label="notes" value="{{subInfo.subNotes}}" readonly=""></paper-input>
                        <paper-input label="Feeder" value="{{subInfo.feeder}}" readonly=""></paper-input>
                        <paper-input label="Earth Tests" value="{{subInfo.earthTests}}" readonly=""></paper-input>
                        <paper-input label="Pole" value="{{subInfo.pole}}" readonly=""></paper-input>
                        <paper-input label="Transformers" value="{{subInfo.transformers}}" readonly=""></paper-input>
                        <paper-input label="Bus Bars" value="{{subInfo.busBars}}" readonly=""></paper-input>
                        <paper-input label="Switches" value="{{subInfo.switches}}" readonly=""></paper-input>
                    </div>
                </div>
                <div>History Details</div>

            </iron-pages>
</app-header-layout>

        <iron-ajax auto="" url="{{url}}" handle-as="json" last-response="{{subInfo}}" loading="{{isLoading}}"></iron-ajax>

        `;
      }

    assetIdChanged(newValue){
        console.log(`new value: ${newValue}`); // eslint-disable-line

        if(newValue!== ""){
            this.set('url',`/api/substation/summary/${this.assetId}`);
        }
        else{
            this.set('url',null);
        }
    }

    close()
    {
        this.dispatchEvent(new CustomEvent('assetdetail-close', {bubbles: true,  composed: true}));
    }


    attached() {
    }

}
customElements.define(SubstationAssetDetail.is, SubstationAssetDetail);
