const IMAGE_ROOT_URL = 'https://storage.googleapis.com/www_img';

export function getIconByAssetType(assetType, category) {
    return _getIcon(assetType, 0, category);
}

function _getIcon(assetType, count, category = '') {
    let image = IMAGE_ROOT_URL + "/assets/";

    switch (assetType) {
        case 'capacitor':
            image += 'capacitor.png';
            break;
        case 'feeder':
            image += 'feeder.png';
            break;
        case 'pillar':
            image += 'link_box_distribution_pillar.png';
            break;
        case 'icp':
            image = getIcpIconByCategory(category);
            break;
        case 'icp_ssn':
            image = getIcpSSNIconByCategory(category);
            break;
        case 'last_gasp':
            image = getLastGaspIconByCategory(category);
            break;
        case 'icp_disconnect':
            image += 'icp_disconnected.png';
            break;
        case 'icp_nic_removed':
            image += 'icp_nic_removed.png';
            break;
        case 'icp_selected':
            image += "SelectedICP.png";
            break;
        case 'pole':
            image += 'pole.png';
            break;
        case 'transformer':
            image += 'transformer.png';
            break;
        case 'substation':
            image += 'm1.png';
            break;
        case 'transformer_selected':
            image += 'transformer-selected.png';
            break;
        case 'transformer_selected_out':
            image += 'transformer-selected-out.png';
            break;
        case 'transformer_outage':
            var img = 'transformer_outage1.png';
            if (count > 15) {
                img = 'transformer_outage2.png';
            }
            if (count > 45) {
                img = 'transformer_outage3.png';
            }
            if (count == -1) {
                img = 'transformer_outage_sd.png';
            }
            image += img;
            break;
        case 'tree':
            image += 'tree.png';
            break;
        case 'light':
            image += 'light.png';
            break;
        case 'linkbox':
            image += 'linkbox.png';
            break;
        case 'oh_switch':
            image += 'oh_switch.png';
            break;
        case 'switching_station':
            image += 'switching_station.png';
            break;
        case 'cluster':
            image += 'blank.png';
            break;
        default:
            image += 'blank.png';
            break;
    }
    return image;
}


export function getLastGaspIconByCategory(category) {
    let image = IMAGE_ROOT_URL + "/assets/";

    switch (category.toString().toLowerCase()) {
        case 'business':
            image += 'business_lastgasp.png';
            break;
        case 'church':
            image += 'community_lastgasp.png';
            break;
        case 'school':
            image += 'community_lastgasp.png';
            break;
        case 'community facility':
            image += 'community_lastgasp.png';
            break;
        case 'hall':
            image += 'community_lastgasp.png';
            break;
        case 'house':
            image += 'icp_lastgasp.png';
            break;
        case 'farm cottage':
            image += 'icp_lastgasp.png';
            break;
        case 'radio site':
            image += 'icp_lastgasp.png';
            break;
        case 'sleep out':
            image += 'icp_lastgasp.png';
            break;
        case 'pump':
            image += 'pump_lastgasp.png';
            break;
        case 'water pump':
            image += 'pump_lastgasp.png';
            break;
        case 'cowshed':
            image += 'cowshed_lastgasp.png';
            break;
        case 'Agriculture':
            image += 'cowshed_lastgasp.png';
            break;
        case 'woolshed':
            image += 'shed_lastgasp.png';
            break;
        case 'workshop':
            image += 'shed_lastgasp.png';
            break;
        case 'shed':
            image += 'shed_lastgasp.png';
            break;
        case 'street light':
            image += 'streetlight_lastgasp.png';
            break;
        default:
            image += 'icp_lastgasp.png';
            break;
    }

    return image;
}

export function getIcpSSNIconByCategory(category) {
    let image = IMAGE_ROOT_URL + "/assets/";

    switch (category.toString().toLowerCase()) {
        case 'business':
            image += 'business_ssn.png';
            break;
        case 'church':
            image += 'community_ssn.png';
            break;
        case 'school':
            image += 'community_ssn.png';
            break;
        case 'community facility':
            image += 'community_ssn.png';
            break;
        case 'hall':
            image += 'community_ssn.png';
            break;
        case 'house':
            image += 'icp_ssn.png';
            break;
        case 'farm cottage':
            image += 'icp_ssn.png';
            break;
        case 'radio site':
            image += 'icp_ssn.png';
            break;
        case 'sleep out':
            image += 'icp_ssn.png';
            break;
        case 'pump':
            image += 'pump_ssn.png';
            break;
        case 'water pump':
            image += 'pump_ssn.png';
            break;
        case 'cowshed':
            image += 'cowshed_ssn.png';
            break;
        case 'Agriculture':
            image += 'cowshed_lastgasp.png';
            break;
        case 'woolshed':
            image += 'shed_ssn.png';
            break;
        case 'workshop':
            image += 'shed_ssn.png';
            break;
        case 'shed':
            image += 'shed_ssn.png';
            break;
        case 'street light':
            image += 'streetlight_ssn.png';
            break;
        default:
            image += 'icp_ssn.png';
            break;
    }

    return image;
}

export function getIcpIconByCategory(category) {
    let image = IMAGE_ROOT_URL + "/assets/";

    switch (category.toString().toLowerCase()) {
        case 'business':
            image += 'business.png';
            break;
        case 'church':
            image += 'community.png';
            break;
        case 'school':
            image += 'community.png';
            break;
        case 'community facility':
            image += 'community.png';
            break;
        case 'hall':
            image += 'community.png';
            break;
        case 'house':
            image += 'icp.png';
            break;
        case 'farm cottage':
            image += 'icp.png';
            break;
        case 'radio site':
            image += 'icp.png';
            break;
        case 'sleep out':
            image += 'icp.png';
            break;
        case 'pump':
            image += 'pump.png';
            break;
        case 'water pump':
            image += 'pump.png';
            break;
        case 'cowshed':
            image += 'cowshed.png';
            break;
        case 'Agriculture':
            image += 'cowshed_lastgasp.png';
            break;
        case 'woolshed':
            image += 'shed.png';
            break;
        case 'workshop':
            image += 'shed.png';
            break;
        case 'shed':
            image += 'shed.png';
            break;
        case 'street light':
            image += 'streetlight.png';
            break;
        case 'dg':
            image += 'icp_dg.png';
            break;
        case 'dg_ssn':
            image += 'icp_dg_ssn.png';
            break;
        case 'sd':
            image += 'icp_sd.png';
            break;
        default:
            image += 'icp.png';
            break;
    }

    return image;
}

export function showAssetIcon(assetType) {
    let isShow = false;
    switch (assetType) {
        case 'capacitor':
            isShow = true;
            break;
        case 'icp':
            isShow = true;
            break;
        case 'icp_ssn':
            isShow = true;
            break;
        case 'last_gasp':
            isShow = true;
            break;
        case 'icp_disconnect':
            isShow = true;
            break;
        case 'feeder':
            isShow = true;
            break;
        case 'tree':
            isShow = true;
            break;
        case 'transformer':
            isShow = true;
            break;
        case 'substation':
            isShow = true;
            break;
        case 'oh_switch':
            isShow = true;
            break;
        case 'pole':
            isShow = true;
            break;
        case 'linkbox':
            isShow = true;
            break;
        default:
            isShow = false;
            break;
    }
    return isShow;
}

export function showCallIcon(assetType) {
    let isShow = false;
    switch (assetType) {
        case 'icp':
            isShow = true;
            break;
        case 'icp_ssn':
            isShow = true;
            break;
        case 'last_gasp':
            isShow = true;
            break;
        case 'icp_disconnect':
            isShow = true;
            break;
        case 'address':
            isShow = true;
            break;
        case '':
            isShow = false;
            break;
        default:
            isShow = true;
            break;
    }

    let secShow = this.isCallManagerCreateCallAllowed(this.app);
    return isShow && secShow;
}