/* <link rel="import" href="../../../bower_components/paper-menu/paper-menu.html"> */
/* <link rel="import" href="../../../bower_components/moment-element/moment-with-locales-import.html"> */
/*
  FIXME(polymer-modulizer): the above comments were extracted
  from HTML and may be out of place here. Review them and
  then delete this comment!
*/
import '@polymer/polymer/polymer-legacy.js';
import moment from 'moment';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-label/iron-label.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-tooltip/paper-tooltip.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/iron-image/iron-image.js';
import '@polymer/iron-icons/notification-icons.js';
import '@polymer/iron-icons/maps-icons.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-input/paper-textarea.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-dropdown-menu/paper-dropdown-menu.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-badge/paper-badge.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/paper-dropdown-menu/paper-dropdown-menu.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-toast/paper-toast.js';
import '@polymer/paper-toggle-button/paper-toggle-button.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import '../wo-detail.js';
import '../core-overlay.js';
import '../fault-alert-detail.js';
import '../../app-security-behavior.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { PolymerElement } from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@vaadin/vaadin-date-picker/vaadin-date-picker.js';
import '@vaadin/vaadin-grid';
import '@vaadin/vaadin-grid/vaadin-grid-sort-column';
import '@vaadin/vaadin-grid/vaadin-grid-column';
import '@vaadin/vaadin-grid/vaadin-grid-selection-column';
import '../../libs/date-picker'
import '@polymer/paper-tooltip/paper-tooltip.js';


let sTimer;
const IMAGE_ROOT_URL = 'https://storage.googleapis.com/www_img';
let isPaused = true;

class DashboardDispatch extends mixinBehaviors([AmiBehaviors.SecurityBehavior], PolymerElement) {
  static get template() {
    return html`
        <style>
            :host {
                display: block;
                width: 100%;
                height: 100%;
                --paper-input-container-input: {
                    font-size: 13px;
                };
                
            }

            paper-progress {
                width: 100%;
                --paper-progress-container-color: white;
                --paper-progress-active-color: var(--paper-orange-500);
                --paper-progress-secondary-color: var(--paper-orange-100);

            }

           
            app-toolbar{
                border-bottom: solid 1px #d3d3d3;
                --app-toolbar-background: transparent;
                --app-toolbar-color: rgb(59,120,231);
                font-size: 13px;
            }

            app-toolbar .title{
                color: black;
                margin-left: 0px;
                font-size: 20px;
            }

        

            /*#chartGauge {*/
            /*height: 300px;*/
            /*width: 300px;*/
            /*}*/
            paper-card{
                margin: 4px;
            }
             /*.right-side-overlay {
            position: fixed;
            right: 0px;
            top: 0px;
            background: white;
            width: 408px;
            height: 100%;
            overflow: auto;

            box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
                  0 1px 18px 0 rgba(0, 0, 0, 0.12),
                  0 3px 5px -1px rgba(0, 0, 0, 0.4);
        }*/
        #wodetail {
            position: fixed;
            left: 0px;
            bottom: 0px;
            background: white;
            width: 100%;
            height: 500px;
            max-height: 1000px;
            overflow: auto;
        }
         paper-input.search {
            margin-bottom: 20px;
        }
        paper-button.merge {
            /*background-color: #FFCC80;*/
            margin-right:32px;
          }
           #faultAlertOverlay {
                position: fixed;
                right: 0px;
                top: 64px;
                background: white;
                width: 408px;
                height: 100%;
                overflow: auto;

            }

            paper-datatable {
                margin: 0px;
                /*--vaadin-grid-sort-column-header: {*/
                    /*display: none;*/
                /*}*/
                --paper-datatable-divider-color: transparent;
                --paper-datatable-cell: {

                    font-size: 0.9em;
                    /*font-weight: 400;*/
                    /*height: auto;*/
                    /*line-height: 1.1em;*/
                    padding: 0px;
                    margin:0px;
                }
            }

            .red{
                color: red;
            }

            #shTable{
                height: 100vh;
                font-size: 1em;
            }

            paper-button.grid {
            /* background: gray; */
            /* color: blue; */
            display:inline
        }
           


        </style>

    <app-header-layout has-scrolling-region="true">
        <app-header scroll-target="content" slot="header" fixed>
            <app-toolbar >
                <div main-title class="title" style="margin-left: 0px; margin-right:0px;">Dispatch</div>
                <paper-menu-button allow-outside-scroll="true">
                        <paper-icon-button icon="filter-list" slot="dropdown-trigger"></paper-icon-button>
                      <div slot="dropdown-content">
                            <paper-card  class="filter">
                                <div class="card-content">
                                    <!-- Custom Filter-->
                                    <div class="horizontal justified layout">
                                    <vaadin-date-picker label="From" placeholder="Placeholder" id="fromCal" value="{{fromDate}}">
                                    </vaadin-date-picker>
                                    <vaadin-date-picker label="To" placeholder="Placeholder" id="toCal" value="{{toDate}}">
                                    </vaadin-date-picker>
                                        <!-- <paper-input label="From (dd.mm.yyyy)" value="{{fromDate}}" class="flex-2" required="" maxlength="10" minlength="10" pattern="^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))\$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$" auto-validate="" error-message="Invalid Date" invalid="{{isFromDateInvalid}}"></paper-input>
                                        <paper-icon-button icon="icons:event" on-click="onCalFromClick"></paper-icon-button> -->
                                        
                                    </div>
                                    <div class="horizontal justified layout">
                                    
                                        <!-- <paper-input label="To (dd.mm.yyyy)" value="{{toDate}}" class="flex-2" required="" maxlength="10" minlength="10" pattern="^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))\$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$" auto-validate="" error-message="Invalid Date" invalid="{{isToDateInvalid}}"></paper-input>
                                        <paper-icon-button icon="icons:event" on-click="onCalToClick"></paper-icon-button> -->
                                       
                                    </div>
                                    <paper-button id="btnCustDateSearch" class="meter-data-filter" on-click="onCustDateClick" disabled="[[isFilterInvalid(isFromDateInvalid, isToDateInvalid,isFromTimeInvalid, isToTimeInvalid)]]" raised="">Search
                                    </paper-button>
                                </div>
                                <div class="card-actions" style="background-color: #f5f5f5">
                                    <p>Quick Filter - days in the future</p>
                                    <div class="horizontal justified">
                                        <paper-icon-button icon="icons:event" disabled=""></paper-icon-button>
            
                                        <paper-button id="btnVb1d" class="meter-data-filter colored" on-click="onBtnFilterClick" data-dur="1">1 Day
                                        </paper-button>
                                        <paper-button id="btnVb2d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="2">2 Days
                                        </paper-button>
                                        <paper-button id="btnVb4d" class="meter-data-filter" data-dur="4" on-click="onBtnFilterClick">4 Days
                                        </paper-button>
                                    </div>
                                    <div class="horizontal justified">
                                        <paper-icon-button icon="icons:event" disabled=""></paper-icon-button>
                                        
                                        <paper-button id="btnVb7d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="7">7 Days</paper-button>
                                        <paper-button id="btnVb14d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="14">14 Days
                                        </paper-button>
                                        <paper-button id="btnVb30d" class="meter-data-filter" on-click="onBtnFilterClick" data-dur="30">30 Days
                                        </paper-button>
                                    </div>
                                    <paper-button>[[startDate]]</paper-button>
                                </div>
                            </paper-card>
                            </div>
                    </paper-menu-button>
                <div class="flex">
                    <paper-input id="tbxSearch" placeholder="Search by Work Order No. or TX Name" class="search" value="{{searchText}}" on-input="onSearchTextInput">
                        <paper-icon-button slot="prefix" icon="search"></paper-icon-button>
                        <paper-icon-button slot="suffix" icon="communication:clear-all" on-click="onClearSearchText"></paper-icon-button>
                    </paper-input>
                </div>
                


                <!--<paper-button on-click="_onAddWorkOrder" disabled="{{isLoading}}">
                <iron-icon icon="add" ></iron-icon>
                New
                </paper-button>-->


                <!--<div class="flex"></div>-->
                
                <paper-checkbox id="cbxShowSo" checked="{{isShowSo}}">Show Service Orders</paper-checkbox>
                <template is="dom-if" if="[[isDispatchMergeAllowed(app)]]">
                <paper-button on-click="_mergeWorkOrders" alt="Merge Work Order" title="Merge Work Orders" raised="" disabled="[[!isWorkOrdersSelected]]" class="merge">
                    <iron-icon icon="communication:call-merge"></iron-icon>
                    Merge
                </paper-button>
                </template>


                <paper-dropdown-menu style="width: 250px;" no-label-float="" no-animations="">
                        <paper-listbox slot="dropdown-content" selected="{{workorderFilterType}}" attr-for-selected="name">
                            <paper-item name="Unallocated">Unallocated</paper-item>
                            <paper-item name="Active">In Progress</paper-item>
                            <paper-item name="All">All (Excluding Closed)</paper-item>
                            <paper-item name="Closed">Recently Closed</paper-item>
                            <paper-item name="incident">Closed - Incident/ Hazard</paper-item>
                            <paper-item name="furtherwork">Closed - Further Work</paper-item>
                            <paper-item name="charge">Closed - Charge</paper-item>
                            <paper-item name="temp">Closed - Temp. Restore</paper-item>
                            <paper-item name="isolated">Closed - Isolated</paper-item>
                        </paper-listbox>
                    </paper-dropdown-menu>
                

                    

                <paper-button on-click="_getWeeklyShutdowns" alt="Refresh Work Order" title="Refresh Work Orders">
                    <iron-icon icon="refresh"></iron-icon>
                    Refresh
                </paper-button>

                <!-- <paper-toggle-button checked="{{isShowAllOn}}">Show All</paper-toggle-button> -->
                <template is="dom-if" if="{{showClose}}">
                    <template is="dom-if" if="{{!isMaxed}}">
                        <paper-icon-button class="chevron" icon="expand-less" on-click="toggleNav" alt="Collapse Side Panel" title="Expand Dispatch"></paper-icon-button>
                    </template>
                    <template is="dom-if" if="{{isMaxed}}">
                        <paper-icon-button class="chevron" icon="expand-more" on-click="toggleNav" alt="Expand Side Panel" title="Compress Dispatch"></paper-icon-button>
                    </template>
                    <paper-icon-button icon="close" on-click="close"></paper-icon-button>
                </template>
            </app-toolbar>
        </app-header>

      
            <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>
            <div class="content">

                <div>
                    <!-- <paper-card style="width: 100%;">
                        <div class="card-content"> -->

                            <vaadin-grid id="shTable" items="[[filterItems(data,isShowSo)]]" theme="compact row-stripes" multi-sort selected-items="{{selectedServiceOrders}}" on-selected-items-changed="_selectedServiceOrdersChanged" on-active-item-changed="_openWo">
                                <vaadin-grid-selection-column></vaadin-grid-selection-column>
                                <vaadin-grid-sort-column header="No#" path="no" width="100px" flex-grow="0" >
                                    <template>
                                    {{formatNo(item.no)}}
                                        <!-- <paper-button class="grid">{{formatNo(item.no)}}</ class="grid"> -->
                                    </template>
                                </vaadin-grid-sort-column>
                                <vaadin-grid-sort-column header="Service Type" path="serviceType" >
                                </vaadin-grid-sort-column>
                               
                                <vaadin-grid-sort-column header="Response Date" path="responseDateTime" width="150px" sortable>
                                    <template>
                                        <span>{{_formatDate(item.responseDateTime)}}</span>
                                    </template>
                                </vaadin-grid-sort-column>
                      
                                 <vaadin-grid-sort-column header="# Affected" path="customersAffected" >
                                    <template>
                                        <!-- <paper-button class="grid" alt="ICPs Affected" title="ICPs Affected"> -->
                                        <iron-icon icon="social:people"></iron-icon>
                                        {{item.customersAffected}}
                                        <!-- </paper-button> -->

                                    </template>
                                </vaadin-grid-sort-column>

                                <vaadin-grid-sort-column header="# Calls" path="noOfCalls" >
                                    <template>
                                        <!-- <paper-button class="grid" alt="Calls" title="Calls"> -->
                                            <iron-icon icon="settings-phone"></iron-icon>
                                            {{item.noOfCalls}}
                                        <!-- </paper-button> -->
                                    </template>
                                </vaadin-grid-sort-column>
                                <vaadin-grid-sort-column header="Target" path="targetResolutionPriority" >
                                    <template>

                                        <!-- <paper-button class="grid" alt="Target" title="Target"> -->
                                            <!-- <template is="dom-if" if="{{item.isMedicallyDependent}}">
                                                <paper-icon-button class="red" icon="maps:local-hospital" alt="Medically Dependent" title="Medically Dependent"></paper-icon-button>
                                            </template> -->
                                            P<span>{{item.targetResolutionPriority}}</span>
                                        <!-- </paper-button> -->

                                    </template>
                                </vaadin-grid-sort-column>

                                <vaadin-grid-sort-column header="Alert" path="isHazard" flex-grow="0" >
                                        <template>
                                            <template is="dom-if" if="{{item.isMedicallyDependent}}">
                                                <div style="display:inline-block">
                                                    <iron-icon icon="maps:local-hospital" style="color:red"></iron-icon>
                                                    <paper-tooltip>Medically Dependent</paper-tooltip>
                                                    <!-- <paper-icon-button class="red" icon="maps:local-hospital" alt="Medically Dependent" title="Medically Dependent"></paper-icon-button> -->
                                                    
                                                </div>
                                                
                                            </template>
                                            <template is="dom-if" if="{{_showWarning(item)}}">
                                                <div style="display:inline-block">
                                                    <iron-icon icon="warning" style="color:#E65100"></iron-icon>
                                                    <paper-tooltip>Hazard or VIP</paper-tooltip>
                                                </div>
                                            </template>
                                            
                                            
                                                
                                        </template>
                                    </vaadin-grid-sort-column>

                                <vaadin-grid-sort-column header="Resource" path="resourceName" >
                                    <template><span class="sss">{{item.resourceName}}</span></template>
                                </vaadin-grid-sort-column>
                             
                                <vaadin-grid-sort-column header="Status" path="status" flex-grow="0" >
                                    <template>
                                        <div style="display:inline-block">
                                            <iron-icon  icon="{{_getStatusIcon(item.status)}}"></iron-icon>
                                            <paper-tooltip >{{item.status}}</paper-tooltip>
                                        </div>
                                    </template>
                                </vaadin-grid-sort-column>
                                <vaadin-grid-sort-column header="Type" path="categoryDescription">
                                    <template>
                                        <div style="display:inline-block;">
                                            <iron-image style="width:16px; height:16px;" sizing="contain" src="{{getLastGaspIconByCategory(item.categoryDescription)}}"></iron-image>
                                            {{_computeTypeDescription(item)}}
                                            <paper-tooltip >{{item.categoryDescription}}</paper-tooltip>
                                        </div>

                                    </template>
                                </vaadin-grid-sort-column>
                                <vaadin-grid-sort-column header="Feeder" path="feederCode" >
                                    <template>
                                        <div style="display:inline-block">
                                            <span>{{item.feederCode}}</span>
                                            <paper-tooltip>{{item.feederName}}</paper-tooltip>
                                        </div>
                                    </template>
                                </vaadin-grid-sort-column>
                                <vaadin-grid-sort-column header="Description" path="description" width="200px" >
                                </vaadin-grid-sort-column>

                                <vaadin-grid-sort-column header="Street" width="200px" >
                                    <template>
                                        {{item.address.unit}} {{item.address.number}} {{item.address.street}}
                                    </template>
                                </vaadin-grid-sort-column>
                           
                                <vaadin-grid-sort-column header="Town"  >
                                    <template>
                                        {{item.address.town}}
                                    </template>
                                </vaadin-grid-sort-column>
                              

                            </vaadin-grid>
                        <!-- </div>
                        
                    </paper-card> -->
                </div>



            </div>
        </app-header-layout>

        <!-- Workorder Overlay-->

        <core-overlay id="wodetail" auto-close-disabled="true" on-wodetail-close="closeWorkOrderOverlay" on-eventdetail-open="openEventOverlay">
            <wo-detail wo-id="{{selectedServiceOrder.no}}" app="{{app}}">
            </wo-detail>
        </core-overlay>

        <!--<core-overlay id="shutdownAlertOverlay" class="right-side-overlay" auto-close-disabled="true" on-shutdowndetail-close="closeShutdownAlertOverlay" on-show-asset-detail="onShowAssetDetail">
           <shutdown-detail shutdown-id="{{selectedServiceOrder.id}}"></shutdown-detail>
        </core-overlay>-->

        <!-- Shutdown Notification Overlay-->
        <core-overlay id="faultAlertOverlay" class="right-side-overlay" auto-close-disabled="true" on-alertdetail-close="closeEventAlertOverlay">
            <fault-alert-detail shutdown-id="{{selectedEventId}}" app="{{app}}"></fault-alert-detail>
        </core-overlay>

        <paper-dialog id="dlgConfirmMerge" class="function-dialog">
            <h1>Confirm Merge Work Orders</h1>
            <p>Are you sure you want to Merge the selected Work Orders into one?</p>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="_onConfirmMergeClick">Confirm</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgResourceAllocate">
            <h3>Allocate Resource</h3>
            <div>
                Are you sure you want to allocated Work Order <span>{{allocateResourceSo.no}}</span> <br>to <span>{{allocateResourceSo.allocatedResource.resourceName}}</span>
            </div>
            <paper-dialog-scrollable>
                Description: <span>{{allocateResourceSo.description}}</span>
            </paper-dialog-scrollable>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                 <paper-button dialog-confirm="" autofocus="" on-click="onConfirmResourceAllocationClick" style="background-color: #FFA000;" raised="">Confirm</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgResourceAllocateWarning">
            <h3>Allocated Warning</h3>
            <div>
                This Work Order <span>{{allocateResourceSo.no}}</span> is allocated to <span>{{allocateResourceSo.resourceName}}</span>.
                <p>Are you sure you want to re-allocated to <span>{{allocateResourceSo.allocatedResource.resourceName}}</span></p>
            </div>
            <paper-dialog-scrollable>
                Description: <span>{{allocateResourceSo.description}}</span>
            </paper-dialog-scrollable>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onConfirmResourceAllocationClick" style="background-color: #FFA000;" raised="">Confirm</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgInvalidMerge" class="function-dialog">
            <h1>Invalid Merge</h1>
            <p>Merging of the selected Service Orders is invalid.</p>
            <p>They belong to different Feeders.</p>
            <div class="buttons">
                <paper-button dialog-dismiss="">CLOSE</paper-button>
                <!-- <paper-button dialog-confirm="" autofocus="" on-click="_onConfirmMergeClick">Confirm</paper-button> -->
            </div>
        </paper-dialog>

       


        <iron-ajax id="ajxDispatch" url="/api/so/filter/[[workorderFilterType]]/from/[[fromDate]]/to/[[toDate]]" handle-as="json" last-response="{{data}}" loading="{{isLoading}}"></iron-ajax>

        <iron-ajax id="ajxSearch" url="/api/so/search/[[searchText]]" handle-as="json" last-response="{{data}}" loading="{{isLoading}}"></iron-ajax>

        <iron-ajax id="ajxMergeWos" url="/api/so/merge/[[app.username]]" method="POST" handle-as="json" content-type="application/json" body="{{selectedServiceOrders}}" on-response="_onMergeResponse" on-error="_onMergeError" loading="{{isLoading}}"></iron-ajax>

        <paper-toast id="toast" text=""></paper-toast>
        <iron-localstorage name="username" use-raw="" value="{{username}}"></iron-localstorage>
`;
  }

    static get is() { return 'dash-dispatch'; }

    static get properties() {
        return {
            app: Object,
            activated: {
                type: Boolean,
                observer: 'activatedChanged'
            },
            url: {
                type: String
            },
            showClose: {
                type: Boolean,
                value: false
            },
            isMaxed: {
                type: Boolean,
                value: false
            },
            isLoading: {
                type: Boolean,
                value: false
            },
            range: {
                type: String,
                value: '7d'
            },
            isShowAllOn: {
                type: Boolean,
                value: false,
                observer: '_isShowAllOnChanged'
            },
            workorderFilterType: {
                type: String,
                value: "Unallocated",
                observer: 'workorderFilterTypeChanged'
            },
            // shutdownStartDate: {
            //     type:String,
            //     observer: '_onShutdownStartDateChanged'
            // },
            selectedServiceOrder: {
                type: Object,
                observer: '_selectedServiceOrderChanged'
            },
            selectedServiceOrders: {
                type: Array,
                observer: '_selectedServiceOrdersChanged'
            },
            isShowSo: {
                type: Boolean,
                //   observer:'_isCheckedSoChanged'
            },
            draggedResource: {
                type: Object,
                observer: 'draggedResourceChanged'
            },
            selectedWoNos: {
                type: Array,
                observer: '_selectedWoNosChanged'
            },
            isWorkOrdersSelected: Boolean,
            woId: Number,
            generateRowCss: {
                type: Object, //this is optional you can skip this also
                value: () => {
                    return (item) => {
                        //                                    if(item.isMedicallyDependent){
                        //                                        return 'background:#EF5350;';
                        //                                    }
                        if (item.isEntireFeeder) {
                            return 'background:#e8f5e9;';
                        }
                        if (item.serviceType == "NOPWR"
                            || item.serviceType == "PPWR"
                            || item.serviceType == "FLICK"
                            || item.serviceType == "HOT_W"
                            || item.serviceType == "RECON") {
                            return 'background:#e3f2fd;';
                        }
                        if (item.serviceType == "LINES"
                            || item.serviceType == "USAFE"
                            || item.serviceType == "VMVA") {
                            return 'background:#ffebee;';
                        }

                        return 'background:#FFFFFF;';

                    }
                }
            },
            showSoFilter: {
                value: () => {
                    return (item, key, items) => {
                        if (document.querySelector("#cbxShowSo").checked) {
                            return true;
                        }
                        return item.no.includes("WO");

                    }
                }
            }

        }
    }
    constructor(){
        super();
        moment.locale('en-NZ');
        
        // var datepicker = document.querySelectorAll('vaadin-date-picker');
        
      
        //     datepicker.i18n = {
        //         week: 'Week',
        //         calendar: 'Calendar',
        //         clear: 'Clear',
        //         today: 'Today',
        //         cancel: 'Cancel',
        //         firstDayOfWeek: 1,
        //         monthNames:
        //             'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
        //         weekdays: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
        //         weekdaysShort: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
        //         formatDate: function(date) {
        //             // Sugar Date expects a native date. The `date` is in format `{ day: ..., month: ..., year: ... }`
        //             return moment(date).format("DD MMM YYYY");//Sugar.Date.format(Sugar.Date.create(date), '{short}');
        //         },
        //         formatTitle: function(monthName, fullYear) {
        //             return monthName + ' ' + fullYear;
        //         },
        //         parseDate: function(dateString) {
        //             return moment.parseZone(dateString).format("DD MMM YYYY");
        //             // const date = Sugar.Date.create(dateString);
        //             // return {
        //             // day: date.getDate(),
        //             // month: date.getMonth(),
        //             // year: date.getFullYear()
        //             // };
        //         }
        //     }
       
    }

    ready() {
        // this.shutdownStartDate = moment().startOf('isoweek').format('DD MMM YYYY');
        super.ready();
        setInterval(() => {
            if (!isPaused && !this.isWorkOrdersSelected) {
                this._getWeeklyShutdowns();
            }
        }, 120000);

        const calDateParser = (dateString)=>{
            
            const dt = moment.parseZone(dateString);
            return {
                day: dt.date(), 
                month: dt.month(), 
                year: dt.year()}
        };

        this.$.fromCal.i18n.formatDate = (date) =>  moment(date).format("DD MMM YYYY");
        this.$.fromCal.i18n.parseDate = calDateParser;
        this.$.toCal.i18n.formatDate = (date) =>  moment(date).format("DD MMM YYYY");
        this.$.toCal.i18n.parseDate = calDateParser;

        // moment.locale('en-NZ');
        // var datepicker = this.$.fromCal;//document.querySelector('vaadin-date-picker');
        // datepicker.i18n = {
        //     week: 'Week',
        //     calendar: 'Calendar',
        //     clear: 'Clear',
        //     today: 'Today',
        //     cancel: 'Cancel',
        //     firstDayOfWeek: 1,
        //     monthNames:
        //         'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
        //     weekdays: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
        //     weekdaysShort: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
        //     formatDate: function(date) {
        //         // Sugar Date expects a native date. The `date` is in format `{ day: ..., month: ..., year: ... }`
        //         return moment(date).format("D MMM YYYY");//Sugar.Date.format(Sugar.Date.create(date), '{short}');
        //     },
        //     formatTitle: function(monthName, fullYear) {
        //         return monthName + ' ' + fullYear;
        //     },
        //     parseDate: function(dateString) {
        //         // const date = Sugar.Date.create(dateString);
        //         // return {
        //         // day: date.getDate(),
        //         // month: date.getMonth(),
        //         // year: date.getFullYear()
        //         // };
        //     }
        // }


    }
    draggedResourceChanged(nDraggedResource) {
        //  console.log(nDraggedResource);
        let rows = document.querySelectorAll('.sss');//Polymer.dom(this.root).querySelectorAll('tr');
        //  console.log(rows);
        rows.forEach((col) => {
            let tr = col.parentNode.parentElement.parentElement.parentElement;
            let origColor = tr.style.background;

            tr.addEventListener('dragover', (e) => {
                if (e.preventDefault) {
                    e.preventDefault(); // Necessary. Allows us to drop.
                }

                e.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.

                return false;
            }, false);

            tr.addEventListener('dragenter', () => {
                //  console.log(tr);
                // this.classList.add('over');
                tr.style.background = "#fff59d";
            }, false);

            tr.addEventListener('dragleave', () => {
                tr.style.background = origColor;
                //  console.log(tr);

            }, false);

            tr.addEventListener('drop', (e) => {
                if (e.stopPropagation) {
                    e.stopPropagation(); // stops the browser from redirecting.
                }
                tr.style.background = origColor;
                // console.log( tr.dataset.key);
                let key = tr.dataset.key.replace("#", "");
                let so = this.data[key];
                so.allocatedResource = nDraggedResource;
                // console.log(so);
                this.allocateResourceSo = so;
                if (so.status == "SCHEDULED") {
                    this.$.dlgResourceAllocate.open();
                } else {
                    this.$.dlgResourceAllocateWarning.open();
                }

                return false;
            }, false);
            // console.log(col.parentNode.parentNode.parentNode);

        });
    }

    showDateFilterDialog() {
        this.$.dlgfilter.open();
    }
    onConfirmResourceAllocationClick() {
        let so = this.allocateResourceSo;
        let data = JSON.stringify({
            no: so.no,
            resourceNo: so.allocatedResource.resourceNo,
            resourceName: so.allocatedResource.resourceName,
            username: this.username
        });

        fetch(`/api/so/${so.no}/allocate`, {
            credentials: 'include',
            method: 'POST',
            body: data
        })
            .then(resp => resp.json())
            .then(() => {

                this.$.ajxDispatch.generateRequest();
                this.showToast("Resource Allocated");
            })
            .catch((error) => {
                console.log('Request failed', error);
                this.showToast("Failed to Allocate Resource");
            });
    }



    activatedChanged(newValue) {
        isPaused = !newValue;
        if (newValue) {
            this.fromDate = moment.parseZone().add(-30,'days').format("YYYY-MM-DD");
            let ed = moment.parseZone().add(1,'days');
            this.toDate = ed.format("YYYY-MM-DD");
            this._getWeeklyShutdowns()
        }
    }
    _selectedServiceOrderChanged(newValue) {
        if (newValue) {
            // this.woId = this.selectedServiceOrder.no;
            this.$.wodetail.open();
        }
    }
    _isShowAllOnChanged(newValue) {
        if (newValue) {
            this.range = "ALL"
        } else {
            this.range = "7d"
        }
        this._getWeeklyShutdowns();

    }
    workorderFilterTypeChanged(newValue) {
        this._getWeeklyShutdowns();

    }

    _selectedServiceOrdersChanged(newValue) {
        if (this.selectedServiceOrders && this.selectedServiceOrders.length > 1) {
            let allSched = true;
            //Check if all SCHEDULED
            let idx = 0;
            this.selectedServiceOrders.forEach((it) => {
                if (it.status == "CLOSED") {
                    allSched = false;
                }

                if (idx > 0 && it.status != "SCHEDULED") {
                    allSched = false;
                }
                idx++;
            });
            if (allSched) {
                this.isWorkOrdersSelected = true;
            } else {
                this.isWorkOrdersSelected = false;
            }

        }
        else {
            this.isWorkOrdersSelected = false;
        }
    }

    _selectedWoNosChanged(elements) {
        this.set('data', []);
        this.$.shTable.notifyResize();
        if (elements.length > 0 ) {
            this.isShowSo = true;
            // let data = elements.join();
            fetch(`/api/sos/nos/${elements}`, {
                credentials: 'include',
                method: 'GET'
            })
                .then(resp => resp.json())
                .then(json => {
                    this.set('data', json);
                    this.$.shTable.notifyResize();
    
                    // this.$.ajxDispatch.generateRequest();
                    // this.showToast("Resource Allocated");
                })
                .catch((error) => {
                    console.log('Request failed', error);
                    // this.showToast("Failed to Allocate Resource");
                });

        }
    }

    _formatDate(s) {
        return s ? moment.parseZone(s).format('DD.MM.YY HH:mm') : "";
    }

    _getWeeklyShutdowns() {
        this.set('data', []);
        this.$.shTable.selectedItems = [];
        // this.$.shTable.deselectAll(false);
        // this.set('url',`/api/so/latest`);
        if(this.fromDate  && this.toDate){
            this.$.ajxDispatch.generateRequest();
        }
        

    }
  

    closeWorkOrderOverlay() {
        this.$.shTable.deselectItem(this.selectedServiceOrder);
        // this.selectedServiceOrders = null;
        this.$.wodetail.close();
        this.selectedServiceOrder = null;
        this.$.shTable.render();
    }

    onSearchTextInput() {
        // this.$.dlgSearch.open();
        isPaused = true;
        if (sTimer !== null) {
            clearTimeout(sTimer);
        }
        sTimer = setTimeout(() => {
            if (this.searchText.length > 3) {

                this.set('data', []);
                //                            this.set('url',`/api/so/search/${this.searchText}`);
                this.$.ajxSearch.generateRequest();

            }
        }, 1500);
    }

    onClearSearchText() {
        this.searchText = "";
        isPaused = false;
        this._getWeeklyShutdowns();

    }

    _openWo(e) {
        // e.stopPropagation();
        // e.preventDefault();
        // console.debug(e);
        const item = e.detail.value;
        this.$.shTable.selectedItems = item ? [item] : [];
        this.selectedServiceOrder = item;
    
    }


    _mergeWorkOrders() {

        let firstFeederCode = this.selectedServiceOrders[0].feederCode;
        // let firstAssetType = this.selectedServiceOrders[0].assetType;
        let valid = true;
        // if(firstAssetType == "TRANSFORMER" || firstAssetType == "FEEDER"){
            
        // }
        this.selectedServiceOrders.forEach((so) => {
            if (so.feederCode !== firstFeederCode  && so.feederCode.trim() != '') {
                valid = false;
                return;
            }
        });
        
        if(valid){
            this.$.dlgConfirmMerge.open();
        }else{
            this.$.dlgInvalidMerge.open();
        }
        
    }

    _onConfirmMergeClick() {
        this.$.ajxMergeWos.generateRequest();
    }
    _onMergeResponse(e) {
        this.$.toast.show("Merged Work Orders, Refreshing...");
        
        this.selectedServiceOrders.forEach((so) => {
            this.$.shTable.deselectItem(so);
        });
        // this.$.shTable.deselectItem(this.selectedServiceOrder);
        this.$.shTable.selectedItems = [];
        this.selectedServiceOrders = [];
        this._getWeeklyShutdowns();
    }
    _onMergeError(e) {
        this.$.toast.show("Failed to Merge Work Orders!");
    }

    openEventOverlay() {

        if (this.selectedServiceOrder.eventId > 0) {
            this.set('selectedEventId', this.selectedServiceOrder.eventId);
            this.$.faultAlertOverlay.open();
        } else {
            this.showToast("No Event associated with Work Order");
        }
    }
    showToast(text) {
        this.$.toast.show(text);
    }
    closeEventAlertOverlay() {
        this.set('selectedEventId', null);
        this.$.faultAlertOverlay.close();
    }

    showSoFilter(item, key, items) {
        return item.No.includes("SO");

    }

    _isCheckedSoChanged(newValue) {
        this.$.shTable.reload();
    }

    filterItems(items, t) {
        if (!items) return [];
        if (t) {
            return items;
        }
        return items.filter((i) => i.no.includes("WO"));

    }

    _getStatusIcon(v) {
        let icon = "warning"
        switch (v) {
            case "ACCEPT":
                icon = "assignment-ind";
                break;
            case "ON SITE":
                icon = "store";
                break;
            case "ON HOLD":
                icon = "error-outline";
                break;
            case "CLOSED":
                icon = "done-all";
                break;
            case "DECLINED":
                icon = "block";
                break;
            default:
        }
        return icon;
    }

    _showWarning(item){

        if (item.isHazard || item.noOfCustomerVipOrKeyAccount>0){
            return true;
        }
        return false;
    }

    _getHazardIcon(v) {
        if (v) {
            return "warning"
        }
        return "";
    }

    getLastGaspIconByCategory(category) {
        let image = IMAGE_ROOT_URL + "/assets/";
        if (category == "TRANSFORMER") {
            return image += 'transformer.png';
        }
        if (category == "FEEDER") {
            return image += 'feeder_new.png';
        }

        switch (category.toLowerCase()) {
            case 'business':
                image += 'business_lastgasp.png';
                break;
            case 'church':
                image += 'community_lastgasp.png';
                break;
            case 'school':
                image += 'community_lastgasp.png';
                break;
            case 'community facility':
                image += 'community_lastgasp.png';
                break;
            case 'hall':
                image += 'community_lastgasp.png';
                break;
            case 'house':
                image += 'icp_lastgasp.png';
                break;
            case 'farm cottage':
                image += 'icp_lastgasp.png';
                break;
            case 'radio site':
                image += 'icp_lastgasp.png';
                break;
            case 'sleep out':
                image += 'icp_lastgasp.png';
                break;
            case 'pump':
                image += 'pump_lastgasp.png';
                break;
            case 'water pump':
                image += 'pump_lastgasp.png';
                break;
            case 'Agriculture':
                image += 'cowshed_lastgasp.png';
                break;
            case 'woolshed':
                image += 'shed_lastgasp.png';
                break;
            case 'workshop':
                image += 'shed_lastgasp.png';
                break;
            case 'shed':
                image += 'shed_lastgasp.png';
                break;
            case 'street light':
                image += 'streetlight_lastgasp.png';
                break;
            default:
                image += 'icp_lastgasp.png';
                break;
        }

        return image;
    }
    _computeTypeDescription(item) {
        if (item.assetType == "TRANSFORMER") {
            return item.transformerCount == 1 ? item.icp : `M. ${item.transformerCount}`;
        }
        return item.categoryDescription;

    }

    close() {

        this.dispatchEvent(new CustomEvent('dispatch-close', { bubbles: true, composed: true }));
    }

    toggleNav() {
        if (this.isMaxed) {
            // this.shadowRoot.querySelector('#dispatchOverlay').style.height = "";
            this.dispatchEvent(new CustomEvent('dispatch-min', { bubbles: true, composed: true }));
        } else {
            this.dispatchEvent(new CustomEvent('dispatch-max', { bubbles: true, composed: true }));
            // this.shadowRoot.querySelector('#dispatchOverlay').style.height = "100%";
        }
        this.set('isMaxed', !this.isMaxed);
    }

    formatNo(s) {
        return s.substring(2, s.length);
    }

    isDispatchMergeAllowed(app) {
        return AmiBehaviors.SecurityBehavior.isDispatchMergeAllowed(app.role);

    }

    onBtnFilterClick(e) {
        let btn = e.currentTarget;
        let dur = btn.dataset.dur;
        this.fromDate = moment.parseZone().format("YYYY-MM-DD");
        let ed = moment.parseZone().add(dur,'days');
        this.toDate = ed.format("YYYY-MM-DD");
        this.$.ajxDispatch.generateRequest();
        // chartCons.clearChart();
        // this._getPowerConsumption(this.transInfo.name);
    }

    onCustDateClick() {
        this.$.ajxDispatch.generateRequest();
    }
}
customElements.define(DashboardDispatch.is, DashboardDispatch);
