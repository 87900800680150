import '@polymer/polymer/polymer-legacy.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
import { PolymerElement } from '@polymer/polymer/polymer-element.js';
/**
* @polymer
* @extends HTMLElement
**/
class AppStorage extends PolymerElement {
  static get template() {
    return html`
<iron-localstorage name="cpl-vehicle-positions" value="{{app.vehPositions}}"></iron-localstorage>
<iron-localstorage name="cpl-so" id="lsSos" value="{{app.sos}}"></iron-localstorage>
`;
  }

  static get is() { return 'app-storage'; }

  static get properties() {
    return {
      app: {
          type: Object,
          notify: true
      }
    }
  }
}
customElements.define(AppStorage.is, AppStorage);
