/* <link rel="import" href="../../bower_components/moment-element/moment-with-locales-import.html"> */
/*
  FIXME(polymer-modulizer): the above comments were extracted
  from HTML and may be out of place here. Review them and
  then delete this comment!
*/
import '@polymer/polymer/polymer-legacy.js';
import moment from 'moment';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/iron-list/iron-list.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-dropdown-menu/paper-dropdown-menu.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-input/paper-textarea.js';
import '@polymer/paper-toast/paper-toast.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';


/**
* @polymer
* @extends HTMLElement
**/
class MeterDetail extends PolymerElement {

    static get is() { return 'meter-detail'; }

    static get properties() {
        return {
            meterNo: {
                type: String,
                observer: 'meterNoChanged'
            },
            selected: {
                type: Number,
                value: 0
            },
            url: {
                type: String
            },
            icpNo: {
                type: String
            },
            username: {
                type: String
            },
            isInputInvalid: {
                type: Boolean,
                value: true
            },
            dateRemoved: {
                type: String
            },
            removeReason: {
                type: String
            },
            removeNote: {
                type: String
            },
            info: {
                type: Object
            },
            icpInfo: {
                type: Object
            },
            icpCategories: Object,
        }
    }

    static get template() {
        return html`
          <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>
 
        <style>
        :host {
            display: block;
            width: 100%;
            height: 100%;
            --paper-input-container-input: {
                font-size: 13px;
            }
            ;
        }
        
        paper-progress {
            width: 100%;
            --paper-progress-container-color: white;
            --paper-progress-active-color: var(--paper-orange-500);
            --paper-progress-secondary-color: var(--paper-orange-100);
        }
        
       
        
        paper-dialog {
            bottom: 50px;
            width: 400px;
            height: 410px;
            overflow: auto;
        }
        
        paper-dropdown-menu {
            width: 100%;
            --paper-dropdown-menu-input: {
                font-size: 12px;
            }
            ;
            /*--paper-dropdown-menu-icon:{*/
            /*color: white;*/
            /*};*/
            /*--paper-input-container-underline: {*/
            /*visibility:hidden;*/
            /*}*/
        }
        
        paper-icon-button.removed {
            color: #F44336;
        }
        </style>
        <app-header-layout has-scrolling-region="true">
        <app-header scroll-target="content" slot="header" fixed>
            <app-toolbar class="small">
                <paper-tabs selected="{{selected}}" class="flex">
                    <paper-tab>Meter Detail</paper-tab>
                    <paper-tab>History</paper-tab>
                </paper-tabs>
                <div class="bottom flex"></div>
                <paper-icon-button class="bottom " icon="close" on-click="close"></paper-icon-button>
            
            </app-toolbar>
        </app-header>
            
            <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>
            <iron-pages selected="{{selected}}">
                <div class="horizontal around-justified layout" style="padding: 0 20px">
                    <div class="verticle layout flex two">
                        <paper-input label="Meter No #" value="{{info.deviceNo}}" readonly=""></paper-input>
                        <paper-input label="ICP #" value="{{info.icp}}" readonly=""></paper-input>
                        <paper-input label="SSN Meter" value="{{info.isSsnSmartMeter}}" readonly=""></paper-input>
                        <paper-input label="Meter Type" value="{{info.meterType}}" readonly=""></paper-input>
                        <paper-input label="Manufacturer" value="{{info.manufacturer}}" readonly=""></paper-input>
                        <paper-input label="Meter Category" value="{{info.categoryDescription}}" readonly="">
                            <paper-icon-button slot="suffix" icon="launch" on-click="onShowCategoryDialog"></paper-icon-button>
                        </paper-input>
                    </div>
                    <div class="flex"></div>
                    <div class="verticle layout flex two">
                        <paper-input label="Manufacturer" value="{{info.manufacturerSerialNo}}" readonly=""></paper-input>
                        <paper-input label="Make" value="{{info.makeModel}}" readonly=""></paper-input>
                        <paper-input label="Certification year" value="{{info.certificationYear}}" readonly=""></paper-input>
                        <paper-input label="Purchase Year" value="{{info.purchaseYear}}" readonly=""></paper-input>
                        <template is="dom-if" if="{{info.isRemoved}}">
                            <paper-input label="Meter Removed" value="[[formatDate(info.removed)]]" readonly="">
                                <paper-icon-button class="removed" slot="suffix" icon="image:leak-remove" on-click="onRemoveMeterClick"></paper-icon-button>
                            </paper-input>
                        </template>
                        <template is="dom-if" if="{{info.isIgnoreFromRealTime}}">
                            <paper-input label="Ignore from Real-time" value="Yes" readonly="">
                                <paper-icon-button slot="suffix" icon="launch" on-click="onShowCategoryDialog"></paper-icon-button>
                            </paper-input>
                        </template>
                        <template is="dom-if" if="{{info.isNicCardRemoved}}">
                            <paper-input label="NIC Card Removed" value="Yes" readonly="">
                                <paper-icon-button slot="suffix" icon="launch" on-click="onShowCategoryDialog"></paper-icon-button>
                            </paper-input>
                        </template>
                    </div>
                    <div class="flex"></div>
                    <div class="verticle layout flex two">
                        <paper-input label="Program ID" value="{{info.programId}}" readonly=""></paper-input>
                        <paper-input label="Ripple Program ID" value="{{info.rippleProgramId}}" readonly=""></paper-input>
                        <paper-input label="Control Channels" value="[[formatChannels(info.controlChannel1, info.controlChannel2, info.controlChannel3)]]" readonly=""></paper-input>
                        <paper-input label="Meter Tariff" value="{{info.configMeterTariff}}" readonly=""></paper-input>
                        <paper-input label="Meter Usage Type" value="{{info.configUsageType}}" readonly=""></paper-input>
                        <paper-input label="Multiplier" value="{{info.multiplier}}" readonly=""></paper-input>
                    </div>
                </div>
                <section>
                 
                    <iron-list items="[[info.subscriberLogs]]" as="item">
                        <template>
                            <paper-card>
                                <div class="card-content">
                                    <div class="horizontal layout">
                                        <div>
                                            Date: <span>[[formatDateTime(item.dateSent)]]</span>
                                        </div>
                                        <div style="padding-left: 20px">
                                            Type: <span>[[item.messageType]]</span>
                                        </div>
                                    </div>
                                    <div>
                                        [[item.message]]
                                    </div>
                                </div>
                            </paper-card>
                        </template>
                    </iron-list>
                </section>
            </iron-pages>
        </app-header-layout>
        <paper-dialog id="dlgRemoveMeter">
            <div class="verticle layout">
                <paper-input label="Meter No #" value="{{info.deviceNo}}" readonly=""></paper-input>
                <paper-input label="ICP #" value="{{info.icp}}" readonly=""></paper-input>
                <paper-input label="Date Removed" value="[[formatDate(info.removed)]]" readonly=""></paper-input>
                <!--<paper-input label="Date Removed (dd.mm.yyyy)" value="{{dateRemoved}}"-->
                <!--required-->
                <!--maxlength="10"-->
                <!--minlength="10"-->
                <!--pattern="^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))\$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$"-->
                <!--auto-validate-->
                <!--error-message="Invalid Date"-->
                <!--invalid="{{isInputInvalid}}"></paper-input>-->
                <paper-dropdown-menu label="Removal Reason" selected-item-label="{{removeReason}}" vertical-align="bottom" horizontal-align="left" no-animations="">
                    <paper-listbox slot="dropdown-content" style="width: 370px; height: 220px; overflow-x: hidden;">
                        <paper-item>Faulty meter</paper-item>
                        <paper-item>Meter used for builders Temp. supply</paper-item>
                        <paper-item>Import/export</paper-item>
                        <paper-item>Rate/Tariff change</paper-item>
                        <paper-item>Smart meter refusal</paper-item>
                        <paper-item>Downgrade from 3 phase to single phase</paper-item>
                        <paper-item>Upgrade from 1 phase to 3 phase</paper-item>
                        <paper-item>Incorrect meter installed</paper-item>
                        <paper-item>ICP Decommissioned</paper-item>
                        <paper-item>Metering point removed but ICP still Active (combined ICP)</paper-item>
                        <paper-item>Meter change for compliance</paper-item>
                        <paper-item>Meter board upgraded</paper-item>
                        <paper-item>Meter board position changed</paper-item>
                        <paper-item>ICP supply up/downgraded from CAT1 – CAT2</paper-item>
                    </paper-listbox>
                </paper-dropdown-menu>
                <paper-textarea label="Note" id="t2" value="{{removeNote}}" rows="2"></paper-textarea>
            </div>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onConfirmRemoveMeterClick">Remove</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgCategory">
            <paper-dropdown-menu label="Meter Category" placeholder="[[info.categoryDescription]]" horizontal-align="right" style="width: 80%;" id="ddlIcpCat">
                <paper-listbox slot="dropdown-content" selected="{{icpInfo.category}}" attr-for-selected="data-value">
                    <template is="dom-repeat" items="[[icpCategories.icpCategories]]">
                        <paper-item data-value="[[item.code]]" on-click="onIcpCategorySelected">[[item.description]]</paper-item>
                    </template>
                </paper-listbox>
            </paper-dropdown-menu>
            <!--<paper-textarea label="Details" id="t2" value="{{icpInfo.categoryDescription}}" rows="2" ></paper-textarea>-->
            <paper-checkbox checked="{{info.isIgnoreFromRealTime}}">Ignore from Real-time Events</paper-checkbox>
            <paper-checkbox checked="{{info.isNicCardRemoved}}">NIC Card Removed</paper-checkbox>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onUpdateCategoryClick">Update</paper-button>
            </div>
        </paper-dialog>
        <iron-ajax auto="" id="ajxMeterInfo" url="{{url}}" handle-as="json" last-response="{{info}}" loading="{{isLoading}}"></iron-ajax>
        <iron-localstorage name="cpl-icp-categories" id="lsCategories" value="{{icpCategories}}"></iron-localstorage>
        <iron-localstorage name="username" use-raw="" value="{{username}}"></iron-localstorage>
        <paper-toast id="toast" text=""></paper-toast>

        `;
      }

    meterNoChanged(newValue) {
        if (newValue !== "") {
            this.set('url', `/api/icp/${this.icpNo}/meter/${this.meterNo}`);
        } else {
            this.set('url', null);
        }
    }

    close() {
        this.dispatchEvent(new CustomEvent('meterdetail-close', {bubbles: true,  composed: true}));
    }

    formatDate(s) {
        // TODO: Moment JS
        //  return FormatDate(s);
        return moment.parseZone(s).format('DD MMM YYYY');
    }

    formatDateTime(s) {
        //TODO: moment js
        // return FormatDateTime(s);
        return moment.parseZone(s).format('DD MMM YYYY HH:mm');
    }

    formatChannels(ch1, ch2, ch3) {
        return `${ch1} - ${ch2} - ${ch3}`;
    }

    onRemoveMeterClick() {
        this.$.dlgRemoveMeter.toggle();
    }

    onShowCategoryDialog() {
        this.$.dlgCategory.toggle();
    }

    validateDate() {
        return false;
    }

    onConfirmRemoveMeterClick() {
        let data = JSON.stringify({ 
            'icp': this.icpNo,
            'deviceNo': this.meterNo,
            'removeReason': this.removeReason,
            'note': this.removeNote,
            'username': this.username
        });

        fetch(`/api/icp/meter/remove/update`, {
                credentials: 'include',
                method: 'POST',
                body: data
            })
            .then(resp => resp.text())
            .then(() => {
                this.$.ajxMeterInfo.generateRequest();
                this.showToast("Meter Removal Posted Successfully");
            })
            .catch(() => {
                this.showToast("Error Update Failed");
            });

    }

    showToast(text) {
        this.$.toast.show(text);
    }

    onIcpCategorySelected(e) {
        let model = e.model;
        this.$.ddlIcpCat.close();
        this.set('info.category', model.item.code);
        this.set('info.categoryDescription', model.item.description);
    }

    onUpdateCategoryClick() {
        let data = JSON.stringify({ 
            'icp': this.icpNo,
            'deviceNo': this.meterNo,
            'category': this.get('info.category'),
            'categoryDescription': this.get('info.categoryDescription'),
            'isNicCardRemoved': this.get('info.isNicCardRemoved'),
            'isIgnoreFromRealTime': this.get('info.isIgnoreFromRealTime'),
            'username': this.username
        });

        fetch(`/api/icp/meter/category/update`, {
                credentials: 'include',
                method: 'POST',
                body: data
            })
            .then(resp => resp.text())
            .then(() => {
                this.$.ajxMeterInfo.generateRequest();
                this.showToast("Meter Removal Posted Successfully");
            })
            .catch(() => {
                this.showToast("Error Update Failed");
            });

    }

}
customElements.define(MeterDetail.is, MeterDetail);
