import '@polymer/polymer/polymer-legacy.js';
import '@polymer/paper-dropdown-menu/paper-dropdown-menu.js';
import '@polymer/iron-list/iron-list.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/iron-icons/iron-icons.js';
import '@polymer/iron-icons/image-icons.js';
import '@polymer/iron-icons/maps-icons.js';
import '@polymer/iron-icons/communication-icons.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-item/paper-item-body.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
import '@polymer/paper-radio-group/paper-radio-group.js';
import '@polymer/paper-radio-button/paper-radio-button.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-toast/paper-toast.js';
import '@polymer/paper-toggle-button/paper-toggle-button.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/paper-dialog/paper-dialog.js';
// import 'paper-date-picker/paper-date-picker.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-slider/paper-slider.js';
import './components/core-overlay.js';
import './components/map-asset-detail.js';
import './components/fault-reported-detail.js';
import './components/shutdown-list.js';
import './components/shutdown-detail.js';
import './components/customer-detail.js';
import './components/live-outages-list.js';
import './components/fault-alert-list.js';
// import '@google/markerwithlabel';
import './components/home/dash-dispatch.js';
import './app-security-behavior.js';
import './components/wo-detail.js';
import './components/fault-alert-detail.js';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import moment from 'moment';
import {TextOverlay, MarkerWithLabel} from './libs/google-maps-imports'
import  'paper-collapse-item';

// import './shared-styles.js';


let gmap; // = document.querySelector('google-map');

let _mapBounds;

let _markers = [];

let _circles = [];
let _textOverlays = [];

let soMarkers = [];

let soInfoWindows = [];
let _outageOverlays = [];

let _pointArray;
let _heatmap = new google.maps.visualization.HeatmapLayer({ data: [] });
// let blDiv;

let geocoder = new google.maps.Geocoder();
let searchMarker = new google.maps.Marker();

let _transformerInfoWindows = [];
let _markerEvents = [];
let _transIcpMarkerEvents = [];
// let realtimeMarkerEvents = [];

let isMapLoaded = false;

let realTimeOutageMarker = [];
let transformerOutageMarkers = [];
let transformerOutageMarkerEvents = [];

let lTimer;

let feederPolygons = [];

let faultAlertEnabled = false;

let dur = "1d";

let distGenMarker = [];
let distGenMarkerEvents = []

let shutdownMarkers = [];
let shutdownMarkersEvents = []


let _transIcpMarker = [];
let _feederLines = [];

let assetLines = [];

const IMAGE_ROOT_URL = 'https://storage.googleapis.com/www_img';

let vehMarkers = [];
let vehInfoWindows = [];

let sTimer;

let faultNewMarkers = [];
let _faultNewEvents = [];
let _faultOtherEvents = [];

let faultOtherMarkers = [];

let icpHeatData = []; //Used for exporting to CSV

// let draggedResourceNo = {resourceNo:"",resourceName:""};
let isInDrag = false;
let _soPoly = [];

class MyView1 extends mixinBehaviors([AmiBehaviors.SecurityBehavior], PolymerElement)  {

  get getUrlMapBounds() {
    let increasePercentage = 1.10;  //10%
    var pointSouthWest = gmap.getBounds().getSouthWest();
    var pointNorthEast = gmap.getBounds().getNorthEast();
    var latAdjustment = (pointNorthEast.lat() - pointSouthWest.lat()) * (increasePercentage - 1);
    var lngAdjustment = (pointNorthEast.lng() - pointSouthWest.lng()) * (increasePercentage - 1);
    var newPointNorthEast = new google.maps.LatLng(pointNorthEast.lat() + latAdjustment, pointNorthEast.lng() + lngAdjustment);
    var newPointSouthWest = new google.maps.LatLng(pointSouthWest.lat() - latAdjustment, pointSouthWest.lng() - lngAdjustment);

    return `${newPointNorthEast.lat()}/${newPointSouthWest.lat()}/${newPointNorthEast.lng()}/${newPointSouthWest.lng()}`;
}

static get is() { return 'map-shell'; }

static get properties() {
    return {
        app: {
            type: Object,
            observer: 'appChanged'
        },
            hasAccess: Boolean,
        outageEventInfo: {
            type: Object,
            value: function() {
                return {};
            },
            observer: 'outageEventInfoChanged',
            notify:true
        },
        isShowCapChecked: {
            type: Boolean,
            value: false
        },
        isShowPolesChecked: {
            type: Boolean,
            value: false
        },
        isShowPillarsChecked: {
            type: Boolean,
            value: false
        },
        isShowFeederChecked: {
            type: Boolean,
            value: false
        },
        isShowIcpChecked: {
            type: Boolean,
            value: true
        },
        isShowOhSwitchChecked: {
            type: Boolean,
            value: false
        },
        isShowLinkBoxChecked: {
            type: Boolean,
            value: false
        },
        isShowLvLinesChecked: {
            type: Boolean,
            value: false
        },
        isShowLvCablesChecked: {
            type: Boolean,
            value: false
        },
        isShowHvLinesChecked: {
            type: Boolean,
            value: false
        },
        isShowHvCablesChecked: {
            type: Boolean,
            value: false
        },
        isShowTreesChecked: Boolean,
        isShowLightChecked: Boolean,
        isShowSwitchingStationsChecked: Boolean,
        isShowTransformers: Boolean,
        isShowSubstations: Boolean,
        selectedAsset: String,
        selectedAssetType: String,
        isShowVehicleCar: Boolean,
        isShowVehicleTruck: Boolean,
        isShowVehicleVan: Boolean,
        isShowVehicleUte: Boolean,
        isShowVehicleHeavy: Boolean,
        isShowSearchResults: {
            type: Boolean,
            value: false
        },
        voltageBreachedSelected: {
            type: Number,
            value: 0
        },
        isNavOpened: {
            type: Boolean,
            value: true
        },
        isRightNavOpened: {
            type: Boolean,
            value: false
        },
        faultAlertInfo: {
            type: Object,
            observer: 'faultAlertInfoChanged'
        },

        isShowUnplannedOutages: {
            type: Boolean,
            value: true
        },
        isShowPlannedOutages: Boolean,
        startDate: String,
        endDate: String,
        searchText: String,
        callTypeSearchText: {
            type: String,
            value: ""
        },
        addressItems: {
            type: Array,
            value: function() {
                return [];
            }
        },
        showBreachLegend: {
            type: Boolean,
            value: true
        },
        callTypes: Array,
        realTimeTransformerOutagesItems: {
            type: Array,
            value: function() {
                return [];
            }
        },

        isShowFaultsReportedNewChecked: Boolean,
        isShowFaultsReportedProcessedChecked: Boolean,
        selectedFaultId: String,
        fromDate: String,
        toDate: String,
        fromTime: {
            type: String,
            value: "00:00"
        },
        toTime: {
            type: String,
            value: "00:00"
        },
        selectedFilter: {
            type: Number,
            value: 0
        },

        breachTypeSelected: {
            type: String,
            value: "HideVoltageBreach",
            observer: 'breachTypeSelectedChanged'
        },

        distGenTypeSelected: {
            type: String,
            value: "HideDistGen",
            observer: 'distGenTypeSelectedChanged'
        },
        controlledLoadTypeSelected: {
            type: String,
            value: "HideControlledLoad",
            observer: 'controlledLoadTypeSelectedChanged'
        },
        consumptionTypeSelected: {
            type: String,
            value: "HideConsumption",
            observer: 'consumptionTypeSelectedChanged'
        },
        // lastGaspTypeSelected: {
        //     type: String,
        //     value: "HideDistGen",
        //     observer: 'lastGaspTypeSelectedChanged'
        // },
        powerDownTypeSelected: {
            type: String,
            value: "HidePowerDown",
            observer: 'powerDownTypeSelectedChanged'
        },

        isLoading: {
            type: Boolean,
            value: false
        },
        heatMapUrl: String,
        multiAssets: {
            type: Array,
            value: function() {
                return [];
            }
        },
        selectedShutdown: Number,
        selectedCustomerNo: String,
        selectedMeterNo: String,
        showShutdownList: {
            type: Boolean,
            value: false
        },
        showFaultAlertList: {
            type: Boolean,
            value: false
        },
        showLiveOutageList: {
            type: Boolean,
            value: false
        },

        sos: {
            type: Array,
            value: function() {
                return [];
            }
        },
        vehicles: {
            type: Array,
            value: function() {
                return [];
            }
        },
        vehiclePositions: {
            type: Array,
            value: function() {
                return [];
            }
        },
        selectedAlert: {
            type: Object,
            value: function() {
                return [];
            }
        },

        feedersData: {
            type: Array,
            value: function() {
                return [];
            }
        },

        activated: {
            type: Boolean,
            observer: 'activatedChanged'
        },

        isShowRealTime: {
            type: Boolean,
            value: true
        },
        realTimeLastGaspCount: {
            type: String,
            value: "Real Time (0)"
        },

        //For display
        isShowLargeMode: {
            type: Boolean,
            value: false,
            observer: 'isShowLargeModeChanged'
        },

        isShowFilter: {
            type: Boolean,
            value: false
        },
        username: String,

        icpCallInfo: {
            type: Object,
            value: function() {
                return [];
            }
        },

        uncheckedCats: {
            type: Array,
            value: function() {
                return [];
            }
        },
        uncheckedDisconnects: {
            type: Array,
            value: function() {
                return [];
            }
        },

        selectedDuration: {
            type: String,
            value: "1D",
            observer: 'selectedDurationChanged'
        },
        filterStartDate: {
            type: Date,
            value: new Date(),
            observer: 'filterStartDateChanged'
        },
        filterEndDate: {
            type: Date,
            value: new Date(),
            observer: 'filterEndDateChanged'
        },
        allocateResourceSo: Object,
        draggedResource: {
            type: Object,
            observer: 'draggedResourceChanged'
        },
        selectedMenu:{
            type: Number,
            value: -1,
            // observer: 'selectedMenuChanged'
        },
        zoomLevel: {
            type: Number,
            value: 14,
            observer: 'onZoomLevelChange'
        },

    }
}


static get observers() {
    return [
        'isDisconnectionCategoryListChanged(uncheckedCats.splices)',
        'uncheckedDisconnectsChanged(uncheckedDisconnects.splices)',
        'onVehiclePositionChanged(app.vehPositions)',
        'onShowUnplannedOutagesChanged(app.sos)'
    ]
}
  static get template() {
    return html`
      <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>
 
 <style>
 :host {
     display: block;
     height: 100%;
 }
 
 html,
 body {
     height: 100%;
 }
 
 
 body {
     font-family: sans-serif;
     color: #FFF;
     margin: 0;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 }
 
 [paper-header-panel] {
     height: 100%;
 }
 
 #left-nav {
     border-right: solid 1.5px #d3d3d3;
     height: 100%;
     background-color: #fafafa;
 }
 
 #left-nav .title {
     color: darkgray;
 }
 
 #left-nav app-toolbar {
     border-bottom: solid 1.5px #d3d3d3;
     --app-toolbar-background: transparent;
 }
 
 .big {
     -height: 32px;
     -width: 32px;
     color: darkgray;
 }
 
 #left-nav paper-listbox {
     --paper-listbox-background-color: #fafafa;
 }
 
 app-toolbar {
     border-bottom: solid 1px #d3d3d3;
     --app-toolbar-background: transparent;
     --app-toolbar-color: rgb(59, 120, 231);
     --app-toolbar-font-size: 14px;
 }
 
 app-toolbar .title {
     color: black;
     margin-left: 0px;
 }


 
 #map-toolbar::shadow #topBar {
     padding-left: 0px;
 }
 
 .chevron {
     color: darkgray;
 }
 
 #mapv {
     /*height: 100%;*/
 }
 
 paper-input.search {
     margin-bottom: 30px;
 }
 
 core-overlay {
     bottom: 0px;
     background: white;
     width: 100%;
     height: 500px;
 }
 
 #dlgfilter {
     position: absolute;
     top: 64px;
     /*left: 50px;*/
     width: 390px;
     height: 240px;
     margin-left: 50px;
 }
 
 paper-button.meter-data-filter {}
 
 .filter-section {
     padding-top: 10px;
 }
 
 .filter-buttons {
     margin-left: 10px;
     margin-right: 10px;
 }
 
 #assetdetail,
 #multidialog {
     position: fixed;
     left: 0px;
     bottom: 0px;
     background: white;
     width: 100%;
     height: 500px;
     max-height: 1000px;
     overflow: auto;
 }
 
 button.link {
     -moz-user-select: text;
     background: none;
     border: none;
     color: blue;
     cursor: pointer;
     font-size: 1em;
     margin: 0;
     padding: 0;
     text-align: left;
 }
 
 .sublist {
     padding-left: 20px;
     padding-right: 20px;
 }
 /*.avatar {*/
 /*height: 40px;*/
 /*width: 40px;*/
 /*border-radius: 20px;*/
 /*box-sizing: border-box;*/
 /*background-color: #DDD;*/
 /*}*/
 
 .avatar {
     margin: 10px;
 }
 
 .item {
     @apply --layout-horizontal;
     cursor: pointer;
 }
 
 .item:focus,
 .item.selected:focus {
     outline: 0;
     background-color: #ddd;
 }
 
 .pad {
     @apply --layout-flex;
     @apply --layout-vertical;
     padding: 0 16px;
 }
 
 .primary {
     font-size: 14px;
 }
 
 .secondary {
     margin-right: 50px;
 }
 /*#dlgFilter::shadow #scroller {*/
 /*width: 300px;*/
 /*height: 300px;*/
 /*}*/
 
 .right-side-overlay {
     position: fixed;
     right: 0px;
     top: 64px;
     background: white;
     width: 408px;
     height: 100%;
     overflow: auto;
     box-shadow: 0px 10px 5px #888888;
 }
 
 .right-side-overlay-large {
     position: fixed;
     right: 0px;
     top: 0px;
     background: white;
     width: 820px;
     height: 100%;
     overflow: auto;
 }
 
 paper-progress {
     /*z-index: 10;*/
     /*position: absolute;*/
     width: 100%;
     --paper-progress-container-color: transparent;
     --paper-progress-active-color: var(--paper-orange-500);
     --paper-progress-secondary-color: var(--paper-orange-100);
 }
 /*@media (min-width: 641px){*/
 /*paper-progress {*/
 /*top: 60px;*/
 /*}*/
 /*}*/
 
 paper-listbox paper-item {
     font-size: 13px;
 }
 
 .search-asset-type {
     color: darkgray;
     margin-right: 20px;
     text-transform: uppercase;
 }
 
 .cards {
     @apply --layout-vertical;
     @apply --center-justified;
     /*max-width: 400px;*/
     margin-left: auto;
     margin-right: auto;
     margin-bottom: 5px;
 }
 
 .search-card {
     font-size: 13px;
 }
 
 paper-button.call {
     color: #FFA000;
 }
 
 #dlgNewCall {
     width: 500px;
     /*height: 500px;*/
     overflow: auto;
 }
 
 #collapse {
     position: absolute;
     background-color: #f5f5f5;
     max-height: 230px;
     z-index: 1000;
     border: solid 1px #B6B6B6;
     width: 290px;
 }
 
 paper-fab.green {
     background: #259b24;
     z-index: 10;
     position: absolute;
     top: 240px;
     margin-left: 7px;
 }
 
 .fh {
     height: 100%;
 }

 #wodetail {
     position: fixed;
     left: 0px;
     bottom: 0px;
     background: white;
     width: 100%;
     height: 500px;
     max-height: 1000px;
     overflow: auto;
 }

 #faultAlertOverlay {
         position: fixed;
         right: 0px;
         top: 64px;
         background: white;
         width: 408px;
         height: 100%;
         overflow: auto;

     }
 
 @media only screen and (min-width: 720px) and (max-width: 1024px) {
     .flex1search {
         @apply --layout-flex-2;
     }
     .flex4search {
         @apply --layout-flex-4;
     }
 }
 
 @media only screen and (min-width: 1025px) {
     .flex1search {
         @apply --layout-flex-2;
     }
     .flex4search {
         @apply --layout-flex-7;
     }
 }

 paper-date-picker{
     margin: 0;
     padding: 0;
 }
 .info {
     background-color: #FFE082;
     width:100%;
 }

 .labels {
     color: red;
     background-color: white;
     font-family: "Lucida Grande", "Arial", sans-serif;
     font-size: 10px;
     font-weight: bold;
     text-align: center;
     width: 140px;
     border: 2px solid darkgray;
     white-space: nowrap;
   }
   .labels-onduty {
     border: 2px solid darkgreen;
   }
   .over {
            background:  orangered;
    }
    #mapv img {
        max-width: none;
    }
 </style>

<div class="horizontal layout flex fh">
            <div id="left-nav" class="flex1search" hidden\$="{{!isNavOpened}}">
                <app-toolbar>
                    <!--<paper-icon-button icon="device:location-searching" class="big"></paper-icon-button>-->
                    <!--<div class="title">Map</div>-->
                    <div class="flex">
                        <template is="dom-if" if="[[isMapSearchViewAllowed(app)]]">
                        <paper-input id="tbxSearch" placeholder="Type address or asset" class="search" value="{{searchText}}" on-input="onSearchTextInput" on-keydown="onSearchTextKeyDown">
                            <paper-icon-button slot="prefix" icon="search"></paper-icon-button>
                            <paper-icon-button slot="suffix" icon="communication:clear-all" on-click="onClearSearchText"></paper-icon-button>
                        </paper-input>
                        </template>
                    </div>
                </app-toolbar>
                <div id="menunav" style="overflow-y: auto;">
                
                <template is="dom-if" if="{{!isShowSearchResults}}">
                    <paper-item hidden\$="[[!isDispatchViewAllowed(app)]]" on-click="openDispatchOverlay">
                        <iron-icon icon="notification:event-busy"></iron-icon>
                        <paper-item-body>
                            Dispatch
                        </paper-item-body>
                    </paper-item>
                    <paper-collapse-item hidden\$="[[!isOutageViewAllowed(app)]]" icon="image:details" header="Outages" >
                        <paper-listbox class="menu-content sublist">
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowPlannedOutages}}" on-change="onShowPlannedOutages"></paper-checkbox>
                                <paper-item-body>
                                    Planned
                                </paper-item-body>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowUnplannedOutages}}" on-change="onShowUnplannedOutages"></paper-checkbox>
                                <paper-item-body>
                                    Unplanned
                                </paper-item-body>
                            </paper-item>
                            <!-- Faults Reported sub-menu-->
                            <!--TODO Make collapsable-->
                            <paper-listbox>
                                <paper-item class="menu-trigger">
                                    <iron-icon icon="flag"></iron-icon>
                                    <paper-item-body>
                                        Faults Reported
                                    </paper-item-body>
                                </paper-item>
                                <paper-listbox class="menu-content sublist">
                                    <paper-item role="menuitemcheckbox">
                                        <paper-checkbox checked="{{isShowFaultsReportedNewChecked}}" on-change="onShowFaultsReportedNewChanged"></paper-checkbox>
                                        <paper-item-body>
                                            New - Priority (Unsafe and Lines Down)
                                        </paper-item-body>
                                    </paper-item>
                                    
                                    <paper-item role="menuitemcheckbox">
                                        <paper-checkbox checked="{{isShowFaultsReportedProcessedChecked}}" on-change="onShowFaultsReportedProcessedChanged"></paper-checkbox>
                                        <paper-item-body>
                                            New - Other
                                        </paper-item-body>
                                    </paper-item>
                                </paper-listbox>
                            </paper-listbox>
                        </paper-listbox>
                    </paper-collapse-item>

                    <!-- Meter Data sub-menu End-->
                    <paper-collapse-item hidden\$="[[!isMeterDataViewAllowed(app)]]" icon="image:transform" header="Meter Data" >
                        <paper-listbox class="menu-content sublist">
                            <!-- Consumption-->
                            
                            <paper-listbox>
                                <paper-item class="menu-trigger">
                                    <iron-icon icon="image:wb-incandescent"></iron-icon>
                                    <paper-item-body>
                                        Consumption
                                    </paper-item-body>
                                </paper-item>
                                <paper-listbox class="menu-content sublist">
                                    <paper-item>
                                        <div>
                                            <paper-radio-group id="rdgCons" selected="{{consumptionTypeSelected}}">
                                                <paper-radio-button name="HideConsumption">None</paper-radio-button>
                                                <br>
                                                <paper-radio-button name="ShowConsumption">Show</paper-radio-button>
                                            </paper-radio-group>
                                        </div>
                                    </paper-item>
                                </paper-listbox>
                            </paper-listbox>
                            <!-- Voltage Breach-->
                            
                            <paper-listbox>
                                <paper-item class="menu-trigger">
                                    <iron-icon icon="image:flash-off"></iron-icon>
                                    <paper-item-body>
                                        Voltage Breached
                                    </paper-item-body>
                                </paper-item>
                                <paper-listbox class="menu-content sublist">
                                    <paper-item>
                                        <div>
                                            <paper-radio-group id="rdgBreachType" selected="{{breachTypeSelected}}">
                                                <paper-radio-button name="HideVoltageBreach" label="None">None
                                                </paper-radio-button>
                                                <br>
                                                <paper-radio-button name="Minimum">Min</paper-radio-button>
                                                <paper-radio-button name="Maximum">Max</paper-radio-button>
                                                <paper-radio-button name="Neutral">Neutral</paper-radio-button>
                                            </paper-radio-group>
                                        </div>
                                    </paper-item>
                                </paper-listbox>
                            </paper-listbox>
                            <!-- Distributed Generation-->
                            
                            <paper-listbox>
                                <paper-item class="menu-trigger">
                                    <iron-icon icon="image:wb-sunny"></iron-icon>
                                    <paper-item-body>
                                        Distributed Generation
                                    </paper-item-body>
                                </paper-item>
                                <paper-listbox class="menu-content sublist">
                                    <paper-item>
                                        <div>
                                            <paper-radio-group id="rdgDistGen" selected="{{distGenTypeSelected}}">
                                                <paper-radio-button name="HideDistGen">None</paper-radio-button>
                                                <br>
                                                <paper-radio-button name="ShowDistGen">Show</paper-radio-button>
                                            </paper-radio-group>
                                        </div>
                                    </paper-item>
                                </paper-listbox>
                            </paper-listbox>
                            <!-- Controlled Load-->
                            
                            <paper-listbox>
                                <paper-item class="menu-trigger">
                                    <iron-icon icon="image:timelapse"></iron-icon>
                                    <paper-item-body>
                                        Controlled Load
                                    </paper-item-body>
                                </paper-item>
                                <paper-listbox class="menu-content sublist">
                                    <paper-item>
                                        <div>
                                            <paper-radio-group id="rdgControlledLoad" selected="{{controlledLoadTypeSelected}}">
                                                <paper-radio-button name="HideControlledLoad">None</paper-radio-button>
                                                <br>
                                                <paper-radio-button name="ShowControlledLoad">Show</paper-radio-button>
                                            </paper-radio-group>
                                        </div>
                                    </paper-item>
                                </paper-listbox>
                            </paper-listbox>
                            <!-- Power Down-->
                            
                            <paper-listbox>
                                <paper-item class="menu-trigger">
                                    <iron-icon icon="image:tonality"></iron-icon>
                                    <paper-item-body>
                                        Power Down
                                    </paper-item-body>
                                </paper-item>
                                <paper-listbox class="menu-content sublist">
                                    <paper-item>
                                        <div>
                                            <paper-radio-group id="rdgPowerDown" selected="{{powerDownTypeSelected}}">
                                                <paper-radio-button name="HidePowerDown">None</paper-radio-button>
                                                <br>
                                                <paper-radio-button name="ShowPowerDown">Show</paper-radio-button>
                                            </paper-radio-group>
                                        </div>
                                    </paper-item>
                                </paper-listbox>
                            </paper-listbox>
                        </paper-listbox>
                    </paper-collapse-item>

                    <!-- Assets -->
                    <paper-collapse-item icon="image:nature-people" header="Assets" >
                        <paper-listbox class="menu-content sublist">
                                <paper-item role="menuitemcheckbox">
                                    <paper-checkbox checked="{{isShowCapChecked}}" on-change="onCapacitorChanged"></paper-checkbox>
                                    <paper-item-body>
                                        Capacitors
                                    </paper-item-body>
                                </paper-item>
                                <!--<paper-item role="menuitemcheckbox">-->
                                <!--<paper-checkbox checked="{{isShowFeederChecked}}"-->
                                <!--on-change="onFeederChanged"></paper-checkbox>-->
                                <!--<paper-item-body>-->
                                <!--Feeder-->
                                <!--</paper-item-body>-->
                                <!--</paper-item>-->
                                <paper-item role="menuitemcheckbox">
                                    <paper-checkbox checked="{{isShowIcpChecked}}" on-change="onIcpChanged"></paper-checkbox>
                                    <paper-item-body>
                                        ICP
                                    </paper-item-body>
                                </paper-item>
                                <paper-item role="menuitemcheckbox">
                                    <paper-checkbox checked="{{isShowLightChecked}}" on-change="onShowLightChanged"></paper-checkbox>
                                    <paper-item-body>
                                        Light
                                    </paper-item-body>
                                </paper-item>
                                <paper-item role="menuitemcheckbox">
                                    <paper-checkbox checked="{{isShowLinkBoxChecked}}" on-change="onShowLinkBoxChanged"></paper-checkbox>
                                    <paper-item-body>
                                        Link Box
                                    </paper-item-body>
                                </paper-item>
                                <paper-item role="menuitemcheckbox">
                                    <paper-checkbox checked="{{isShowOhSwitchChecked}}" on-change="onShowOhSwitchChanged"></paper-checkbox>
                                    <paper-item-body>
                                        OH Switch
                                    </paper-item-body>
                                </paper-item>
                                <paper-item role="menuitemcheckbox">
                                    <paper-checkbox checked="{{isShowPillarsChecked}}" on-change="onPillarsChanged"></paper-checkbox>
                                    <paper-item-body>
                                        Pillars
                                    </paper-item-body>
                                </paper-item>
                                <paper-item role="menuitemcheckbox">
                                    <paper-checkbox checked="{{isShowPolesChecked}}" on-change="onPoleChanged"></paper-checkbox>
                                    <paper-item-body>
                                        Poles
                                    </paper-item-body>
                                </paper-item>
                                <paper-item role="menuitemcheckbox">
                                    <paper-checkbox checked="{{isShowSwitchingStationsChecked}}" on-change="onShowTreesChanged"></paper-checkbox>
                                    <paper-item-body>
                                        RMU
                                    </paper-item-body>
                                </paper-item>
                                <paper-item role="menuitemcheckbox">
                                    <paper-checkbox checked="{{isShowTransformers}}" on-change="onShowTransformersChanged"></paper-checkbox>
                                    <paper-item-body>
                                        Transformers
                                    </paper-item-body>
                                </paper-item>
                                <paper-item role="menuitemcheckbox">
                                    <paper-checkbox checked="{{isShowSubstations}}" on-change="onShowSubstationsChanged"></paper-checkbox>
                                    <paper-item-body>
                                        Substations
                                    </paper-item-body>
                                </paper-item>
                                <paper-item role="menuitemcheckbox">
                                    <paper-checkbox checked="{{isShowTreesChecked}}" on-change="onShowTreesChanged"></paper-checkbox>
                                    <paper-item-body>
                                        Trees
                                    </paper-item-body>
                                </paper-item>
                            </paper-listbox>
                        </paper-listbox>
                    </paper-collapse-item>

                    <!-- Feeders -->
                    <paper-collapse-item icon="timeline" header="Feeders" >
                        <paper-listbox class="menu-content sublist">
                            <template is="dom-repeat" items="[[feedersData]]">
                                <paper-item role="menuitemcheckbox">
                                    <paper-checkbox checked="{{item.checked}}" disabled="[[isLoading]]" on-change="onShowFeederChanged"></paper-checkbox>
                                    <paper-item-body>
                                        [[item.name]]
                                    </paper-item-body>
                                </paper-item>
                            </template>
                        </paper-listbox>
                    </paper-collapse-item>

                    <!-- Lines and Cables -->

                    <paper-collapse-item icon="line-weight" header="Lines and Cables" >
                        <paper-listbox class="menu-content sublist">
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowLvLinesChecked}}" disabled="[[isLoading]]" on-change="onShowLvLinesChanged"></paper-checkbox>
                                <paper-item-body>
                                    LV Lines
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: orange;"></iron-icon>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowHvLinesChecked}}" disabled="[[isLoading]]" on-change="onShowLvLinesChanged"></paper-checkbox>
                                <paper-item-body>
                                    HV Lines
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: red;"></iron-icon>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowLvCablesChecked}}" disabled="[[isLoading]]" on-change="onShowLvLinesChanged"></paper-checkbox>
                                <paper-item-body>
                                    LV Cables
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: blue;"></iron-icon>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowHvCablesChecked}}" disabled="[[isLoading]]" on-change="onShowLvLinesChanged"></paper-checkbox>
                                <paper-item-body>
                                    HV Cables
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: green;"></iron-icon>
                            </paper-item>
                        </paper-listbox>
                    </paper-collapse-item>

                    <!-- Vehicles -->
                    <paper-collapse-item hidden\$="[[!isVehiclesViewAllowed(app)]]" icon="maps:directions-car"" header="Vehicles" >
                        <paper-listbox class="menu-content sublist">
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowVehicleAsset}}" on-change="onVehicleChanged"></paper-checkbox>
                                <paper-item-body>
                                    Asset Management
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: #FF5722;"></iron-icon>
                            </paper-item>
                            
                            <!-- <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowVehicleCorporate}}" on-change="onVehicleChanged"></paper-checkbox>
                                <paper-item-body>
                                    Corporate
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: #4CAF50;"></iron-icon>
                            </paper-item> -->
                            
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowVehicleField}}" on-change="onVehicleChanged"></paper-checkbox>
                                <paper-item-body>
                                    Field Ops Administration
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: #009688;"></iron-icon>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowVehicleHv}}" on-change="onVehicleChanged"></paper-checkbox>
                                <paper-item-body>
                                    HV Services
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: #FFC107;"></iron-icon>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowVehicleInspectors}}" on-change="onVehicleChanged"></paper-checkbox>
                                <paper-item-body>
                                    Inspectors
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: #FF5722;"></iron-icon>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowVehicleMetering}}" on-change="onVehicleChanged"></paper-checkbox>
                                <paper-item-body>
                                    Metering
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: #FBA1FF;"></iron-icon>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowVehicleOverhead}}" on-change="onVehicleChanged"></paper-checkbox>
                                <paper-item-body>
                                    Overhead
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: #FBA107;"></iron-icon>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowVehiclePeopleSafety}}" on-change="onVehicleChanged"></paper-checkbox>
                                <paper-item-body>
                                    People Safety & Comms
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: #009688;"></iron-icon>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowVehicleProcurement}}" on-change="onVehicleChanged"></paper-checkbox>
                                <paper-item-body>
                                Procurement
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: #FAB688;"></iron-icon>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowVehicleReactiveServices}}" on-change="onVehicleChanged"></paper-checkbox>
                                <paper-item-body>
                                    Reactive Services
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: #8FB107;"></iron-icon>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowVehicleUndergroundCapital}}" on-change="onVehicleChanged"></paper-checkbox>
                                <paper-item-body>
                                    Underground Capital Projects
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: #FBB107;"></iron-icon>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowVehicleUnderground}}" on-change="onVehicleChanged"></paper-checkbox>
                                <paper-item-body>
                                    Underground New Connections
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: #4FC107;"></iron-icon>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowVehicleSubDivisions}}" on-change="onVehicleChanged"></paper-checkbox>
                                <paper-item-body>
                                    Underground Sub Divisions
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: #BFC107;"></iron-icon>
                            </paper-item>
                            <paper-item role="menuitemcheckbox">
                                <paper-checkbox checked="{{isShowVehicleVegetation}}" on-change="onVehicleChanged"></paper-checkbox>
                                <paper-item-body>
                                    Vegetation
                                </paper-item-body>
                                <iron-icon icon="remove" style="fill: #8FC107;"></iron-icon>
                            </paper-item>
                        </paper-listbox>
                    </paper-collapse-item>

                </template>

                <template is="dom-if" if="{{isShowSearchResults}}">
                    <template is="dom-repeat" items="{{addressItems}}">
                        <div class="cards">
                            <paper-card>
                                <div class="card-content search-card">
                                    <div>{{item.name}}</div>
                                    <div class="search-asset-type" secondary="">{{item.assetType}}</div>
                                    <div>{{item.customerName}}</div>
                                    <div><span>{{item.streetAddress}}</span> <span>{{item.town}}</span></div>
                                    <template is="dom-if" if="{{item.isInShutdown}}">
                                        <div class="info"><span>In Current Shutdown:</span> <span>{{item.shutdownId}}</span></div>
                                    </template>
                                </div>
                                <div class="card-actions">
                                    <div class="horizontal layout">
                                        <img class="avatar" src="[[iconForAssetType(item.assetType, item.category)]]">
                                        <div class="flex"></div>
                                        <paper-button on-click="onNewCallClick" class="call" hidden\$="[[!showCallIcon(item.assetType)]]">
                                            <iron-icon icon="perm-phone-msg"></iron-icon>
                                            New Call
                                        </paper-button>
                                        <paper-icon-button icon="file-upload" on-click="onAssetSelectedFromSearch" disabled="[[!showAssetIcon(item.assetType)]]"></paper-icon-button>
                                        <paper-icon-button icon="communication:location-on" title="Show on Map" on-click="onAddressClick" data-lat="{{item.lat}}" data-lng="{{item.lng}}"></paper-icon-button>
                                        <!--<paper-icon-button icon="note-add" alt="Add Call" title="Add Call" on-click="onAddCallClicked"></paper-icon-button>-->
                                    </div>
                                </div>
                            </paper-card>
                        </div>
                    </template>
                </template>
                </div>
                
            </div>
            <div class="flex4search">
                <app-toolbar  id="map-toolbar">
                    <!--<paper-progress indeterminate disabled="{{!isLoading}}" class="bottom fit"></paper-progress>-->
                    <template is="dom-if" if="{{isNavOpened}}">
                        <paper-icon-button class="chevron" icon="chevron-left" on-click="toggleNav" alt="Collapse Side Panel" title="Collapse Side Panel"></paper-icon-button>
                    </template>
                    <template is="dom-if" if="{{!isNavOpened}}">
                        <paper-icon-button class="chevron" icon="chevron-right" on-click="toggleNav" alt="Expand Side Panel" title="Expand Side Panel"></paper-icon-button>
                    </template>
                    <!--<paper-icon-button class="chevron" hidden\$="{{!isNavOpened}}" icon="chevron-left"-->
                    <!--on-click="toggleNav" alt="Collapse Side Panel"-->
                    <!--title="Collapse Side Panel"></paper-icon-button>-->
                    <!--<paper-icon-button class="chevron" hidden\$="{{isNavOpened}}" icon="chevron-right"-->
                    <!--on-click="toggleNav" alt="Expand Side Panel"-->
                    <!--title="Expand Side Panel"></paper-icon-button>-->
                    <paper-icon-button class="chevron" icon="maps:zoom-out-map" on-click="onZoomOut" alt="Zoom Out" title="Zoom Out"></paper-icon-button>
                    <!--<paper-dropdown-menu style="width: 130px" no-label-float no-animations>-->
                    <!--<paper-listbox slot="dropdown-content" selected="{{rangeSelected}}" >-->
                    <!--<paper-item>1 Hour</paper-item>-->
                    <!--<paper-item>6 Hours</paper-item>-->
                    <!--<paper-item>1 Day</paper-item>-->
                    <!--<paper-item>2 Days</paper-item>-->
                    <!--<paper-item>7 Days</paper-item>-->
                    <!--<paper-item>14 Days</paper-item>-->
                    <!--<paper-item>30 Days</paper-item>-->
                    <!--<paper-item>Select Range</paper-item>-->
                    <!--</paper-listbox>-->
                    <!--</paper-dropdown-menu>-->
                    <template is="dom-if" if="[[isShowFilter]]">
                        <paper-button on-click="showDateFilterDialog" alt="Filter" title="Filter">
                            <iron-icon icon="filter-list"></iron-icon>
                            Filter
                        </paper-button>
                    </template>
                    <!--<template is="dom-if" if="1">-->
                    <!--<paper-dropdown-menu  no-animations label="{{dateFromSelected}}" no-label-float style="width: 130px; margin-left: 10px;">-->
                    <!--<div slot="dropdown-content" style="background-color: white" selected="{{dateFromSelected}}">-->
                    <!--<paper-date-picker force-narrow date="{{dateFromSelected}}"></paper-date-picker>-->
                    <!--<div class="buttons">-->
                    <!--<paper-button dialog-dismiss>Cancel</paper-button>-->
                    <!--<paper-button dialog-confirm>OK</paper-button>-->
                    <!--</div>-->
                    <!--</div>-->
                    <!--</paper-dropdown-menu>-->
                    <!--</template>-->
                    <paper-slider id="slZoomLevel" pin snaps min="10" max="22" max-markers="11" step="1" value="{{zoomLevel}}" ></paper-slider>
                    <div main-title class="flex"></div>
                    
                    <paper-button on-click="showShutdownListOverlay" alt="Shutdowns" title="Shutdowns" hidden\$="[[!isShutdownsViewAllowed(app)]]">
                        <iron-icon icon="assignment-late"></iron-icon>
                        Shutdowns
                    </paper-button>
                    <paper-button on-click="showFaultAlertListOverlay" alt="Events" title="Events" hidden\$="[[!isEventViewAllowed(app)]]" disabled="[[isRightNavOpened]]">
                        <iron-icon icon="warning"></iron-icon>
                        Events
                    </paper-button>

                    
                    <!--<paper-button on-click="showDateFilterDialog" alt="Filter" title="Filter">-->
                    <!--<iron-icon icon="filter-list"></iron-icon>-->
                    <!--Filter-->
                    <!--</paper-button>-->
                    <template is="dom-if" if="[[isRealTimeViewAllowed(app)]]">
                      <paper-button on-click="showLiveOutageListOverlay" alt="Real Time" title="Real Time" hidden\$="[[!isRealTimeViewAllowed(app)]]">
                        <iron-icon icon="announcement"></iron-icon>
                        {{realTimeLastGaspCount}}
                        </paper-button>
                        <paper-dropdown-menu style="width: 100px;" no-label-float="" no-animations="">
                            <paper-listbox slot="dropdown-content" selected="{{selectedDuration}}" attr-for-selected="name">
                                <paper-item name="5M">5 Min</paper-item>
                                <paper-item name="30M">30 Min</paper-item>
                                <paper-item name="1H">1 Hour</paper-item>
                                <paper-item name="6H">6 Hours</paper-item>
                                <paper-item name="12H">12 Hours</paper-item>
                                <paper-item name="1D">1 Day</paper-item>
                                <paper-item name="7D">7 Days</paper-item>
                                <paper-item name="30D">30 Days</paper-item>
                                <paper-item name="60+">60+ Days</paper-item>
                            </paper-listbox>
                        </paper-dropdown-menu>
                        <paper-toggle-button checked="{{isShowRealTime}}"></paper-toggle-button>
                    </template>
                    
                    
                </app-toolbar>
                
                <paper-progress indeterminate="" disabled="{{!isLoading}}" class="bottom fit"></paper-progress>
                <core-overlay id="dlgfilter" autoclosedisabled="true">
                    <paper-card>
                        <div class="card-content">
                            <!-- Custom Filter-->
                            <div class="horizontal justified layout">
                                <paper-input label="From (dd.mm.yyyy)" value="{{fromDate}}" required="" maxlength="10" minlength="10" pattern="^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))\$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$" auto-validate="" error-message="Invalid Date" invalid="{{isFromDateInvalid}}"></paper-input>
                                <paper-icon-button icon="icons:event" on-click="onCalFromClick"></paper-icon-button>
                                <!--<input id="tbxDateFrom" is="iron-input" bind-value="{{fromDate}}" type="date" min="2014-1-1">-->
                                <!--<input is="iron-input" bind-value="{{fromTime}}" type="time">-->
                                <paper-input label="HH:MM" value="{{fromTime}}" class="flex" required="" maxlength="5" minlength="5" pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]\$" auto-validate="" error-message="Invalid Time" invalid="{{isFromTimeInvalid}}"></paper-input>
                            </div>
                            <div class="horizontal justified layout">
                                <!--<input is="iron-input" bind-value="{{toDate}}" type="date" min="2014-1-1">-->
                                <paper-input label="To (dd.mm.yyyy)" value="{{toDate}}" required="" maxlength="10" minlength="10" pattern="^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))\$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$" auto-validate="" error-message="Invalid Date" invalid="{{isToDateInvalid}}"></paper-input>
                                <paper-icon-button icon="icons:event" on-click="onCalToClick"></paper-icon-button>
                                <!--<input is="iron-input" bind-value="{{toTime}}" type="time">-->
                                <paper-input label="HH:MM" value="{{toTime}}" class="flex" required="" maxlength="5" minlength="5" pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]\$" auto-validate="" error-message="Invalid Time" invalid="{{isToTimeInvalid}}"></paper-input>
                            </div>
                            <paper-button id="btnCustDateSearch" class="meter-data-filter" on-click="onCustDateClick" disabled="[[isFilterInvalid(isFromDateInvalid, isToDateInvalid,isFromTimeInvalid, isToTimeInvalid)]]" raised="">Search
                            </paper-button>
                        </div>
                        <div class="card-actions" style="background-color: #f5f5f5">
                            <p>Quick Filter</p>
                            <div class="horizontal justified">
                                <paper-icon-button icon="icons:event" disabled=""></paper-icon-button>
                                <paper-button id="btnVb1h" class="meter-data-filter" on-click="onBtnVbClick" data-dur="1h">1 Hour
                                </paper-button>
                                <paper-button id="btnVb6h" class="meter-data-filter" on-click="onBtnVbClick" data-dur="6h">6 Hours
                                </paper-button>
                                <paper-button id="btnVb1d" class="meter-data-filter colored" on-click="onBtnVbClick" data-dur="1d">1 Day
                                </paper-button>
                                <paper-button id="btnVb2d" class="meter-data-filter" on-click="onBtnVbClick" data-dur="2d">2 Days
                                </paper-button>
                                <!--<paper-button>1 Hour</paper-button>-->
                                <!--<paper-button>4 Hours</paper-button>-->
                                <!--<paper-button>8 Hours</paper-button>-->
                            </div>
                            <div class="horizontal justified">
                                <paper-icon-button icon="icons:event" disabled=""></paper-icon-button>
                                <paper-button id="btnVb4d" class="meter-data-filter" data-dur="4d" on-click="onBtnVbClick">4 Days
                                </paper-button>
                                <paper-button id="btnVb7d" class="meter-data-filter" on-click="onBtnVbClick" data-dur="7d">7 Days</paper-button>
                                <paper-button id="btnVb14d" class="meter-data-filter" on-click="onBtnVbClick" data-dur="14d">14 Days
                                </paper-button>
                                <paper-button id="btnVb30d" class="meter-data-filter" on-click="onBtnVbClick" data-dur="30d">30 Days
                                </paper-button>
                                <!--<paper-button>1 Day</paper-button>-->
                                <!--<paper-button>4 Days</paper-button>-->
                                <!--<paper-button>8 Days</paper-button>-->
                            </div>
                            <paper-button>[[startDate]]</paper-button>
                            <paper-button on-click="_onHeatMapExportToCsv">
                                <iron-icon icon="file-download"></iron-icon>
                                Download CSV
                            </paper-button>
                        </div>
                    </paper-card>
                </core-overlay>
                <!--<google-map disable-default-ui zoom="15"></google-map>-->
                <div id="mapv"></div>
                <!--<paper-fab mini icon="more-vert" class="green" title="done"></paper-fab>-->
            </div>
            <div id="right-nav" hidden\$="{{!isRightNavOpened}}" style="width: 410px">

            </div>
        </div>

      <!-- Asset Detail Overlay-->
      <core-overlay id="assetdetail" auto-close-disabled="true" on-assetdetail-close="closeAssetDetail" on-show-on-map="showShutdownOnMap">
            <div class="content">
                <map-asset-detail asset-type="{{selectedAssetType}}" asset-id="{{selectedAsset}}" app="{{app}}">
                </map-asset-detail>
            </div>
        </core-overlay>
        <!--<core-overlay id="assetdetail" auto-close-disabled="true">-->
        <!--<app-toolbar class="small">-->
        <!--<div class="title">Asset Details - <span>{{selectedAsset}}</span></div>-->
        <!--<paper-icon-button icon="close" on-click="closeAssetDetail"></paper-icon-button>-->
        <!--</app-toolbar>-->
        <!--<div class="content">-->
        <!--<map-asset-detail asset-type="{{selectedAssetType}}" asset-id="{{selectedAsset}}">-->
        <!--</map-asset-detail>-->
        <!--</div>-->
        <!--</core-overlay>-->
        <paper-dialog id="dlgCalFrom" class="paper-date-picker-dialog" with-backdrop="" on-iron-overlay-closed="dismissDialog">
            <paper-date-picker id="pickerFrom" date="{{filterStartDate}}" heading-format="D MMM"></paper-date-picker>
            <!--<div class="buttons">-->
            <!--<paper-button dialog-dismiss>Cancel</paper-button>-->
            <!--<paper-button dialog-confirm>OK</paper-button>-->
            <!--</div>-->
        </paper-dialog>
        <paper-dialog id="dlgCalTo" class="paper-date-picker-dialog" with-backdrop="" on-iron-overlay-closed="dismissDialog">
            <paper-date-picker id="pickerTo" date="{{filterEndDate}}" heading-format="D MMM"></paper-date-picker>
        </paper-dialog>
        <core-overlay id="multidialog" auto-close-disabled="true">
            <app-toolbar class="small">
                <div class="title">Multiple Assets
                </div>
                <paper-icon-button icon="close" on-click="closeMultiOverlayDetail"></paper-icon-button>
            </app-toolbar>
            <div class="content">
                <iron-list id="multiAssetList" items="[[multiAssets]]" as="item" selection-enabled="">
                    <template>
                        <div>
                            <div class="item" tabindex="0" on-click="onItemSelectedFromMulti">
                                <div class="pad">
                                    <div class="primary">
                                        <img class="avatar" src="[[iconForAssetType(item.assetType, item.category)]]">
                                        <!--<span class="primary">[[item.id]]</span>-->
                                        <span class="secondary">[[item.assetType]]</span>
                                        <span class="secondary dim">[[item.name]]</span>
                                    </div>
                                </div>
                                <!--<iron-icon icon\$="[[iconForItem(item)]]"></iron-icon>-->
                            </div>
                        </div>
                    </template>
                </iron-list>
            </div>
        </core-overlay>
        <!-- Fault Reported Overlay-->
        <core-overlay id="faultReportedDetail" auto-close-disabled="true" on-fault-reported-close="closeFaultReportedDetail" on-faults-refresh="onFaultsRefresh">
            <fault-reported-detail fault-id="{{selectedFaultId}}" app="{{app}}">
                </fault-reported-detail>
           <!--  <app-toolbar class="small">
                <div class="title">Fault Reported Details - <span>{{selectedFaultId}}</span></div>
                <paper-icon-button icon="close" on-click="closeFaultReportedDetail"></paper-icon-button>
            </app-toolbar>
            <div class="content">
                <fault-reported-detail fault-id="{{selectedFaultId}}">
                </fault-reported-detail>
            </div> -->
        </core-overlay>
        <!-- Shutdown List Overlay-->
        <div on-show-on-map="showShutdownOnMap">
            <core-overlay id="shutdownListOverlay" class="right-side-overlay" auto-close-disabled="true" on-shutdownlist-close="closeShutdownListOverlay">
                <shutdown-list show-list="{{showShutdownList}}"></shutdown-list>
            </core-overlay>
        </div>
        <!-- Shutdown Notification Overlay-->
        <core-overlay id="shutdownAlertOverlay" class="right-side-overlay" auto-close-disabled="true" on-shutdowndetail-close="closeShutdownAlertOverlay" on-show-asset-detail="onShowAssetDetail">
            <shutdown-detail shutdown-id="{{selectedShutdown}}" app="{{app}}"></shutdown-detail>
        </core-overlay>
        <!--Customer-->
        <core-overlay id="customerdetail" auto-close-disabled="true" on-customerdetail-close="closeCustomerDetailOverlay">
            <customer-detail customer-no="{{selectedCustomerNo}}">
            </customer-detail>
        </core-overlay>
        <!-- Fault Alert List Overlay-->
        <div on-show-on-map="showShutdownOnMap" on-fault-alert-open="onFaultAlertOpen" on-fault-alert-close="onFaultAlertClose" on-show-asset-detail="onShowAssetDetail" on-show-fault-reported-detail="onShowFaultReportedDetail">
            <core-overlay id="faultAlertListOverlay" class="right-side-overlay" auto-close-disabled="true" on-fault-alert-list-close="closeFaultAlertListOverlay">
                <fault-alert-list show-list="{{showFaultAlertList}}" app="{{app}}" selected-fault-alert="{{faultAlertInfo}}"></fault-alert-list>
            </core-overlay>
        </div>
        <!-- Live Outages List Overlay-->
        <core-overlay id="liveOutageListOverlay" class="right-side-overlay" auto-close-disabled="true" on-shutdownlist-close="closeLiveOutageListOverlay" style="background-color: transparent;" on-show-on-map="showShutdownOnMap" on-show-asset-detail="onShowAssetDetail">
            <live-outages-list show-list="{{showLiveOutageList}}" data="{{realTimeTransformerOutagesItems}}" icp-data="{{outageEventInfo.icpEvents}}" show-large-mode="{{isShowLargeMode}}" unchecked-cats="{{uncheckedCats}}" unchecked-disconnects="{{uncheckedDisconnects}}" selected-duration="{{selectedDuration}}">
            </live-outages-list>
        </core-overlay>
        <!--Meter-->
        <core-overlay id="meterdetail" auto-close-disabled="true" on-meterdetail-close="closeMeterDetailOverlay">
            <meter-detail meter-no="{{selectedMeterNo}}">
            </meter-detail>
        </core-overlay>

        <!--Meter-->
        <core-overlay id="dispatchOverlay" auto-close-disabled="true" on-dispatch-close="closeDispatchOverlay" on-dispatch-min="onDispatchMin" on-dispatch-max="onDispatchMax">
            <dash-dispatch app="{{app}}" dragged-resource="{{draggedResource}}" show-close="" activated="{{showDispatch}}"></dash-dispatch>
        </core-overlay>

        <!-- WO-Details -->
        <core-overlay id="wodetail" auto-close-disabled="true" on-wodetail-close="closeWorkOrderOverlay" on-eventdetail-open="openEventOverlay">
            <wo-detail wo-id="{{selectedServiceOrder.no}}" app="{{app}}">
            </wo-detail>
        </core-overlay>

        <core-overlay id="faultAlertOverlay" class="right-side-overlay" auto-close-disabled="true" on-alertdetail-close="closeEventAlertOverlay">
            <fault-alert-detail shutdown-id="{{selectedEventId}}" app="{{app}}" info="{{faultAlertInfo}}"></fault-alert-detail>
        </core-overlay>
        
        <iron-localstorage name="cpl-call-types" id="lsCallTypes" value="{{callTypes}}"></iron-localstorage>
        <paper-dialog id="dlgNewCall" >
             <div class="horizontal layout">
               <template is="dom-if" if="{{!icpCallInfo.isWorkOrderRequired}}">
                    <template is="dom-if" if="{{icpCallInfo.isPartOfEvent}}">
                        <paper-card class="info">
                            <div class="card-content">This ICP is part of Event <span>{{icpCallInfo.eventId}}</span> and will be attached to that event.</div>
                        </paper-card>
                    </template>
                    <template is="dom-if" if="{{icpCallInfo.isPartOfShutdown}}">
                        <paper-card class="info">
                            <div class="card-content">This ICP is part of Planned Shutdown <span>{{icpCallInfo.shutdownId}}</span> and will be attached to that Shutdown.</div>
                        </paper-card>
                    </template>
                </template>
                <template is="dom-if" if="{{icpCallInfo.isWorkOrderRequired}}">
                    <paper-card class="info">
                        <div class="card-content">A new Event and associated Work Order will be created.</div>
                    </paper-card>
                </template>
             </div>

             <template is="dom-if" if="{{icpCallInfo.isWorkOrderRequired}}">
                <div class="horizontal layout justified">
                    <paper-checkbox checked="{{icpCallInfo.isShowOnPublicSite}}">Show on Public Site</paper-checkbox>
                    <paper-checkbox checked="{{icpCallInfo.isSendAlertEnabled}}">Send Comms</paper-checkbox>
                </div>
            </template>
             
            <paper-input label="New Call - Name" value="{{icpCallInfo.customerName}}"></paper-input>
            <div class="horizontal layout">
                <paper-input label="Email" value="{{icpCallInfo.email}}" class="flex-2"></paper-input>
                <paper-input label="Mobile" value="{{icpCallInfo.mobileNo}}" class="flex-1"></paper-input>
            </div>
            <div class="horizontal layout">
                <div class="flex">
                    <paper-input label="Call Type" value="{{callTypeSearchText}}" on-input="onCallTypeSearchTextInput">
                        <paper-icon-button slot="suffix" icon="arrow-drop-down" on-click="onToggleCallTypes"></paper-icon-button>
                    </paper-input>
                    <iron-collapse id="collapse" style="overflow:auto">
                        <div>
                            <template is="dom-repeat" items="[[filterItems(callTypes,callTypeSearchText)]]">
                                <paper-item style="border-bottom: solid 1px #B6B6B6" on-click="onCallTypeSelected">
                                    {{item.description}}
                                </paper-item>
                            </template>
                        </div>
                    </iron-collapse>
                </div>
                
            </div>
            <div>
                <paper-checkbox checked="{{icpCallInfo.isWorkOrderRequired}}" disabled="{{_checkDisabledWorkorder(icpCallInfo.icpInfo, icpCallInfo.serviceCallType)}}">Create Work Order Event</paper-checkbox>
            </div>
            


             <div class="horizontal layout">
                <div class="flex-2">
                     <paper-dropdown-menu label="Premises Type" placeholder="HOUSE">
                        <paper-listbox slot="dropdown-content" selected="{{icpCallInfo.premisesType}}" attr-for-selected="data-value">
                            <paper-item data-value="BUSINESS">BUSINESS</paper-item>
                            <paper-item data-value="AGRICULTURE">Agriculture</paper-item>
                            <paper-item data-value="FARM COTTAGE">FARM COTTAGE</paper-item>
                            <paper-item data-value="HOUSE">HOUSE</paper-item>
                            <paper-item data-value="PUMP">PUMP</paper-item>
                            <paper-item data-value="RADIO SITE">RADIO SITE</paper-item>
                            <paper-item data-value="WATER PUMP">WATER PUMP</paper-item>
                            <paper-item data-value="WOOLSHED">WOOLSHED</paper-item>
                            <paper-item data-value="WORKSHOP">WORKSHOP</paper-item>
                        </paper-listbox>
                    </paper-dropdown-menu>
                </div>
                <div class="flex-1">
                   <paper-dropdown-menu label="Relationship">
                        <paper-listbox slot="dropdown-content" selected="{{icpCallInfo.relationship}}" attr-for-selected="name">
                            <paper-item name="Owner">Owner</paper-item>
                            <paper-item name="Tenant">Tenant</paper-item>
                            <paper-item name="Property Manager">Property Manager</paper-item>
                            <paper-item name="Other">Other</paper-item>
                        </paper-listbox>
                    </paper-dropdown-menu>
                </div>
            </div>
            <!--<paper-checkbox checked="{{icpCallInfo.isPropertyOwner}}">Property Owner</paper-checkbox>-->
            <paper-checkbox checked="{{icpCallInfo.isAcceptAllCharges}}">Accept All Charges</paper-checkbox>
            <paper-checkbox checked="{{icpCallInfo.isCovidQuestionsConfirmed}}" style="margin-top: 30px" >All current COVID-19 or infectious disease screening questions confirmed to be of no interest</paper-checkbox>
            <paper-textarea label="Details" id="t2" value="{{icpCallInfo.details}}" rows="2"></paper-textarea>
            <paper-checkbox checked="{{icpCallInfo.isEmailUpdates}}">Email Updates</paper-checkbox>
            <paper-checkbox checked="{{icpCallInfo.isSmsUpdates}}">SMS Updates</paper-checkbox>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onNewCallSubmitClick">Create Call</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgResourceAllocate">
            <h3>Allocate Resource</h3>
            <div>
                Are you sure you want to allocated Work Order <span>{{allocateResourceSo.no}}</span> <br>to <span>{{allocateResourceSo.allocatedResource.resourceName}}</span>
            </div>
            <paper-dialog-scrollable>
                Description: <span>{{allocateResourceSo.description}}</span>
            </paper-dialog-scrollable>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                 <paper-button dialog-confirm="" autofocus="" on-click="onConfirmResourceAllocationClick" style="background-color: #FFA000;" raised="">Confirm</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgResourceAllocateWarning">
            <h3>Allocated Warning</h3>
            <div>
                This Work Order <span>{{allocateResourceSo.no}}</span> is allocated to <span>{{allocateResourceSo.resourceName}}</span>.
                <p>Are you sure you want to re-allocated to <span>{{allocateResourceSo.allocatedResource.resourceName}}</span></p>
            </div>
            <paper-dialog-scrollable>
                Description: <span>{{allocateResourceSo.description}}</span>
            </paper-dialog-scrollable>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onConfirmResourceAllocationClick" style="background-color: #FFA000;" raised="">Confirm</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgFaultAllocate">
            <h3>Allocate Fault</h3>
        <div>
            Are you sure you want to allocated the fault to Work Order <span>{{allocateFaultSo.no}}</span>.
        </div>
        <paper-dialog-scrollable>
            <div>WO Description: <span>{{allocateFaultSo.description}}</span></div>
            <div>Fault Description: <span>{{allocateFaultSo.allocatedFault.commentReported}}</span></div>
        </paper-dialog-scrollable>
            <div class="buttons">
                <paper-button dialog-dismiss="" on-click="onCancelFaultAllocationClick">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onConfirmFaultAllocationClick" style="background-color: #FFA000;" raised="">Confirm</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgNewFeederEvent" no-cancel-on-outside-click="">
            
        <h2>Add Feeder Event Work Order</h2>
        <paper-dialog-scrollable>
                    <div class="horizontal around-justified layout">
                        <div class="verticle layout flex ">
                            <paper-checkbox checked="{{feInfo.isShowOnPublicSite}}">Show on Public Site</paper-checkbox>
                            <paper-input label="Feeder" value="{{feInfo.feederName}}" readonly=""></paper-input>
                           
                            <paper-dropdown-menu label="Priority" style="width:100%">
                                <paper-listbox slot="dropdown-content" selected="{{feInfo.priority}}">
                                    <paper-item>Low</paper-item>
                                    <paper-item>Medium</paper-item>
                                    <paper-item>High</paper-item>
                                </paper-listbox>
                            </paper-dropdown-menu>
    
                            <paper-dropdown-menu label="Service Type" style="width:100%">
                                <paper-listbox slot="dropdown-content" selected="{{feInfo.serviceType}}" attr-for-selected="name">
                                    <paper-item name="FEEDER">Feeder Fault</paper-item>
                                </paper-listbox>
                            </paper-dropdown-menu>
    
                        </div>
                        <div class="spacer"></div>
                        <div class="verticle layout flex ">
                            <paper-checkbox checked="{{feInfo.isSendAlertEnabled}}">Send Comms</paper-checkbox>
                            <paper-input label="Asset ({{feInfo.assetType}})" value="{{feInfo.feederCode}}"></paper-input>
                            <paper-input label="Equipment #" value="{{feInfo.equipmentNo}}"> </paper-input>
                            <paper-input label="Equipment Desc#" value="{{feInfo.equipmentDescription}}"> </paper-input>
                        </div>
                    </div>
                    <paper-input label="Description" value="{{feInfo.description}}"></paper-input>
                    <paper-textarea label="Public Comment" value="{{feInfo.generalComments}}" rows="3"></paper-textarea>
                    <paper-textarea label="Fault Comment" value="{{feInfo.faultComments}}" rows="3"></paper-textarea>
        </paper-dialog-scrollable>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onConfirmNewFeederClick" style="background-color: #FFA000;" raised="">Confirm</paper-button>
            </div>
        </paper-dialog>

        <iron-ajax id="ajaxHeatMap" url="{{heatMapUrl}}" handle-as="json" on-response="drawHeatMap" loading="{{isLoading}}"></iron-ajax>
        <paper-toast id="toast" text=""></paper-toast>
        <iron-ajax id="ajxWOs" url="/api/so/latest" handle-as="json" last-response="{{sos}}" on-response="_onSoLatestResponse"></iron-ajax>
        <iron-localstorage name="cpl-so" id="lsSos" value="{{sos}}"></iron-localstorage>
        <iron-localstorage name="cpl-feeders" id="lsFeeders" value="{{feedersData}}"></iron-localstorage>
        <iron-localstorage name="cpl-vehicles" id="lsVehicles" value="{{vehicles}}"></iron-localstorage>
        <!--<iron-localstorage name="cpl-vehicle-positions" id="lsVehiclePositions" value="{{vehiclePositions}}"></iron-localstorage>-->
        <iron-localstorage name="cpl-selected-alert" id="lsAlert" iron-localstorage-load="onSelectedAlertLoaded" value="{{selectedAlert}}"></iron-localstorage>
        <iron-localstorage name="username" use-raw="" value="{{username}}"></iron-localstorage>
    `;
  }

    _initMap() {
        _mapBounds = new google.maps.LatLngBounds();
        _mapBounds.extend(new google.maps.LatLng(-37.050245, 174.530761)); //nw
        _mapBounds.extend(new google.maps.LatLng(-37.590839, 174.760101)); //sw
        _mapBounds.extend(new google.maps.LatLng(-36.92245, 175.204253)); //ne
        _mapBounds.extend(new google.maps.LatLng(-37.150466, 175.30725)); //se

        gmap = new google.maps.Map(this.$.mapv, {
            center: { lat: -37.212009, lng: 174.902743 },
            zoom: 14,
            minZoom: 10,
            mapTypeId: 'roadmap',
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: google.maps.ControlPosition.TOP_CENTER
            },
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_TOP
            },
            streetViewControl: true,
            streetViewControlOptions: {
                position: google.maps.ControlPosition.LEFT_TOP
            }
        });
        var trafficLayer = new google.maps.TrafficLayer();
        trafficLayer.setMap(gmap);

        google.maps.event.addListener(gmap, 'idle', () => {
            // console.log(gmap.bounds);
            this._getAssetByBounds();
        });
        gmap.addListener('zoom_changed', () => {
            this.onShowPlannedOutages();
            this.zoomLevel = gmap.getZoom();
        });
        // setInterval(() => {
        //     this._clearVehMarkers();
        //     this.showVehicles();
        // }, 60000);
        isMapLoaded = true;

        //Get boundary details
        fetch(`/api/boundary`, { credentials: 'include' })
                .then(resp => resp.json())
                .then(json => {
                    if (json !== null) {
                        this.drawBoundaryOnMap(json);
                    }
                    this.set('isLoading', false);
                });
    }

  appChanged(nApp){
    if(nApp.role.toUpperCase() === "EXTERNALUSER"){
        this.hasAccess = false;
    }else{
        this.hasAccess = true;
    }

}

    faultAlertInfoChanged(fInfo) {
        if (this.faultAlertInfo) {
            var items = [];
            var itemsRestored = [];
            this.faultAlertInfo.feeders.forEach(f => {
                var trans = f.transformers;
                var trxNos = trans.map((v) => {
                    return v.transformerNo;
                });
                items = [...new Set(trxNos)];
            });


            this.faultAlertInfo.restoredFeeders.forEach(f => {
                var transRestored = f.transformers;
                var trxNosRestored = transRestored.map((v) => {
                    return v.transformerNo;
                });
                itemsRestored = [...new Set(trxNosRestored)];
            });



            this.dispatchEvent(new CustomEvent('show-alert-transformers', {
                bubbles: true, composed: true, detail: {
                    items: items,
                    itemsRestored: itemsRestored
                }
            }));

        }

        // console.log(fInfo);
    }

    draggedResourceChanged(nDraggedResource) {
        // console.log(nDraggedResource);
        isInDrag = true;
        if (nDraggedResource.resourceNo) {
            // google.maps.event.addListener(gmap, 'mouseup',()=>{
            //         console.log("mouse up on map");
            //     });
            this.$.mapv.focus();
            soMarkers.forEach((m) => {
                m.addListener('mouseover', () => {
                    let so = m.get("so");
                    so.allocatedResource = nDraggedResource;
                    // console.log(so);
                    this.allocateResourceSo = so;
                    if (so.status == "SCHEDULED") {
                        this.$.dlgResourceAllocate.open();
                    } else {
                        this.$.dlgResourceAllocateWarning.open();
                    }
                    soMarkers.forEach((m) => {
                        google.maps.event.clearListeners(m, 'mouseover');
                    });
                });
            });

        } else {

            setTimeout(() => {
                isInDrag = false;
                // Remove all click listeners from marker instance.
                //  draggedResourceNo = null;
                soMarkers.forEach((m) => {
                    google.maps.event.clearListeners(m, 'mouseover');
                });

            }, 2000);
        }
    }

    filterStartDateChanged(newValue) {
        this.set('fromDate', moment(newValue).format("DD.MM.YYYY"));
        this.$.dlgCalFrom.close();
    }

    filterEndDateChanged(newValue) {
        this.set('toDate', moment(newValue).format("DD.MM.YYYY"));
        this.$.dlgCalTo.close();
    }

    isShowLargeModeChanged(newValue) {
        // console.log(`show large mode ${newValue}`);
        this.$.liveOutageListOverlay.toggleClass('right-side-overlay-large', newValue);
        this.$.liveOutageListOverlay.toggleClass('right-side-overlay', !newValue);
    }

    isDisconnectionCategoryListChanged(changeRecord) {
        if (changeRecord === null) return;

        if (this.outageEventInfo !== null) {
            this.outageEventInfoChanged(this.outageEventInfo, null);
        }
    }

    uncheckedDisconnectsChanged(changeRecord) {
        if (changeRecord === null) return;

        if (this.outageEventInfo !== null) {
            this.outageEventInfoChanged(this.outageEventInfo, null);
        }
    }

    selectedDurationChanged() {
        // if (this.outageEventInfo !== null) {
        //     this.outageEventInfoChanged(this.outageEventInfo, null);
        // }
        this.set('isLoading', true);
        this.getTransformerOutages();
    }
    openDispatchOverlay() {
        this.$.dispatchOverlay.open();
        this.set('showDispatch', true);
    }
    closeDispatchOverlay() {
        this.selectedMenu = -1;
        this.$.dispatchOverlay.close();
        this.set('showDispatch', false);
    }

    onDispatchMin(){
        this.$.dispatchOverlay.style.height = "";

    }

    onDispatchMax(){
        this.$.dispatchOverlay.style.height = "100%";
    }

    getDurationFromFilterDays(duration) {
        let dur = 0;
        switch (duration) {
            case "5M":
                dur = 300;
                break;
            case "30M":
                dur = 1800;
                break;
            case "1H":
                dur = 3600;
                break;
            case "6H":
                dur = 21600;
                break;
            case "12H":
                dur = 43200;
                break;
            case "1D":
                dur = 86400;
                break;
            case "7D":
                dur = 604800;
                break;
            case "30D":
                dur = 2592000;
                break;
            case "60+":
                dur = 9999999999999;
                break;

        }
        return dur;
    }

    outageEventInfoChanged(newValue) {

        // let transformerEvents = newValue.transformerEvents || [];
        // let icpEvents = newValue.icpEvents || [];
        // this.set(`realTimeLastGaspCount`, `Real Time (${icpEvents.length})`);

        realTimeOutageMarker.forEach((m) => {

            m.setMap(null);
            m = null;
        });
        realTimeOutageMarker = [];

        if (newValue.progressPingEvents) {
            newValue.progressPingEvents.forEach(p => {
                let ll = new google.maps.LatLng(p.lat, p.lng);
                let marker = new google.maps.Marker({
                    map: gmap,
                    position: ll,
                    title: `Pinging ${p.assetNo}`,
                    // icon: {
                    //     path: google.maps.SymbolPath.CIRCLE,
                    //     scale: 6,
                    //     strokeWeight: 3
                    //   }

                    icon: this._getIcon("light")
                });
                realTimeOutageMarker.push(marker);
            });
        }



        // realtimeMarkerEvents.forEach((e) => e.cancel);
        // realtimeMarkerEvents = [];

        // if (this.isShowRealTime && this.isRealTimeViewAllowed(this.app)) {

        //     var dur = this.getDurationFromFilterDays(this.selectedDuration);
        //     icpEvents.forEach((oe) => {

        //             var catDesc = oe.categoryDescription;
        //             var discDesc = oe.disconnectionReason;
        //             var isIcpIgnore = oe.isIgnoreFromRealTime;
        //             var isMeterRemoved = oe.isRemoved;
        //             var shouldBeHidden = false;
        //             if (this.uncheckedCats.includes("HIDE-IGNORED") && isIcpIgnore) {
        //                 shouldBeHidden = true;
        //             }

        //             if (this.uncheckedDisconnects.includes("DISCONNECT AND REMOVE METERS") && isMeterRemoved) {
        //                 shouldBeHidden = true;
        //             }

        //             if (this.uncheckedCats.includes('uncategorised') && catDesc.length === 0) {
        //                 shouldBeHidden = true;
        //             }
        //             if (oe.durSec > dur) {
        //                 shouldBeHidden = true;
        //             }

        //             if (!this.uncheckedCats.includes(catDesc) && !this.uncheckedDisconnects.includes(discDesc) && !shouldBeHidden) {
        //                 this.showInRealTime(transformerEvents, oe.transformerCode.toString());


        //             }else{
        //                 //Remove from tranformer count so it doesn't show.
        //                 transformerEvents.forEach((te) => {
        //                     if (te.transformerCode === oe.transformerCode ) {
        //                         //Reduce Unverified
        //                         te.unverifiedCount --;
        //                     }
        //                 });

        //             }

        //     });

        //     // Transformers
        //     var trx = [];
        //     // this.set('realTimeTransformerOutagesItems', trx);
        //     transformerEvents.forEach((oe) => {
        //         var outageVsMeterCount = Math.round((oe.verifiedCount + oe.unverifiedCount)/(oe.smartMeterCount ) * 100);
        //         oe.outageVsMeterCount = outageVsMeterCount;

        //         if(outageVsMeterCount > 15 && oe.isShowOnMap){
        //             trx.push(oe);
        //             // this.push('realTimeTransformerOutagesItems', oe);

        //             let ll = new google.maps.LatLng(oe.lat, oe.lng);
        //             let marker = new google.maps.Marker({
        //             map: gmap,
        //             position: ll,
        //             title: oe.transformerCode.toString() + "-" + outageVsMeterCount.toString(),
        //             icon: oe.verifiedCount > oe.unverifiedCount ? this._getIcon("transformer_outage",-1): this._getIcon("transformer_outage", oe.outageVsMeterCount)
        //         });


        //         realTimeOutageMarker.push(marker);

        //         }else{
        //     //         var index = transformerEvents.indexOf(oe);
        //     // this.splice(transformerEvents, index, 1);
        //         }

        //     });
        //      trx.sort((a, b)=>{return b.outageVsMeterCount - a.outageVsMeterCount});
        //     this.set('realTimeTransformerOutagesItems', trx);
        //     // this.set("realTimeLastGaspCount", `Real Time (${totalIcpShowing}/${icpEvents.length})`);
        //     this.set("realTimeLastGaspCount", `Real Time (${transformerEvents.length})`);
        // }
        //Show Pings on Map

        // let currentPings = newValue.progressPingEvents;

    }

    showOnMapLiveAlerts(transformerEvents, transformerCode) {
        if (gmap === null) {
            return false;
        }
        if (gmap.getZoom() > 16) {
            return true;
        } else {
            var isShow = true;
            transformerEvents.forEach((te) => {
                if (te.transformerCode === transformerCode) {
                    isShow = false;
                }
            });
            return isShow;
        }
    }

    showInRealTime(transformerEvents, transformerCode) {
        // if(transformerCode.toString() === "5D881"){
        //     console.log("Here");
        // }
        //  console.log(transformerCode);
        transformerEvents.forEach((te) => {
            // var tc = te.transformerCode.toString();
            if (te.transformerCode === transformerCode) {
                // te.unverifiedCount += 1;
                te.isShowOnMap = true;
            }
        });
    }

    activatedChanged(newValue) {
        // console.log(`map view changed ${newValue}`);
        if (newValue) {
            if (isMapLoaded === false) {
                this._initMap();
                setInterval(() => {
                    this.set('isNavOpened', true);
                }, 1000);

            }
            google.maps.event.trigger(gmap, "resize");
            this.$.lsFeeders.reload();
        }
    }

    // connectedCallback() {
    //   super.connectedCallback();
    //   window.addEventListener('assetdetail-close', this._boundListener);
    // }

    // disconnectedCallback() {
    //   super.disconnectedCallback();
    //   window.removeEventListener('assetdetail-close', this._boundListener);
    // }


    ready() {
        super.ready();

        //   this._boundListener = this.closeAssetDetail.bind(this);


        //   this.addEventListener('assetdetail-close', this.closeAssetDetail);
        this._initMap();
        const h = window.innerHeight;
        this.$.mapv.style.height = (h - 128) + "px";
        this.$.menunav.style.height = (h - 128) + "px";

        this.addEventListener('show-asset-detail', this.onShowAssetDetail);
        this.addEventListener('show-power-down-heatmap', this.onPowerDownHeatMap);
        this.addEventListener('show-alert-transformers', this.showAlertTransformers);
        this.addEventListener('show-fault-reported-detail', this.onShowFaultReportedDetail);
        this.addEventListener('fault-alert-open', this.onFaultAlertOpen);
        this.addEventListener('fault-alert-close', this.onFaultAlertClose);

        window.addEventListener('resize', () => {
            const h = window.innerHeight;
            this.$.mapv.style.height = (h - 128) + "px";
            this.$.menunav.style.height = (h - 128) + "px";
        }, false);


        window.addEventListener('storage', (e) => {
            if (e.key === "cpl-selected-alert") {
                this.$.lsAlert.reload();
            }
        }, false);

        this.uncheckedCats.push("HIDE-IGNORED");
        this.isShowFaultsReportedNewChecked = true;
        this.isShowFaultsReportedProcessedChecked = true;

        setInterval(() => {
            this.onFaultsRefresh();
        }, 60000);
        this.onFaultsRefresh();

        setInterval(() => {
            this.getTransformerOutages();
        }, 30000);
        this.getTransformerOutages();
    }

    isFilterInvalid(fromDateInvalid, toDateInvalid, fromTimeInvalid, toTimeInvalid) {
        if (fromDateInvalid || toDateInvalid || fromTimeInvalid || toTimeInvalid) {
            return true;
        } else {
            return false;
        }
    }

    onCalFromClick() {
        this.$.dlgCalFrom.open();
    }

    onCalToClick() {
        this.$.dlgCalTo.open();
    }

    onCapacitorChanged() {
        this._getAssetByBounds();
    }

    onFeederChanged() {
        this._getAssetByBounds();
    }

    onShowTreesChanged() {
        this._getAssetByBounds();
    }

    onShowLightChanged() {
        this._getAssetByBounds();
    }

    onShowLinkBoxChanged() {
        this._getAssetByBounds();
    }

    onShowOhSwitchChanged() {
        this._getAssetByBounds();
    }

    onShowLvLinesChanged() {
        if (lTimer !== null) {
            clearTimeout(lTimer);
        }
        lTimer = setTimeout(() => this.getLines(), 1500);
    }

    onShowFeederChanged(e) {
        var model = e.model;

        let item = model.get("item");
        let feederId = item.id;
        if (model.get("item.checked")) {
            this.set('isLoading', true);
            fetch(`/api/feeder/geo/${feederId}`, { credentials: 'include' })
                .then(resp => resp.json())
                .then(json => {
                    if (json !== null) {
                        this.drawFeederPoly(json, item);
                    }
                    this.set('isLoading', false);
                });

        } else {

            for (var i = 0; i < feederPolygons.length; i++) {
                if (feederPolygons[i].get("id") == feederId) {
                    feederPolygons[i].setMap(null);
                    feederPolygons[i].get("evt").cancel;
                    feederPolygons.splice(i, 1);
                }
            }

        }

    }

    getTransformerOutages() {

        fetch(`/api/transformers/outages/dur/${this.selectedDuration}`, { credentials: 'include' })
            .then(resp => resp.json())
            .then(json => {
                if (json !== null) {
                    this._drawRealtimeTransformerOutages(json);
                }
                this.set('isLoading', false);
            });
    }

    _drawRealtimeTransformerOutages(transformerEvents) {

        // this.set(`realTimeLastGaspCount`, `Real Time (${transformerEvents.length})`);

        transformerOutageMarkers.forEach((m) => {
            m.setMap(null);
        });
        // realTimeOutageMarker = [];

        transformerOutageMarkerEvents.forEach((e) => e.cancel);
        transformerOutageMarkerEvents = [];

        if (this.isShowRealTime && this.isRealTimeViewAllowed(this.app)) {

            // var dur = this.getDurationFromFilterDays(this.selectedDuration);


            // Transformers
            var trx = [];
            // this.set('realTimeTransformerOutagesItems', trx);
            transformerEvents.forEach((oe) => {

                if (oe.outageVsMeterCount > 15) {
                    trx.push(oe);
                    // this.push('realTimeTransformerOutagesItems', oe);

                    let ll = new google.maps.LatLng(oe.lat, oe.lng);
                    let marker = new google.maps.Marker({
                        map: gmap,
                        position: ll,
                        title: oe.transformerCode.toString() + "-" + oe.outageVsMeterCount.toString(),
                        icon: oe.verifiedCount > oe.unverifiedCount ? this._getIcon("transformer_outage", -1) : this._getIcon("transformer_outage", oe.outageVsMeterCount)
                    });


                    transformerOutageMarkers.push(marker);

                }
            });
            trx.sort((a, b) => { return b.outageVsMeterCount - a.outageVsMeterCount });
            this.set('realTimeTransformerOutagesItems', trx);
            // this.set("realTimeLastGaspCount", `Real Time (${totalIcpShowing}/${icpEvents.length})`);
            this.set("realTimeLastGaspCount", `Real Time (${transformerEvents.length})`);
        }

    }

    drawBoundaryOnMap(lines) {

        var lineSymbol = {
            path: google.maps.SymbolPath.CIRCLE,
            strokeOpacity: 1,
            scale: 3
        };

        lines.forEach((line) => {
            line.geometries.forEach((geo) => {
                var boundaryCoords = [];
                geo.coordinates.forEach((c) => {
                    boundaryCoords.push(new google.maps.LatLng(c[1], c[0]));
                });
                // console.log(boundaryCoords);
                var boundaryPoly = new google.maps.Polyline({
                    path: boundaryCoords,
                    geodesic: true,
                    icons: [{
                        icon: lineSymbol,
                        offset: '0',
                        repeat: '10px'
                    }],
                    strokeColor: "#CC365E",
                    strokeOpacity: 0,
                    // strokeWeight: 3,
                });
                boundaryPoly.setMap(gmap);
                // boundaryPoly.set("id", "boundary");
            });
        });
    }

    drawFeederPoly(feederGeos, item) {
        var feederCoords = [];
        let latSum = 0;
        let lngSum = 0;
        let feederId = item.id;
        feederGeos.forEach((so) => {
            let lat = so.lat;
            let lng = so.lng;
            latSum += so.lat;
            lngSum += so.lng
            feederCoords.push(new google.maps.LatLng(lat, lng));
        });
        if (feederCoords.length > 0) {
            var feederPoly = new google.maps.Polygon({
                paths: feederCoords,
                strokeColor: '#FF0000',
                strokeOpacity: 0.5,
                strokeWeight: 1,
                fillColor: '#FF0000',
                fillOpacity: 0.10
            });

            feederPoly.setMap(gmap);
            feederPoly.set("id", feederId);
            feederPolygons.push(feederPoly);


            let avgLat = latSum / feederGeos.length;
            let avgLng = lngSum / feederGeos.length;
            const latLng = new google.maps.LatLng(avgLat, avgLng);



            let addAlertBtn = "";
            if (faultAlertEnabled) {
                    addAlertBtn = `<paper-button id="btnFeederAddToAlert" raised>
                    ADD FEEDER
                    </paper-button>`;
            }
            //<iron-icon icon="add"></iron-icon>

            let infoDiv = document.createElement('div');
            infoDiv.innerHTML =
                `<div id='feederInfoContainer'>
                <paper-card heading="Feeder - ${item.name}">
                    <div class="card-content">
                    
                    </div>
                    <div class="card-actions">
                    <div class="horizontal justified">
                        
                        <paper-button id="btnShowFeederDetails" raised>DETAILS</paper-button>
                        <paper-button id="btnNewFeederEvent" class='call' raised>NEW EVENT</paper-button>
                        ${addAlertBtn}
                        </div>
                    </div>
                </paper-card>
            </div>`;

            let infoContainer = infoDiv.firstChild;

            let infoWindow = new google.maps.InfoWindow({
                content: infoDiv,
                position: latLng
            });



            var sub = feederPoly.addListener('click', () => {
                // this.set('selectedAssetType', 'feeder');
                // this.set('selectedAsset', feederId);
                // this.$.assetdetail.open();
                infoWindow.open(gmap)
            });
            feederPoly.set("evt", sub);

            google.maps.event.addDomListener(infoContainer, 'click', (e) => {
                let tid = e.target.id.toString();
                // console.log(tid);
                // if (tid.includes('btnShowFeederIcps')) {
                //     this._hideTransformerIcps();
                //     infoWindow.close();
                //     // this.getTransformerIcps(code, latLng);
                // }
                if (tid.includes('btnShowFeederDetails')) {
                    this.set('selectedAssetType', 'feeder');
                    this.set('selectedAsset', feederId);
                    this.$.assetdetail.open();
                }
                if (tid.includes('btnNewFeederEvent')) {
                    // New Feeder Event
                    this.set('feInfo', {
                        assetID: item.id,
                        feederName: item.name,
                        assetType: "FEEDER",
                        isShowOnPublicSite: true,
                        category: item.category,
                        priority: 2,
                        serviceType: "FEEDER",
                        orderType: "FAULTS",
                        isSendAlertEnabled: true,
                        description: '',
                        generalComments: '',
                        faultComments: '',
                        feederCode: item.feederCode,
                        equipmentNo: `${item.id}`,
                        equipmentDescription: `FEEDER - ${item.name}`,
                        lat: item.lat,
                        lng: item.lng,
                        username: this.app.username,
                    });
                    // this._onNewFeederEvent();
                    this.$.dlgNewFeederEvent.open();
                    infoWindow.close();
                }

                if (tid.includes('btnFeederAddToAlert')) {

                    this.dispatchEvent(new CustomEvent('add-to-alert', {
                        bubbles: true, composed: true,
                        detail: {
                            'transformerId': null,
                            'code': null,
                            'feederId': feederId,
                            'feederCode': item.feederCode,
                            'feederName': item.name
                        }
                    }));
                    infoWindow.close();
                }
                e.preventDefault();
                e.stopPropagation();
            });

        }
    }



    toggleNav() {
        this.set('isNavOpened', !this.isNavOpened);
        google.maps.event.trigger(gmap, "resize");
    }

    toggleRightNav() {
        this.set('isRightNavOpened', !this.isRightNavOpened);
        google.maps.event.trigger(gmap, "resize");
    }
    openRightNav() {
        this.set('isRightNavOpened', true);
        google.maps.event.trigger(gmap, "resize");
    }

    onZoomOut() {
        this.zoomLevel = 10;//gmap.setZoom(10);
    }
    onZoomLevelChange(newZoomLevel) {
        if (gmap) {
            gmap.setZoom(newZoomLevel);
        }
        
    }

    onMainNavClick() {
        this.dispatchEvent(new CustomEvent('nav-open', { bubbles: true, composed: true }));
    }

    onPoleChanged() {
        // console.log("Check Changed Poles $isShowPolesChecked");
        this._getAssetByBounds();
    }

    onShowTransformersChanged() {
        // console.log("Check Changed Transformers $isShowTransformers");
        this._getAssetByBounds();
    }

    onShowSubstationsChanged() {
        // console.log("Check Changed Transformers $isShowTransformers");
        this._getAssetByBounds();
    }

    _showHidePoles() {
        var assetType = 'pole';
        if (!this.isShowPolesChecked) {
            this._removeMarkersByType(assetType);

        }
    }

    _clearVoltageBreachHeatMap() {
        if (_pointArray !== null) {
            _pointArray = [];
            _heatmap.setMap(null);
        }
    }

    // onMeterDataNoneChanged() {
    //     this._clearVoltageBreachHeatMap();
    //     gmap.controls.ControlPosition.TOP_RIGHT.clear();
    // }

    // onVoltageBreachChanged() {
    //     // console.log("Voltage Breach Changed. ");
    //     this._getVoltageBreached();
    //     gmap.controls.ControlPosition.TOP_RIGHT.push(this.$.breachedlegend);
    // }

    breachTypeSelectedChanged(newValue) {
        // console.log(`Old Changed. ${oldValue}`);
        // console.log(`New Changed. ${newValue}`);
        this.set('isShowFilter', false);
        this._clearVoltageBreachHeatMap();
        this._getVoltageBreached();
        if (newValue !== "HideVoltageBreach") {
            this.powerDownTypeSelected = "HidePowerDown";
            this.distGenTypeSelected = "HideDistGen";
            this.consumptionTypeSelected = "HideConsumption";
            this.controlledLoadTypeSelected = "HideControlledLoad";
        }
    }

    controlledLoadTypeSelectedChanged(newValue) {
        // console.log(`Old Changed. ${oldValue}`);
        // console.log(`New Changed. ${newValue}`);
        this.set('isShowFilter', false);
        this._clearVoltageBreachHeatMap();
        this._getControlledLoad();
        if (newValue !== "HideControlledLoad") {
            this.powerDownTypeSelected = "HidePowerDown";
            this.breachTypeSelected = "HideVoltageBreach";
            this.consumptionTypeSelected = "HideConsumption";
            this.distGenTypeSelected = "HideDistGen";
        }
    }

    distGenTypeSelectedChanged(newValue) {
        // do something when the another radio button got selected
        // console.log(`Old Changed. ${oldValue}`);
        // console.log(`New Changed. ${newValue}`);
        this.set('isShowFilter', false);
        if (newValue !== "HideDistGen") {
            this.powerDownTypeSelected = "HidePowerDown";
            this.breachTypeSelected = "HideVoltageBreach";
            this.consumptionTypeSelected = "HideConsumption";
            this.controlledLoadTypeSelected = "HideControlledLoad";

        } else {
            //Delete Markers and Events
            distGenMarker.forEach((m) => {
                m.setMap(null);
            });
            distGenMarker = []; //.clear();

            distGenMarkerEvents.forEach((e) => e.cancel);
            distGenMarkerEvents = []; //.clear();
        }
        this._getVoltageDistributedGeneration();
    }

    powerDownTypeSelectedChanged(newValue) {
        // do something when the another radio button got selected
        // console.log(`Old Changed. ${oldValue}`);
        // console.log(`New Changed. ${newValue}`);
        this.set('isShowFilter', false);
        this._clearVoltageBreachHeatMap();
        this._getPowerDown();
        if (newValue !== "HidePowerDown") {
            this.consumptionTypeSelected = "HideConsumption";
            this.breachTypeSelected = "HideVoltageBreach";
            this.distGenTypeSelected = "HideDistGen";
            this.controlledLoadTypeSelected = "HideControlledLoad";
        } else {
            shutdownMarkers.forEach((m) => {
                m.setMap(null);
            });
            shutdownMarkers = []; //.clear();

            shutdownMarkersEvents.forEach((e) => e.cancel);
            shutdownMarkersEvents = [];
        }
    }

    consumptionTypeSelectedChanged(newValue) {
        // do something when the another radio button got selected
        // console.log(`Old Changed. ${oldValue}`);
        // console.log(`New Changed. ${newValue}`);
        this.set('isShowFilter', false);
        this._clearVoltageBreachHeatMap();
        this._getConsumptionLoad();
        if (newValue != "HideConsumption") {
            this.powerDownTypeSelected = "HidePowerDown";
            this.breachTypeSelected = "HideVoltageBreach";
            this.distGenTypeSelected = "HideDistGen";
            this.controlledLoadTypeSelected = "HideControlledLoad";

        }
    }

    onBreachTypeClick(details, target) {
        var btn = target;
        this.breachTypeSelected = btn.dataset.breach;
    }

    closeFaultReportedDetail() {
        this.$.faultReportedDetail.close();
    }

    closeAssetDetail() {
        this.$.assetdetail.close();
    }

    closeMultiOverlayDetail() {
        this.$.multidialog.close();
    }

    signout() {
        this.dispatchEvent(new CustomEvent('login-required', { bubbles: true, composed: true }));
    }

    onItemSelectedFromMulti(e) {
        var model = e.model;// this.$.multiAssetList.modelForElement(e.target);
        this.set('selectedAssetType', model.get('item.assetType'));
        this.set('selectedAsset', model.get('item.id'));
        this.$.assetdetail.open();
    }

    showShutdownAlertOverlay() {
        this.$.shutdownAlertOverlay.open();
    }

    closeShutdownAlertOverlay() {

        this.$.shutdownAlertOverlay.close();
    }

    showCustomerDetailOverlay() {
        this.$.customerdetail.open();
    }

    closeCustomerDetailOverlay() {
        this.$.customerdetail.close();
    }

    closeMeterDetailOverlay() {
        this.$.meterdetail.close();
    }

    showShutdownListOverlay() {
        this.set('showShutdownList', true);
        this.$.shutdownListOverlay.open();
    }

    closeShutdownListOverlay() {

        this.set('showShutdownList', false);
        this.$.shutdownListOverlay.close();
    }

    showLiveOutageListOverlay() {
        this.set('showLiveOutageList', true);
        this.$.liveOutageListOverlay.open();
    }

    closeLiveOutageListOverlay() {
        this.set('showLiveOutageList', false);
        this.$.liveOutageListOverlay.close();
    }

    showFaultAlertListOverlay() {
        this.set('showFaultAlertList', true);
        this.$.faultAlertListOverlay.open();
        this.toggleRightNav();
    }

    closeFaultAlertListOverlay() {
        this.set('showFaultAlertList', false);
        this.$.faultAlertListOverlay.close();
        this.toggleRightNav();
    }

    onFaultAlertOpen() {
        this.set('isShowTransformers', true);
        faultAlertEnabled = true;
        this._getAssetByBounds();
    }

    onFaultAlertClose() {
        faultAlertEnabled = false;
        this.set('isShowTransformers', false);
        this._getAssetByBounds();
    }

    onToggleCallTypes() {
        this.$.collapse.toggle();
    }

    onCallTypeSearchTextInput() {
        this.$.collapse.show();
    }

    onCallTypeSelected(e) {
        let model = e.model;
        this.$.collapse.hide();
        this.set('icpCallInfo.isWorkOrderRequired', false);
        if (model.item.callType === "Fault" && this.icpCallInfo.icpInfo && this.icpCallInfo.icpInfo.eventId === 0 && this.icpCallInfo.icpInfo.shutdownId === 0) {
            this.set('icpCallInfo.isWorkOrderRequired', true);
        } else if (model.item.callType === "Fault" && this.icpCallInfo.icpInfo && (this.icpCallInfo.icpInfo.eventId > 0 || this.icpCallInfo.icpInfo.shutdownId > 0)) {
            this.set('icpCallInfo.isPartOfEvent', this.icpCallInfo.icpInfo.eventId > 0);
            this.set('icpCallInfo.isPartOfShutdown', this.icpCallInfo.icpInfo.shutdownId > 0);

        } else {
            this.set('icpCallInfo.isWorkOrderRequired', false);
            this.set('icpCallInfo.isPartOfEvent', false);
            this.set('icpCallInfo.isPartOfShutdown', false);
        }
        this.set('icpCallInfo.callType', model.item.value);
        this.set('icpCallInfo.callTypeDescription', model.item.description);
        this.set('icpCallInfo.serviceCallType', model.item.callType);
        this.set('callTypeSearchText', model.item.description);
        if ( model.item.value == 'MEMO' || model.item.value == 'DISCF'){
            this.set('icpCallInfo.isShowOnPublicSite', false);
            this.set('icpCallInfo.isSendAlertEnabled', false);
        }else{
            this.set('icpCallInfo.isShowOnPublicSite', true);
            this.set('icpCallInfo.isSendAlertEnabled', true);
        }


    }

    _checkDisabledWorkorder(icpInfo, serviceCallType) {
        // if(icpInfo && (icpInfo.eventId >0 || icpInfo.shutdownId > 0) && serviceCallType === "Fault"){
        //     return true;
        // }
        return false;
    }

    onAssetSelectedFromSearch(e) {
        let model = e.model;
        this.set('selectedAssetType', model.get('item.assetType'));
        this.set('selectedAsset', model.get('item.id'));
        this.$.assetdetail.open();
    }

    filterItems(items, t) {
        if (items) {
            return items.filter(
                (i) => i.description.toUpperCase().includes(t.toUpperCase()));
        }
        return null;
    }

    onNewCallClick(e) {
        this.$.lsCallTypes.reload();
        let model = e.model;
        let item = model.get('item');
        let id = model.get('item.id');
        let assetType = model.get('item.assetType');
        let lat = model.get('item.lat');
        let lng = model.get('item.lng');
        if (assetType != "icp" && assetType != "icp_ssn" && assetType != "last_gasp" && assetType != "icp_disconnect") {
            this.set('icpCallInfo', {
                icp: item.name,
                customerNo: "",
                customerName: item.customerName,
                email: "",
                mobileNo: "",
                username: this.app.username,
                assetType: assetType.toUpperCase(),
                callType: '',
                callTypeDescription: '',
                premisesType: assetType.toUpperCase(),
                isPropertyOwner: false,
                isAcceptAllCharges: false,
                details: '',
                isEmailUpdates: false,
                isSmsUpdates: false,
                lat: lat,
                lng: lng,
                relationship: '',
                isPartOfEvent: false,
                eventId: 0,
                isPartOfShutdown: false,
                shutdownId: 0,
                isWorkOrderRequired: false,
                icpInfo: {
                    icp: item.name,
                    customerName: item.customerName,
                    phone2: '',
                    propertyName: item.streetAddress,
                    streetAddress: '',
                    lat: lat,
                    lng: lng,
                    id: item.id,
                    town: item.town,
                    feederCode: ''
                },
                serviceCallType: '',
                isShowOnPublicSite: true,
            });
            this.set('callTypeSearchText', "");
            this.set('icpCallInfo.details', model.get('item.name'));


            this.$.dlgNewCall.toggle();
        } else {

            fetch(`/api/icp/summary/${id}`, { credentials: 'include' })
                .then(resp => resp.json())
                .then(info => {
                    this.set(
                        'icpCallInfo', {
                        icp: info.name,
                        customerNo: info.customerNumber,
                        customerName: info.customerName,
                        email: info.email,
                        mobileNo: info.mobileNo,
                        username: this.app.username,
                        assetType: "ICP",
                        callType: '',
                        callTypeDescription: '',
                        premisesType: "HOUSE",
                        isPropertyOwner: false,
                        isAcceptAllCharges: false,
                        details: '',
                        isEmailUpdates: false,
                        isSmsUpdates: false,
                        lat: lat,
                        lng: lng,
                        isWorkOrderRequired: false,
                        isMedicallyDependent: info.isMedicallyDependent,
                        feederCode: info.feederCode,
                        transformerCode: info.transformerCode,
                        relationship: '',
                        isPartOfEvent: info.isPartOfEvent,
                        eventId: info.eventId,
                        isPartOfShutdown: info.isPartOfShutdown,
                        shutdownId: info.shutdownId,
                        icpInfo: info,
                        serviceCallType: '',
                        isShowOnPublicSite: true
                    });
                    this.set('isLoading', false);

                    this.set('callTypeSearchText', "");
                    this.$.dlgNewCall.toggle();
                });


        }
    }

    onNewCallSubmitClick() {

        if (this.icpCallInfo.relationship == "Owner") {
            this.set('icpCallInfo.isPropertyOwner', true);
        }

        if (!this.icpCallInfo.isPartOfEvent) {
            this.set('icpCallInfo.eventId', 0);
        }
        if (!this.icpCallInfo.isPartOfShutdown) {
            this.set('icpCallInfo.shutdownId', 0);
        }

        let data = JSON.stringify(this.icpCallInfo);

        fetch(`/api/icp/calls`, {
            credentials: 'include',
            method: 'POST',
            body: data
        })
            .then(resp => resp.text())
            .then(() => {
                this.showToast("New Call Created");
            })
            .catch(() => {
                this.showToast("Failed to create New Call");
            });


    }
    onConfirmNewFeederClick() {

        let data = JSON.stringify(this.feInfo);

        fetch(`/api/feeder/event`, {
            credentials: 'include',
            method: 'POST',
            body: data
        })
            .then(resp => resp.text())
            .then(() => {
                this.showToast("New Feeder Event Created");
            })
            .catch(() => {
                this.showToast("Failed to create New Feeder Event");
            });

    }

    showToast(text) {
        this.$.toast.show(text);
    }

    onBtnVbClick(e) {
        let btn = e.currentTarget;
        dur = btn.dataset.dur;

        if (this.distGenTypeSelected !== "HideDistGen") {
            this._getVoltageDistributedGeneration();
        }
        if (this.breachTypeSelected !== "HideVoltageBreach") {
            this._getVoltageBreached();
        }
        if (this.controlledLoadTypeSelected !== "HideControlledLoad") {
            this._getControlledLoad();
        }
        if (this.powerDownTypeSelected !== "HidePowerDown") {
            this._getPowerDown();
        }
        if (this.consumptionTypeSelected !== "HideConsumption") {
            this._getConsumptionLoad();
        }
    }

    onCustDateClick() {
        let from = `${this.fromDate} ${this.fromTime}`;
        let to = `${this.toDate} ${this.toTime}`;
        this.getHeatMapDetails(from, to);
    }

    getHeatMapDetails(from, to) {
        this._clearVoltageBreachHeatMap();
        if (this.distGenTypeSelected === "ShowDistGen") {

            this.set('isLoading', true);
            fetch(`/api/icp/distgen/bound/${this.getUrlMapBounds}/from/${from}/to/${to}`, { credentials: 'include' })
                .then(resp => resp.json())
                .then(json => {
                    this._drawHeatMap(json, false);
                    this.drawDistGenMarkers(json);
                    this.set('isLoading', false);
                });


        }
        if (this.controlledLoadTypeSelected === "ShowControlledLoad") {
            this.set(
                'heatMapUrl',
                `/api/icp/cl/bound/${this.getUrlMapBounds}/from/${from}/to/${to}`);
            this.$.ajaxHeatMap.generateRequest();
        }

        if (this.breachTypeSelected === "Neutral") {
            this.set(
                'heatMapUrl',
                `/api/icp/breach/neutral/bound/${this.getUrlMapBounds}/from/${from}/to/${to}`);
            this.$.ajaxHeatMap.generateRequest();
        }

        if (this.breachTypeSelected === "Minimum" || this.breachTypeSelected === "Maximum") {
            this.set(
                'heatMapUrl',
                `api/icp/breach/bound/${this.getUrlMapBounds}/${this.breachTypeSelected}/from/${from}/to/${to}`);
            this.$.ajaxHeatMap.generateRequest();
        }

        if (this.powerDownTypeSelected === "ShowPowerDown") {
            this.set(
                'heatMapUrl',
                `/api/icp/po/bound/${this.getUrlMapBounds}/from/${from}/to/${to}`);
            this.$.ajaxHeatMap.generateRequest();
        }
        if (this.consumptionTypeSelected === "ShowConsumption") {
            this.set('isLoading', true);
            fetch(`/api/icp/pc/bound/${this.getUrlMapBounds}/from/${from}/to/${to}`, { credentials: 'include' })
                .then(resp => resp.json())
                .then(json => {
                    this._drawHeatMap(json, false);
                    this.set('isLoading', false);
                });

        }
    }

    showDateFilterDialog() {
        this.$.dlgfilter.open();
    }

    _getVoltageBreached() {
        this._clearVoltageBreachHeatMap();
        this.set('startDate', "Loading...");
        if (this.breachTypeSelected === "HideVoltageBreach") {
            this.set('startDate', "");
            this.set('heatMapUrl', null);
        }
        if (this.breachTypeSelected === "Neutral") {
            let url =
                `api/icp/breach/neutral/bound/${this.getUrlMapBounds}/${dur}`;
            this.set('heatMapUrl', url);
            this.$.ajaxHeatMap.generateRequest();

            this.set('isShowFilter', true);

        } else if (this.breachTypeSelected !== "HideVoltageBreach") {
            let url =
                `api/icp/breach/bound/${this.getUrlMapBounds}/${this.breachTypeSelected}/${dur}`;
            this.set('heatMapUrl', url);
            this.set('isShowFilter', true);
            this.$.ajaxHeatMap.generateRequest();
        }
    }

    _getVoltageDistributedGeneration() {
        this._clearVoltageBreachHeatMap();
        this.set('startDate', "Loading...");
        if (this.distGenTypeSelected == "HideDistGen") {
            this.set('startDate', "");
        } else {
            this.set('isLoading', true);
            fetch(`/api/icp/distgen/bound/${this.getUrlMapBounds}/${dur}`, { credentials: 'include' })
                .then(resp => resp.json())
                .then(json => {
                    this._drawHeatMap(json, false);
                    this.drawDistGenMarkers(json);
                    this.set('isLoading', false);
                });


        }
    }

    drawDistGenMarkers(caps) {
        //Delete Markers and Events
        distGenMarker.forEach((m) => {
            m.setMap(null);
        });
        distGenMarker = []; //.clear();

        distGenMarkerEvents.forEach((e) => e.cancel);
        distGenMarkerEvents = [];

        let icps = caps.icps;
        icps.forEach((oe) => {
            let ll = new google.maps.LatLng(oe.lat, oe.lng);
            let isDg = oe.isDgIcp;
            // console.log(oe);
            if (isDg) {
                let marker = new google.maps.Marker({
                    map: gmap,
                    position: ll,
                    title: oe.icp.toString(),
                    icon: this.getIcpIconByCategory('dg')
                });

                distGenMarker.push(marker);

                let sub = marker.addListener('click', () => {

                    this.set('selectedAssetType', "icp");
                    this.set('selectedAsset', oe.id.toString());

                    // console.log(selectedAssetType);
                    this.$.assetdetail.open();
                });
                distGenMarkerEvents.push(sub);
            }
        });
    }

    _getControlledLoad() {
        this._clearVoltageBreachHeatMap();
        this.set('startDate', "Loading...");
        if (this.controlledLoadTypeSelected === "HideControlledLoad") {
            this.set('startDate', "");
        } else {
            this.set('isLoading', true);
            fetch(`/api/icp/cl/bound/${this.getUrlMapBounds}/${dur}`, { credentials: 'include' })
                .then(resp => resp.json())
                .then(json => {
                    this._drawHeatMap(json, false);
                    this.set('isLoading', false);
                });

        }
    }

    _getPowerDown() {
        this._clearVoltageBreachHeatMap();
        this.set('startDate', "Loading...");
        if (this.powerDownTypeSelected === "HidePowerDown") {
            this.set('startDate', "");
        } else {
            this.set('isLoading', true);
            fetch(`/api/icp/po/bound/${this.getUrlMapBounds}/${dur}`, { credentials: 'include' })
                .then(resp => resp.json())
                .then(json => {
                    this._drawHeatMap(json, false);
                    this.set('isLoading', false);
                });

        }
    }

    _getConsumptionLoad() {
        this._clearVoltageBreachHeatMap();
        this.set('startDate', "Loading...");
        if (this.consumptionTypeSelected === "HideConsumption") {
            this.set('startDate', "");
        } else {

            this.set('isLoading', true);
            fetch(`/api/icp/pc/bound/${this.getUrlMapBounds}/${dur}`, { credentials: 'include' })
                .then(resp => resp.json())
                .then(json => {
                    // console.log(json);
                    this._drawHeatMap(json, false);
                    this.set('isLoading', false);
                });
        }
    }

    drawHeatMap(response, details) {
        this._drawHeatMap(details.response);
    }

    _onHeatMapExportToCsv() {
        var csvContent = "data:text/csv;charset=utf-8,";
        if (icpHeatData.length > 0) {
            icpHeatData.forEach((item) => {
                let dataRow = item.icp + "," + item.weight;
                csvContent += dataRow + "\n";
            });

            var encodedUri = encodeURI(csvContent);
            window.open(encodedUri);
        }
    }

    _drawHeatMap(caps, trimWeight = true) {
        let icps = caps.icps;
        icpHeatData = [];
        if (icps !== null) {
            let heatData = [];
            icpHeatData = icps;
            icps.forEach((obj) => {
                let ll = new google.maps.LatLng(obj.lat, obj.lng);
                let wt = obj.weight;
                if (trimWeight) {
                    wt = obj.weight > 100 ? 100 : obj.weight;
                }
                heatData.push({ location: ll, weight: wt });
            });

            // _pointArray = new google.maps.MVCArray({elements: heatData});

            _heatmap =
                new google.maps.visualization.HeatmapLayer({ data: heatData });
            _heatmap.setMap(gmap);

            this.$.toast.show("Total ICPS: " + icps.length.toString());
            this.set(`startDate`, `From ${caps.startDate} to ${caps.endDate}`);
            this.set('isShowFilter', true);
        }
    }

    onVehicleChanged() {
        this._clearVehMarkers();
        this.showVehicles();
    }

    onIcpChanged() {
        this._getAssetByBounds();
    }

    onPillarsChanged() {
        // console.log("Check Changed Pillars $isShowPolesChecked");
        this._getAssetByBounds();
    }

    _showHidePillars() {
        if (!this.isShowPillarsChecked) {
            this._removeMarkersByType('pillar');
        }
    }


    _getAssetsSelected() {
        let mAssets = "";
        if (this.isShowCapChecked) {
            mAssets += "|cap";
        }
        if (this.isShowPillarsChecked) {
            mAssets += "|pil";
        }
        if (this.isShowPolesChecked) {
            mAssets += "|pol";
        }
        if (this.isShowIcpChecked && gmap.getZoom() > 16 && this.isIcpLocationViewAllowed(this.app)) {
            mAssets += "|icp";
        }
        if (this.isShowTransformers) {
            mAssets += "|tfr";
        }
        if (this.isShowSubstations) {
            mAssets += "|substn";
        }
        if (this.isShowFeederChecked) {
            mAssets += "|fdr";
        }
        if (this.isShowTreesChecked) {
            mAssets += "|tre";
        }
        if (this.isShowLightChecked) {
            mAssets += "|slt";
        }
        if (this.isShowLinkBoxChecked) {
            mAssets += "|lbx";
        }
        if (this.isShowOhSwitchChecked) {
            mAssets += "|ohs";
        }
        if (this.isShowSwitchingStationsChecked) {
            mAssets += "|swstn";
        }

        return mAssets.substr(1);
    }

    _getAssetByBounds() {
        let mAssets = this._getAssetsSelected();
        _markers.forEach((marker) => marker.setMap(null));
        _markers = []
        _markerEvents.forEach((e) => e.cancel);

        _textOverlays.forEach((m) => m.setMap(null));
        _textOverlays = [];

        _transformerInfoWindows = [];

        if (mAssets.length > 0) {
            // console.log(`requesting...: ${new Date()}`);
            this.isLoading = true;
            fetch(`/api/assets/${mAssets}/bound/${this.getUrlMapBounds}/dist/${this._getGroupingDistance()}`, { credentials: 'include' })
                .then(resp => resp.json())
                .then(json => {
                    if (json !== null) {
                        json.forEach((obj) => this._drawAssetMarker(obj));
                        setTimeout(() => {
                            this.faultAlertInfoChanged()
                        }, 1000);

                    }
                    this.isLoading = false;
                });

            this.getLines();

        } else {
            this.getLines();
        }
    }

    _removeMarkersByType(assetType) {
        _markers.where((m) => m.get('type') === assetType).forEach((marker) => marker.setMap(null));
        _markers.removeWhere((m) => m.get('type') === assetType);
    }

    _drawAssetMarker(obj) {
        const latLng = new google.maps.LatLng(obj.lat, obj.lng);

        let id = obj.id;
        let exists = _markers.find((m) => m.get('id') === id);
        let assetType = obj.assetType;

        if (!exists) {
            let count = obj.totalCount;

            if (assetType === "cluster") {

                let swBound = latLng;
                let neBound = latLng;
                let bounds = new google.maps.LatLngBounds(swBound, neBound);

                let txt = new TextOverlay(bounds, count.toString(), gmap);

                _textOverlays.push(txt);
                let marker = new google.maps.Marker({
                    map: gmap,
                    position: latLng,
                    title: obj.name.toString(),
                    icon: this._getIcon(assetType, count),
                    zIndex: Date.now()
                });


                marker.set("id", id);
                marker.set("type", assetType);

                _markers.push(marker);
                let sub = marker.addListener('click', () => {
                    // let group = id;
                    // console.log("Asset: {$group}");
                    this.$.multidialog.open();
                    this.set('multiAssets', obj.assets);
                    this.$.multiAssetList.notifyResize();
                });
                _markerEvents.push(sub);
            } else {
                let marker = new google.maps.Marker({
                    map: gmap,
                    position: latLng,
                    title: obj.name.toString(),
                    icon: this._getIcon(assetType, count, obj.category),
                    draggable: true,
                    zIndex: Date.now()
                });

                marker.set("id", id);
                marker.set("type", assetType);

                _markers.push(marker);

                if (assetType === "transformer") {
                    let code = obj.name;
                    let addAlertBtn = "";
                    let removeAlertBtn = "";
                    if (faultAlertEnabled) {
                        addAlertBtn = '<paper-button id="btnTransformerAddToAlertIcon">ADD</paper-button>';
                        removeAlertBtn = '<paper-button id="btnTransformerRemoveAlert">RESTORE</paper-button>';
                    }
                    //<paper-icon-button icon="icons:event"></paper-icon-button>

                    let pingButton = `<paper-button id="btnPingTransformer">PING</paper-button>`;
                    // if (this.outageEventInfo.progressPingEvents.filter(v=> v.assetNo == code && v.pingType == "transformer").length > 0){
                    //     pingButton = `<paper-button id="btnNoAction">PING in Progress</paper-button>`;

                    // }

                    let infoDiv = document.createElement('div');
                    infoDiv.innerHTML =
                        `<div id='infoContainer'>
                    
                        <paper-card heading="Transformer - ${obj.name}">
                            <div class="card-content">
                            <p>Rating: ${obj.text1} Kva</p>
                            <p>Site Type: ${obj.text2}</p>
                            </div>
                            <div class="card-actions">
                            <div class="horizontal justified">
                                ${addAlertBtn}
                                ${removeAlertBtn}
                                <paper-button id="btnShowTransformerDetailsIcon">DETAILS</paper-button>
                                <paper-button id="btnShowTransformerIcpsIcon">ICPS</paper-button>
                                <paper-button id="btnHideTransformerIcpsIcon">HIDE</paper-button>
                                ${pingButton}
                                </div>
                            </div>
                        </paper-card>
                    </div>`;

                    let infoContainer = infoDiv.firstChild;

                    let infoWindow = new google.maps.InfoWindow({
                        content: infoDiv,
                        position: latLng
                    });

                    marker.addListener('click', () => infoWindow.open(gmap));
                    _transformerInfoWindows.push(infoWindow);

                    google.maps.event.addDomListener(infoContainer, 'click', (e) => {
                        let tid = e.target.id.toString();
                        // console.log(tid);
                        if (tid.includes('btnShowTransformerIcps')) {
                            this._hideTransformerIcps();
                            infoWindow.close();
                            this.getTransformerIcps(code, latLng);
                        }
                        if (tid.includes('btnShowTransformerDetails')) {
                            this.set('selectedAssetType', assetType);
                            this.set('selectedAsset', id);
                            this.$.assetdetail.open();
                        }
                        if (tid.includes('btnHideTransformerIcps')) {
                            this._hideTransformerIcps();
                            infoWindow.close();
                        }

                        if (tid.includes('btnPingTransformer')) {

                            if (!this.outageEventInfo.progressPingEvents || this.outageEventInfo.progressPingEvents.filter(v => v.assetNo == code && v.pingType == "transformer").length == 0) {
                                this._pingTransformerMeters(code);
                            } else {
                                //toast
                                this.showToast("Ping already in progress...");
                            }

                            infoWindow.close();
                        }

                        if (tid.includes('btnTransformerAddToAlert')) {

                            this.dispatchEvent(new CustomEvent('add-to-alert', {
                                bubbles: true, composed: true, detail: {
                                    'transformerId': id,
                                    'code': code
                                }
                            }));
                            infoWindow.close();
                        }

                        if (tid.includes('btnTransformerRemoveAlert')) {

                            this.dispatchEvent(new CustomEvent('remove-from-alert', {
                                bubbles: true, composed: true, detail: {
                                    'transformerId': id,
                                    'code': code
                                }
                            }));
                            infoWindow.close();
                        }
                        e.preventDefault();
                        e.stopPropagation();
                    });
                } else {
                    let sub = marker.addListener('click', () => {
                        // selectedAssetType = assetType;
                        this.set('selectedAssetType', assetType);
                        // selectedAsset = id;
                        this.set('selectedAsset', id);

                        // console.log(this.selectedAssetType);
                        this.$.assetdetail.open();
                    });
                    _markerEvents.push(sub);
                }
            }
        }
    }

    _pingTransformerMeters(trx) {
        let data = JSON.stringify({
            'transformer': trx,
            'username': this.app.username
        });

        fetch(`/api/ping/transformer/${trx}`, {
            credentials: 'include',
            method: 'POST',
            body: data
        })
            .then(resp => resp.json())
            .then(() => {

                // this._getRealTimeEvents(this.transInfo.name);
                // this.$.ajaxMessageLog.generateRequest();
                this.showToast("Transformer Meters Pinged, you will be notified of the result");
            })
            .catch((error) => {
                console.log('Request Ping failed', error);
                this.showToast("Error Submitting Ping Request");
            });
    }

    _hideTransformerIcps() {
        _transIcpMarker.forEach((m) => m.setMap(null));
        _transIcpMarker = [];
        _feederLines.forEach((polygon) => polygon.setMap(null));
        _feederLines = [];
        _transIcpMarkerEvents.forEach((e) => e.cancel);
    }

    getTransformerIcps(code, centre) {
        this._hideTransformerIcps();
        this.set('isLoading', true);
        fetch(`/api/transformers/icps/${code}`, {
            credentials: 'include'
        })
            .then(resp => resp.json())
            .then(icps => {
                icps.forEach((icp) => {
                    const latLng = new google.maps.LatLng(icp.lat, icp.lng);

                    let id = icp.id;
                    let marker = new google.maps.Marker({
                        map: gmap,
                        position: latLng,
                        title: icp.name,
                        icon: this._getIcon("icp_selected", null)
                    });

                    _transIcpMarker.push(marker);

                    let sub = marker.addListener(() => {
                        this.selectedAssetType = "icp";
                        // this.set('selectedAssetType', "icp");
                        this.selectedAsset = id;
                        // this.set('selectedAsset', id);
                        this.$.assetdetail.open();
                    });
                    _transIcpMarkerEvents.push(sub);

                    let lines = [centre, latLng];
                    let transPath = new google.maps.Polyline({
                        path: lines,
                        geodesic: true,
                        strokeColor: '#922BA7',
                        strokeOpacity: 0.5,
                        strokeWeight: 1
                    });

                    transPath.setMap(gmap);
                    _feederLines.push(transPath);
                });
                this.isLoading = false;
            });



    }

    get assetsLinesSelected() {
        let mAssets = "";
        if (this.isShowLvLinesChecked) {
            mAssets += "|lvl";
        }
        if (this.isShowHvLinesChecked) {
            mAssets += "|hvl";
        }
        if (this.isShowLvCablesChecked) {
            mAssets += "|lvc";
        }
        if (this.isShowHvCablesChecked) {
            mAssets += "|hvc";
        }
        return mAssets.substr(1);
    }

    getLineColor(lineType) {
        let color = 'orange';
        switch (lineType) {
            case "hv_line":
                color = 'red';
                break;
            case "hv_cable":
                color = 'green';
                break;
            case "lv_cable":
                color = 'blue';
                break;
            default:
                break;
        }
        return color;
    }

    getLines() {
        assetLines.forEach((polygon) => polygon.setMap(null));
        assetLines = [];
        let lAssets = this.assetsLinesSelected;
        if (lAssets.length > 0) {
            this.set('isLoading', true);
            fetch(`/api/lines/${lAssets}/bound/${this.getUrlMapBounds}`, { credentials: 'include' })
                .then(resp => resp.json())
                .then(lineDtos => {
                    lineDtos.forEach((icp) => {
                        // let id = icp.id;
                        let points = icp.Points;
                        var lines = [];
                        points.forEach((p) => {
                            const latLng = new google.maps.LatLng(p.lat, p.lng);

                            lines.push(latLng);

                            let transPath = new google.maps.Polyline({
                                path: lines,
                                geodesic: true,
                                strokeColor: this.getLineColor(p.lineType),
                                strokeOpacity: 0.5,
                                strokeWeight: 1
                            });

                            transPath.setMap(gmap);
                            assetLines.push(transPath);
                        });
                    });
                    this.set('isLoading', false);
                });


        }
    }

    iconForAssetType(assetType, category) {
        return this._getIcon(assetType, 0, category);
    }

    showAssetIcon(assetType) {
        let isShow = false;
        switch (assetType) {
            case 'capacitor':
                isShow = true;
                break;
            case 'icp':
                isShow = true;
                break;
            case 'icp_ssn':
                isShow = true;
                break;
            case 'last_gasp':
                isShow = true;
                break;
            case 'icp_disconnect':
                isShow = true;
                break;
            case 'feeder':
                isShow = true;
                break;
            case 'tree':
                isShow = true;
                break;
            case 'transformer':
                isShow = true;
                break;
            case 'substation':
                isShow = true;
                break;
            case 'oh_switch':
                isShow = true;
                break;
            case 'pole':
                isShow = true;
                break;
            case 'linkbox':
                isShow = true;
                break;
            default:
                isShow = false;
                break;
        }
        return isShow;
    }

    showCallIcon(assetType) {
        let isShow = false;
        switch (assetType) {
            case 'icp':
                isShow = true;
                break;
            case 'icp_ssn':
                isShow = true;
                break;
            case 'last_gasp':
                isShow = true;
                break;
            case 'icp_disconnect':
                isShow = true;
                break;
            case 'address':
                isShow = true;
                break;
            case '':
                isShow = false;
                break;
            default:
                isShow = true;
                break;
        }

        let secShow = this.isCallManagerCreateCallAllowed(this.app);
        return isShow && secShow;
    }

    getLastGaspIconByCategory(category) {
        let image = IMAGE_ROOT_URL + "/assets/";

        switch (category.toLowerCase()) {
            case 'business':
                image += 'business_lastgasp.png';
                break;
            case 'church':
                image += 'community_lastgasp.png';
                break;
            case 'school':
                image += 'community_lastgasp.png';
                break;
            case 'community facility':
                image += 'community_lastgasp.png';
                break;
            case 'hall':
                image += 'community_lastgasp.png';
                break;
            case 'house':
                image += 'icp_lastgasp.png';
                break;
            case 'farm cottage':
                image += 'icp_lastgasp.png';
                break;
            case 'radio site':
                image += 'icp_lastgasp.png';
                break;
            case 'sleep out':
                image += 'icp_lastgasp.png';
                break;
            case 'pump':
                image += 'pump_lastgasp.png';
                break;
            case 'water pump':
                image += 'pump_lastgasp.png';
                break;
            case 'cowshed':
                image += 'cowshed_lastgasp.png';
                break;
            case 'Agriculture':
                image += 'cowshed_lastgasp.png';
                break;
            case 'woolshed':
                image += 'shed_lastgasp.png';
                break;
            case 'workshop':
                image += 'shed_lastgasp.png';
                break;
            case 'shed':
                image += 'shed_lastgasp.png';
                break;
            case 'street light':
                image += 'streetlight_lastgasp.png';
                break;
            default:
                image += 'icp_lastgasp.png';
                break;
        }

        return image;
    }

    getIcpSSNIconByCategory(category) {
        let image = IMAGE_ROOT_URL + "/assets/";

        switch (category.toLowerCase()) {
            case 'business':
                image += 'business_ssn.png';
                break;
            case 'church':
                image += 'community_ssn.png';
                break;
            case 'school':
                image += 'community_ssn.png';
                break;
            case 'community facility':
                image += 'community_ssn.png';
                break;
            case 'hall':
                image += 'community_ssn.png';
                break;
            case 'house':
                image += 'icp_ssn.png';
                break;
            case 'farm cottage':
                image += 'icp_ssn.png';
                break;
            case 'radio site':
                image += 'icp_ssn.png';
                break;
            case 'sleep out':
                image += 'icp_ssn.png';
                break;
            case 'pump':
                image += 'pump_ssn.png';
                break;
            case 'water pump':
                image += 'pump_ssn.png';
                break;
            case 'cowshed':
                image += 'cowshed_ssn.png';
                break;
            case 'Agriculture':
                image += 'cowshed_lastgasp.png';
                break;
            case 'woolshed':
                image += 'shed_ssn.png';
                break;
            case 'workshop':
                image += 'shed_ssn.png';
                break;
            case 'shed':
                image += 'shed_ssn.png';
                break;
            case 'street light':
                image += 'streetlight_ssn.png';
                break;
            default:
                image += 'icp_ssn.png';
                break;
        }

        return image;
    }

    getIcpIconByCategory(category) {
        let image = IMAGE_ROOT_URL + "/assets/";

        switch (category.toLowerCase()) {
            case 'business':
                image += 'business.png';
                break;
            case 'church':
                image += 'community.png';
                break;
            case 'school':
                image += 'community.png';
                break;
            case 'community facility':
                image += 'community.png';
                break;
            case 'hall':
                image += 'community.png';
                break;
            case 'house':
                image += 'icp.png';
                break;
            case 'farm cottage':
                image += 'icp.png';
                break;
            case 'radio site':
                image += 'icp.png';
                break;
            case 'sleep out':
                image += 'icp.png';
                break;
            case 'pump':
                image += 'pump.png';
                break;
            case 'water pump':
                image += 'pump.png';
                break;
            case 'cowshed':
                image += 'cowshed.png';
                break;
            case 'Agriculture':
                image += 'cowshed_lastgasp.png';
                break;
            case 'woolshed':
                image += 'shed.png';
                break;
            case 'workshop':
                image += 'shed.png';
                break;
            case 'shed':
                image += 'shed.png';
                break;
            case 'street light':
                image += 'streetlight.png';
                break;
            case 'dg':
                image += 'icp_dg.png';
                break;
            case 'dg_ssn':
                image += 'icp_dg_ssn.png';
                break;
            case 'sd':
                image += 'icp_sd.png';
                break;
            default:
                image += 'icp.png';
                break;
        }

        return image;
    }

    _getIcon(assetType, count, category = '') {
        let image = IMAGE_ROOT_URL + "/assets/";

        switch (assetType) {
            case 'capacitor':
                image += 'capacitor.png';
                break;
            case 'feeder':
                image += 'feeder.png';
                break;
            case 'pillar':
                image += 'link_box_distribution_pillar.png';
                break;
            case 'icp':
                image = this.getIcpIconByCategory(category);
                break;
            case 'icp_ssn':
                image = this.getIcpSSNIconByCategory(category);
                break;
            case 'last_gasp':
                image = this.getLastGaspIconByCategory(category);
                break;
            case 'icp_disconnect':
                image += 'icp_disconnected.png';
                break;
            case 'icp_nic_removed':
                image += 'icp_nic_removed.png';
                break;
            case 'icp_selected':
                image += "SelectedICP.png";
                break;
            case 'pole':
                image += 'pole.png';
                break;
            case 'transformer':
                image += 'transformer.png';
                break;
            case 'substation':
                image += 'm1.png';
                break;
            case 'transformer_selected':
                image += 'transformer-selected.png';
                break;
            case 'transformer_selected_out':
                image += 'transformer-selected-out.png';
                break;
            case 'transformer_outage':
                var img = 'transformer_outage1.png';
                if (count > 15) {
                    img = 'transformer_outage2.png';
                }
                if (count > 45) {
                    img = 'transformer_outage3.png';
                }
                if (count == -1) {
                    img = 'transformer_outage_sd.png';
                }
                image += img;
                break;
            case 'tree':
                image += 'tree.png';
                break;
            case 'light':
                image += 'light.png';
                break;
            case 'linkbox':
                image += 'linkbox.png';
                break;
            case 'oh_switch':
                image += 'oh_switch.png';
                break;
            case 'switching_station':
                image += 'switching_station.png';
                break;
            case 'cluster':
                image += 'blank.png';
                break;
            default:
                image += 'blank.png';
                break;
        }
        return image;
    }

    _getGroupingDistance() {
        let dist = 10000; //meters
        let zoomLevel = gmap.getZoom();
        switch (zoomLevel) {
            case 10:
                dist = 7000;
                break;
            case 11:
                dist = 5000;
                break;
            case 12:
                dist = 2000;
                break;
            case 13:
                dist = 500;
                break;

            case 14:
                dist = 300;
                break;
            case 15:
                dist = 200;
                break;
            case 16:
                dist = 100;
                break;
            case 17:
                dist = 50;
                break;
            case 18:
                dist = 10;
                break;
            case 19:
                dist = 5;
                break;
            case 20:
                dist = 3;
                break;
            case 21:
                dist = 3;
                break;

            default:
                dist = 10000;
                break;
        }
        return dist;
    }

    getZoomLeveFromDistance(distance) {
        let zl = 20;
        if (distance > 2256) {
            zl = 19;
        }
        if (distance > 4513) {
            zl = 18;
        }
        if (distance > 9027) {
            zl = 17;
        }
        if (distance > 18055) {
            zl = 16;
        }
        if (distance > 36111) {
            zl = 15;
        }
        if (distance > 72223) {
            zl = 14;
        }
        if (distance > 144447) {
            zl = 13;
        }
        if (distance > 288895) {
            zl = 12;
        }
        if (distance > 577790) {
            zl = 11;
        }
        if (distance > 1155581) {
            zl = 10;
        }
        if (distance > 2311162) {
            zl = 9;
        }
        if (distance > 4622324) {
            zl = 8;
        }
        if (distance > 9244649) {
            zl = 7;
        }
        return zl;
    }
    onVehiclePositionChanged() {
        // console.log("vehiclePositions");
        this.showVehicles();

    }


    showVehicles() {
        this.$.lsVehicles.reload();
        // this.$.lsVehiclePositions.reload();
        let ids = [];

        if (this.vehicles !== null) {
            // this.vehicles.forEach((value) => {
            //     if (this.isShowVehicleCar && value.type === "Car") {
            //         ids.push(value.id);
            //     }
            //     if (this.isShowVehicleHeavy && value.type === "Heavy Equipment") {
            //         ids.push(value.id);
            //     }
            //     if (this.isShowVehicleTruck && value.type === "Truck") {
            //         ids.push(value.id);
            //     }
            //     if (this.isShowVehicleUte && value.type === "Utility") {
            //         ids.push(value.id);
            //     }
            //     if (this.isShowVehicleVan && value.type === "Van") {
            //         ids.push(value.id);
            //     }
            // });

            this.vehicles.forEach((value) => {
                let fleet = value.fleet.trim();
                if (this.isShowVehicleAsset && (fleet === "Asset Management" || fleet === "Holly")) {
                    ids.push(value.id);
                }

                if (this.isShowVehicleCorporate && fleet === "Corporate") {
                    ids.push(value.id);
                }
              
                if (this.isShowVehicleField && (fleet === "Field Ops Administrtion" || fleet === "Field Ops Administration")) {
                    ids.push(value.id);
                }
                if (this.isShowVehicleHv && fleet === "HV Services") {
                    ids.push(value.id);
                }
                if (this.isShowVehicleInspectors && fleet === "Inspectors") {
                    ids.push(value.id);
                }
                if (this.isShowVehicleMetering && fleet === "Metering") {
                    ids.push(value.id);
                }
                if (this.isShowVehicleOverhead && fleet === "Overhead") {
                    ids.push(value.id);
                }
                if (this.isShowVehiclePeopleSafety && fleet.includes("Safety")) {
                    ids.push(value.id);
                }
                if (this.isShowVehicleProcurement && fleet === "Procurement") {
                    ids.push(value.id);
                }
                if (this.isShowVehicleUndergroundCapital && fleet === "Underground Capital Projects") {
                    ids.push(value.id);
                }
                if (this.isShowVehicleUnderground && fleet.includes("Connections")) {
                    ids.push(value.id);
                }
                if (this.isShowVehicleSubDivisions && fleet === "Underground Sub Divisions") {
                    ids.push(value.id);
                }
                if (this.isShowVehicleReactiveServices && fleet === "Reactive Services") {
                    ids.push(value.id);
                }
                if (this.isShowVehicleVegetation && fleet === "Vegetation") {
                    ids.push(value.id);
                }
            });

            if (this.app.vehPositions) {
                this.app.vehPositions.forEach((veh) => {
                    let assetId = veh.ownerID;
                    if (ids.includes(assetId)) {
                        this._showVehicles(veh);
                    }
                });
            }
        }
    }

    _clearVehMarkers() {
        this._clearVehicleInfoWindows();
        vehMarkers.forEach((marker) => {
            marker.setMap(null);
        });
        vehMarkers = [];
    }

    _clearVehicleInfoWindows() {
        vehInfoWindows.forEach((infoWindow) => {
            infoWindow.setMap(null);
        });
        vehInfoWindows = [];
    }

    _getColorFromType(id) {
        let veh = this.vehicles.filter((a) => a.id === id)[0];
        let typeColor = "#000";
        // switch (veh.type) {
        //     case 'Truck':
        //         typeColor = "#FF5722";
        //         break;
        //     case 'Van':
        //         typeColor = "#4CAF50";
        //         break;
        //     case 'Utility':
        //         typeColor = "#4CAF50";
        //         break;
        //     case 'Heavy Equipment':
        //         typeColor = "#FFC107";
        //         break;
        //     default:
        //         typeColor = "#009688";
        // }
        switch (veh.fleet.trim()) {
            case 'Asset Management':
                typeColor = "#FF5722";
                break;
            case 'Corporate':
                typeColor = "#4CAF50";
                break;
            
            case 'HV Services':
                typeColor = "#FFC107";
                break;
            case 'Inspectors':
                typeColor = "#FF8107";
                break;
            case 'Metering':
                typeColor = "#FBA1FF";
                break;
            case 'Overhead':
                typeColor = "#FBA107";
                break;
            case 'Procurement':
                typeColor = "#FAB688";
                break;
            case 'Underground Capital Projects':
                typeColor = "#FBB107";
                break;
            case 'Underground Connections':
                typeColor = "#4FC107";
                break;
            case 'Underground New Connections':
                typeColor = "#4FC107";
                break;
            case 'Underground Sub Divisions':
                typeColor = "#BFC107";
                break;
            case 'Vegetation':
                typeColor = "#8FC107";
                break;
            case 'Reactive Services':
                typeColor = "#8FB107";
                break;
            default:
                typeColor = "#009688";
        }
        return typeColor;
    }

    _getVehicleType(id) {
        let veh = this.vehicles.filter((a) => a.id === id)[0];
        return veh.type
    }

    _showVehicles(veh) {
        let typeColor = this._getColorFromType(veh.ownerID);
        let sHeading = veh.lastLocation.direction;
        let heading = parseFloat(sHeading);
        let speed = veh.lastLocation.state.includes("OFF") ? 0: parseFloat(veh.lastLocation.speed);
        const arrow = {
            path: (veh.lastLocation.state === "IGNITION_OFF" || veh.lastLocation.state == "DISCONNECTED" ) ?  google.maps.SymbolPath.CIRCLE : google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            rotation: heading,
            scale: 5,
            strokeColor: typeColor,
            strokeWeight: 2,
            fillColor: typeColor,
            fillOpacity: 1.00
        };


        let lat = parseFloat(veh.lastLocation.latitude);
        let lng = parseFloat(veh.lastLocation.longitude);
        let position = { lat: lat, lng: lng };

        let marker = vehMarkers.filter(
            (m) => m.get('id') === veh.ownerID.toString(),
            () => null)[0];
        if (marker === undefined || marker === null) {
        
            marker = new MarkerWithLabel({
                position: position,
                map: gmap,
                title: `${veh.vehicleName} - ${veh.driverName}`,
                icon: arrow,
                draggable: true,
                labelContent: `${veh.vehicleName} - ${veh.driverName}`,
                labelAnchor: new google.maps.Point(-10, 10),
                labelClass: veh.isOnDuty ? "labels labels-onduty" : "labels", // the CSS class for the label
                labelStyle: { opacity: 0.75 }
            });

            let infoWindow = new google.maps.InfoWindow({
                content: this._getInfoWindowFromVehicle(veh)
            });
            infoWindow.set('id', veh.ownerID.toString());

            vehInfoWindows.push(infoWindow);

            marker.set('id', veh.ownerID.toString());
            marker.addListener('click', () => {
                infoWindow.open(gmap, marker);
            });
            marker.addListener('dragstart', () => {
                // draggedResourceNo = {resourceNo: veh.resourceNo, resourceName:veh.resourceName};
                // console.log(`Drag Start - Resource ${veh.resourceName}`);
                soMarkers.forEach((m) => {
                    m.addListener('mouseover', () => {
                        let so = m.get("so");
                        so.allocatedResource = veh;
                        // console.log(so);
                        this.allocateResourceSo = so;
                        if (so.status == "SCHEDULED") {
                            this.$.dlgResourceAllocate.open();
                        } else {
                            this.$.dlgResourceAllocateWarning.open();
                        }
                    });
                });

            });
            marker.addListener('dragend', () => {

                setTimeout(() => {
                    // Remove all click listeners from marker instance.
                    //  draggedResourceNo = null;
                    soMarkers.forEach((m) => {
                        google.maps.event.clearListeners(m, 'mouseover');
                    });
                }, 1000);

            });
            vehMarkers.push(marker);
        } else {
            marker.setPosition(position);
            marker.setIcon(arrow);
            let w = vehInfoWindows.filter(
                (m) => m.get('id') === veh.ownerID.toString())[0];
            w.position = position;
            // veh.assetType = this._getVehicleType(veh.ownerID);
            w.content = this._getInfoWindowFromVehicle(veh);
        }
    }

    onConfirmResourceAllocationClick() {
        let so = this.allocateResourceSo;
        let data = JSON.stringify({
            no: so.no,
            resourceNo: so.allocatedResource.resourceNo,
            resourceName: so.allocatedResource.resourceName,
            username: this.app.username
        });

        fetch(`/api/so/${so.no}/allocate`, {
            credentials: 'include',
            method: 'POST',
            body: data
        })
            .then(resp => resp.json())
            .then(() => {

                this.$.ajxWOs.generateRequest();
                this.showToast("Resource Allocated");
            })
            .catch((error) => {
                console.log('Request failed', error);
                this.showToast("Failed to Allocate Resource");
            });
    }

    onFaultsRefresh() {
        this.onShowFaultsReportedNewChanged();
        this.onShowFaultsReportedProcessedChanged();
    }

    onConfirmFaultAllocationClick() {
        let so = this.allocateFaultSo;
        let data = JSON.stringify({
            no: so.no,
            allocatedFault: so.allocatedFault.id,
            username: this.app.username
        });

        fetch(`/api/so/${so.no}/fault/allocate`, {
            credentials: 'include',
            method: 'POST',
            body: data
        })
            .then(resp => resp.json())
            .then(() => {
                // this._clearFaultNewMarkers();
                // this._getFaultsReported("new");
                this.showToast("Fault Allocated");
                this.onCancelFaultAllocationClick();
            })
            .catch((error) => {
                console.log('Request failed', error);
                this.showToast("Failed to Allocate Fault");
                this.onCancelFaultAllocationClick();
            });
    }

    onCancelFaultAllocationClick() {
        this._clearFaultNewMarkers();
        if (this.isShowFaultsReportedNewChecked) {
            this._getFaultsReported("new");
        }


        this._clearFaultOtherMarkers();
        if (this.isShowFaultsReportedProcessedChecked) {
            this._getFaultsReported("other");
        }
    }


    _getInfoWindowFromVehicle(veh) {
        // let speed = veh.lastLocation.state.includes("OFF") ? 0: parseFloat(veh.lastLocation.speed);
        let infoTemplate = `
                <div id='infoContainer'>
                <h5>Vehicle: ${veh.vehicleName}</h5>
                <h5>State: ${veh.name}</h5>
                <h5>Driver: ${veh.driverName}</h5>
                <div id='infoContent'>
                    <p>${veh.lastLocation.description}</p>
                    <p>Login: ${veh.loginDate?moment.parseZone(veh.loginDate).format("DD MMM YYYY hh:mm") : ""}</p>
                    ${veh.driverName.length === 0? "<p>Last Driver:" + veh.lastDriverName + "</p>": ""}
                    <p>Type of Vehicle: ${this._getVehicleType(veh.ownerID)}</p>
                    <p>Groups: ${veh.vehicleFleetGroups}</p>
                    <p>Occured: ${moment.parseZone(veh.lastUpdate).format("DD MMM YYYY hh:mm")}</p>
                </div>
                </div>
                `;
        let div = document.createElement('div');
        div.innerHTML = infoTemplate;
        return div;
    }

    onSelectedAlertLoaded() {
        // console.log("Storage loaded.");
        // console.log(event);
        // console.log(detail);
    }

    onShowUnplannedOutages() {
        // console.log(`Check Unplanned Outages ${this.isShowUnplannedOutages}`);
        if (!isInDrag) {
            this._clearSoInfoWindows();
            this._clearSoMarkers();
            this.$.lsSos.reload();
            if (this.isShowUnplannedOutages) {
                if (this.sos !== null) {
                    this.showServiceOrders(this.sos);
                }
            }
        }
    }

    onShowUnplannedOutagesChanged() {
        // console.log(`Changed Unplanned Outages`);
        this.$.lsSos.reload();
        this.onShowUnplannedOutages();
    }

    _getImageFromStatus(status, serviceType) {
        let image = "";
        let type = "";

        switch (status) {
            case 'SCHEDULED':
                image = 'NEW.png';
                break;
            case 'CLOSED':
                image = 'REPAIRED.png';
                break;
            case 'ON HOLD':
                image = 'ONHOLD_CURRENT.png';
                break;
            default:
                image = 'CURRENT.png';
                break;
        }

        switch (serviceType) {
            case 'FEEDER':
                type = 'FEEDER';
                break;
            case 'FLICK':
                type = 'FLICKR';
                break;
            case 'HOT_W':
                type = 'HOT_W';
                break;
            case 'LINES':
                type = 'LINES';
                break;
            case 'MEMO':
                type = 'MEMO';
                break;
            case 'NOPWR':
                type = 'NOPWR';
                break;
            case 'OSH-N':
                type = 'OSH';
                break;
            case 'OSH-C':
                type = 'OSH';
                break;
            case 'USAFE':
                type = 'OSH';
                break;
            case 'PPWR':
                type = 'PPWR';
                break;

            default:
                type = 'OTHER';
                break;
        }

        if (status === 'ON HOLD'){
            return IMAGE_ROOT_URL + "/so/OTHER_ONHOLD_CURRENT.png";
        }else{
            return IMAGE_ROOT_URL + "/so/" + type + "_" + image;
        }
        
    }

    _getInfoWindowFromSo(so) {
        let alert = "error";
        if (so.status.toString() === 'CLOSED') {
            alert = "success";
        }
        let infoTemplate =
            `<div id='infoContainer'>
          <paper-card heading="No: ${so.no} -  ${so.status} ">
              <div class="card-content">
              <div>Assigned to: ${so.resourceName}</div>
                  <p>Customer:  ${so.customerName}
                  <br/>Phone:  ${so.phone}
                  <br/>${so.address.unit} ${so.address.number} ${so.address.street}
                  <br/>${so.address.suburb} ${so.address.town}
                  </p>
                  <div class='alert alert-${alert}'>
                      <p>Asset Type: ${so.assetType}
                      <br/>Service Type: ${so.serviceType}  </p>
                      <h5/>Description ${so.description}</h5>
                      <p>Details: ${so.comments}</p>
                      <p>No. of VIP Key Accounts: ${so.noOfCustomerVipOrKeyAccount}</p>
                  </div>
              </div>

              <div class="horizontal justified">
              
              <paper-button id="btnShowWODetails" raised>SHOW WO</paper-button>
              <paper-button id="btnShowEventDetails" class='call' raised>SHOW EVENT</paper-button>

              </div>

          </paper-card>
      </div>`;
        return infoTemplate;
    }

    //  _getInfoWindowFromSoResourceAllocate(so, res) {
    //     let alert = "error";
    //     if (so.status.toString() === 'CLOSED') {
    //         alert = "success";
    //     }
    //     let infoTemplate =
    //         `<div id='infoContainer'>
    //          <paper-card heading="No: ${so.no} -  ${so.status} ">
    //                 <div class="card-content">
    //                 <div>Assigned to: ${so.resourceName}</div>
    //                     <p>Customer:  ${so.customerName}
    //                     <br/>Phone:  ${so.phone}
    //                     <br/>${so.address.unit} ${so.address.number} ${so.address.street}
    //                     <br/>${so.address.suburb} ${so.address.town}
    //                     </p>
    //                     <div class='alert alert-${alert}'>
    //                         <p>Asset Type: ${so.assetType}
    //                         <br/>Service Type: ${so.serviceType}  </p>
    //                         <h5/>Description ${so.description}</h5>
    //                         <p>Details: ${so.comments}</p>
    //                     </div>
    //                 </div>
    //                 <div class="card-actions">
    //                     <div class="horizontal justified">
    //                         <paper-button id="btnAllocateResource" style="background-color: #FFA000;" raised>Allocate to ${res.resourceName}</paper-button>
    //                     </div>
    //                 </div>
    //             </paper-card>
    //         </div>`;
    //     return infoTemplate;
    // }

    _clearSoMarkers() {
        soMarkers.forEach((marker) => {
            marker.setMap(null);
        });
        soMarkers = [];
        //Clear polygon
        _soPoly.forEach((p) => p.setMap(null));
        _soPoly = [];
    }

    _clearSoInfoWindows() {
        // eslint-disable-next-line no-unused-vars
        soInfoWindows.forEach((iw) => {
            iw = null;
        });
        soInfoWindows = [];
    }


    showServiceOrders(serviceOrders) {
        if (serviceOrders) {
            serviceOrders.forEach((so) => {
                let lat = so.lat;
                let lng = so.lng;
                let latLng = new google.maps.LatLng(lat, lng);

                let svcType = so.assetType == "ICP" && so.serviceType == "NOPWR" ? "OTHER" : so.serviceType;
                let image = this._getImageFromStatus(so.status, svcType);

                // Construct the polygon.
                var triangleCoords = [];
                if (so.hull) {
                    so.hull.forEach((p) => {
                        triangleCoords.push(new google.maps.LatLng(p.lat, p.lng));

                    });
                }


                var poly = new google.maps.Polygon({
                    paths: triangleCoords,
                    strokeColor: 'red',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: 'red',
                    fillOpacity: 0.35,
                    geodesic: true
                });
                poly.setMap(gmap);

                poly.set("so", so);
                poly.addListener('click', () => {
                    infoWindow.open(gmap)
                });
                _soPoly.push(poly);

                //Construct Marker 
                let marker = new google.maps.Marker({
                    position: latLng,
                    map: gmap,
                    title: so.description,
                    icon: image,
                    clickable: true,
                    zIndex: Date.now()
                });
                marker.set("so", so);

                let infoDiv = document.createElement('div');
                infoDiv.innerHTML = this._getInfoWindowFromSo(so);
                let infoContainer = infoDiv.firstChild;
                let infoWindow = new google.maps.InfoWindow({
                    content: infoDiv,
                    position: latLng
                });
                marker.addListener('click', () => infoWindow.open(gmap));

                marker.addListener('mouseup', () => {
                    console.log(this.allocateResourceSo);
                    if (this.allocateResourceSo) {
                        console.log(this.allocateResourceSo);
                    }

                });


                google.maps.event.addDomListener(infoContainer, 'click', (e) => {
                    let tid = e.target.id.toString();

                    if (tid.includes('btnShowWODetails')) {
                        this.set('selectedServiceOrder', so);
                        this.$.wodetail.open();
                        infoWindow.close();
                    }

                    if (tid.includes('btnShowEventDetails')) {
                        this.set('selectedServiceOrder', so);
                        // this.set('selectedEventId', this.selectedServiceOrder.eventId);
                        // this.$.faultAlertOverlay.open();
                        this.openEventOverlay();
                        infoWindow.close();
                    }
                    e.preventDefault();
                    e.stopPropagation();
                });


                soMarkers.push(marker);
                soInfoWindows.push(infoWindow);

            });
        }

    }

    closeWorkOrderOverlay() {
        // this.$.shTable.deselectAll(false);
        this.$.wodetail.close();
        this.selectedServiceOrder = null;
    }

    openEventOverlay() {

        if (this.selectedServiceOrder.eventId > 0) {
            this.set('selectedEventId', this.selectedServiceOrder.eventId);
            this.dispatchEvent(new CustomEvent('fault-alert-open', { bubbles: true, composed: true }))
            this.$.faultAlertOverlay.open();
            this.openRightNav();

        } else {
            this.showToast("No Event associated with Work Order");
        }
    }

    closeEventAlertOverlay() {
        this.set('selectedEventId', null);
        this.$.faultAlertOverlay.close();
        this.dispatchEvent(new CustomEvent('fault-alert-close', { bubbles: true, composed: true }));
        this.toggleRightNav();
    }

    _onSoLatestResponse() {
        this.onShowUnplannedOutages();
    }

    _clearOutages() {
        _outageOverlays.forEach((overlay) => overlay.setMap(null));
        _circles.forEach((circle) => circle.setMap(null));
        _circles = [];
    }

    onShowPlannedOutages() {
        this._clearOutages();
        if (this.isShowPlannedOutages) {
            // if (gmap.getZoom() > 13) {}
            this.set('isLoading', true);

            fetch('/api/sr/latest', { credentials: 'include' })
                .then(resp => resp.json())
                .then(caps => {
                    this.showOutages(caps);
                    this.set('isLoading', false);
                });
            // .catch(function (error) {
            //   console.log('Request failed', error);
            // });

        }
    }

    onShowAssetDetail(e) {
        let item = e.detail;
        this.set('selectedAssetType', item.assetType);
        this.set('selectedAsset', item.id);

        this.$.assetdetail.open();
    }
    onShowFaultReportedDetail(e) {
        let item = e.detail;
        this.set('selectedFaultId', item.id);
        this.$.faultReportedDetail.open();
    }

    onPowerDownHeatMap(e) {
        let item = e.detail;
        let st = item.plannedStartTime.split(' ');
        let et = item.plannedEndTime.split(' ');

        this.set('fromDate', st[0]);
        this.set('fromTime', st[1]);

        this.set('toDate', et[0]);
        this.set('toTime', et[1]);
        this.set('powerDownTypeSelected', 'ShowPowerDown');

        gmap.setZoom(this.getZoomLeveFromDistance(100 * item.distance));
        let latLng = new google.maps.LatLng(item.lat, item.lng);
        gmap.panTo(latLng);
        this.getHeatMapDetails(item.plannedStartTime, item.plannedEndTime);
        this.drawShutdownMarkers(item.transformerIcps);
    }

    drawShutdownMarkers(transformers) {
        //Delete Markers and Events
        shutdownMarkers.forEach((m) => {
            m.setMap(null);
        });
        shutdownMarkers = []; //.clear();

        shutdownMarkersEvents.forEach((e) => e.cancel);
        shutdownMarkersEvents = [];

        transformers.forEach((tr) => {
            tr.icps.forEach((oe) => {
                let ll = new google.maps.LatLng(oe.lat, oe.lng);
                let marker = new google.maps.Marker({
                    map: gmap,
                    position: ll,
                    title: oe.icp,
                    icon: this.getIcpIconByCategory('sd')
                });

                shutdownMarkers.push(marker);

                let sub = marker.addListener('click', () => {

                    this.set('selectedAssetType', "icp");
                    this.set('selectedAsset', oe.icpId);

                    // console.log(selectedAssetType);
                    this.$.assetdetail.open();
                });
                shutdownMarkersEvents.push(sub);
            });
        });
    }

    showShutdownOnMap(e) {
        let sd = e.detail.item;
        //  console.log(sd);
        if (sd.lat !== 0) {
            let latLng = new google.maps.LatLng(sd.lat, sd.lng);
            gmap.panTo(latLng);
            gmap.setZoom(17);
        }
    }

    showAlertTransformers(e) {
        let items = e.detail.items;
        let itemsRestored = e.detail.itemsRestored;
        _markers.forEach((m) => {
            if (items.includes(m.title)) {
                m.setIcon(this._getIcon("transformer_selected_out", null));
            } else if (itemsRestored.includes(m.title)) {
                m.setIcon(this._getIcon("transformer_selected", null));
            } else if (m.get("type") == "transformer") {
                m.setIcon(this._getIcon("transformer", null));
            }
        });
    }

    showOutages(outages) {
        outages.forEach((outage) => {
            this.showOutage(outage);
        });
    }

    showOutage(outage) {
        if (outage.status != "Approved") {
            return;
        }
        let center = new google.maps.LatLng(outage.lat, outage.lng);
        // const circle = new google.maps.Circle({
        //     strokeColor: '#f89406',
        //     strokeOpacity: 0.8,
        //     strokeWeight: 2,
        //     fillColor: 'transparent',
        //     fillOpacity: 0.00,
        //     map: gmap,
        //     center: center,
        //     radius: outage.distance,
        //     clickable: true
        // });

        let btnId = `btnShow${outage.id}`;
        let btnHideId = `btnHide${outage.id}`;
        // let divBtn = document.createElement('div');
        //     divBtn.innerHTML = `
        // <button id="$btnId" class="btn btn-link">show ICPs</button>
        // <button id="$btnHideId" class="btn btn-link">Hide ICPs</button>
        // <button id="btnSendAlert" class="btn btn-link">Details</button>`;

        let infoTemplate = document.createElement('div');
        const shutdownDate = moment.parseZone(outage.shutdownDate).format('DD MMM YYYY');
        infoTemplate.innerHTML = `
                    <div id='infoContainer'>
                    <paper-card heading="Planned Outage - ${outage.id}">
                            <div class="card-content">
                            <div>Status: ${outage.status}</div>
                            <p>Planned Shutown on ${outage.feeder} Feeder <br/>Customers Affected: ${outage.affectedCustomers}
                            <br/>Shutdown Date: ${shutdownDate}
                                <br/>Shutdown Period: ${outage.shutdownTime1} ${outage.shutdownTime2}
                                <br/>Details: ${outage.description}
                            </p>
                            </div>
                            <div class="card-actions">
                            <div class="horizontal justified">
                                <paper-button id="btnSendAlert">DETAILS</paper-button>
                                <paper-button id="${btnId}" >SHOW ICPS</paper-button>
                                <paper-button id="${btnHideId}">HIDE ICPS</paper-button>
                                </div>
                            </div>
                        </paper-card>

                    </div>`

        // infoTemplate.appendChild(divBtn);
        let divBtn = infoTemplate.firstChild.nextSibling;
        //Info window
        let infoWindow = new google.maps.InfoWindow({
            content: infoTemplate,
            position: center
        });

        if (outage.hull) {
            var triangleCoords = [];
            outage.hull.forEach((p) => {
                triangleCoords.push(new google.maps.LatLng(p.lat, p.lng));

            });


            // Construct the polygon.
            var poly = new google.maps.Polygon({
                paths: triangleCoords,
                strokeColor: '#3a87ad',
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: '#3a87ad',
                fillOpacity: 0.35,
                geodesic: true
            });
            poly.setMap(gmap);

            poly.set("outage", outage);
            poly.addListener('click', () => {
                infoWindow.open(gmap);
            });
            _circles.push(poly);

        }

        // circle.addListener('click', () => {
        //     infoWindow.open(gmap);
        // });
        // _circles.push(circle);
        const swBound = center;
        const neBound = center;
        const bounds = new google.maps.LatLngBounds(swBound, neBound);
        let txt = new TextOverlay(bounds, outage.affectedCustomers.toString(), gmap);
        _outageOverlays.push(txt);

        google.maps.event.addDomListener(divBtn, 'click', (e) => {
            let id = e.target.id.toString();
            // console.log(id);
            let sid = outage.id;
            if (id.includes("btnShow")) {
                this.showIcpMarkers(center, outage.icps);
            }
            if (id.includes("btnHide")) {
                this._hideTransformerIcps();
            }
            if (id.includes("btnSendAlert")) {

                this.set('selectedShutdown', sid);
                this.$.shutdownAlertOverlay.open();
            }
        });
    }

    showIcpMarkers(centre, icps) {
        icps.forEach((icp) => {
            if (icp.lat !== 0) {
                const latLng = new google.maps.LatLng(icp.lat, icp.lng);

                let id = icp.id;
                let marker = new google.maps.Marker({
                    map: gmap,
                    position: latLng,
                    title: icp.icp,
                    icon: this._getIcon("icp_selected", null)
                });
                _transIcpMarker.push(marker);

                let lines = [centre, latLng];
                let transPath = new google.maps.Polyline({
                    path: lines,
                    geodesic: true,
                    strokeColor: '#922BA7',
                    strokeOpacity: 0.5,
                    strokeWeight: 1
                });

                transPath.setMap(gmap);
                _feederLines.push(transPath);

                let sub = marker.addListener('click', () => {
                    // selectedAssetType = "icp";
                    this.set('selectedAssetType', "icp");
                    // selectedAsset = id;
                    this.set('selectedAsset', id);
                    this.$.assetdetail.open();
                });
                _transIcpMarkerEvents.push(sub);
            }
        });
    }

    showSearch() {
        let inp = this.$.tbxSearchText;
        inp.value = '';
        this.clear('addressItems');
        this.$.apages.selected = 1;
        this.setTimeout(() => inp.focus(), 500);
    }

    back() {
        this.$.apages.selected = 0;
    }

    onSearchTextInput() {
        // this.$.dlgSearch.open();
        if (this.searchText.length > 0) {
            this.isShowSearchResults = true;
        } else {
            this.isShowSearchResults = false;
        }
        if (sTimer !== null) {
            clearTimeout(sTimer);
        }


        this.set('addressItems', []);


        sTimer = setTimeout(() => {
            if (this.searchText.length > 0) {
                this.addressSearch(this.searchText);
                this.searchAsset();
            }
        }, 1000);
    }

    onSearchTextKeyDown(e) {
        if (e.keyCode === 27) { // 27 is the ESC key
            this.searchText = "";
            this.isShowSearchResults = false;
        }

    }

    onClearSearchText() {
        this.searchText = "";
        // this.$.dlgSearch.close();
        this.isShowSearchResults = false;
    }

    checkResponseStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }



    searchAsset() {
        if (this.searchText.trim().length > 0) {
            this.set('isLoading', true);
            fetch(`/api/assets/search?q=${encodeURIComponent(this.searchText)}`, { credentials: 'include' })
                .then(resp => resp.json())
                .then(results => {
                    if (results !== null) {

                        results.forEach((r) => {
                            var info = {
                                id: r.id,
                                lat: r.lat,
                                lng: r.lng,
                                assetType: r.assetType,
                                name: r.name,
                                customerName: r.customerName,
                                streetAddress: r.streetAddress,
                                category: r.category,
                                town: r.town,
                                isInShutdown: r.isInShutdown,
                                shutdownId: r.shutdownId
                            };


                            if (this.addressItems.length < 20) {
                                this.push('addressItems', info);
                            }

                        });

                        var result = this.addressItems.reduce((unique, o) => {
                            if (!unique.some(obj => obj.id === o.id && obj.name === o.name)) {
                                unique.push(o);
                            }
                            return unique;
                        }, []);

                        this.addressItems = result;

                    }
                    this.set('isLoading', false);
                }).catch(err => {
                    if (err.name === 'AbortError') {
                        console.log('Fetch aborted');
                    } else {
                        console.error('Uh oh, an error!', err);
                    }
                });


        }
    }

    onAddressClick(e) {
        let model = e.model;

        let myLatLng = new google.maps.LatLng(model.get('item.lat'), model.get('item.lng'));
        searchMarker.setMap(null);
        searchMarker = new google.maps.Marker({
            map: gmap,
            position: myLatLng
        });
        // this.$.dlgSearch.close();

        gmap.setZoom(18);
        gmap.panTo(myLatLng);
    }

    addressSearch(address) {
        const request = {
            address: address,
            region: "nz",
            bounds: new google.maps.LatLngBounds(new google.maps.LatLng(-37.772886, 174.528379),
                new google.maps.LatLng(-36.90598, 175.289183))
        };

        geocoder.geocode(request, (results, status) => {
            if (status === "OK") {
                results.forEach((r) => {
                    let info = {
                        lat: r.geometry.location.lat(),
                        lng: r.geometry.location.lng(),
                        assetType: "address",
                        name: r.formatted_address
                    };

                    if (this.addressItems.length < 20) {
                        this.push('addressItems', info);
                    }
                });

            } else {
                // console.log(`Address Lookup was not successful for the following reason: ${status}`);
            }
        });
    }

    _clearFaultNewMarkers() {
        faultNewMarkers.forEach((marker) => {
            marker.setMap(null);
        });
        faultNewMarkers = [];
        _faultNewEvents.forEach((e) => e.cancel);
    }

    _clearFaultOtherMarkers() {
        faultOtherMarkers.forEach((marker) => {
            marker.setMap(null);
        });
        faultOtherMarkers = [];
        _faultOtherEvents.forEach((e) => e.cancel);
    }

    onShowFaultsReportedNewChanged() {
        this._clearFaultNewMarkers();
        if (this.isShowFaultsReportedNewChecked) {
            this._getFaultsReported("new");
        }
    }

    onShowFaultsReportedProcessedChanged() {
        this._clearFaultOtherMarkers();
        if (this.isShowFaultsReportedProcessedChecked) {
            this._getFaultsReported("other");
        }
    }

    _getFaultsReported(faultStatus) {

        this.set('isLoading', true);
        fetch(`api/faults/status/${faultStatus}`, { credentials: 'include' })
            .then(resp => resp.json())
            .then(json => {
                // console.log(json);
                if (json.length > 0) {
                    this.showToast("Total Faults: " + json.length.toString());
                }

                json.forEach((fault) => {
                    let lat = fault.lat;
                    let lng = fault.lng;

                    let latLng = new google.maps.LatLng(lat, lng);
                    let image;
                    if (fault['faultType'] !== "Lines down" && fault['faultType'] !== "Unsafe Equipment" && fault['faultType']!=='Vehicle or Man vs Asset') {

                        image = IMAGE_ROOT_URL + "/so/REPORTED_PROCESSED.png";
                    } else {
                        image = IMAGE_ROOT_URL + "/so/REPORTED_NEW.png";
                    }

                    let marker = new google.maps.Marker({
                        position: latLng,
                        map: gmap,
                        draggable: true,
                        title: fault.commentReported,
                        icon: image,
                        clickable: true,
                        zIndex: Date.now()
                    });

                    let sub = marker.addListener('click', () => {
                        // this.selectedFaultId = fault.id;
                        this.set('selectedFaultId', fault.id);
                        this.$.faultReportedDetail.open();
                    });

                    if (faultStatus === "new") {
                        faultNewMarkers.push(marker);
                        _faultNewEvents.push(sub);
                    } else {
                        faultOtherMarkers.push(marker);
                        _faultOtherEvents.push(sub);
                    }

                    marker.addListener('dragstart', () => {
                        soMarkers.forEach((m) => {
                            m.addListener('mouseover', () => {
                                let so = m.get("so");
                                so.allocatedFault = fault;
                                console.log(so);
                                this.allocateFaultSo = so;
                                if (so.status != "CLOSED") {
                                    this.$.dlgFaultAllocate.open();
                                }
                            });
                        });

                    });
                    marker.addListener('dragend', () => {
                        setTimeout(() => {
                            // Remove all click listeners from marker instance.
                            //  draggedResourceNo = null;
                            soMarkers.forEach((m) => {
                                google.maps.event.clearListeners(m, 'mouseover');
                            });
                            if (!this.$.dlgFaultAllocate.opened) {
                                this.onFaultsRefresh();
                            }
                        }, 1000);

                    });
                });
                this.set('isLoading', false);
            });

    }

    _getInfoWindowFromFault(fault) {
        let alert = "error";
        if (fault.status == 1) {
            alert = "success";
        }
        let infoTemplate = `
<div id='infoContainer'>
  <h5>No: ${fault.id} -  ${fault.status} - Assigned to: None</h5>
  <div id='infoContent' class='well well-small'>
      <p>Customer:  ${fault.fullName}
      <br/>Phone:  ${fault.phone}
      <br/>${fault.address}

      </p>
  <div class='alert alert-${alert}'>
      <p>Date: ${fault.dateReported}
      <br/>Fault Type: ${fault.faultType}  </p>
      <p>Details: ${fault.commentReported}</p>
  </div>
  </div>
</div>`;
        return infoTemplate;
    }

    isMapSearchViewAllowed(app) {
        return AmiBehaviors.SecurityBehavior.isMapSearchViewAllowed(app.role);
    }

    isDispatchViewAllowed(app) {
        return AmiBehaviors.SecurityBehavior.isDispatchViewAllowed(app.role);
    }

    isOutageViewAllowed(app) {
        return AmiBehaviors.SecurityBehavior.isOutageViewAllowed(app.role);
    }

    isMeterDataViewAllowed(app) {
        return AmiBehaviors.SecurityBehavior.isMeterDataViewAllowed(app.role);
    }
    isVehiclesViewAllowed(app) {
        return AmiBehaviors.SecurityBehavior.isVehicleViewAllowed(app.role);
    }
    isShutdownsViewAllowed(app) {
        return AmiBehaviors.SecurityBehavior.isMapShutdownViewAllowed(app.role);
    }

    isEventViewAllowed(app) {
        return AmiBehaviors.SecurityBehavior.isEventViewAllowed(app.role);

    }
    isRealTimeViewAllowed(app) {
        if (app) {
            return AmiBehaviors.SecurityBehavior.isRealTimeViewAllowed(app.role);
        }
        return false;
    }
    isIcpLocationViewAllowed(app) {
        if (app) {
            return AmiBehaviors.SecurityBehavior.isIcpLocationViewAllowed(app.role);
        }
        return false;

    }

    isCallManagerCreateCallAllowed(app) {
        if (app) {
            return AmiBehaviors.SecurityBehavior.isCallManagerCreateCallAllowed(app.role);
        }
        return false;
    }
}

window.customElements.define('my-view1', MyView1);
