// // import '@polymer/polymer/polymer-legacy.js';
// import '@polymer/iron-overlay-behavior/iron-overlay-behavior.js';
// import { html } from '@polymer/polymer/lib/utils/html-tag.js';
// import { PolymerElement } from '@polymer/polymer/polymer-element.js';
// /**
// * @polymer
// * @extends HTMLElement
// **/
// class CoreOverlay extends PolymerElement {
//   static get template() {
//     return html`
//         <style>
//             :host {
//                 display: block;
//                 height: 100%;
//                 width: 100%;
//             }
//         </style>
//         <slot></slot>
// `;
//   }

//   static get is() { return 'core-overlay'; }

//   static get properties() {
//       return {
//           autoCloseDisabled: {
//               type: Boolean,
//               observer: 'autoCloseDisabledChanged'
//           },
//       }
//   }

//   autoCloseDisabledChanged(newValue) {
//       this.noCancelOnOutsideClick = newValue;
//   }
// }
// customElements.define(CoreOverlay.is, CoreOverlay);


import '@polymer/polymer/polymer-element.js';
import { IronOverlayBehavior } from '@polymer/iron-overlay-behavior/iron-overlay-behavior.js';
import { Polymer } from '@polymer/polymer/lib/legacy/polymer-fn.js';
import { html } from '@polymer/polymer/lib/utils/html-tag.js';
Polymer({
  _template: html`
    <style>
      :host {
        background: white;
        color: black;
        /* padding: 24px; */
        box-shadow: rgba(0, 0, 0, 0.24) -2px 5px 12px 0px, rgba(0, 0, 0, 0.12) 0px 0px 12px 0px;
      }
    </style>
    <slot></slot>
`,

  is: 'core-overlay',
  behaviors: [IronOverlayBehavior],
  properties: {
    autoCloseDisabled: {
      type: Boolean,
      observer: 'autoCloseDisabledChanged'
    }
  },

    autoCloseDisabledChanged: function(newValue) {
      this.noCancelOnOutsideClick = newValue;
  }
});
