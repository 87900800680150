
import '@polymer/polymer/polymer-legacy.js';
import moment from 'moment';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-input/paper-textarea.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-dropdown-menu/paper-dropdown-menu.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import '@polymer/paper-checkbox/paper-checkbox.js';




/**
* @polymer
* @extends HTMLElement
**/
class HubCms extends PolymerElement {

    static get is() { return 'hub-cms'; }

    static get properties() {
        return {
            activated: {
                type: Boolean,
                observer: 'activatedChanged'
            },
            isLoading: {
                type: Boolean,
                value: false
            },
            // url: {
            //     type: String,
            //     value: '/api/managers'
            // },
            userInfo: Object,
            username: String,
            urlAddUser: String,
            data: Array,
            info: Object,
            pInfo: Object,
            isUsernameInvalid: Boolean,
            isUserPasswordInvalid: Boolean,
            isUserFirstnameInvalid: Boolean,
            isUserLastnameInvalid: Boolean,
            searchText: String
        }
    }

    static get template() {
        return html`
          <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>

        <style>
        :host {
            display: block;
            width: 100%;
            height: 100%;
            /*--paper-input-container-input: {
                font-size: 13px;
            }
            ;*/
        }
        
        paper-progress {
            width: 100%;
            --paper-progress-container-color: white;
            --paper-progress-active-color: var(--paper-orange-500);
            --paper-progress-secondary-color: var(--paper-orange-100);
        }
        
    
        
        app-toolbar {
            border-bottom: solid 1px #d3d3d3;
            --app-toolbar-background: transparent;
            --app-toolbar-color: rgb(59, 120, 231);
            font-size: 13px;
        }
        
        app-toolbar .title {
            margin-left: 0px;
            font-size: 20px;
        }
        
        paper-input.search {
            margin-bottom: 20px;
        }
        .content{
            margin:16px;
        }
        paper-card{
            width:100%;
            margin-bottom:8px;
        }
        </style>
        <app-header-layout has-scrolling-region="true">
        <app-header scroll-target="content" slot="header" fixed>
            <app-toolbar >
            <div main-title class="title">Public Hub CMS</div>
                <div class="flex">
                    <!-- <paper-input id="tbxSearch" placeholder="Filter" class="search" value="{{searchText}}">
                        <paper-icon-button slot="prefix" icon="search"></paper-icon-button>
                        <paper-icon-button slot="suffix" icon="communication:clear-all" on-click="onClearSearchText"></paper-icon-button>
                    </paper-input> -->
                </div>
                <paper-button on-click="onRefreshClick">
                    <iron-icon icon="refresh"></iron-icon>
                    Refresh
                </paper-button>
            </app-toolbar>
        </app-header>
          
            <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>
            <div class="content">
                <paper-card>
                    <div class="card-content">
                        <h2>Main Message Details</h2>
                        <p>Edit the Main Message below and save</p>
                        <paper-checkbox checked="{{info.isShowGlobalMessage}}">Show Main Message</paper-checkbox>
                        <paper-textarea maxlength="256" char-counter rows="5" class="custom" no-label-float placeholder="Start typing here"  value="{{info.globalMessage}}" ></paper-textarea>

                    </div>
                </paper-card>

                <paper-card>
                    <div class="card-content">
                        <h2>Planned Outage Message</h2>
                    
                        <paper-checkbox checked="{{info.isShowShutdownMessage}}">Show Outage Message</paper-checkbox>
                        <paper-textarea maxlength="256" char-counter rows="5" class="custom" no-label-float placeholder="Start typing here"  value="{{info.shutdownMessage}}" ></paper-textarea>
                

                    </div>
                    
                </paper-card>

                <paper-card>
                    <div class="card-content">
                        <h2>Current Status</h2>
                    
                        <paper-checkbox checked="{{info.isShowCurrentStatus}}">Show Current Status</paper-checkbox>
                        <paper-textarea maxlength="1000" char-counter rows="10" class="custom" no-label-float placeholder="Start typing here"  value="{{info.currentStatus}}" ></paper-textarea>
                

                    </div>
                    <div class="card-actions right-align">
                        <paper-button class="primary" on-click="onSaveClick" alt="Save" title="Save" raised="">
                                    <iron-icon icon="done"></iron-icon>
                                    Save Details
                        </paper-button>
                    </div>
                </paper-card>

               

                
            </div>
        </app-header-layout>


        

        <iron-ajax id="ajxNewUser" url="/api/hub/cms" handle-as="json" method="POST" content-type="application/json" body="{{info}}" on-response="onRefreshClick" loading="{{isLoading}}"></iron-ajax>
        <iron-localstorage name="username" use-raw="" value="{{username}}"></iron-localstorage>
        <iron-ajax id="ajxUsers" url="/api/hub/cms" handle-as="json" last-response="{{info}}" loading="{{isLoading}}"></iron-ajax>
        `;
      }
      

      ready(){
          super.ready();
           
      }

    activatedChanged(newValue) {
        if (newValue) {
            this.$.ajxUsers.generateRequest();
        }
    }

    onRefreshClick() {
        // this.userInfo = {};
        this.$.ajxUsers.generateRequest();
    }
    onSaveClick(){
        this.$.ajxNewUser.generateRequest();

    }

    computeIsUserInfoInvalid(isUsernameInvalid) {
        if (isUsernameInvalid ) {
            return true;
        } else {
            false;
        }
    }

    computeIsUpdateUserInfoInvalid(isUserPasswordInvalid, isUserFirstnameInvalid, isUserLastnameInvalid) {
        if (isUserPasswordInvalid || isUserFirstnameInvalid || isUserLastnameInvalid) {
            return true;
        } else {
            false;
        }
    }

}
customElements.define(HubCms.is, HubCms);
