
import '@polymer/polymer/polymer-legacy.js';
import moment from 'moment';

import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-input/paper-textarea.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-dialog-scrollable/paper-dialog-scrollable.js';
// import 'paper-date-picker/paper-date-picker.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-item/paper-icon-item.js';
import '@polymer/paper-toast/paper-toast.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
import '@polymer/paper-tooltip/paper-tooltip.js';
import '../app-security-behavior.js';
// import '../libs/jspdf-import.js';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';
import '@vaadin/vaadin-grid';
import '@vaadin/vaadin-grid/vaadin-grid-column';
import '@vaadin/vaadin-grid/vaadin-grid-sort-column';

import { getIconByAssetType } from "../utils/assetIcons.js";


let prevWorkOrders = [];
let sTimer;

class WorkOrderDetail extends mixinBehaviors([AmiBehaviors.SecurityBehavior], PolymerElement) {

    static get is() { return 'wo-detail'; }

    static get properties() {
        return {
            app: Object,
            info: Object,
            woId:{
                type: Number,
                observer: 'woIdChanged'
            },
            etr:{
                type: Number,
                observer: 'etrChanged'
            },
            promoteInfo: Object,

            selected:{
                type: Number,
                value: 0
            },
            changeWoStatusInfo:Object,
            changedResponseDate: {
                type: Date,
                value: new Date(),
                observer: 'changedResponseDateChanged'
            },


            url: {
                type: String
            },
            username: String,
//                        selectedServiceOrder:{
//                            type: Object,
//                            observer:'_selectedServiceOrderChanged'
//                        }
        }
        
    }

    static get template() {
        return html`
          <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>
        <style>
            :host {
                display: block;
                width: 100%;
                height: 100%;
                --paper-input-container-input: {
                    font-size: 13px;
                };
            }
            paper-progress {
                width: 100%;
                --paper-progress-container-color: white;
                --paper-progress-active-color: var(--paper-orange-500);
                --paper-progress-secondary-color: var(--paper-orange-100);

            }
        
            .spacer{
                margin: 10px;
            }

            #dlgResources {
                bottom: 0px;
                width: 400px;
                /*height: 400px;*/
                overflow: auto;
            }
            paper-card {
                width:100%;
            }
            .ping-success-icon{
                color:#66BB6A;
            }
            .ping-error-icon{
                color:#FF7043;
            }
            .info {
            background-color: #FFE082;
            width:100%;
            padding: 10px;
            
            }
            .card-action {
                padding-bottom: 16px;
            }

            #overlaySearchResults {
                position: absolute;
                top: 64px;
                right:20%;
                border-radius: 16px;
                width: 450px;
                height: 630px;
                background-color: #fafafa;
                /*margin-left: 50px;*/
            }
            #overlaySearchResults .cards {
                height:450px;
            }
    
            #overlaySearchResults paper-card {
                width: 100%;
                margin-bottom: 4px;
                
            }
            .avatar {
                margin: 10px;
            }

        </style>

<app-header-layout has-scrolling-region="true">
    <app-header scroll-target="content" slot="header" fixed >
        <app-toolbar class="small" sticky>
        
            <paper-tabs  selected="{{selected}}" class="flex">
                <paper-tab>Work Order</paper-tab>
                <paper-tab>Detail</paper-tab>
                <paper-tab>Incident/ Hazard</paper-tab>
                <paper-tab>Images</paper-tab>
                <paper-tab>History</paper-tab>
                <paper-tab>Pings</paper-tab>
                <paper-tab>Sub WOs</paper-tab>
                <paper-tab>Lone Worker</paper-tab>
                <paper-tab>Timesheets</paper-tab>

            </paper-tabs>
            <paper-icon-button class="bottom " icon="close" on-click="close"></paper-icon-button>
        </app-toolbar>
    </app-header>

    <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>

    <div id="content">
            <iron-pages selected="{{selected}}">
                <section>
                    <div class="horizontal justified layout" style="padding: 0 20px;">
                        <paper-input label="No" value="{{info.No}}" readonly=""></paper-input>
                        <paper-input label="Customer" value="{{info.Name}}" readonly=""></paper-input>
                        <paper-input label="Asset ({{info.AssetType.String}})" value="{{info.Icp}}" readonly=""></paper-input>

                        <paper-input label="Status" value="{{info.Status}}" readonly="">
                            <template is="dom-if" if="[[isWoEditAllowed(app)]]">
                                <paper-icon-button slot="suffix" icon="build" on-click="onSetStatusClick"></paper-icon-button>
                            </template>
                        </paper-input>

                        <div>
                            <!--Flex-->

                        </div>
                        <div>
                            
                            <template is="dom-if" if="[[isWoEditAllowed(app)]]">

                            <paper-button id="btnAssignResource" on-click="onAssignResourceClick" class="call" raised="">
                                <iron-icon icon="account-circle"></iron-icon>
                                Allocate
                            </paper-button>
                            <paper-tooltip for="btnSetEtr">Set ETR</paper-tooltip>

                                <paper-button id="btnSetEtr" on-click="onSetEtr" class="call" raised="">
                                    <iron-icon icon="event"></iron-icon>
                                    Set ETR
                                </paper-button>
                                <paper-tooltip for="btnAssignResource">Allocate Resource to Work Order</paper-tooltip>

                                <paper-button id="btnEdit" on-click="_onEditClick" raised="">
                                    <iron-icon icon="create"></iron-icon>
                                    Edit
                                </paper-button>
                                <paper-tooltip for="btnEdit">Edit Work Order</paper-tooltip>



                            <paper-button id="btnSubOrder" on-click="_onCreateSubOrderClick" raised="">
                                <iron-icon icon="link"></iron-icon>
                                + WO
                            </paper-button>

                            <paper-button id="btnPromote" on-click="_onPromoteOrderClick" raised="">
                                <iron-icon icon="cloud-upload"></iron-icon>
                                Promote to Feeder
                            </paper-button>

                            <paper-tooltip for="btnSubOrder">Create a linked Work Order</paper-tooltip>
                            </template>
                            <template is="dom-if" if="[[isEventViewAllowed(app)]]">
                             <paper-button id="btnShowEvent" on-click="_onShowEventClick" raised="">
                                <iron-icon icon="chrome-reader-mode"></iron-icon>
                                Event
                            </paper-button>
                            <paper-tooltip for="btnShowEvent">Show Event</paper-tooltip>
                            </template>

                            <a href\$="/api/so/pdf/[[info.No]]" target="_blank" tabindex="-1">
                                <paper-button id="btnPdf" raised="">
                                    <iron-icon icon="file-download"></iron-icon>
                                    PDF
                                </paper-button>
                                <paper-tooltip for="btnPdf">Download PDF</paper-tooltip>
                            </a>

                            <!-- <paper-button id="btnPdf" on-click="_onPdfClick"  raised>
                                <iron-icon icon="link"></iron-icon>
                                PDF
                            </paper-button>

                            <paper-tooltip for="btnPdf">Download PDF</paper-tooltip> -->
                            <!--<paper-button on-click="onEmailClick" raised>
                                <iron-icon icon="communication:email"></iron-icon>
                                Email
                            </paper-button>
                            <paper-button on-click="onSmsClick" raised>
                                <iron-icon icon="communication:textsms"></iron-icon>
                                SMS
                            </paper-button>
                            <paper-button on-click="onAddNoteClick" raised>
                                <iron-icon icon="communication:comment"></iron-icon>
                                Note
                            </paper-button>-->
                        </div>
                    </div>
                
                    <div id="idWo" class="horizontal around-justified layout" style="padding: 0 20px">
                        <div class="verticle layout flex ">
                            <!--<paper-input label="No" value="{{info.No}}" readonly></paper-input>-->

                            <paper-input label="Status Text" value="{{info.StatusText.String}}" readonly=""></paper-input>
                            <paper-input label="Service Type" value="{{info.ServiceType}}" readonly=""></paper-input>
                            <paper-input label="Description" value="{{info.Description}}" readonly=""></paper-input>
                            <paper-textarea label="Fault Comment" value="{{info.FaultComments}}" rows="3" readonly=""></paper-textarea>
                            <paper-input label="Parent #" value="{{info.ParentNo}}" readonly=""></paper-input>
                        </div>
                        <div class="spacer"></div>
                        <div class="verticle layout flex ">

                            <paper-input label="Contact" value="{{info.ContactName}}" readonly=""> </paper-input>
                            <paper-input label="Email" value="{{info.Email}}" readonly=""> </paper-input>
                            <div class="horizontal layout flex ">
                                <paper-input label="Phone" value="{{info.PhoneNo}}" readonly=""></paper-input>
                                <paper-input label="Phone 2" value="{{info.PhoneNo2}}" readonly=""></paper-input>
                            </div>
                            <paper-checkbox checked="{{info.IsCovidQuestionsConfirmed}}" style="margin-top: 30px" disabled="">Covid/Infectious disease no interest</paper-checkbox>
                            <paper-checkbox checked="{{info.IsAcceptAllCharges}}" style="margin-top: 30px" disabled="">Accept Charges</paper-checkbox>
                        
                            <paper-input label="Retailer" value="{{info.Trader}}" readonly=""></paper-input>

                           <div>Alert</div>
                            <template is="dom-if" if="{{info.Hazard}}">
                                <iron-icon icon="warning" style="color:#E65100"></iron-icon>
                                <paper-checkbox checked="{{info.Hazard}}" style="color:#E65100" disabled="">Hazard</paper-checkbox>
                                <paper-textarea label="Hazard Info" value="{{info.AlertText}}" rows="3" readonly=""></paper-textarea>
                                <paper-checkbox checked="{{info.DaytimeOnly}}" disabled="">Daytime Only</paper-checkbox>
                                <paper-checkbox checked="{{info.TwoPersonCrew}}" disabled="">2-Person Crew</paper-checkbox>
                                <paper-checkbox checked="{{info.Dogs}}" disabled="">Dogs</paper-checkbox>
                            </template>
                            <template is="dom-if" if="{{info.IsMedicallyDependent}}">
                                <div style="display:inline-block">
                                
                                    <iron-icon icon="maps:local-hospital" style="color:red"></iron-icon>
                                    <paper-tooltip>Medically Dependent</paper-tooltip>
                                    <!-- <paper-icon-button class="red" icon="maps:local-hospital" alt="Medically Dependent" title="Medically Dependent"></paper-icon-button> -->
                                    Medically Dependent 
                                </div>
                                
                            </template>
                            <template is="dom-if" if="{{_showWarning(info)}}">
                            
                                <div style="display:inline-block">
                                    <iron-icon icon="warning" style="color:#E65100"></iron-icon>
                                    <paper-tooltip>VIP or Key Account</paper-tooltip>
                                    VIP or Key Account (<span>{{info.NoOfCustomerVipOrKeyAccount}}</span>)
                                </div>
                            </template>
                                    <!-- <template is="dom-if" if="{{!info.Hazard}}">
                                            <paper-checkbox checked="{{info.Hazard}}" disabled>Hazard</paper-checkbox>
                                    </template> -->
                                    
                        </div>
                        <div class="spacer"></div>
                        <div class="verticle layout flex ">

                            <paper-input label="Equipment #" value="{{info.EquipmentNo}}" readonly=""> </paper-input>
                            <paper-input label="Equipment Desc#" value="{{info.EquipmentDescription}}" readonly=""> </paper-input>
                            <paper-input label="Property" value="{{info.PropertyName}}" readonly=""></paper-input>
                            <div class="horizontal layout flex ">
                                <paper-input label="Unit" value="{{info.Unit}}" readonly=""></paper-input>
                                <paper-input label="Street No" value="{{info.Number}}" readonly=""></paper-input>
                            </div>

                            <paper-input label="Street" value="{{info.Street}}" readonly=""></paper-input>
                            <paper-input label="Suburb" value="{{info.Suburb}}" readonly=""></paper-input>
                            <paper-input label="Town" value="{{info.Town}}" readonly=""></paper-input>
                            
                        </div>
                        <div class="spacer"></div>
                        <div class="verticle layout flex ">
                            <paper-input label="Resource" value="{{info.ResourceName}}" readonly="">
                                <!--<paper-icon-button suffix icon="account-circle" on-click="onSetStatusClick"></paper-icon-button>-->
                            </paper-input>
                            <paper-input label="Priority" value="{{_formatPriority(info.Priority)}}" readonly=""> </paper-input>

                            <paper-textarea label="Public Comment" value="{{info.GeneralComments}}" rows="6" readonly=""></paper-textarea>
                            
                        </div>
                        <div class="spacer"></div>
                        <div class="verticle layout flex ">
                            <paper-input label="Order Date" value="{{formatDateTime(info.OrderDateTime)}}" readonly=""></paper-input>
                            <paper-input label="Response" value="{{formatDateValid(info.ResponseDateTime)}}" readonly="">
                                <paper-icon-button slot="suffix" icon="icons:event" on-click="onShowDateResponseDateDialog"></paper-icon-button>
                            </paper-input>
                            <paper-input label="ETA" value="{{formatDateValid(info.EtadateTime)}}" readonly=""></paper-input>
                            <paper-input label="ETA Countdown" value="{{info.EtaCountdown.String}}" readonly=""></paper-input>
                            <paper-input label="ETR" value="{{formatDateValid(info.EtrdateTime)}}" readonly=""></paper-input>
                            <paper-input label="ETR Countdown" value="{{info.EtaCountdown.String}}" readonly=""></paper-input>
                        
                            <paper-input label="Completed" value="{{formatDateValid(info.FinishingDateTime)}}" readonly=""></paper-input>
                        </div>
                        <div class="spacer"></div>
                        <div class="verticle layout flex ">
                            <paper-input label="Dispatch Due ({{info.targetResolution.dispatchMin}} Minutes)" value="{{formatDispatchTargetDateFromMin(info.OrderDateTime,info.targetResolution.dispatchMin)}}" readonly=""></paper-input>
                            <!--<paper-input label="Dispatch (Mins)" value="{{info.targetResolution.dispatchMin}}" readonly></paper-input>-->
                            <template is="dom-if" if="{{info.IssuedAt.Valid}}">
                                <paper-input label="Dispatched At" value="{{formatDateValid(info.IssuedAt)}}" readonly=""></paper-input>
                                <paper-input label="Dispatched In" value="{{formatDiffCompletedAt(info.targetResolution.issuedDurSecs)}}" readonly=""></paper-input>
                            </template>
                            <!--<template is="dom-if" if="{{!info.IssuedAt.Valid}}">-->
                                <!--<paper-input label="Elapsed" value="{{formatDateFromNow(info.OrderDateTime)}}" readonly></paper-input>-->

                            <!--</template>-->


                            <!--<paper-input label="Elapsed Time" value="{{formatDateFromNow(info.OrderDateTime)}}" readonly></paper-input>-->

                            <paper-input label="Resolution Due ({{info.targetResolution.targetResolutionHours}} Hours)" value="{{formatResolutionTargetDateFromNow(info.OrderDateTime,info.targetResolution.targetResolutionHours)}}" readonly=""></paper-input>
                            <!--<paper-input label="Target Resolution (Hours)" value="{{info.targetResolution.targetResolutionHours}}" readonly></paper-input>-->


                            <template is="dom-if" if="{{checkIsClosed(info.Status)}}">
                                <paper-input label="Completed At" value="{{formatDateValid(info.CompletedAt)}}" readonly=""></paper-input>
                                <paper-input label="Completed In" value="{{formatDiffCompletedAt(info.targetResolution.completedDurSecs)}}" readonly=""></paper-input>
                            </template>
                            <template is="dom-if" if="{{!checkIsClosed(info.Status)}}">
                                <paper-input label="Elapsed Time" value="{{formatElapsedTime(info.OrderDateTime)}}" readonly=""></paper-input>
                                <paper-input label="Time Remaining" value="{{formatResolutionRemainingFromTargetDate(info.OrderDateTime,info.targetResolution.targetResolutionHours)}}" readonly=""></paper-input>
                            </template>

                        </div>
                    </div>
                </section>
                <section>
                    <!--Detail Tab-->
                    <div class="horizontal around-justified layout" style="padding: 0px 20px; margin:10px 0;">
                        <paper-card heading="On-Hold Detail">
                            <div class="card-content">
                                <paper-checkbox checked="{{info.HasSpokenToCustomer}}" disabled="">Spoken to Customer</paper-checkbox>
                                <paper-textarea label="Comment" value="{{info.OnHoldComment}}" rows="2" readonly=""></paper-textarea>
                            </div>
                            
                        </paper-card>
                        
                    </div>
                    <div class="horizontal around-justified layout" style="padding: 0 20px">
                        <div class="verticle layout flex ">
                            <paper-card heading="Closed Detail">
                                <div class="card-content">
                                     <paper-textarea label="Resolution Comment" value="{{info.ResolutionComment.String}}" rows="4" readonly=""></paper-textarea>
                                    <paper-input label="Closed - Charge To" value="{{info.ClosedChargeTo}}" readonly=""></paper-input>
                                    <paper-input label="Closed - Line" value="{{info.ClosedLine}}" readonly=""></paper-input>
                                    <paper-input label="Closed - Repair Type" value="{{info.ClosedRepairType}}" readonly=""></paper-input>
                                    <paper-input label="Closed - Pole No." value="{{info.ClosedPoleNumber}}" readonly=""></paper-input>
                                    <paper-input label="Closed - Asset No." value="{{info.ClosedAssetNumber.String}}" readonly=""></paper-input>
                                    <paper-input label="Closed - Fault Type" value="{{info.ClosedFaultType}}" readonly=""></paper-input>
                                    <paper-input label="Closed - Response Priority" value="{{info.ClosedResponsePriority.String}}" readonly=""></paper-input>
                                    <paper-input label="Closed - Priority Confirmation" value="{{_formatDate(info.ClosedResponsePriorityConfirmationDate.String)}}" readonly=""> </paper-input>
                                   
                                </div>
                                
                            </paper-card>
                            
                        </div>
                        <div class="spacer"></div>
                        <div class="verticle layout flex ">
                            <paper-card heading="Cause Items">
                                <div class="card-content">
                                    <vaadin-grid items="[[info.ServiceOrderClosedCauseItems]]" theme="compact row-stripes">
                                        <vaadin-grid-column header="Code" path="code" >
                                        </vaadin-grid-column>
                                        <vaadin-grid-column header="Category" path="category" >
                                        </vaadin-grid-column>
                                        <vaadin-grid-column header="Description" path="description" >
                                        </vaadin-grid-column>
                                
                                    </vaadin-grid>
                                </div>
                                
                            </paper-card>
                            
                            
                        </div>
                        <div class="spacer"></div>
                        <div class="verticle layout flex ">
                            <paper-card heading="Resolution Items">
                                <div class="card-content">
                            <vaadin-grid items="[[info.ServiceOrderClosedResolutionItems]]" theme="compact row-stripes">
                                <vaadin-grid-column header="Qty" path="qty" >
                                </vaadin-grid-column>
                                <vaadin-grid-column header="Code" path="code" >
                                </vaadin-grid-column>
                                <vaadin-grid-column header="Category" path="category" >
                                </vaadin-grid-column>
                                <vaadin-grid-column header="Description" path="description" >
                                </vaadin-grid-column>
                        
                            </vaadin-grid>
                                </div>
                                
                            </paper-card>
                        </div>
                    </div>

                </section>
                <section>
                    <div class="horizontal around-justified layout" style="padding: 0 20px">
                    <div class="verticle layout flex ">
                            <paper-card heading="Incident Reported">
                                <div class="card-content">
                                    <paper-input label="Category" value="{{info.ServiceOrderIncindentReport.IncidentCategory}}" readonly=""></paper-input>
                                    <paper-input label="Type" value="{{info.ServiceOrderIncindentReport.IncidentType}}" readonly=""></paper-input>
                                    <paper-input label="Other" value="{{info.ServiceOrderIncindentReport.IncidentTypeOther}}" readonly=""></paper-input>
                                    <paper-input label="Name" value="{{info.ServiceOrderIncindentReport.IncidentName}}" readonly=""></paper-input>
                                    <paper-textarea label="Description" value="{{info.ServiceOrderIncindentReport.IncidentDescription}}" rows="2"></paper-textarea>
                                    <paper-input label="Injury" value="{{info.ServiceOrderIncindentReport.IncidentInjury}}" readonly=""></paper-input>
                                    <paper-input label="Treatment" value="{{info.ServiceOrderIncindentReport.IncidentTreatment}}" readonly=""></paper-input>
                                    <paper-input label="Other Treatment" value="{{info.ServiceOrderIncindentReport.IncidentTreatmentOther}}" readonly=""> </paper-input>
                                    <paper-input label="Police Reference" value="{{info.ServiceOrderIncindentReport.IncidentPoliceReference}}" readonly=""> </paper-input>
                                    <paper-input label="Confirmation Date" value="{{_formatDate(info.ServiceOrderIncindentReport.IncidentInjuryConfirmationDate)}}" readonly=""> </paper-input>
                                </div>
                                
                            </paper-card>
                            
                            
                        </div>
                        <div class="spacer"></div>
                        <div class="verticle layout flex ">
                            <paper-card heading="Hazard Reported">
                                <div class="card-content">
                                    <paper-input label="Fault Consequence" value="{{info.ServiceOrderHazardForm.FaultConsequence}}" readonly=""></paper-input>
                                    <paper-input label="Fault Frequency" value="{{info.ServiceOrderHazardForm.FaultFrequency}}" readonly=""></paper-input>
                                    <paper-input label="Fault Likelihood" value="{{info.ServiceOrderHazardForm.FaultLikelihood}}" readonly=""></paper-input>
                                    <paper-input label="Fault Risk" value="{{info.ServiceOrderHazardForm.FaultRisk}}" readonly=""></paper-input>
                                    <paper-input label="Fault Risk Total" value="{{info.ServiceOrderHazardForm.FaultRiskTotal}}" readonly=""></paper-input>
                                    <paper-input label="Owned By" value="{{info.ServiceOrderHazardForm.OwnedBy}}" readonly=""></paper-input>
                                    <paper-textarea label="Fault Description" value="{{info.ServiceOrderHazardForm.FaultDescription}}" rows="2"></paper-textarea>
                                    <paper-textarea label="Fault Notes" value="{{info.ServiceOrderHazardForm.FaultNotes}}" rows="3"></paper-textarea>
                                    
                                    <paper-input label="Posted At" value="{{_formatDate(info.ServiceOrderHazardForm.PostedAt)}}" readonly=""> </paper-input>
                                </div>
                                
                            </paper-card>
                        </div>
                    </div>
                
                </section>
                
                <section>
                    <!--<div>Images List</div>-->
                    <template is="dom-repeat" items="[[info.ServiceOrderImages]]">
                        <iron-image style="width:400px; height:400px; background-color: lightgray;" sizing="cover" preload="" src="[[item.URL]]"></iron-image>
                    </template>
                    
                </section>
                <div>
                    <vaadin-grid items="[[info.ServiceOrderHistory]]" theme="compact row-stripes">
                        <vaadin-grid-column header="Posted" path="PostedAt">
                                <template>
                                    <span>[[_formatDate(item.PostedAt)]]</span>
                            </template>
                        </vaadin-grid-column>
                        <vaadin-grid-column header="Type" path="HistoryType" >
                        </vaadin-grid-column>
                        <vaadin-grid-column header="Subject" path="Subject" >
                        </vaadin-grid-column>
                        <vaadin-grid-column header="Username" path="Username" >
                        </vaadin-grid-column>
                        
                    </vaadin-grid>
                </div>
                <section>
                    <template is="dom-repeat" items="[[info.PingResults]]">
                        <paper-item>
                            <paper-item-body two-line="">
                                <div>[[item.deviceNo]]</div>
                                <div secondary=""> <span>[[item.icp]]</span> - <span>[[formatDateFromNow(item.dateSubmitted)]]</span></div>
                            </paper-item-body>

                            <iron-icon class\$="[[getIconClass(item.pingResponse)]]" icon="[[getIcon(item.pingResponse)]]"></iron-icon>
                        </paper-item>
                    </template>
                </section>
                <div>
                    <vaadin-grid id="shTable" items="[[_getSubWos(info.SubWorkOrders)]]" theme="compact row-stripes" on-active-item-changed="_openWo">
                        <vaadin-grid-column header="No#" path="No" resize-priority="-1" >
                            <template>
                                <paper-button><iron-icon icon="file-upload"></iron-icon>{{item.No}}</paper-button>
                            </template>
                        </vaadin-grid-column>
                        <vaadin-grid-column header="Service Type" path="ServiceType" >
                        </vaadin-grid-column>

                        <vaadin-grid-column header="Resource" path="ResourceName" >
                            <template><span class="sss">{{item.ResourceName}}</span></template>
                        </vaadin-grid-column>
                        <!--<vaadin-grid-column header="Status" path="status" sortable>
                        </vaadin-grid-column>-->
                        <vaadin-grid-column header="Status" path="Status" width="20px" >
                            <template>
                                <paper-button alt="{{item.Status}}" title="{{item.Status}}">
                                    <iron-icon icon="{{_getStatusIcon(item.Status)}}"></iron-icon>

                                </paper-button>
                            </template>
                        </vaadin-grid-column>
                        <vaadin-grid-column header="Description" path="Description" width="200px" >
                        </vaadin-grid-column>

                    </vaadin-grid>
                </div>
                <div>
                    <paper-card>
                        <vaadin-grid items="[[info.LoneWorkerHistory]]" theme="compact row-stripes">
                            <vaadin-grid-column header="Resource" path="resourceName">
                                <template>
                                    [[item.resourceName]]
                                    <iron-icon icon="done" style="color: #00ad00"> </iron-icon>
                                </template>
                            </vaadin-grid-column>
                            <vaadin-grid-column header="Checked At" path="createdAt">
                                <template>
                                    <span>[[formatDateTime(item.createdAt)]]</template>
                                </template>
                            </vaadin-grid-column>
                            <vaadin-grid-column header="Comment" path="comment" >
                            </vaadin-grid-column>
                        </vaadin-grid>
                    </paper-card>
                </div>
                <section>
                 <vaadin-grid id="shTable" items="[[info.Timesheets]]" theme="compact row-stripes">
                        <vaadin-grid-column header="Resource No" path="ResourceNo">
                        </vaadin-grid-column>
                        <vaadin-grid-column header="Start Date" path="StartDate">
                            <template>
                            <span>[[_formatDate(item.StartDate)]]</span>
                            </template>
                        </vaadin-grid-column>
                        <vaadin-grid-column header="Stop Date" path="StopDate">
                            <template>
                            <span>[[_formatDate(item.StopDate)]]</span>
                            </template>
                        </vaadin-grid-column>
                        <vaadin-grid-column header="Time (mins)" path="TotalSeconds">
                            <template>
                                <span>[[_getTimeInMins(item.TotalSeconds)]]</span>
                            </template>
                        </vaadin-grid-column>

                    </vaadin-grid>

                </section>


            </iron-pages>
            </div>
</app-header-layout>

        <paper-dialog id="dlgResources">
            <h3>Allocate Resource</h3>
            <div>
                <paper-input id="tbxSearch" placeholder="Search by Name" class="search" value="{{searchText}}">
                    <paper-icon-button slot="prefix" icon="search"></paper-icon-button>
                    <paper-icon-button slot="suffix" icon="communication:clear-all" on-click="onClearSearchText"></paper-icon-button>
                </paper-input>
            </div>
            <paper-dialog-scrollable>
                <div role="listbox">
                     <template is="dom-repeat" items="[[resources]]" filter="{{computeResourceFilter(searchText)}}">
                        <paper-item data-value="[[item.ResourceNo]]" on-click="onResourceSelected">[[item.ResourceName]]</paper-item>
                    </template>
                </div>
            </paper-dialog-scrollable>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
            </div>
        </paper-dialog>

         <paper-dialog id="dlgChangeStatus">
            <h3>Change Status</h3>
         
                <div role="listbox">
                    <paper-icon-item data-value="SCHEDULED" on-click="onStatusChangeClick">
                        <iron-icon icon="warning" item-icon="">
                        </iron-icon>
                        <paper-item-body two-line="">
                            <div>SCHEDULED</div>
                            <div secondary="">Unallocated to Resource</div>
                        </paper-item-body>
                    </paper-icon-item>
                    <!--<paper-icon-item>
                        <iron-icon icon="watch-later" item-icon>
                        </iron-icon>
                        <paper-item-body two-line>
                            <div>ISSUED</div>
                            <div secondary>Allocated to Resource</div>
                        </paper-item-body>
                    </paper-icon-item>-->
                    <paper-icon-item data-value="ACCEPT" on-click="onStatusChangeClick">
                        <iron-icon icon="assignment-ind" item-icon="">
                        </iron-icon>
                        <paper-item-body two-line="">
                            <div>ACCEPT</div>
                            <div secondary="">Accepted by Resource, on way to site</div>
                        </paper-item-body>
                    </paper-icon-item>
                    <paper-icon-item data-value="ON SITE" on-click="onStatusChangeClick">
                        <iron-icon icon="store" item-icon="">
                        </iron-icon>
                        <paper-item-body two-line="">
                            <div>ON SITE</div>
                            <div secondary="">Onsite - Repairs in Progress</div>
                        </paper-item-body>
                    </paper-icon-item>
                    <paper-icon-item data-value="ON HOLD" on-click="onStatusChangeClick">
                        <iron-icon icon="error-outline" item-icon="">
                        </iron-icon>
                        <paper-item-body two-line="">
                            <div>ON HOLD</div>
                            <div secondary="">On Hold - Repairs on hold</div>
                        </paper-item-body>
                    </paper-icon-item>
                    <paper-icon-item data-value="CLOSED" on-click="onStatusChangeClick">
                        <iron-icon icon="done-all" item-icon="">
                        </iron-icon>
                        <paper-item-body two-line="">
                            <div>CLOSED</div>
                            <div secondary="">Completed All and Close</div>
                        </paper-item-body>
                    </paper-icon-item>
                </div>
           
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgEditWorkOrder" no-cancel-on-outside-click="">
            <!--<h2>Add Work Order</h2>-->
            <paper-dialog-scrollable>
                <div class="horizontal around-justified layout">
                    <div class="verticle layout flex ">
                        <paper-checkbox checked="{{info.IsShowOnPublicSite}}">Show on Public Site</paper-checkbox>
                        <paper-input label="No" value="{{info.No}}" readonly=""></paper-input>
                       
                        <paper-dropdown-menu label="Priority" style="width:100%">
                            <paper-listbox slot="dropdown-content" selected="{{info.Priority}}">
                                <paper-item>Low</paper-item>
                                <paper-item>Medium</paper-item>
                                <paper-item>High</paper-item>
                            </paper-listbox>
                        </paper-dropdown-menu>

                        <paper-dropdown-menu label="Service Type" style="width:100%">
                            <paper-listbox slot="dropdown-content" selected="{{info.ServiceType}}" attr-for-selected="name">
                                    <paper-item name="CONST">Construction</paper-item>
                                <paper-item name="DISCF">Disconnect for operations</paper-item>
                                <paper-item name="FEEDER">Feeder Fault</paper-item>
                                <paper-item name="FIBRE">Fibre Fault</paper-item>
                                <paper-item name="FLICK">Flickering power </paper-item>
                                <paper-item name="MEMO">General request (unspecified)</paper-item>
                                <paper-item name="HIGHLD">High-load</paper-item>
                                <paper-item name="HOT_W">Customer has no hot water</paper-item>
                                <paper-item name="LABEL">Identify and label supply fuses</paper-item>
                                <paper-item name="LINES">Electricity line down</paper-item>
                                <paper-item name="NOPWR">No power to installation</paper-item>
                                <paper-item name="OSH-C">Unsafe customer equipment</paper-item>
                                <paper-item name="OSH-N">Unsafe network equipment</paper-item>
                                <paper-item name="PINFAULT">Pinpower Fault</paper-item>
                                <paper-item name="PPWR">Part power to installation </paper-item>
                                <paper-item name="RECON">Reconnect supply</paper-item>
                                <paper-item name="TREE">Trees in Network lines</paper-item> 
                                <paper-item name="UGCABLE">Underground cable fault</paper-item>
                                <paper-item name="USAFE">Unsafe, immediate action</paper-item>
                                <paper-item name="VMVA">Vehicle or Man vs Asset</paper-item>
                                <paper-item name="VOLTQ">Voltage complaint at site</paper-item>
                            </paper-listbox>
                        </paper-dropdown-menu>

                    </div>
                    <div class="spacer"></div>
                    <div class="verticle layout flex ">
                        <paper-checkbox checked="{{info.IsSendAlertEnabled}}">Send Comms</paper-checkbox>
                        <paper-input label="Asset ({{info.AssetType.String}})" value="{{info.Icp}}" readonly>
                            <paper-icon-button slot="suffix" icon="search" on-click="onSearchAssetClick"></paper-icon-button>
                        </paper-input>
                        <paper-input label="Equipment #" value="{{info.EquipmentNo}}" readonly> </paper-input>
                        <paper-input label="Equipment Desc#" value="{{info.EquipmentDescription}}" readonly> </paper-input>
                    </div>
                </div>
                <paper-input label="Description" value="{{info.Description}}"></paper-input>
                <paper-textarea label="Public Comment" value="{{info.GeneralComments}}" rows="3"></paper-textarea>
                
                <!--<paper-input label="Public Comment" value="{{info.GeneralComments}}"></paper-input>-->
                <paper-textarea label="Fault Comment" value="{{info.FaultComments}}" rows="3"></paper-textarea>
                
                <!-- <paper-checkbox checked="{{info.Hazard}}" >Hazard</paper-checkbox>
                
                <template is="dom-if" if="{{info.Hazard}}">
                        <paper-textarea label="Alert Text" value="{{info.AlertText}}" rows="3" ></paper-textarea>
                        <paper-checkbox checked="{{info.DaytimeOnly}}" >Daytime Only</paper-checkbox>
                        <paper-checkbox checked="{{info.TwoPersonCrew}}" >2-Person Crew</paper-checkbox>
                        <paper-checkbox checked="{{info.Dogs}}" >Dogs</paper-checkbox>

                </template> -->
                
                <div class="horizontal around-justified layout">
                     <div class="verticle layout flex ">
                        <paper-input label="Customer" value="{{info.Name}}"></paper-input>
                        <paper-input label="Contact" value="{{info.ContactName}}"> </paper-input>
                        <paper-input label="Email" value="{{info.Email}}"> </paper-input>
                        <paper-input label="Phone" value="{{info.PhoneNo}}"></paper-input>
                        <paper-input label="Phone 2" value="{{info.PhoneNo2}}"></paper-input>
                        
                    </div>
                    <div class="spacer"></div>
                    <div class="verticle layout flex ">
                        <paper-input label="Property" value="{{info.PropertyName}}"></paper-input>
                        <paper-input label="Unit" value="{{info.Unit}}"></paper-input>
                        <paper-input label="Street No" value="{{info.Number}}"></paper-input>
                        <paper-input label="Street" value="{{info.Street}}"></paper-input>
                        <paper-input label="Suburb" value="{{info.Suburb}}"></paper-input>
                        <paper-input label="Town" value="{{info.Town}}"></paper-input>
                        
                    </div>
                </div>
            </paper-dialog-scrollable>
            
            <div class="buttons">
                <paper-button dialog-dismiss="" on-click="_onCancelEditWorkOrderClick">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="_onEditWorkOrderConfirmClick">Confirm</paper-button>
            </div>

            <paper-dialog id="overlaySearchResults" no-cancel-on-outside-click="">
                    <div class="flex">
                        <paper-input id="tbxSearch" placeholder="Type address or asset" class="search" value="{{searchText}}" on-input="onSearchTextInput" on-keydown="onSearchTextKeyDown">
                            <paper-icon-button slot="prefix" icon="search"></paper-icon-button>
                            <paper-icon-button slot="suffix" icon="communication:clear-all" on-click="onClearSearchText"></paper-icon-button>
                        </paper-input>
                    </div>
                    <paper-dialog-scrollable>
                        <div class="cards">
                            <template is="dom-repeat" id="addressItems" items="[[addressItems]]">
                                
                                    <paper-card>
                                        <div class="card-content search-card">
                                            <div>{{item.name}}</div>
                                            <div class="search-asset-type" secondary="">{{item.assetType}}</div>
                                            <div>{{item.customerName}}</div>
                                            <div><span>{{item.streetAddress}}</span> <span>{{item.town}}</span></div>
                                            <template is="dom-if" if="{{item.isInShutdown}}">
                                                <div class="info"><span>In Current Shutdown:</span> <span>{{item.shutdownId}}</span></div>
                                            </template>
                                        </div>
                                        <div class="card-actions">
                                            <div class="horizontal layout">
                                                <img class="avatar" src="[[iconForAssetType(item.assetType, item.category)]]">
                                                <div class="flex"></div>
                                                <paper-button on-click="onSelectAssetClick" >
                                                    <iron-icon icon="check"></iron-icon>
                                                    Select
                                                </paper-button>
                                                
                                            </div>
                                        </div>
                                    </paper-card>
                            </template>
                        </div>
                    </paper-dialog-scrollable>
                    
                    <div class="buttons">
                        <paper-button dialog-dismiss="">Cancel</paper-button>
                        
                    </div>
                </paper-dialog>
        </paper-dialog>

        <paper-dialog id="dlgSubWorkOrder" no-cancel-on-outside-click="">
            <!--<h2>Add Work Order</h2>-->
            <paper-dialog-scrollable>
                <div class="horizontal around-justified layout">
                    <div class="verticle layout flex ">
                        <paper-checkbox checked="{{subInfo.IsShowOnPublicSite}}">Show on Public Site</paper-checkbox>
                        <paper-input label="Parent No" value="{{subInfo.ParentNo}}" readonly=""></paper-input>

                        <paper-dropdown-menu label="Priority" style="width:100%">
                            <paper-listbox slot="dropdown-content" selected="{{subInfo.Priority}}">
                                <paper-item>Low</paper-item>
                                <paper-item>Medium</paper-item>
                                <paper-item>High</paper-item>
                            </paper-listbox>
                        </paper-dropdown-menu>

                        <paper-dropdown-menu label="Service Type" style="width:100%">
                            <paper-listbox slot="dropdown-content" selected="{{subInfo.ServiceType}}" attr-for-selected="name">
                                <paper-item name="CONST">Construction</paper-item>
                                <paper-item name="DISCF">Disconnect for operations</paper-item>
                                <paper-item name="FEEDER">Feeder Fault</paper-item>
                                <paper-item name="FIBRE">Fibre Fault</paper-item>
                                <paper-item name="FLICK">Flickering power </paper-item>
                                <paper-item name="MEMO">General request (unspecified)</paper-item>
                                <paper-item name="HIGHLD">High-load</paper-item>
                                <paper-item name="HOT_W">Customer has no hot water</paper-item>
                                <paper-item name="LABEL">Identify and label supply fuses</paper-item>
                                <paper-item name="LINES">Electricity line down</paper-item>
                                <paper-item name="NOPWR">No power to installation</paper-item>
                                <paper-item name="OSH-C">Unsafe customer equipment</paper-item>
                                <paper-item name="OSH-N">Unsafe network equipment</paper-item>
                                <paper-item name="PINFAULT">Pinpower Fault</paper-item>
                                <paper-item name="PPWR">Part power to installation </paper-item>
                                <paper-item name="RECON">Reconnect supply</paper-item>
                                <paper-item name="TREE">Trees in Network lines</paper-item>
                                <paper-item name="UGCABLE">Underground cable fault</paper-item>
                                <paper-item name="USAFE">Unsafe, immediate action</paper-item>
                                <paper-item name="VMVA">Vehicle or Man vs Asset</paper-item>
                                <paper-item name="VOLTQ">Voltage complaint at site</paper-item>
                            </paper-listbox>
                        </paper-dropdown-menu>

                    </div>
                    <div class="spacer"></div>
                    <div class="verticle layout flex ">
                        <paper-checkbox checked="{{subInfo.IsSendAlertEnabled}}">Send Comms</paper-checkbox>
                        <paper-input label="Asset ({{subInfo.AssetType.String}})" value="{{subInfo.Icp}}"></paper-input>
                        <paper-input label="Equipment #" value="{{subInfo.EquipmentNo}}"> </paper-input>
                        <paper-input label="Equipment Desc#" value="{{subInfo.EquipmentDescription}}"> </paper-input>
                    </div>
                </div>
                <paper-input label="Description" value="{{subInfo.Description}}"></paper-input>
                <paper-textarea label="Public Comment" value="{{subInfo.GeneralComments}}" rows="3"></paper-textarea>
                
                <!--<paper-input label="Public Comment" value="{{subInfo.GeneralComments}}"></paper-input>-->
                <paper-textarea label="Fault Comment" value="{{subInfo.FaultComments}}" rows="3"></paper-textarea>
                

                <!-- <paper-checkbox checked="{{subInfo.Hazard}}" >Hazard</paper-checkbox>
                <template is="dom-if" if="{{subInfo.Hazard}}">
                        <paper-textarea label="Alert Text" value="{{subInfo.AlertText}}" rows="3" ></paper-textarea>
                        <paper-checkbox checked="{{subInfo.DaytimeOnly}}" >Daytime Only</paper-checkbox>
                        <paper-checkbox checked="{{subInfo.TwoPersonCrew}}" >2-Person Crew</paper-checkbox>
                        <paper-checkbox checked="{{subInfo.Dogs}}" >Dogs</paper-checkbox>

                </template> -->

            
                <div class="horizontal around-justified layout">
                    <div class="verticle layout flex ">
                        <paper-input label="Customer" value="{{subInfo.Name}}"></paper-input>
                        <paper-input label="Contact" value="{{subInfo.ContactName}}"> </paper-input>
                        <paper-input label="Email" value="{{subInfo.Email}}"> </paper-input>
                        <paper-input label="Phone" value="{{subInfo.PhoneNo}}"></paper-input>
                        <paper-input label="Phone 2" value="{{subInfo.PhoneNo2}}"></paper-input>

                    </div>
                    <div class="spacer"></div>
                    <div class="verticle layout flex ">
                        <paper-input label="Property" value="{{subInfo.PropertyName}}"></paper-input>
                        <paper-input label="Unit" value="{{subInfo.Unit}}"></paper-input>
                        <paper-input label="Street No" value="{{subInfo.Number}}"></paper-input>
                        <paper-input label="Street" value="{{subInfo.Street}}"></paper-input>
                        <paper-input label="Suburb" value="{{subInfo.Suburb}}"></paper-input>
                        <paper-input label="Town" value="{{subInfo.Town}}"></paper-input>

                    </div>
                </div>
            </paper-dialog-scrollable>

            <div class="buttons">
                <paper-button dialog-dismiss="" on-click="_onCancelEditWorkOrderClick">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="_onSubWorkOrderConfirmClick">Confirm</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgEtr">
            <h3>Estimated Time of Restoration from now</h3>
        <div><span>ETR: </span>{{etrFormatted}}</div>

        <paper-dialog-scrollable>
        <div role="listbox">
            <paper-dropdown-menu label="Restoration" style="width:100%">
                    <paper-listbox slot="dropdown-content" selected="{{etr}}" attr-for-selected="name" style="height:300px">
                        <paper-item name="15">&lt; 30 Min</paper-item>
                        <paper-item name="30">30 Min</paper-item>
                        <paper-item name="60">1 Hour</paper-item>
                        <paper-item name="120">2 Hours</paper-item>
                        <paper-item name="180">3 Hours</paper-item>
                        <paper-item name="240">4 Hours</paper-item>
                        <paper-item name="300">5 Hours</paper-item>
                        <paper-item name="360">6 Hours</paper-item>
                        <paper-item name="420">7 Hours</paper-item>
                        <paper-item name="480">8 Hours</paper-item>
                        <paper-item name="540">9 Hours</paper-item>
                        <paper-item name="600">10 Hours</paper-item>
                        <paper-item name="660">11 Hours</paper-item>
                        <paper-item name="720">12 Hours</paper-item>
                        <paper-item name="1440">Next Day</paper-item>
                    </paper-listbox>
            </paper-dropdown-menu>
        </div>
        </paper-dialog-scrollable>
            <div class="buttons">

                <paper-button dialog-dismiss="" on-click="_onCancelSetEtrClick">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="_onSetEtrClick">Confirm</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgPromoteWorkOrderConfirm" no-cancel-on-outside-click="">
            <h2>Confirm Promote to Feeder</h2>
            <paper-dialog-scrollable>
                <p>Are you sure you want to promote this Workorder to Feeder Level?</p>
                <p>This will add all ICPs and Transformers for that Feeder and send out initial subscriber notification.</p>

                <template is="dom-if" if="{{isShowFeederSelect}}">
                    <paper-dropdown-menu label="Feeder" style="width:100%;">
                        <paper-listbox slot="dropdown-content" selected="{{info.FeederCode}}" attr-for-selected="name" style="height:300px">
                            <paper-item name="">Select a Feeder</paper-item>
                            <template is="dom-repeat" items="{{feeders}}">
                                <paper-item name="[[item.feederCode]]">[[item.name]]</paper-item>
                            </template>
                        </paper-listbox>
                    </paper-dropdown-menu>
                </template>

                
            </paper-dialog-scrollable>
            
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="_onPromoteOrdeConfirmClick" raised="">Confirm</paper-button>
            </div>
        </paper-dialog>

        <paper-dialog id="dlgChangeStatusClosedConfirm" no-cancel-on-outside-click="">
                <h2>Confirm Close Work Order</h2>
                <paper-dialog-scrollable>
                    <p>Are you sure you want to Close this Work Order?</p>
                    <template is="dom-if" if="{{info.IsSendAlertEnabled}}">
                            <paper-card class="info">CLOSED Message will be sent.</paper-card>
                    </template>
                    <template is="dom-if" if="{{!info.IsSendAlertEnabled}}">
                            <paper-card class="info">No CLOSED message will be sent, Work Order is not enabled for notifications.</paper-card>
                        </template>
                    <p>Select a Repair Type below.</p>
                    <!-- <paper-checkbox checked="{{changeWoStatusInfo.sendNotification}}">Send Notification to Subscribers</paper-checkbox> -->
                    <div role="listbox">
                            <paper-dropdown-menu label="Repair Type" style="width:100%">
                                    <paper-listbox slot="dropdown-content" selected="{{changeWoStatusInfo.repairType}}" attr-for-selected="name">
                                        <paper-item name="NONE"></paper-item>
                                        <paper-item name="NWKPERM">NWK supply permanently restored</paper-item>
                                        <paper-item name="CUSTPERM">CUST supply permanently restored</paper-item>
                                        <paper-item name="ISOLATE">ISOLATED. Supply not restored</paper-item>
                                        <paper-item name="NWKTEMP">NWK supply temporarily restored</paper-item>
                                        <paper-item name="CUSTTEMP">CUST supply temporarily restored</paper-item>
                                        <paper-item name="TELECOM">Telecom equipment</paper-item>
                                        <paper-item name="OTHER">Other</paper-item>
                                    </paper-listbox>
                            </paper-dropdown-menu>
                        </div>
                </paper-dialog-scrollable>
                
                <div class="buttons">
                    <paper-button dialog-dismiss="">Cancel</paper-button>
                    <paper-button dialog-confirm="" autofocus="" on-click="_onCloseWorkorderConfirmClick" raised="">Confirm</paper-button>
                </div>
            </paper-dialog>

            <paper-dialog id="dlgChangeStatusConfirm" no-cancel-on-outside-click="">
                    <h2>Confirm Change Status</h2>
                    <paper-dialog-scrollable>
                        <p>Are you sure you want to change the Status of the Work Order?</p>
                    </paper-dialog-scrollable>
                    
                    <div class="buttons">
                        <paper-button dialog-dismiss="">Cancel</paper-button>
                        <paper-button dialog-confirm="" autofocus="" on-click="_onChangeStatusConfirmClick" raised="">Confirm</paper-button>
                    </div>
                </paper-dialog>

                <paper-dialog id="dlgResponseDate" autoclosedisabled="true">
                    <h2>Confirm Change Response Date</h2>
                <paper-card>
                    <div class="card-content">
                        <!-- Custom Filter-->
                        
                        <div class="horizontal justified layout">
                            <!--<input is="iron-input" bind-value="{{toDate}}" type="date" min="2014-1-1">-->
                            <paper-input label="To (dd.mm.yyyy)" value="{{toDate}}" required="" maxlength="10" minlength="10" pattern="^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[1,3-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))\$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})\$" auto-validate="" error-message="Invalid Date" invalid="{{isToDateInvalid}}"></paper-input>
                            <paper-icon-button icon="icons:event" on-click="onCalToClick"></paper-icon-button>
                            <!--<input is="iron-input" bind-value="{{toTime}}" type="time">-->
                            <paper-input label="HH:MM" value="{{toTime}}" class="flex" required="" maxlength="5" minlength="5" pattern="^([01]?[0-9]|2[0-3]):[0-5][0-9]\$" auto-validate="" error-message="Invalid Time" invalid="{{isToTimeInvalid}}"></paper-input>
                        </div>
                        
                    </div>
                    <div class="card-action">
                        <paper-button id="btnSetResponseDate" class="meter-data-filter" on-click="onSetResponseDateClick" disabled="[[isFilterInvalid(isToDateInvalid,isToTimeInvalid)]]" raised="">Confirm
                        </paper-button>

                    </div>
                </paper-card>

                <paper-dialog id="dlgCalTo" class="paper-date-picker-dialog" on-iron-overlay-closed="dismissDialog">
                    <paper-date-picker id="pickerTo" date="{{changedResponseDate}}" heading-format="D MMM"></paper-date-picker>
                </paper-dialog>
            <paper-dialog>


                
        <iron-ajax id="ajxWO" url="{{url}}" handle-as="json" last-response="{{info}}" loading="{{isLoading}}"></iron-ajax>

        <iron-ajax id="ajxResources" url="/api/resources" handle-as="json" last-response="{{resources}}" loading="{{isLoading}}"></iron-ajax>

            <iron-ajax id="ajxUpdateWo" url="/api/so/{{info.No}}/update/[[app.username]]" method="POST" handle-as="json" content-type="application/json" body="{{info}}" on-response="_onUpdateWoSuccess" on-error="_onUpdateWoError" loading="{{isLoading}}"></iron-ajax>

            <iron-ajax id="ajxUpdateEtr" url="/api/so/{{info.No}}/etr/update/[[app.username]]" method="POST" handle-as="json" content-type="application/json" body="{{updateEtrDto}}" on-response="_onUpdateWoSuccess" on-error="_onUpdateWoError" loading="{{isLoading}}"></iron-ajax>

        <iron-ajax id="ajxSubWoAdd" url="/api/so/{{info.No}}/sub/add/[[app.username]]" method="POST" handle-as="json" content-type="application/json" body="{{subInfo}}" on-response="_onUpdateWoSuccess" on-error="_onUpdateWoError" loading="{{isLoading}}"></iron-ajax>

        <iron-ajax id="ajxPromoteToFeeder" url="/api/so/{{info.No}}/feeder/promote" method="POST" handle-as="json" content-type="application/json" body="{{promoteInfo}}" on-response="_onUpdateWoSuccess" on-error="_onUpdateWoError" loading="{{isLoading}}"></iron-ajax>

           
<iron-localstorage name="username" use-raw="" value="{{username}}"></iron-localstorage>
<iron-localstorage name="cpl-feeders" id="lsFeeders" value="{{feeders}}"></iron-localstorage>
<paper-toast id="toast" text=""></paper-toast>

    </paper-dialog></paper-dialog></template>
        `;
      }
    woIdChanged(newValue){
        // this.info = {
        //     PingResults:[],
        //     ServiceOrderImages:[],
        //     ServiceOrderHistory:[],
        //     SubWorkOrders: [],
        //     ServiceOrderClosedCauseItems : [],
        //     ServiceOrderClosedResolutionItems: []
        // };
        if(newValue){
            this.set('url','/api/so/summary/' + newValue);
            this.$.ajxWO.generateRequest();
        }
    }
    etrChanged(newValue){
        if(newValue){
            this.set('etrFormatted',moment.parseZone(moment()).add(newValue,'minutes').format('DD MMM YYYY HH:mm'));
        }
    }

    changedResponseDateChanged(newValue) {
            this.set('toDate', moment(newValue).format("DD.MM.YYYY"));
            // this.$.dlgCalTo.close();
        }

    formatDateValid(s) {
        
        if(s){
            return s.Valid ? moment.parseZone(s.Time).format('DD MMM YYYY HH:mm'): "Not Set";
        }
        return "";
        
    }
    formatDateTime(s) {
        return s ? moment.parseZone(s).format('DD MMM YYYY HH:mm'): "";
    }
    formatDateFromNow(s) {
        return moment.parseZone(s).fromNow();
    }
    formatResolutionTargetDateFromNow(s, hours) {
        return moment.parseZone(s).add(hours,'hours').format('DD MMM YYYY HH:mm');
    }
    formatDispatchTargetDateFromMin(s, mins) {
        return moment.parseZone(s).add(mins,'minutes').format('DD MMM YYYY HH:mm');
    }

    formatDateInputValid(s) {
        
        return s.Valid ? moment.parseZone(s.Time).format('DD.MM.YYYY'): "Not Set";
    }

    formatTimeInputValid(s) {
        
        return s.Valid ? moment.parseZone(s.Time).format('HH:mm'): "Not Set";
    }

    formatElapsedTime(s) {
        let ms =  moment().diff(moment.parseZone(s));
        let dur = moment.duration(ms,'ms').asSeconds();
        return this.formatDiffCompletedAt(dur);
    }

    formatResolutionRemainingFromTargetDate(s, hours) {
            let d = moment.parseZone(s).add(hours,'hours');
        let ms =  d.diff(moment());
        let dur = moment.duration(ms,'ms').asSeconds();
        return this.formatDiffCompletedAt(dur);
//                     return this.formatElapsedTime(d);
    }

    formatDiffCompletedAt(dur) {
//                    let ms =  moment.parseZone(completedDate.Time).diff(moment.parseZone(orderDate));
//                    let dur = moment.duration(ms,'ms').asSeconds();
//                    return moment.utc(ms).format("HH:mm");

        let days = parseInt((dur / 3600) / 24);
        let hours = parseInt((dur / 3600) % 24);
        let minutes = parseInt((dur / 60) % 60);
        // let seconds = parseInt(dur % 60);
        if (days > 0) {
            return `${days} days, ${hours} hrs and ${minutes} mins`;
        } else {
            return `${hours} hrs and ${minutes} mins`;
        }
//                     return moment.duration(ms,'ms').humanize();
    }

    getIcon(s){
        return s === "ping_success"? "thumb-up": "thumb-down";
    }
    getIconClass(s){
        return s === "ping_success"? "ping-success-icon": "ping-error-icon";
    }
    checkIsClosed(s){
        if (s === 'CLOSED'){
            return true;
        }
        return false;
    }

    close(){
        if( prevWorkOrders.length === 0){
            
            this.dispatchEvent(new CustomEvent('wodetail-close', {bubbles: true,  composed: true}));
        }else{
            this.woIdChanged(prevWorkOrders.pop())
        }
    }

    _getSubWos(subs){
        if (subs){
            return subs;
        }else{
            []
        }
    }

    onAssignResourceClick(e){
        this.$.ajxResources.generateRequest();
        this.$.dlgResources.toggle();
    }

    // _onPdfClick(e){
    //     var doc = new jsPDF()

    //     doc.text(`Work Order Report: ${this.info.No}`, 50, 10);

    //     doc.text("Customer Details",0,20);
    //     doc.text("Customer Details",0,20);
        
    //     doc.save('a4.pdf');
        
    // }

    onSetStatusClick(e){
//                    if (this.info.ResourceNo){
//                        this.$.dlgChangeStatus.toggle();
//                    }else{
//                        this.onAssignResourceClick(null);
//                    }
        this.$.dlgChangeStatus.toggle();
        
    }

    computeResourceFilter(string) {
        if (!string) {
        // set filter to null to disable filtering
        return null;
        } else {
        // return a filter function for the current search string
        string = string.toLowerCase();
        return function(item) {
            var first = item.ResourceName.toLowerCase();
            // var last = employee.lastname.toLowerCase();
            return (first.indexOf(string) != -1 );
        };
        }
    }

    onResourceSelected(e) {
        let model = e.model;
        this.$.dlgResources.close();

            let data = JSON.stringify({
                no: this.info.No,
                resourceNo: model.item.ResourceNo,
                resourceName: model.item.ResourceName,
                username : this.app.username
            });

        fetch(`/api/so/${this.info.No}/allocate`, {
            credentials: 'include',
            method: 'POST',
            body: data
        })
        .then(resp => resp.json())
        .then(() => {
            this.$.ajxWO.generateRequest();
            this.showToast("Resource Allocated");
        })
        .catch((error) => {
            console.log('Request failed', error);
            this.showToast("Failed to Allocate Resource");
        });
        
    }
    showToast(text) {
        this.$.toast.show(text);
    }

    _onCloseWorkorderConfirmClick(){
        if(this.changeWoStatusInfo.repairType == "NONE"){
            this.showToast("Select a Repair Type");
            this.$.dlgChangeStatusClosedConfirm.open();
            return;
        }
        this.postChangeStatus();
    }

    _onChangeStatusConfirmClick(){
        this.postChangeStatus();

    }

    onStatusChangeClick(e) {
        //   let model = e.model;
        let status = e.currentTarget.getAttribute("data-value");

        if (status == this.info.Status){
            this.showToast("Status is unchanged!")
            return;
        }

        this.$.dlgChangeStatus.close();

        this.changeWoStatusInfo = {
            no: this.info.No,
            status: status,
            username : this.app.username,
            repairType: "NONE",
            eventId: this.info.eventId
        };
        if (status == "CLOSED"){
            this.$.dlgChangeStatusClosedConfirm.open();

        }else{
        this.$.dlgChangeStatusConfirm.open();

        }

    //  let data = JSON.stringify({
    //      no: this.info.No,
    //      status: status,
    //      username : this.app.username
    //  });

    // fetch(`/api/so/${this.info.No}/status`, {
    //     credentials: 'include',
    //     method: 'POST',
    //     body: data
    // })
    // .then(resp => resp.json())
    // .then(() => {
    //     this.$.ajxWO.generateRequest();
    //     this.showToast(`Status Changed to ${status}`);
    // })
    // .catch((error) => {
    //     console.log('Request failed', error);
    //     this.showToast("Failed to Change Status");
    // });
    }


    _showWarning(item){

        if (item.NoOfCustomerVipOrKeyAccount>0){
            return true;
        }
        return false;
    }

    postChangeStatus() {

        let data = JSON.stringify(this.changeWoStatusInfo);

        fetch(`/api/so/${this.info.No}/status`, {
            credentials: 'include',
            method: 'POST',
            body: data
        })
        .then(resp => resp.json())
        .then(() => {
            this.$.ajxWO.generateRequest();
            this.showToast(`Status Changed to ${status}`);
        })
        .catch((error) => {
            this.$.ajxWO.generateRequest();
            console.log('Request failed', error);
            this.showToast("Failed to Change Status");
            
        });
    }

    _formatDate(s) {
        if (moment.parseZone(s).get('year') < 1900){
            return "";
        }
        return s ? moment.parseZone(s).format('DD MMM YYYY HH:mm'): "";
    }

    _formatPriority(p){
        let pText = "Low";
        switch (p) {
            case 1:
                pText = "Medium";
                break;
        case 2:
            pText = "High";
            break;
        
            default:
                break;
        }
        return pText;
    }

    _refresh(){
        this.$.ajxWO.generateRequest();

    }

    _onEditClick(){
        this.$.dlgEditWorkOrder.open();
        
    }
    
    _onCancelEditWorkOrderClick(){
        this._refresh();
    }
    _onEditWorkOrderConfirmClick(){
        if (this.info.ServiceType.length < 2) {
            this.showToast("Service Type is required.");
            return;
        }
        this.$.ajxUpdateWo.generateRequest();
    }
    _onUpdateWoSuccess(){
        this.showToast("Work Order Update Successful");
        this._refresh();
    }
    _onUpdateWoError(){
        this.showToast("Failed to Update Work Order");
        this._refresh();
    }

    _onShowEventClick(){
        this.dispatchEvent(new CustomEvent('eventdetail-open', {bubbles: true,  composed: true}));
        
    }

    _onCreateSubOrderClick(){
        this.subInfo = this.info;
        this.set('subInfo.status',"SCHEDULED");
        this.set('subInfo.IsSendAlertEnabled',false);
        this.set('subInfo.IsShowOnPublicSite',false);
        this.set('subInfo.ParentNo',this.info.No);
        this.set('subInfo.ModifiedBy',this.app.username);
        this.$.dlgSubWorkOrder.open();
    }

    _onSubWorkOrderConfirmClick(){   
        if (this.subInfo.ServiceType.length < 2) {
            this.showToast("Service Type is required");
            return
        } 
        this.$.ajxSubWoAdd.generateRequest();
    }

    _onPromoteOrderClick(){
        this.$.lsFeeders.reload();
        this.isShowFeederSelect = this.info.FeederCode.trim().length == 0;
        
        if(this.info.Status != "SCHEDULED"){
            this.showToast("Unable to promote, Work Order is allocated!");
            return;
        }
        
        this.set ('promoteInfo',{
            serviceOrder: this.info,
            username: this.app.username
        })
        this.$.dlgPromoteWorkOrderConfirm.open();
    }
    _onPromoteOrdeConfirmClick(){
        if(this.info.FeederCode.trim().length == 0){
            this.$.dlgPromoteWorkOrderConfirm.open();
            this.showToast("Unable to promote, no Feeder is set on the Work Order!");
            return;
        }
        this.$.ajxPromoteToFeeder.generateRequest();
    }

    onSetEtr(){
        this.etr = null;
        this.etrFormatted = '';
        this.$.dlgEtr.open();
    }
    _onSetEtrClick(){
        
        this.info.EtrdateTime = {Time: moment.parseZone(moment()).add(this.etr,'minutes').format('YYYY-MM-DDTHH:mm:ssZZ'), Valid: true};
        
        this.updateEtrDto = {no:this.info.No, 
            etr: moment.parseZone(moment()).add(this.etr,'minutes').format('YYYY-MM-DD HH:mm'),
            username: this.app.username
        }
        
        // console.log(this.info);
        this.$.ajxUpdateEtr.generateRequest();

    }

    _onCancelSetEtrClick(){
        this.etr = null;
        this.etrFormatted = '';
    }

    _getStatusIcon(v){
        let icon = "warning"
        switch (v){
            case "ACCEPT":
                icon = "assignment-ind";
                break;
            case "ON SITE":
                icon = "store";
                break;
            case "ON HOLD":
                icon = "error-outline";
                break;
            case "CLOSED":
                icon = "done-all";
                break;
            case "DECLINED":
                icon = "block";
                break;
            default:
        }
        return icon;
    }

    _openWo(e){

        const item = e.detail.value;
        this.$.shTable.selectedItems = item ? [item] : [];
        // this.selectedSubscriber = item;
        if(item){
            let wo = item.No;
        // console.debug(wo);
            prevWorkOrders.push(this.info.No);
            this.woIdChanged(wo);
            this.selected = 0;
        }
        
    }

    isWoEditAllowed(app){
        return AmiBehaviors.SecurityBehavior.isDispatchMergeAllowed(app.role);
    }

    isEventViewAllowed(app){
        return AmiBehaviors.SecurityBehavior.isEventViewAllowed(app.role);

    }

    onShowDateResponseDateDialog() {
        this.toDate = this.formatDateInputValid(this.info.ResponseDateTime);
        this.toTime = this.formatTimeInputValid(this.info.ResponseDateTime);
        this.$.dlgResponseDate.open();
    }

    onCalToClick() {
        this.$.dlgCalTo.open();
    }

    isFilterInvalid( toDateInvalid,  toTimeInvalid) {
        if (toDateInvalid ||  toTimeInvalid) {
            return true;
        } else {
            return false;
        }
    }

    onSetResponseDateClick(){
        //Validate
        let dt = moment(this.toDate,"DD-MM-YYYY").format('YYYY-MM-DD')

        let dateTime = moment.parseZone(`${dt} ${this.toTime}`);//.format('YYYY-MM-DDTHH:mm')
        this.postScheduleDate(this.info.No, dateTime)

    }

    postScheduleDate(no, responseDate){
            
        let data = JSON.stringify({
            'no':no,
            'responseDateTime': responseDate,//moment.parseZone(responseDate).format('YYYY-MM-DD HH:mm'),
            'username': this.app.username
        });

        fetch(`/api/so/${no}/schedule/update/${this.app.username}`, {
                credentials: 'include',
                method: 'POST',
                body: data
            })
            .then(resp => resp.json())
            .then(() => {

                // this._getRealTimeEvents(this.transInfo.name);
                // this.$.ajaxMessageLog.generateRequest();
                this.showToast("Scheduled Date Change Successful");
            })
            .catch((error) => {

                this.showToast("Error Submitting New ScheduledDate");
            }).finally(() => {
                this.$.dlgResponseDate.close();
                this._refresh();
            })
    }

    _getTimeInMins(item) {
        return item/60;
    }

    onSearchAssetClick(){
        this.searchText = "";
        this.$.overlaySearchResults.open();
        this.addressItems = this.info.nearbyAssets;
    }

    onSearchTextInput() {
        // this.$.dlgSearch.open();
        if (this.searchText.length > 0) {
            this.isShowSearchResults = true;
        } else {
            this.isShowSearchResults = false;
        }
        if (sTimer !== null) {
            clearTimeout(sTimer);
        }


        this.set('addressItems', []);


        sTimer = setTimeout(() => {
            if (this.searchText.length > 0) {
                // this.addressSearch(this.searchText);
                this.searchAsset();
            }
        }, 1000);
    }

    onSearchTextKeyDown(e) {
        if (e.keyCode === 27) { // 27 is the ESC key
            this.searchText = "";
            this.isShowSearchResults = false;
        }

    }

    onClearSearchText() {
        this.searchText = "";
        this.isShowSearchResults = false;
        this.addressItems = this.info.nearbyAssets;
    }

    iconForAssetType(assetType, category) {
        const img =  getIconByAssetType(assetType, 0, category);
        console.log(img);
        return img;
    }

    searchAsset() {
        if (this.searchText.trim().length > 0) {
            this.set('isLoading', true);
            fetch(`/api/assets/search?q=${encodeURIComponent(this.searchText)}`, { credentials: 'include' })
                .then(resp => resp.json())
                .then(results => {
                    if (results !== null) {
                        this.addressItems = results;
                    }
                    this.set('isLoading', false);
                }).catch(err => {
                    if (err.name === 'AbortError') {
                        console.log('Fetch aborted');
                    } else {
                        console.error('Uh oh, an error!', err);
                    }
                });


        }
    }

    onSelectAssetClick(e){
        console.log(e.model.item);
        const item = e.model.item;
        const assetType = item.assetType.toLowerCase().includes("icp")|| item.assetType.toLowerCase().includes("last_gasp")?"ICP":item.assetType.toUpperCase();
        this.set('info.Icp',item.name);
        this.set('info.TransformerCode',item.transformerCode);
        this.set('info.FeederCode',item.feederCode);
        this.set('info.AssetType',{String:assetType, Valid:true});
        this.set('info.EquipmentNo',item.name);
        this.set('info.EquipmentDescription',assetType);
        this.set('info.CustomerNo',item.customerNo);
        this.set('info.Name',item.customerName);
        // this.set('info.PropertyName',item.streetAddress);
        // this.set('info.AlertText',item.category);
        this.set('info.Lat',item.lat);
        this.set('info.Lng',item.lng);
        this.set('info.ModifiedBy',this.app.username);
        this.$.overlaySearchResults.close();
        
    }
}

customElements.define(WorkOrderDetail.is, WorkOrderDetail);
