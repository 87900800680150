/* <link rel="import" href="../../bower_components/moment-element/moment-with-locales-import.html"> */
/*
  FIXME(polymer-modulizer): the above comments were extracted
  from HTML and may be out of place here. Review them and
  then delete this comment!
*/
import '@polymer/polymer/polymer-legacy.js';
import moment from 'moment';
import '@polymer/iron-list/iron-list.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/iron-pages/iron-pages.js';
import '@polymer/iron-list/iron-list.js';
import '@polymer/paper-input/paper-textarea.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/iron-scroll-threshold/iron-scroll-threshold.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import './core-overlay.js';
import './fault-alert-detail.js';
import '../app-security-behavior.js';
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js';
import { PolymerElement , html } from '@polymer/polymer/polymer-element.js';
import '@polymer/polymer/lib/elements/dom-if.js';
import '@polymer/polymer/lib/elements/dom-repeat.js';

const HISTORY_URL = '/api/fas/past';

class FaultAlertList extends mixinBehaviors([AmiBehaviors.SecurityBehavior], PolymerElement) {

    static get is() { return 'fault-alert-list'; }

    static get properties() {
        return {
            app: Object,
            showList: {
                type: Boolean,
                observer: 'showListChanged'
            },

            selected: {
                type: Number,
                observer: 'selectedTabChanged',
                value: 0
            },

            url: {
                type: String,
                value: '/api/fas/latest'
            },

            isLoading: {
                type: Boolean,
                value: false
            },

            username: {
                type: String
            },

            data: {
                type: Array
            },
            pastData: {
                type: Array,
                value: ()=> [],
                observer: 'pastDataChanged'
            },

            selectedShutdownId: {
                type: Number
            },

            addAlertUrl: {
                type: String
            },
            info: {
                type: Object
            },
            selectedFaultAlert: {
                type: Object,
                notify: true
            }
        }
    }
    ready(){
        super.ready();
        this.$.historyList.scrollTarget = this.$.pshp1.scroller;
        this.$.scrollThreshold.scrollTarget = this.$.pshp1.scroller;
        
    }

    static get template() {
        return html`
          <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>
 
        <style>
            :host {
                display: block;
                width: 100%;
                height: 100%;
                --paper-input-container-input: {
                    font-size: 13px;
                };
            }
            paper-progress {
                width: 100%;
                --paper-progress-container-color: white;
                --paper-progress-active-color: var(--paper-orange-500);
                --paper-progress-secondary-color: var(--paper-orange-100);

            }
            .container{
                padding: 10px;
            }
          

            .cards {
                @apply --layout-vertical;
                @apply --center-justified;
                /*max-width: 400px;*/
                margin-left: auto;
                margin-right: auto;
            }
            paper-card {
                /*width: 100%;*/
                margin: 8px;
            }
            #faultAlertOverlay {
                position: fixed;
                right: 0px;
                top: 64px;
                background: white;
                width: 408px;
                height: 100%;
                overflow: auto;

            }

            paper-dialog.size-position {
                position: fixed;
                top: 16px;
                right: 16px;
                width: 300px;
                height: 220px;
                overflow: auto;
            }

            app-toolbar {
            border-bottom: solid 1px #d3d3d3;
            --app-toolbar-background: transparent;
            --app-toolbar-color: rgb(59, 120, 231);
            font-size: 13px;
        }
        
        app-toolbar .title {
            margin-left: 0px;
            font-size: 20px;
        }


        </style>


    
        <app-header-layout has-scrolling-region="true">
        <app-header scroll-target="content" slot="header" id="pshp1" fixed>
            <app-toolbar sticky class="tall">
                <template is="dom-if" if="{{isEventCreateEditAllowed(app)}}">
                    <paper-button alt="New Event" title="New Event" on-click="onNewAlertClick">
                        <iron-icon icon="note-add"></iron-icon>
                    New Event
                    </paper-button>
                </template>
                <div class="flex"></div>
                <paper-icon-button icon="close" on-click="close" alt="Close" title="Close"></paper-icon-button>
 
            </app-toolbar>
            <app-toolbar>
                <div fixed sticky class="middle title">Events</div>
            </app-toolbar>
            <paper-tabs sticky bottom-item selected="{{selected}}" class="bottom self-end" style="width: 300px;">
                <paper-tab>Current</paper-tab>
                <paper-tab>History</paper-tab>
            </paper-tabs>
        </app-header>
        <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>
            <div class="content" style="padding-right: 0px">

                <iron-pages selected="{{selected}}">
                    <iron-list id="itemList" items="[[data]]" as="item">
                        <template>

                            <div class="cards">
                                <paper-card>
                                    <div class="card-content">
                                        <div class="horizontal layout justified">
                                            <div>
                                                ID: <span>[[item.id]]</span>
                                            </div>
                                            <div>
                                                Date: <span style="font-weight: bold">[[formatDate(item.createdDate)]]</span>
                                            </div>
                                            <div style="padding-left: 20px">
                                                By: <span style="font-weight: bold">[[item.createdBy]]</span>
                                            </div>

                                        </div>

                                        <div class="horizontal layout justified">
                                            <div>
                                                Feeder: <span style="font-weight: bold">[[item.feeder]]</span>
                                            </div>
                                            <div>
                                                Affected#: <span style="font-weight: bold">[[item.affectedCustomers]]</span>
                                            </div>
                                        </div>
                                        <div>
                                            <p>[[item.description]]</p>
                                        </div>

                                    </div>
                                    <div class="card-actions">
                                        <paper-icon-button icon="communication:location-on" title="Show on Map" on-click="_onShutdownGoToLocationClicked"></paper-icon-button>
                                        <paper-icon-button icon="chrome-reader-mode" title="Details" on-click="onShutdownClicked"></paper-icon-button>

                                    </div>
                                </paper-card>
                            </div>
                        </template>
                    </iron-list>

                    <iron-scroll-threshold on-lower-threshold="_loadMoreHistoryData" id="scrollThreshold" lower-threshold="500">
                        <iron-list id="historyList" items="[[pastData]]" as="item">
                            <template>

                                <div class="cards">
                                    <paper-card>
                                        <div class="card-content">
                                            <div class="horizontal layout justified">
                                                <div>
                                                    ID: <span>[[item.id]]</span>
                                                </div>
                                                <div>
                                                    Date: <span style="font-weight: bold">[[formatDate(item.createdDate)]]</span>
                                                </div>
                                                <div style="padding-left: 20px">
                                                    By: <span style="font-weight: bold">[[item.createdBy]]</span>
                                                </div>

                                            </div>

                                            <div class="horizontal layout justified">
                                                <div>
                                                    Feeder: <span style="font-weight: bold">[[item.feeder]]</span>
                                                </div>
                                                <div>
                                                    Affected#: <span style="font-weight: bold">[[item.affectedCustomers]]</span>
                                                </div>
                                            </div>
                                            <div>
                                                <p>[[item.description]]</p>
                                            </div>

                                        </div>
                                        <div class="card-actions">
                                            <paper-icon-button icon="communication:location-on" title="Show on Map" on-click="_onShutdownGoToLocationClicked"></paper-icon-button>
                                            <paper-icon-button icon="chrome-reader-mode" title="Details" on-click="onPastAlertClicked"></paper-icon-button>

                                        </div>
                                    </paper-card>
                                </div>
                            </template>
                        </iron-list>
                        <div class="loader">Fetching new items...</div>
                    </iron-scroll-threshold>
                </iron-pages>


            </div>
        </app-header-layout>

        <paper-dialog id="dlgNewAlert" class="size-position">
            <h2>New Fault Event</h2>
            <paper-textarea label="Description" value="{{info.description}}"></paper-textarea>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onAddNewFaultAlert">Accept</paper-button>
            </div>
        </paper-dialog>

        <!-- Event Notification Overlay-->
        <core-overlay id="faultAlertOverlay" class="right-side-overlay" auto-close-disabled="true" on-alertdetail-close="closeShutdownAlertOverlay">
            <fault-alert-detail shutdown-id="{{selectedShutdownId}}" app="{{app}}" info="{{selectedFaultAlert}}"></fault-alert-detail>

        </core-overlay>




        <iron-ajax id="ajax" url="{{url}}" handle-as="json" last-response="{{data}}" loading="{{isLoading}}"></iron-ajax>


        <iron-ajax id="ajaxAlert" url="{{addAlertUrl}}" handle-as="json" method="POST" content-type="application/json" body="{{info}}" on-response="onNewAlertResponse" loading="{{isLoading}}"></iron-ajax>

        <iron-ajax id="ajaxPast" url="/api/fas/past/0" handle-as="json" on-response="_onHistoryResponse" loading="{{isLoading}}"></iron-ajax>

        <iron-localstorage name="username" use-raw="" value="{{username}}"></iron-localstorage>


        `;
      }

    showListChanged(newValue) {
        if (newValue == true) {
            this.$.ajax.generateRequest();
        }
    }

    selectedTabChanged(newValue) {
        if (newValue == 0) {
            this.$.ajax.generateRequest();
        }
        if (newValue == 1) {
//                        this.$.ajaxPast.generateRequest();
            this.set('pastData',[]);
            this.$.historyList.notifyResize();
            this._loadMoreHistoryData();
        }
    }

    pastDataChanged() {
        this.$.historyList.notifyResize();
    }

    close() {
        this.set('showList', false);
        this.set('data', []);
        
        this.dispatchEvent(new CustomEvent('fault-alert-list-close', {bubbles: true,  composed: true}))
    }

    onNewAlertClick() {
        this.set("info", { description: '' });
        this.$.dlgNewAlert.open();
    }

    onAddNewFaultAlert() {
        this.set('addAlertUrl', '/api/fas/add/' + this.username);
        this.$.ajaxAlert.body = JSON.stringify(this.info);
        this.$.ajaxAlert.generateRequest();
    }

    onNewAlertResponse(e) {
        this.set('selectedShutdownId', e.detail.response);

        this.$.faultAlertOverlay.open();
        this.dispatchEvent(new CustomEvent('fault-alert-open', {bubbles: true,  composed: true}))
        this.set("info", { description: '' });
    }

    formatDate(s) {
        return moment.parseZone(s).format('DD MMM YYYY');//FormatDate(s);
    }

    onShutdownClicked(e) {
        var el = e.target;
        var model = this.$.itemList.modelForElement(el);
        this.set('selectedShutdownId', model.get('item.id'));
        this.$.faultAlertOverlay.open();
        this.dispatchEvent(new CustomEvent('fault-alert-open', {bubbles: true,  composed: true}))

       
        this.dispatchEvent(new CustomEvent('show-on-map', {bubbles: true,  composed: true,
            detail:{
                'item': model.get('item')
            }}));
    }

    onPastAlertClicked(e) {
        var el = e.target;
        var model = this.$.historyList.modelForElement(el);
        this.set('selectedShutdownId', model.get('item.id'));
        this.$.faultAlertOverlay.open();
       
        this.dispatchEvent(new CustomEvent('show-on-map', {bubbles: true,  composed: true,
            detail:{
                'item': model.get('item')
            }}));
    }

    closeShutdownAlertOverlay() {
        this.set('selectedShutdownId', null);
        this.$.faultAlertOverlay.close();
       
        this.dispatchEvent(new CustomEvent('fault-alert-close', {bubbles: true,  composed: true}));
        this.$.ajax.generateRequest();
    }

    _loadMoreHistoryData(){
        this.$.ajaxPast.url = HISTORY_URL + "/" + this.pastData.length;
        this.$.ajaxPast.generateRequest();
    }
    
    _onHistoryResponse(e){
        let results = e.detail.response;

        results.forEach((r)=> {
            this.$.historyList.push('items', r);
            // this.push('pastData', r);
        });
        this.$.scrollThreshold.clearTriggers();
        this.$.historyList.notifyResize();
    }

    _onShutdownGoToLocationClicked(e) {
        var el = e.target;
        var model = this.$.itemList.modelForElement(el);
    
        this.dispatchEvent(new CustomEvent('show-on-map', {bubbles: true,  composed: true,
            detail:{
                'item': model.get('item')
            }}));
    }

    isEventCreateEditAllowed(app){
        return AmiBehaviors.SecurityBehavior.isEventCreateEditAllowed(app.role);

    }
}
customElements.define(FaultAlertList.is, FaultAlertList);
