/* <link rel="import" href="../../../bower_components/moment-element/moment-with-locales-import.html"> */
/*
  FIXME(polymer-modulizer): the above comments were extracted
  from HTML and may be out of place here. Review them and
  then delete this comment!
*/
import '@polymer/polymer/polymer-legacy.js';
import moment from 'moment';
import '@polymer/iron-ajax/iron-ajax.js';
import '@polymer/iron-localstorage/iron-localstorage.js';
import '@polymer/paper-progress/paper-progress.js';
import '@polymer/app-layout/app-header-layout/app-header-layout.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-tabs/paper-tabs.js';
import '@polymer/paper-input/paper-textarea.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-dialog/paper-dialog.js';
import '@polymer/paper-dropdown-menu/paper-dropdown-menu.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/iron-flex-layout/iron-flex-layout-classes.js';
import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';

/**
* @polymer
* @extends HTMLElement
**/
class UserList extends PolymerElement {

    static get is() { return 'user-list'; }

    static get properties() {
        return {
            activated: {
                type: Boolean,
                observer: 'activatedChanged'
            },
            isLoading: {
                type: Boolean,
                value: false
            },
            url: {
                type: String,
                value: '/api/users'
            },
            userInfo: Object,
            username: String,
            urlAddUser: String,
            data: Array,
            info: Object,
            pInfo: Object,
            isUsernameInvalid: Boolean,
            isUserPasswordInvalid: Boolean,
            isUserFirstnameInvalid: Boolean,
            isUserLastnameInvalid: Boolean,
            searchText: String
        }
    }

    static get template() {
        return html`
          <style is="custom-style" include="iron-flex iron-flex-alignment iron-flex-factors"></style>

        <style>
        :host {
            display: block;
            width: 100%;
            height: 100%;
            /*--paper-input-container-input: {
                font-size: 13px;
            }
            ;*/
        }
        
        paper-progress {
            width: 100%;
            --paper-progress-container-color: white;
            --paper-progress-active-color: var(--paper-orange-500);
            --paper-progress-secondary-color: var(--paper-orange-100);
        }
        
    
        
        app-toolbar {
            border-bottom: solid 1px #d3d3d3;
            --app-toolbar-background: transparent;
            --app-toolbar-color: rgb(59, 120, 231);
            font-size: 13px;
        }
        
        app-toolbar .title {
            margin-left: 0px;
            font-size: 20px;
        }
        
        paper-input.search {
            margin-bottom: 20px;
        }
        </style>
        <app-header-layout has-scrolling-region="true">
        <app-header scroll-target="content" slot="header" fixed>
            <app-toolbar >
            <div main-title class="title">Users</div>
                <div class="flex">
                    <paper-input id="tbxSearch" placeholder="Filter" class="search" value="{{searchText}}">
                        <paper-icon-button slot="prefix" icon="search"></paper-icon-button>
                        <paper-icon-button slot="suffix" icon="communication:clear-all" on-click="onClearSearchText"></paper-icon-button>
                    </paper-input>
                </div>
                <paper-button on-click="onAddUserClicked">
                    <iron-icon icon="social:person-add"></iron-icon>
                    Add User
                </paper-button>
            </app-toolbar>
        </app-header>
          
            <paper-progress indeterminate="" disabled="{{!isLoading}}"></paper-progress>
            <div class="content">
                <template is="dom-repeat" items="[[data]]" filter="{{computeFilter(searchText)}}">
                    <paper-card style="width: 100%; margin-right: 20px;">
                        <div class="card-content">
                            <div class="horizontal  layout">
                                <div class="flex-2"><span>[[item.email]]</span>@countiespower.com</div>
                                <div class="flex">[[fullname(item.firstName, item.lastName)]]</div>
                                <div class="flex-2">[[item.role]]</div>
                                <div class="flex-2">[[formatDateTime(item.lastLoginTime)]]</div>
                                <paper-icon-button icon="create" on-click="onUpdateUserClicked" alt="Edit User"></paper-icon-button>
                                <paper-icon-button icon="delete" on-click="onRemoveUserClicked" alt="Remove User"></paper-icon-button>
                            </div>
                        </div>
                    </paper-card>
                </template>
            </div>
        </app-header-layout>


        <iron-ajax id="ajxUsers" url="{{url}}" handle-as="json" last-response="{{data}}" loading="{{isLoading}}"></iron-ajax>
        <!--Alert-->
        <paper-dialog id="dlg" class="size-position" >
            <h2>Create User</h2>
            <!--<paper-input  label="Email" value="{{userInfo.email}}" required auto-validate error-message="Required!"></paper-input>-->
            <paper-input label="User Name" id="inputWithButton" value="{{userInfo.email}}" required="" auto-validate="" error-message="Required!" invalid="{{isUsernameInvalid}}">
                <iron-icon icon="mail" slot="prefix"></iron-icon>
                <div slot="suffix">@countiespower.com</div>
                <!--<paper-icon-button suffix onclick="clearInput" icon="clear" alt="clear" title="clear">-->
                <!-- </paper-icon-button> -->
            </paper-input>
            <paper-input label="Password" value="{{userInfo.password}}" type="password" required="" auto-validate="" error-message="Required!" invalid="{{isUserPasswordInvalid}}"></paper-input>
            <paper-input label="First name" value="{{userInfo.firstName}}" required="" auto-validate="" error-message="Required!" invalid="{{isUserFirstnameInvalid}}"></paper-input>
            <paper-input label="Last name" value="{{userInfo.lastName}}" required="" auto-validate="" error-message="Required!" invalid="{{isUserLastnameInvalid}}"></paper-input>
            <paper-dropdown-menu label="Select a Role" placeholder="User">
                <paper-listbox slot="dropdown-content" selected="{{userInfo.role}}" attr-for-selected="data-value">
                    <paper-item data-value="Administrator">Administrator</paper-item>
                    <paper-item data-value="User">User (removing)</paper-item>
                    <paper-item data-value="ExternalUser">External User (removing)</paper-item>
                    <paper-item data-value="CUSTOMER SERVICE">Customer Service</paper-item>
                    <paper-item data-value="CALL CARE">Call Care</paper-item>
                    <paper-item data-value="CORPORATE">Corporate and SLT</paper-item>
                    <paper-item data-value="FSM">FSM and TL</paper-item>
                    <paper-item data-value="TEAM">Team Members</paper-item>
                    <paper-item data-value="CONTRACTOR">Contractors</paper-item>
                    <paper-item data-value="PLANNING">Planning</paper-item>
                    <paper-item data-value="PERFORMANCE">Performance</paper-item>
                    <paper-item data-value="FINANCE">Finance</paper-item>
                    <paper-item data-value="PEOPLE_SAFETY">People and Safety</paper-item>
                    <paper-item data-value="BILLING">Billing</paper-item>
                    <paper-item data-value="METERING">Metering</paper-item>
                </paper-listbox>
            </paper-dropdown-menu>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onCreateUser" disabled="[[computeIsUserInfoInvalid(isUsernameInvalid,isUserPasswordInvalid,isUserFirstnameInvalid,isUserLastnameInvalid)]]">Create User</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgResetPassword" class="size-position" >
            <h2>Reset Password</h2>
            <!--<paper-input  label="Email" value="{{userInfo.email}}" required auto-validate error-message="Required!"></paper-input>-->
            <paper-input label="User Name" id="inputWithButton" value="{{pInfo.email}}" readonly="">
                <iron-icon icon="mail" slot="prefix"></iron-icon>
                <div slot="suffix">@countiespower.com</div>
                <!--<paper-icon-button suffix onclick="clearInput" icon="clear" alt="clear" title="clear">-->
                
            </paper-input>
            <paper-input label="Password" value="{{pInfo.password}}" type="password" required="" auto-validate="" error-message="Required!" invalid="{{isUserPasswordInvalid}}"></paper-input>
            <paper-input label="First name" value="{{pInfo.firstName}}" required="" auto-validate="" error-message="Required!" invalid="{{isUserFirstnameInvalid}}"></paper-input>
            <paper-input label="Last name" value="{{pInfo.lastName}}" required="" auto-validate="" error-message="Required!" invalid="{{isUserLastnameInvalid}}"></paper-input>
            <paper-dropdown-menu label="Select a Role" placeholder="User">
                <paper-listbox slot="dropdown-content" selected="{{pInfo.role}}" attr-for-selected="data-value">
                    <paper-item data-value="Administrator">Administrator</paper-item>
                    <paper-item data-value="User">User (removing)</paper-item>
                    <paper-item data-value="ExternalUser">External User (removing)</paper-item>
                    <paper-item data-value="CUSTOMER SERVICE">Customer Service</paper-item>
                    <paper-item data-value="CALL CARE">Call Care</paper-item>
                    <paper-item data-value="CORPORATE">Corporate and SLT</paper-item>
                    <paper-item data-value="FSM">FSM and TL</paper-item>
                    <paper-item data-value="TEAM">Team Members</paper-item>
                    <paper-item data-value="CONTRACTOR">Contractors</paper-item>
                    <paper-item data-value="PLANNING">Planning</paper-item>
                    <paper-item data-value="PERFORMANCE">Performance</paper-item>
                    <paper-item data-value="FINANCE">Finance</paper-item>
                    <paper-item data-value="PEOPLE_SAFETY">People and Safety</paper-item>
                    <paper-item data-value="BILLING">Billing</paper-item>
                    <paper-item data-value="METERING">Metering</paper-item>
                </paper-listbox>
            </paper-dropdown-menu>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onUpdateUserConfirmed" disabled="[[computeIsUpdateUserInfoInvalid(isUsernameInvalid,isUserPasswordInvalid,isUserFirstnameInvalid,isUserLastnameInvalid)]]">Update</paper-button>
            </div>
        </paper-dialog>
        <paper-dialog id="dlgRemoveUser" class="size-position" >
            <h2>Are you sure you want to remove the user?</h2>
            <!--<paper-input  label="Email" value="{{userInfo.email}}" required auto-validate error-message="Required!"></paper-input>-->
            <paper-input label="User Name" id="inputWithButton" value="{{pInfo.email}}" readonly="">
                <iron-icon icon="mail" slot="prefix"></iron-icon>
                <div slot="suffix">@countiespower.com</div>
                <!--<paper-icon-button suffix onclick="clearInput" icon="clear" alt="clear" title="clear">-->
                
            </paper-input>
            <div class="buttons">
                <paper-button dialog-dismiss="">Cancel</paper-button>
                <paper-button dialog-confirm="" autofocus="" on-click="onRemoveUserConfirmed">Confirm</paper-button>
            </div>
        </paper-dialog>
        <iron-ajax id="ajxNewUser" url="{{urlAddUser}}" handle-as="json" method="POST" content-type="application/json" body="{{info}}" on-response="onNewUserResponse" loading="{{isLoading}}"></iron-ajax>
        <iron-localstorage name="username" use-raw="" value="{{username}}"></iron-localstorage>

        `;
      }

    activatedChanged(newValue) {
        if (newValue) {
            this.$.ajxUsers.generateRequest();
        }
    }

    onAddUserClicked() {
        this.userInfo = {};
        this.$.dlg.open();
    }

    onCreateUser() {
        this.urlAddUser = `/api/users/add/${this.username}`;
        this.$.ajxNewUser.body = JSON.stringify(this.userInfo);
        this.$.ajxNewUser.generateRequest();
    }

    onNewUserResponse() {
        // this.userInfo = {};
        this.$.ajxUsers.generateRequest();
    }

    fullname(first, last) {
        return `${first} ${last}`;
    }
    computeIsUserInfoInvalid(isUsernameInvalid, isUserPasswordInvalid, isUserFirstnameInvalid, isUserLastnameInvalid) {
        if (isUsernameInvalid || isUserPasswordInvalid || isUserFirstnameInvalid || isUserLastnameInvalid) {
            return true;
        } else {
            false;
        }
    }

    computeIsUpdateUserInfoInvalid(isUserPasswordInvalid, isUserFirstnameInvalid, isUserLastnameInvalid) {
        if (isUserPasswordInvalid || isUserFirstnameInvalid || isUserLastnameInvalid) {
            return true;
        } else {
            false;
        }
    }

    onUpdateUserClicked(e) {
        let model = e.model;
        this.pInfo = {
            id: model.item.id,
            email: model.item.email,
            password: '',
            role: model.item.role,
            firstName: model.item.firstName,
            lastName: model.item.lastName
        };
        this.isUserPasswordInvalid = true;
        this.$.dlgResetPassword.open();
    }

    onUpdateUserConfirmed() {
        this.urlAddUser = `/api/user/edit/password/${this.username}`;
        this.$.ajxNewUser.body = JSON.stringify(this.pInfo);
        this.$.ajxNewUser.generateRequest();
    }

    onRemoveUserClicked(e) {
        let model = e.model;
        this.pInfo = {
            id: model.item.id,
            email: model.item.email,
            password: '',
            role: model.item.role,
            firstName: model.item.firstName,
            lastName: model.item.lastName
        };
        this.$.dlgRemoveUser.open();
    }

    onRemoveUserConfirmed() {
        this.urlAddUser = `/api/user/remove/${this.username}`;
        this.$.ajxNewUser.body = JSON.stringify(this.pInfo);
        this.$.ajxNewUser.generateRequest();
    }

    computeFilter(string) {
        if (!string) {
            // set filter to null to disable filtering
            return null;
        } else {
            // return a filter function for the current search string
            string = string.toLowerCase();
            return function(item) {
                var first = item.firstName.toLowerCase();
                var last = item.lastName.toLowerCase();
                var email = item.email.toLowerCase();
                return (first.indexOf(string) != -1 ||
                    last.indexOf(string) != -1 || email.indexOf(string) != -1) ;
            };
        }
    }

    onClearSearchText(){
        this.searchText = '';
    }

    formatDateTime(s) {
        return s ? moment.parseZone(s).format('DD MMM YYYY HH:mm') : "";
    }
}
customElements.define(UserList.is, UserList);
